import { useEffect } from "react";
import { empleadosApi } from "src/services/empleadosApi";
import { URL_ORIGEN } from "src/utils/urlBack_End";

// Custom Hook para manejar el historial de visitas
export function useHistorialVisita(user) {
  const [crearHistorialPropuesta] =
    empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    // Se asegura no guardar visita en caso de estar en modo desarrollo
    if (URL_ORIGEN && !URL_ORIGEN.includes("http://localhost:3000")) {
      // Realiza la llamada para crear el historial de visita
      crearHistorialPropuesta({
        user: user.rut,
        body: {
          urlAccion: URL_ORIGEN,
        },
      })
        .then(() => {
          console.log("Historial creado con éxito");
        })
        .catch((error) => {
          console.error("Error al crear historial", error);
        });
    }
  }, [user.rut, crearHistorialPropuesta]);
}
