import React, { useState, useCallback } from "react";
import {
  Card,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardContent,
  FormControl,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import { capitalizarPalabras } from "src/utils/sharedFunctions";
import { useSnackbar } from "src/components/snackbar";
import {
  DisabledProvider,
  DisabledWrapper,
} from "../disabled-screen/DisabledContext";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  hidden: {
    display: "none",
  },
});

// ----------------------------------------------------------------------

export default function EmpleadosAsociadosPropuesta({
  propuesta,
  isDisabled = false,
}) {
  const classes = useStyles();
  const { sucursal: sucursalObtenida, rol: rolObtenido } = useSelector(
    (state) => state.auth.user
  );
  const { enqueueSnackbar } = useSnackbar();

  const { data: empleadosAsociados = [] } =
    empleadosApi.useGetEmpleadosPropuestaQuery(propuesta.id);
  const { data: empleados, isLoading: cargandoEmpleados2 } =
    empleadosApi.useGetEmpleadosActivosQuery({
      id: sucursalObtenida.refSucursal,
      estado: "ALL",
    });

  const [eliminarEmpleadoPropuesta] =
    empleadosApi.useDeleteEmpleadosPropuestaMutation();
  const [agregarEmpleadoPropuesta] =
    empleadosApi.useAddEmpleadosPropuestaMutation();

  const [empleadoAdicionalSelect, setEmpleadoAdicionalSelect] = useState(null);

  const handleChangeEmployee = (event, value) => {
    if (value !== null) {
      setEmpleadoAdicionalSelect(value);
    } else {
      setEmpleadoAdicionalSelect(null);
    }
  };

  const handleOptionLabel = (option) => {
    const nombreCompleto = `${option.nombre} ${option.apellidos}`;
    return `${capitalizarPalabras(nombreCompleto)} (${formatRut(
      option.rut,
      RutFormat.DOTS_DASH
    )})`;
  };

  const handleAddNewEmployee = useCallback(async () => {
    if (isDisabled) return;

    if (propuesta.supervisor === empleadoAdicionalSelect.rut) {
      AlertaToast({
        type: "error",
        action: "El empleado a agregar es supervisor",
        fullText: true,
      }).fire();
    } else if (propuesta.refEmpleado === empleadoAdicionalSelect.rut) {
      AlertaToast({
        type: "error",
        action: "El empleado a agregar es el encargado de la tarea",
        fullText: true,
      }).fire();
    } else {
      let existe = empleadosAsociados.filter(
        (e) => e.rut === empleadoAdicionalSelect.rut
      )[0];
      if (existe) {
        AlertaToast({
          type: "error",
          action: "El empleado a agregar ya se encuentra.",
          fullText: true,
        }).fire();
      } else {
        try {
          await agregarEmpleadoPropuesta({
            idTarea: propuesta.id,
            empleadoObtenido: empleadoAdicionalSelect.rut,
          }).unwrap();

          enqueueSnackbar("Empleado adicional agregado exitosamente", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          setEmpleadoAdicionalSelect(null);
        } catch (error) {
          console.error(
            "handleAddNewEmployee (empleadosAsociadosPropuesta)",
            error
          );
          enqueueSnackbar(
            error?.data?.message ??
              "Ocurrió un error desconocido al agregar el empleado adicional",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            }
          );
        }
      }
    }
  }, [
    agregarEmpleadoPropuesta,
    empleadoAdicionalSelect?.rut,
    empleadosAsociados,
    enqueueSnackbar,
    isDisabled,
    propuesta.id,
    propuesta.refEmpleado,
    propuesta.supervisor,
  ]);

  const handleDeleteEmployee = useCallback(
    (id) => {
      if (isDisabled) return;
      AlertaModal({
        title: "eliminar el empleado",
        confirmButtonText: "Sí, eliminar",
      })
        .fire()
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await eliminarEmpleadoPropuesta(id).unwrap();
              enqueueSnackbar("Empleado adicional eliminado exitosamente", {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              });
            } catch (error) {
              console.error(
                "handleDeleteEmployee (EmpleadosAsociadosPropuesta)",
                error
              );
              enqueueSnackbar(
                error?.data?.message ??
                  "Ocurrió un error desconocido al eliminar el empleado adicional",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                }
              );
            }
          }
        });
    },
    [eliminarEmpleadoPropuesta, enqueueSnackbar, isDisabled]
  );

  return (
    <DisabledProvider isDisabled={isDisabled}>
      <DisabledWrapper
        message="Vista de solo lectura"
        overlayColor="rgba(240, 242, 245, 0.45)"
      >
        <Card>
          <CardContent>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <FormControl fullWidth style={{ marginTop: "30px" }}>
                      <Autocomplete
                        fullWidth
                        value={empleadoAdicionalSelect}
                        disableClearable
                        onChange={handleChangeEmployee}
                        options={
                          !cargandoEmpleados2 && empleados ? empleados : []
                        }
                        getOptionLabel={handleOptionLabel}
                        isOptionEqualToValue={(option, value) =>
                          option.rut === value.rut
                        }
                        disabled={cargandoEmpleados2}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Seleccione una tarea"
                            variant="outlined"
                            disabled={cargandoEmpleados2}
                            placeholder="Buscar tarea..."
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {cargandoEmpleados2 ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} md={2} style={{ marginTop: "30px" }}>
                    <Button
                      fullWidth
                      style={{ height: "100%" }}
                      variant="outlined"
                      onClick={handleAddNewEmployee}
                      disabled={cargandoEmpleados2}
                    >
                      <AddIcon /> Agregar
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card style={{ padding: "1rem" }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre Empleado</TableCell>
                      {(rolObtenido === "ADMIN" ||
                        rolObtenido === "SUPER_ADMIN") && (
                        <TableCell></TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {empleadosAsociados &&
                      empleadosAsociados.map((row, idx) => (
                        <TableRow key={"empleadosAsociados_" + idx}>
                          <TableCell component="th" scope="row">
                            {row.nombreEmpleado + " " + row.apellidosEmpleado}
                          </TableCell>
                          {(rolObtenido === "ADMIN" ||
                            rolObtenido === "SUPER_ADMIN") && (
                            <TableCell align="right">
                              <Button
                                color="error"
                                onClick={() => handleDeleteEmployee(row.id)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </CardContent>
        </Card>
      </DisabledWrapper>
    </DisabledProvider>
  );
}
