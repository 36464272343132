import { Autocomplete, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";

export const FormFrecuenciaPlan = (props) => {
  const { formik } = props;
  const { touched, getFieldProps, errors, setFieldValue, values } = formik;

  const [tipoFrecuencia, setTipoFrecuencia] = useState({
    label:
      values.tipoFrecuencia === ""
        ? "Mes"
        : values.tipoFrecuencia === "MES"
        ? "Mes"
        : "Año",
    value: values.tipoFrecuencia === "" ? "MES" : values.tipoFrecuencia,
  });
  const [esAnio, setEsAnio] = useState(
    tipoFrecuencia?.value === "AÑO" ? true : false
  );
  const [mesCargaTareas, setMesCargaTareas] = useState({
    label:
      values.mesCargaTareas === ""
        ? "Febrero"
        : MonthOptions.find((month) => month.value === values.mesCargaTareas)
            .label,
    value:
      values.mesCargaTareas === ""
        ? "02"
        : MonthOptions.find((month) => month.value === values.mesCargaTareas)
            .value,
  });


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={5} md={4}>
        <TextField
          fullWidth
          disabled
          required
          type="Frecuencia"
          label="Frecuencia"
          onChange={formik.handleChange}
          {...getFieldProps("frecuencia")}
          error={Boolean(touched.frecuencia && errors.frecuencia)}
          helperText={touched.frecuencia && errors.frecuencia}
        />
      </Grid>
      <Grid item xs={12} sm={7} md={5}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={options}
          value={tipoFrecuencia}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.label}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setFieldValue("tipoFrecuencia", newValue.value);
              setTipoFrecuencia(newValue);
              if (newValue.value === "AÑO") {
                setEsAnio(true);
                setFieldValue(
                  "mesCargaTareas",
                  mesCargaTareas !== null ? mesCargaTareas.value : "02"
                );
                setFieldValue("mesFacturacion", "03");
              } else {
                setEsAnio(false);
                setFieldValue("mesCargaTareas", "");
                setFieldValue("mesFacturacion", "");
              }
            } else {
              setFieldValue("tipoFrecuencia", "");
              setTipoFrecuencia(null);
              setEsAnio(false);
              setFieldValue("mesCargaTareas", "");
              setFieldValue("mesFacturacion", "");
              setMesCargaTareas({
                label: "Febrero",
                value: "02",
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              label="Tipo Frecuencia"
              {...getFieldProps("tipoFrecuencia")}
              error={Boolean(touched.tipoFrecuencia && errors.tipoFrecuencia)}
              helperText={touched.tipoFrecuencia && errors.tipoFrecuencia}
            />
          )}
        />
      </Grid>

      {esAnio && (
        <Grid item xs={12} sm={12} md={9} style={{ marginTop: 10 }}>
          <Autocomplete
            id="combo-b"
            options={MonthOptions}
            value={mesCargaTareas}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                setFieldValue("mesCargaTareas", newValue.value);
                setMesCargaTareas(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Mes dónde se cargarán las tareas"
                {...getFieldProps("mesCargaTareas")}
                error={Boolean(touched.mesCargaTareas && errors.mesCargaTareas)}
                helperText={touched.mesCargaTareas && errors.mesCargaTareas}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

const options = [
  { label: "Mes", value: "MES" },
  { label: "Año", value: "AÑO" },
];

const MonthOptions = [
  { label: "Enero", value: "01" },
  { label: "Febrero", value: "02" },
  { label: "Marzo", value: "03" },
  { label: "Abril", value: "04" },
  { label: "Mayo", value: "05" },
  { label: "Junio", value: "06" },
  { label: "Julio", value: "07" },
  { label: "Agosto", value: "08" },
  { label: "Septiembre", value: "09" },
  { label: "Octubre", value: "10" },
  { label: "Noviembre", value: "11" },
  { label: "Diciembre", value: "12" },
];
