import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const planesApi = createApi({
  reducerPath: "planesApi",
  baseQuery: baseQuery(`${API_SERVER}/planes`),
  tagTypes: ["planesCliente", "infoPlanCliente"],
  endpoints: (builder) => ({
    getPlan: builder.query({
      query: (id) => `/${id}`,
      providesTags: ["infoPlanCliente"],
    }),
    obtenerPlanesMasCotizados: builder.query({
      query: () => `/planesMasCotizados`,
    }),
    obtenerContribuyentesPlanes: builder.query({
      query: () => `/contribuyentesPlanes`,
    }),
    obtenerCantidadPromedioPlanesCliente: builder.query({
      query: () => `/cantidadPromedioPlanesCliente`,
    }),
    obtenerPlanesCliente: builder.query({
      query: (rutCliente) => `/planesCliente/${rutCliente}`,
    }),
    actualizarDatosPlan: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["infoPlanCliente"],
    }),
    obtenerPlanesAsociadosCliente: builder.query({
      query: (id) => `/relacionPlanCliente/${id}`,
      providesTags: ["planesCliente"],
    }),
    editarPlan: builder.mutation({
      query: (body) => ({
        url: "/relacionPlanCliente",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["planesCliente"],
    }),
    editarFacturacionGestor: builder.mutation({
      query: ({ refPlan, refTipoPlan, ...body }) => ({
        url: `/relacionPlanCliente/${refPlan}/${refTipoPlan}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["planesCliente"],
    }),
    eliminarPlan: builder.mutation({
      query: ({ refPlan, refTipoPlan }) => ({
        url: `/relacionPlanCliente/${refPlan}/${refTipoPlan}`,
        method: "DELETE",
      }),
      invalidatesTags: ["planesCliente"],
    }),
  }),
});

export const {
  useGetPlanQuery,
  useObtenerPlanesClienteQuery,
  useObtenerContribuyentesPlanesQuery,
  useObtenerPlanesMasCotizadosQuery,
  useObtenerCantidadPromedioPlanesClienteQuery,
  useLazyObtenerPlanesClienteQuery,
  useLazyObtenerContribuyentesPlanesQuery,
  useLazyObtenerCantidadPromedioPlanesClienteQuery,
  useLazyObtenerPlanesMasCotizadosQuery,
  useActualizarDatosPlanMutation,
  useObtenerPlanesAsociadosClienteQuery,
  useEditarPlanMutation,
  useEliminarPlanMutation,
  useEditarFacturacionGestorMutation,
  useLazyObtenerPlanesAsociadosClienteQuery,
  useLazyGetPlanQuery,
} = planesApi;
