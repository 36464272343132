import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, TextField } from "@material-ui/core";
import {
  formatRut,
  generateRut,
  RutFormat,
  validateRut,
} from "@fdograph/rut-utilities";

const SolicitanteForm = ({ formik }) => {
  const {
    values,
    touched,
    errors,
    getFieldProps,
    setFieldValue,
    setFieldError,
  } = formik;

  useEffect(() => {
    if (values.sinRutSolicitante) {
      const rutGenerado = generateRut();
      setFieldValue("rutSolicitante", rutGenerado);
    }
  }, [setFieldValue, values.sinRutSolicitante]);

  const handleRutBlur = () => {
    const rawRut = values.rutSolicitante;
    // Valida y formatea el RUT solo al perder el foco
    if (validateRut(rawRut)) {
      const formattedRut = formatRut(rawRut, RutFormat.DOTS_DASH);
      setFieldValue("rutSolicitante", formattedRut);
    } else {
      setFieldError("rutSolicitante", "El RUT no es válido");
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <TextField
          fullWidth
          required
          id="textField-rutSolicitante"
          label="RUT Solicitante"
          value={values.rutSolicitante}
          variant="outlined"
          helperText={touched.rutSolicitante && errors.rutSolicitante}
          error={touched.rutSolicitante && Boolean(errors.rutSolicitante)}
          disabled={values.sinRutSolicitante}
          {...getFieldProps("rutSolicitante")}
          onBlur={handleRutBlur}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <TextField
          fullWidth
          required
          id="textField-nombreSolicitante"
          label="Nombre Solicitante"
          value={values.nombreSolicitante}
          variant="outlined"
          helperText={touched.nombreSolicitante && errors.nombreSolicitante}
          error={touched.nombreSolicitante && Boolean(errors.nombreSolicitante)}
          {...getFieldProps("nombreSolicitante")}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <TextField
          fullWidth
          required
          id="textField-correoSolicitante"
          label="Correo Solicitante"
          value={values.correoSolicitante}
          variant="outlined"
          helperText={touched.correoSolicitante && errors.correoSolicitante}
          error={touched.correoSolicitante && Boolean(errors.correoSolicitante)}
          {...getFieldProps("correoSolicitante")}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <TextField
          fullWidth
          required
          id="textField-telefonoSolicitante"
          label="Teléfono Solicitante"
          value={values.telefonoSolicitante}
          variant="outlined"
          helperText={touched.telefonoSolicitante && errors.telefonoSolicitante}
          error={
            touched.telefonoSolicitante && Boolean(errors.telefonoSolicitante)
          }
          {...getFieldProps("telefonoSolicitante")}
        />
      </Grid>
    </Grid>
  );
};

SolicitanteForm.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default React.memo(SolicitanteForm);
