import React from "react";
import {
  Skeleton,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@material-ui/core";
import Scrollbar from "src/components/Scrollbar";

const ServiciosFormSkeleton = () => {
  return (
    <Box>
      <Scrollbar>
        {[1, 2, 3].map((item) => (
          <Box key={item} mb={2}>
            <Accordion variant="outlined">
              <AccordionSummary>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={3}>
                    <Skeleton variant="text" width="60%" height={30} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" gap={1}>
                      <Skeleton variant="rectangular" width={70} height={25} />
                      <Skeleton variant="rectangular" width={100} height={25} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3} container justifyContent="flex-end">
                    <Skeleton variant="circular" width={40} height={40} />
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Skeleton variant="text" width="50%" height={30} />
                    <Skeleton variant="text" width="100%" height={25} />
                    <Skeleton variant="text" width="100%" height={25} />
                    <Skeleton variant="text" width="100%" height={25} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Skeleton variant="text" width="50%" height={30} />
                    <Skeleton variant="text" width="100%" height={25} />
                    <Skeleton variant="text" width="100%" height={25} />
                    <Skeleton variant="text" width="100%" height={25} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant="text" width="50%" height={30} />
                    <Skeleton variant="text" width="100%" height={50} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Scrollbar>
    </Box>
  );
};

export default ServiciosFormSkeleton;
