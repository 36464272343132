import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistorialVisita } from "src/hooks/useHistorialVisita";
import { propuestasApi } from "src/services/propuestasApi";

export default function usePropuestas(tipoPropuesta) {
  const {
    empleado: user,
    sucursal: sucursalObtenida,
    rol: rolObtenido,
  } = useSelector((state) => state.auth.user);

  const isSucursalRole = ["SUPER_ADMIN", "ADMIN", "SUPERVISOR"].includes(
    rolObtenido
  );

  useHistorialVisita(user);

  const [getPropuestas, { data, isLoading, error }] = isSucursalRole
    ? propuestasApi.useLazyGetPropuestasSucursalesQuery()
    : propuestasApi.useLazyGetPropuestasEmpleadoQuery();

  const params = useMemo(() => {
    return isSucursalRole
      ? {
          sucursal: sucursalObtenida.refSucursal,
          tipoPropuesta,
        }
      : {
          usuario: user.rut,
          id: sucursalObtenida.refSucursal,
          tipo: tipoPropuesta,
        };
  }, [isSucursalRole, sucursalObtenida.refSucursal, tipoPropuesta, user.rut]);

  const fetchPropuestas = useCallback(() => {
    getPropuestas(params);
  }, [getPropuestas, params]);

  useEffect(() => {
    fetchPropuestas();
  }, [fetchPropuestas]);

  return {
    propuestas: data || [],
    isLoading,
    error,
    refetch: fetchPropuestas,
  };
}
