import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Button,
  Paper,
  DialogActions,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useState, useEffect, useMemo } from "react";
import GraficoComisionesEmpleado2 from "src/components/graficos/GraficoComisionesEmpleado2";
import {
  capitalizarPalabras,
  capitalizarPrimeraLetra,
  obtenerNombreCorto,
} from "src/utils/sharedFunctions";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  infoGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  infoItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    "& > span:first-child": {
      fontWeight: 500,
    },
  },
  comisionField: {
    marginBottom: theme.spacing(2),
  },
  comisionInput: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gap: theme.spacing(2),
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  montoText: {
    color: theme.palette.success.main,
    fontWeight: 500,
    textAlign: "right",
  },
  utilidadBox: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  utilidadGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  utilidadItem: {
    padding: theme.spacing(1),
    textAlign: "center",
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  },
  utilidadPositiva: {
    color: theme.palette.success.main,
    fontWeight: "bold",
  },
  utilidadNegativa: {
    color: theme.palette.error.main,
    fontWeight: "bold",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  graphContainer: {
    height: "100%",
    minHeight: 400,
    display: "flex",
    flexDirection: "column",
  },
}));

export default function ModalParticipaciones({
  open,
  setOpen,
  maxWidth = "md",
  fullWidth = true,
  setDataCommissions,
  propuesta,
  editar = false,
  comisionesIniciales = null,
  setOpenModalAcceptation,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [comisiones, setComisiones] = useState({
    vendedor: propuesta.vendidoTaxtic ? 0 : "",
    empleado: "",
    supervisor: "",
    taxtic: 15, // Valor fijo
  });

  const total = useMemo(() => {
    return Object.values(comisiones).reduce(
      (acc, val) => acc + (Number(val) || 0),
      0
    );
  }, [comisiones]);

  useEffect(() => {
    if (editar && comisionesIniciales) {
      setComisiones(comisionesIniciales);
    }
  }, [editar, comisionesIniciales]);

  useEffect(() => {
    const newErrors = {};

    if (Number(comisiones.vendedor) > 15) {
      newErrors.vendedor = "La comisión del vendedor no debe superar el 15%";
    } else {
      delete newErrors.vendedor;
    }

    Object.entries(comisiones).forEach(([key, value]) => {
      if (key !== "taxtic") {
        if (propuesta.vendidoTaxtic && key === "vendedor") {
          return;
        }
        if (!value && value !== 0) {
          newErrors[key] = `El campo ${key} es requerido`;
        } else if (isNaN(value) || value < 0) {
          newErrors[key] = "Ingrese un número válido mayor o igual a 0";
        }
      }
    });

    setErrors(newErrors);
  }, [comisiones.vendedor, comisiones, propuesta.vendidoTaxtic]);

  const calcularMonto = (porcentaje) => {
    const monto = (porcentaje / 100) * propuesta.precioFinal;
    return monto.toFixed(2);
  };

  const calcularUtilidad = useMemo(() => {
    const totalComisiones = Object.entries(comisiones).reduce(
      (acc, [key, value]) => {
        const porcentaje = Number(value) || 0;
        const monto = (porcentaje / 100) * propuesta.precioFinal;
        return acc + monto;
      },
      0
    );

    const utilidad = propuesta.precioFinal - totalComisiones;
    const porcentajeUtilidad = (utilidad / propuesta.precioFinal) * 100;

    return {
      montoTotal: totalComisiones.toFixed(2),
      utilidad: utilidad.toFixed(2),
      porcentajeUtilidad: porcentajeUtilidad.toFixed(1),
    };
  }, [comisiones, propuesta.precioFinal]);

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    setComisiones((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = () => {
    if (Object.keys(errors).length === 0) {
      const comisionesConMontos = {
        ...comisiones,
        montos: {
          vendedor: calcularMonto(comisiones.vendedor),
          empleado: calcularMonto(comisiones.empleado),
          supervisor: calcularMonto(comisiones.supervisor),
          taxtic: calcularMonto(comisiones.taxtic),
        },
      };

      handleSaveComisiones(comisionesConMontos);
    }
  };

  const handleSaveComisiones = (comisiones) => {
    // Aquí manejas el guardado de las comisiones
    const comisionesFormateadas = {
      empleado: {
        valor: Number(comisiones.montos.empleado),
        porcentaje: Number(comisiones.empleado),
      },
      supervisor: {
        valor: Number(comisiones.montos.supervisor),
        porcentaje: Number(comisiones.supervisor),
      },
      taxtic: {
        valor: Number(comisiones.montos.taxtic),
        porcentaje: Number(comisiones.taxtic),
      },
      vendedor: {
        valor: Number(comisiones.montos.vendedor),
        porcentaje: Number(comisiones.vendedor),
      },
      utilidad: {
        valor: Number(calcularUtilidad.utilidad),
        porcentaje: Number(calcularUtilidad.porcentajeUtilidad),
      },
      tipoMoneda: propuesta.tipoMoneda,
    };

    setDataCommissions(comisionesFormateadas);
    setComisiones({
      vendedor: "",
      empleado: "",
      supervisor: "",
      taxtic: 15,
    });
    setOpenModalAcceptation(true);
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      onClose={handleClose}
      fullWidth={fullWidth}
      disableEscapeKeyDown
    >
      <DialogTitle className={classes.dialogTitle}>
        {editar ? "Editar Comisiones" : "Asignación de Comisiones"}
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Paper className={classes.section}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Información de la Tarea
          </Typography>
          <div className={classes.infoGrid}>
            <div className={classes.infoItem}>
              <span>Tarea:</span>
              <span>{capitalizarPrimeraLetra(propuesta.nombreTarea)}</span>
            </div>
            <div className={classes.infoItem}>
              <span>Valor:</span>
              <span>
                {propuesta.precioFinal} {propuesta.tipoMoneda}
              </span>
            </div>
            <div className={classes.infoItem}>
              <span>Cliente:</span>
              <span>{capitalizarPalabras(propuesta.nombreCliente)}</span>
            </div>
            <div className={classes.infoItem}>
              <span>RUT Cliente:</span>
              <span>
                {formatRut(propuesta.rutCliente, RutFormat.DOTS_DASH)}
              </span>
            </div>
          </div>
        </Paper>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.section}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Distribución de Comisiones
              </Typography>

              {!propuesta.vendidoTaxtic && (
                <div className={classes.comisionField}>
                  <div className={classes.comisionInput}>
                    <TextField
                      label="Comisión Vendedor (%)"
                      value={comisiones.vendedor}
                      onChange={handleInputChange("vendedor")}
                      fullWidth
                      type="number"
                      error={!!errors.vendedor}
                      helperText={errors.vendedor}
                      onWheel={(e) => e.target.blur()}
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                      }}
                    />
                    <Typography variant="body2" className={classes.montoText}>
                      {calcularMonto(comisiones.vendedor)}{" "}
                      {propuesta.tipoMoneda}
                    </Typography>
                  </div>
                </div>
              )}

              <div className={classes.comisionField}>
                <div className={classes.comisionInput}>
                  <TextField
                    label="Comisión Empleado (%)"
                    value={comisiones.empleado}
                    onChange={handleInputChange("empleado")}
                    fullWidth
                    type="number"
                    error={!!errors.empleado}
                    helperText={errors.empleado}
                    onWheel={(e) => e.target.blur()}
                    InputProps={{
                      inputProps: { min: 0, max: 100 },
                    }}
                  />
                  <Typography variant="body2" className={classes.montoText}>
                    {calcularMonto(comisiones.empleado)} {propuesta.tipoMoneda}
                  </Typography>
                </div>
              </div>

              <div className={classes.comisionField}>
                <div className={classes.comisionInput}>
                  <TextField
                    label="Comisión Supervisor (%)"
                    value={comisiones.supervisor}
                    onChange={handleInputChange("supervisor")}
                    fullWidth
                    type="number"
                    error={!!errors.supervisor}
                    helperText={errors.supervisor}
                    onWheel={(e) => e.target.blur()}
                    InputProps={{
                      inputProps: { min: 0, max: 100 },
                    }}
                  />
                  <Typography variant="body2" className={classes.montoText}>
                    {calcularMonto(comisiones.supervisor)}{" "}
                    {propuesta.tipoMoneda}
                  </Typography>
                </div>
              </div>

              <div className={classes.comisionField}>
                <div className={classes.comisionInput}>
                  <TextField
                    label="Comisión Taxtic (%)"
                    value={comisiones.taxtic}
                    fullWidth
                    disabled
                    className={classes.readOnlyField}
                  />
                  <Typography variant="body2" className={classes.montoText}>
                    {calcularMonto(comisiones.taxtic)} {propuesta.tipoMoneda}
                  </Typography>
                </div>
              </div>

              <Typography
                variant="subtitle1"
                style={{
                  marginTop: theme.spacing(2),
                  fontWeight: "bold",
                  color:
                    total > 100
                      ? theme.palette.error.main
                      : theme.palette.text.primary,
                }}
              >
                Total asignado: {total}%
              </Typography>

              <Paper className={classes.utilidadBox}>
                <Typography variant="h6" gutterBottom>
                  Resumen Financiero
                </Typography>
                <div className={classes.utilidadGrid}>
                  <div className={classes.utilidadItem}>
                    <Typography variant="body2">Total Comisiones</Typography>
                    <Typography variant="h6">
                      {calcularUtilidad.montoTotal} {propuesta.tipoMoneda}
                    </Typography>
                  </div>
                  <div className={classes.utilidadItem}>
                    <Typography variant="body2">Utilidad</Typography>
                    <Typography
                      variant="h6"
                      className={
                        calcularUtilidad.utilidad > 0
                          ? classes.utilidadPositiva
                          : classes.utilidadNegativa
                      }
                    >
                      {calcularUtilidad.utilidad} {propuesta.tipoMoneda}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={
                        calcularUtilidad.utilidad > 0
                          ? classes.utilidadPositiva
                          : classes.utilidadNegativa
                      }
                    >
                      ({calcularUtilidad.porcentajeUtilidad}%)
                    </Typography>
                  </div>
                </div>
              </Paper>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper className={`${classes.section} ${classes.graphContainer}`}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Visualización de Comisiones
              </Typography>
              <GraficoComisionesEmpleado2
                title="Distribución de Comisiones"
                chart={{
                  colors: [
                    theme.palette.primary.main,
                    theme.palette.info.main,
                    theme.palette.error.main,
                    theme.palette.warning.main,
                  ],
                  series: [
                    ...(!propuesta.vendidoTaxtic
                      ? [
                          {
                            label: `Vendedor (${obtenerNombreCorto(
                              propuesta.nombreVendedor
                            )})`,
                            value:
                              Number(calcularMonto(comisiones.vendedor)) || 0,
                          },
                        ]
                      : []),
                    {
                      label: `Empleado (${obtenerNombreCorto(
                        propuesta.nombreEmpleado
                      )})`,
                      value: Number(calcularMonto(comisiones.empleado)) || 0,
                    },
                    {
                      label: `Supervisor (${obtenerNombreCorto(
                        propuesta.nombreSupervisor
                      )})`,
                      value: Number(calcularMonto(comisiones.supervisor)) || 0,
                    },
                    {
                      label: "Taxtic",
                      value: Number(calcularMonto(comisiones.taxtic)),
                    },
                  ],
                  typeMoney: propuesta.tipoMoneda,
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          {editar ? "Actualizar" : "Guardar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
