import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { formatRut, RutFormat, validateRut } from "@fdograph/rut-utilities";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
} from "@material-ui/core";
import SimpleModal from "../../../layouts/SimpleModal";
import { toPascalCase } from "../../../utils/converter";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  formLabel: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  radioGroup: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
    marginTop: theme.spacing(1),
  },
}));

function TaskStateUpdateModal({
  task,
  onSubmit,
  onClose,
  states,
  open,
  cargandoDatos,
  tipoEstado,
}) {
  const classes = useStyles();

  // Usar useMemo para optimizar el cálculo del índice inicial
  const initialStateIndex = useMemo(
    () => states.indexOf(task.estado),
    [states, task.estado]
  );

  // Estado con validaciones
  const [state, setState] = useState({
    selectedIndex: `${initialStateIndex}`,
    estadoActual: task.estado,
    rutReal: "",
    rutError: "",
  });

  // Añadir useEffect para actualizar el estado cuando cambia la tarea
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selectedIndex: `${initialStateIndex}`,
      estadoActual: task.estado,
    }));
  }, [task, initialStateIndex]);

  // Manejador de cambios con validaciones
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "rutReal") {
      // Formatear y validar RUT usando la librería
      setState((prevState) => ({
        ...prevState,
        rutReal: value,
        rutError: value && !validateRut(value) ? "RUT inválido" : "",
      }));
    } else if (name === "selectedIndex") {
      setState((prevState) => ({
        ...prevState,
        selectedIndex: value,
        estadoActual: states[value],
      }));
    }
  };

  // Calcular condicionalmente la visibilidad del campo de RUT
  const shouldShowRutField = useMemo(
    () =>
      tipoEstado === "PROPUESTA" &&
      state.estadoActual === "ACEPTADA" &&
      task?.rutAuxiliar === "1",
    [tipoEstado, state.estadoActual, task.rutAuxiliar]
  );

  // Manejar el submit con validaciones
  const handleSubmit = () => {
    // Validar RUT si es necesario
    if (shouldShowRutField) {
      if (!state.rutReal) {
        setState((prevState) => ({
          ...prevState,
          rutError: "RUT es requerido",
        }));
        return;
      }

      if (!validateRut(state.rutReal)) {
        setState((prevState) => ({
          ...prevState,
          rutError: "RUT inválido",
        }));
        return;
      }
    }

    // Llamar al submit con los datos
    onSubmit(states[state.selectedIndex], state.rutReal);
  };

  const handleBlurRut = (event) => {
    const { value } = event.target;

    const formattedRut = formatRut(value, RutFormat.DOTS_DASH);
    setState((prevState) => ({
      ...prevState,
      rutReal: formattedRut,
    }));
  };

  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title="Cambiar Estado"
      cargandoDatos={cargandoDatos}
    >
      <FormControl
        component="fieldset"
        className={classes.formControl}
        fullWidth
      >
        <FormLabel component="legend" className={classes.formLabel}>
          Estado
        </FormLabel>
        <RadioGroup
          aria-label="estado"
          name="selectedIndex"
          className={classes.radioGroup}
          value={state.selectedIndex}
          onChange={handleChange}
        >
          {states.map((state, idx) => (
            <FormControlLabel
              key={idx}
              control={<Radio color="primary" />}
              label={toPascalCase(state)}
              value={`${idx}`}
            />
          ))}
        </RadioGroup>
      </FormControl>

      {shouldShowRutField && (
        <>
          <TextField
            required
            fullWidth
            name="rutReal"
            className={classes.textField}
            value={state.rutReal}
            label="Rut Empresa Real"
            variant="outlined"
            onChange={handleChange}
            onBlur={handleBlurRut}
            error={!!state.rutError}
            helperText={state.rutError}
          />
        </>
      )}
    </SimpleModal>
  );
}

// Definición de PropTypes para validación de props
TaskStateUpdateModal.propTypes = {
  task: PropTypes.shape({
    estado: PropTypes.string.isRequired,
    rutAuxiliar: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  states: PropTypes.arrayOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  cargandoDatos: PropTypes.bool,
  tipoEstado: PropTypes.string,
};

// Valores por defecto para props opcionales
TaskStateUpdateModal.defaultProps = {
  cargandoDatos: false,
  tipoEstado: "",
};

export default TaskStateUpdateModal;
