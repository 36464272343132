import React, { useState } from "react";
import * as XLSX from "xlsx";

import { toTitleCase } from "src/utils/converter";
import { Icon } from "@iconify/react";
import documentExport from "@iconify/icons-carbon/document-export";
import { LoadingButton } from "@material-ui/lab";
export default function ExportToExcel(props) {
  const {
    data,
    filename,
    sheetName,
    setShowAlert,
    setColor,
    setMessage,
    type,
    ...rest
  } = props;

  const [loading, setLoading] = useState(false);

  const generarInfo = (data, tipo) => {
    let cabecera = [];
    Object.entries(data)
      .filter(
        (info) =>
          info[0] !== "contrasena" &&
          info[0] !== "refEmpleado" &&
          info[0] !== "supervisor" &&
          info[0] !== "vendedor" &&
          info[0] !== "refIdFactura" &&
          info[0] !== "refPlan" &&
          info[0] !== "token" &&
          info[0] !== "correoFacturacion" &&
          info[0] !== "refTarea"
      )
      .forEach((info) => {
        if (tipo !== "Cabecera") {
          cabecera.push(info[1]);
        } else {
          cabecera.push(toTitleCase(info[0]));
        }
      });
    return cabecera;
  };

  const formatData = () => {
    let excel;
    if (type !== "objects") {
      excel = [
        generarInfo(data.find((info) => info.length > 0)[0], "Cabecera"),
      ];
      data.map((info) =>
        info.length > 0
          ? info.map(
              (informacion) =>
                (excel = [...excel, generarInfo(informacion, "Datos")])
            )
          : false
      );
    } else {
      excel = [generarInfo(data[0], "Cabecera")];
      data.map((info) => (excel = [...excel, generarInfo(info, "Datos")]));
    }
    return excel;
  };

  const exportar = () => {
    setLoading(true);
    if (data?.length > 0) {
      let worksheet = XLSX.utils.aoa_to_sheet(formatData(data));
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, sheetName);
      XLSX.writeFile(new_workbook, filename + ".xlsx");
    } else {
      if (setShowAlert !== undefined) {
        setColor("error");
        setMessage("No hay datos que exportar");
        setShowAlert(true);
      }
    }
    setLoading(false);
  };

  return (
    <LoadingButton
      variant="contained"
      loading={loading}
      onClick={exportar}
      startIcon={<Icon icon={documentExport} />}
      style={{ minWidth: "170px", ...rest.style }}
      {...rest}
    >
      Exportar A Excel
    </LoadingButton>
  );
}
