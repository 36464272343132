import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/Scrollbar";
import { formatAsCLP } from "src/utils/formatNumber";
import {
  capitalizarPalabras,
  capitalizarPrimeraLetra,
} from "src/utils/sharedFunctions";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import ServiciosFormSkeleton from "../skeleton/ServiciosFormSkeleton";
import { propuestasApi } from "src/services/propuestasApi";
import { useSnackbar } from "src/components/snackbar";
import { AlertaModal } from "src/components/AlertaSwall";

const ServiciosForm = ({
  formik,
  isLoading = false,
  rutCliente,
  deleteFromDB = false,
}) => {
  const [eliminarPlanPropuesta] =
    propuestasApi.useEliminarPlanPropuestaMutation();
  const [loadingItem, setLoadingItem] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  if (isLoading) {
    return <ServiciosFormSkeleton />;
  }

  const eliminarPropuestaBD = async (refPropuesta, tipoPlan, rutCliente) => {
    setLoadingItem(tipoPlan);
    await AlertaModal({
      title: "eliminar plan",
      confirmButtonText: "Aceptar",
    })
      .fire()
      .then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const elementoAEliminar = {
            idPropuesta: refPropuesta,
            tipoPlan,
            rutCliente,
          };
          const { error } = await eliminarPlanPropuesta(elementoAEliminar);

          if (error) {
            enqueueSnackbar(
              error?.data?.message || "Ocurrió un error al eliminar el plan",
              {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              }
            );
            return;
          }
          enqueueSnackbar(
            `Plan ${tipoPlan.toLowerCase()} eliminado correctamente`,
            {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            }
          );
        }
      })
      .finally(() => {
        setLoadingItem(null);
      });
  };

  const eliminarPropuestaFormik = (tipoPlan) => {
    const updatedAccordions = formik.values.planesSeleccionados.filter(
      (item) => item.tipoPlan !== tipoPlan
    );
    formik.setFieldValue("planesSeleccionados", updatedAccordions);
    enqueueSnackbar(`Plan ${tipoPlan.toLowerCase()} eliminado correctamente`, {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  };

  const handleDelete = async (plan) => {
    const { tipoPlan, refPropuesta } = plan;

    if (deleteFromDB) {
      await eliminarPropuestaBD(refPropuesta, tipoPlan, rutCliente);
    } else {
      eliminarPropuestaFormik(tipoPlan);
    }
  };

  const formatearValorPlan = (tipoMoneda, precio) => {
    switch (tipoMoneda) {
      case "DOLAR":
        return `${formatAsCLP(precio)} USD`;
      case "PESOS":
        return `${formatAsCLP(precio)} CLP`;
      case "UF":
        return `${precio} UF`;
      case "UTM":
        return `${precio} UTM`;
      default:
        return "NVF";
    }
  };

  return (
    <Box>
      <Scrollbar>
        {formik.values.planesSeleccionados.map((item, index) => (
          <Box key={item.tipoPlan} mb={2}>
            <Accordion variant="outlined">
              <AccordionSummary
                expandIcon={<Iconify icon={arrowIosDownwardFill} />}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={3}>
                    <Typography variant="subtitle2">
                      {capitalizarPalabras(item.tipoPlan)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" gap={1}>
                      <Chip
                        label={item.frecuencia === "MES" ? "Mensual" : "Anual"}
                        color="primary"
                        size="small"
                      />
                      <Chip
                        label={formatearValorPlan(
                          item.tipoMoneda,
                          item.precioFinal
                        )}
                        color="secondary"
                        size="small"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3} container justifyContent="flex-end">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(item);
                      }}
                      color="error"
                      disabled={loadingItem === item.tipoPlan}
                    >
                      {loadingItem === item.tipoPlan ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <Iconify icon={trash2Outline} />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2">
                      Detalles del Plan
                    </Typography>
                    <Typography variant="body2">
                      <strong>Encargado:</strong>{" "}
                      {capitalizarPalabras(item.nombreEncargado)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>RUT Encargado:</strong>{" "}
                      {formatRut(item.rutEncargado, RutFormat.DOTS_DASH)}
                    </Typography>
                    {item.frecuencia === "AÑO" && (
                      <>
                        <Typography variant="body2">
                          <strong>Mes carga tarea:</strong>{" "}
                          {capitalizarPrimeraLetra(item.mesCargaTarea)}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Mes facturación:</strong>{" "}
                          {capitalizarPrimeraLetra(item.mesFacturacion)}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2">
                      Información Financiera
                    </Typography>
                    <Typography variant="body2">
                      <strong>Precio Fijo:</strong>{" "}
                      {formatearValorPlan(
                        item.tipoMoneda,
                        item.precioFijoFinal
                      )}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Descuento:</strong> {item.descuento}%
                    </Typography>
                    <Typography variant="body2">
                      <strong>Precio Final:</strong>{" "}
                      {formatearValorPlan(item.tipoMoneda, item.precioFinal)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Descripción</Typography>
                    <Typography variant="body2">{item.descripcion}</Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {formik.errors.planesSeleccionados &&
              formik.errors.planesSeleccionados[index] && (
                <Alert severity="error" sx={{ mt: 1 }}>
                  {Object.values(formik.errors.planesSeleccionados[index]).map(
                    (error, idx) => (
                      <Typography key={idx} variant="body2">
                        {error}
                      </Typography>
                    )
                  )}
                </Alert>
              )}
          </Box>
        ))}
      </Scrollbar>

      {formik.touched.planesSeleccionados &&
        formik.errors.planesSeleccionados &&
        typeof formik.errors.planesSeleccionados === "string" && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <Typography variant="body2">
              {formik.errors.planesSeleccionados}
            </Typography>
          </Alert>
        )}
    </Box>
  );
};

ServiciosForm.propTypes = {
  formik: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  rutCliente: PropTypes.string,
};

export default React.memo(ServiciosForm);
