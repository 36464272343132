import { useFormik } from "formik";
import { useMemo } from "react";

export const useFormikStepper = ({
  onFormSubmit,
  activeStep,
  validationSchemas,
  initialValues,
}) => {
  // Memoizar el esquema de validación del paso actual
  const currentValidationSchema = useMemo(
    () => validationSchemas[activeStep],
    [activeStep, validationSchemas]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: currentValidationSchema,
    onSubmit: (values) => {
      onFormSubmit(values);
    },
  });

  // Función para validar solo el paso actual
  const validateStep = async () => {
    // Marcar todos los campos del paso actual como touched
    const currentStepFields = Object.keys(
      validationSchemas[activeStep]?.fields || {}
    );
    const touchedFields = {};
    currentStepFields.forEach((field) => {
      touchedFields[field] = true;
    });
    formik.setTouched({ ...formik.touched, ...touchedFields });

    // Validar los campos del paso actual
    try {
      await validationSchemas[activeStep].validate(formik.values, {
        abortEarly: false,
      });
      return true; // Si no hay errores, el paso es válido
    } catch (error) {
      formik.setErrors(
        error.inner.reduce((acc, err) => {
          acc[err.path] = err.message;
          return acc;
        }, {})
      );
      return false; // Si hay errores, el paso no es válido
    }
  };

  return { formik, validateStep };
};
