import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const propuestasApi = createApi({
  reducerPath: "propuestasApi",
  baseQuery: baseQuery(`${API_SERVER}/propuestas`),
  tagTypes: ["Propuestas", "PlanesPropuesta"],
  endpoints: (builder) => ({
    getPropuestasSucursales: builder.query({
      query: ({ sucursal, tipoPropuesta }) =>
        `/sucursales/${sucursal}/${tipoPropuesta}`,
      providesTags: ["Propuestas"],
    }),
    getResumenPorEstadoYSucursal: builder.query({
      query: ({ rut, fecha }) => {
        return {
          url: `/resumenPorEstadoYSucursal/${rut}`,
          method: "GET",
          params: {
            fecha,
          },
        };
      },
    }),
    obtenerPropuestaCliente: builder.query({
      query: ({ token }) => ({
        url: "/ver",
        method: "GET",
        extra: { token },
      }),
    }),
    trackingPropuesta: builder.query({
      query: ({ id }) => ({
        url: `/${id}/tracking`,
        method: "GET",
      }),
    }),
    generarOTP: builder.mutation({
      query: ({ token, otpSentTime }) => ({
        url: "/generarOTP",
        method: "POST",
        body: { otpSentTime },
        extra: { token },
      }),
    }),
    verificarOTP: builder.mutation({
      query: ({ token, ...body }) => ({
        url: "/verificarOTP",
        method: "POST",
        body,
        extra: { token },
      }),
      invalidatesTags: ["Propuestas"],
    }),
    rechazarPropuesta: builder.mutation({
      query: ({ token, ...body }) => ({
        url: "/rechazar",
        method: "POST",
        body,
        extra: { token },
      }),
      invalidatesTags: ["Propuestas"],
    }),
    enviarPropuestaCliente: builder.mutation({
      query: ({ id, ...body }) => {
        return {
          url: `/${id}/enviar`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Propuestas"],
    }),
    getPropuestasPorSucursalYEstado: builder.query({
      query: ({ sucursal, estado, fecha }) => {
        return {
          url: `/propuestasPorSucursalYEstado/${sucursal}/${estado}`,
          method: "GET",
          params: {
            fecha,
          },
        };
      },
    }),
    actualizarServicioPropuestoTareaSegunID: builder.mutation({
      query: ({ idPropuesta, ...body }) => ({
        url: `/actualizarServicioPropuestoTareaSegunID/${idPropuesta}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Propuestas"],
    }),
    agregarSubtareaPropuestaTarea: builder.mutation({
      query: ({ idPropuesta, ...body }) => ({
        url: `/agregarSubtareaPropuestaTarea/${idPropuesta}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Propuestas"],
    }),
    eliminarSubtareaPropuestaTarea: builder.mutation({
      query: ({ idSubtareaPropuesta }) => ({
        url: `/eliminarSubtareaPropuestaTarea/${idSubtareaPropuesta}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Propuestas"],
    }),
    getPropuestasEmpleado: builder.query({
      query: ({ usuario, id, tipo }) => `/empleado/${usuario}/${id}/${tipo}`,
      providesTags: ["Propuestas"],
    }),
    getPropuesta: builder.query({
      query: ({ id, tipo }) => `/${tipo}/${id}`,
      providesTags: ["Propuestas"],
    }),
    addPropuesta: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Propuestas"],
    }),
    actualizarSolicitantePropuesta: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Propuestas"],
    }),
    addPropuestaTareaEspecial: builder.mutation({
      query: (body) => ({
        url: `/crearPropuestaTareaEspecial`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Propuestas"],
    }),
    deletePropuesta: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Propuestas"],
    }),
    updateEstadoPropuesta: builder.mutation({
      query: ({ id, tipo, body }) => ({
        url: `/estado/${tipo}/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Propuestas"],
    }),
    updateDatosPropuesta: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/datosPropuesta/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Propuestas"],
    }),
    actualizarArchivoPropuesta: builder.mutation({
      query: ({ idPropuesta, ...body }) => ({
        url: `/actualizarArchivoPropuesta/${idPropuesta}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Propuestas"],
    }),
    actualizarDatosCliente: builder.mutation({
      query: ({ idPropuesta, ...body }) => ({
        url: `/actualizarDatosCliente/${idPropuesta}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Propuestas"],
    }),
    actualizarDatosClienteTareaEspecial: builder.mutation({
      query: ({ idPropuesta, ...body }) => ({
        url: `/actualizarDatosClienteTareaEspecial/${idPropuesta}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Propuestas"],
    }),
    obtenerPlanesPropuesta: builder.query({
      query: (idPropuesta) => `/relacionPlan/${idPropuesta}`,
      providesTags: ["PlanesPropuesta"],
    }),
    obtenerServicios: builder.query({
      query: ({ id, tipo }) => `/relacionPlan/exportar/${tipo}/${id}`,
    }),
    agregarPlanPropuesta: builder.mutation({
      query: (body) => ({
        url: `/relacionPlan`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["PlanesPropuesta"],
    }),
    eliminarPlanPropuesta: builder.mutation({
      query: ({ idPropuesta, tipoPlan, rutCliente }) => ({
        url: `/relacionPlan/${idPropuesta}/${tipoPlan}/${rutCliente}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PlanesPropuesta"],
    }),
  }),
});

export const {
  useGetPropuestasEmpleadoQuery,
  useGetPropuestasSucursalesQuery,
  useGetResumenPorEstadoYSucursalQuery,
  useGetPropuestasPorSucursalYEstadoQuery,
  useGetPropuestaQuery,
  useEnviarPropuestaClienteMutation,
  useObtenerPlanesPropuestaQuery,
  useObtenerPropuestaClienteQuery,
  useActualizarSolicitantePropuestaMutation,
  useGenerarOTPMutation,
  useVerificarOTPMutation,
  useRechazarPropuestaMutation,
  useObtenerServiciosQuery,
  useTrackingPropuestaQuery,
  useLazyTrackingPropuestaQuery,
  useLazyObtenerServiciosQuery,
  useLazyObtenerPlanesPropuestaQuery,
  useLazyObtenerPropuestaClienteQuery,
  useLazyGetPropuestasPorSucursalYEstadoQuery,
  useLazyGetPropuestasEmpleadoQuery,
  useLazyGetPropuestasSucursalesQuery,
  useLazyGetResumenPorEstadoYSucursalQuery,
  useLazyGetPropuestaQuery,
  useDeletePropuestaMutation,
  useAddPropuestaMutation,
  useAddPropuestaTareaEspecialMutation,
  useUpdateEstadoPropuestaMutation,
  useActualizarServicioPropuestoTareaSegunIDMutation,
  useActualizarArchivoPropuestaMutation,
  useUpdateDatosPropuestaMutation,
  useActualizarDatosClienteTareaEspecialMutation,
  useActualizarDatosClienteMutation,
  useAgregarPlanPropuestaMutation,
  useEliminarPlanPropuestaMutation,
  useEliminarSubtareaPropuestaTareaMutation,
  useAgregarSubtareaPropuestaTareaMutation,
} = propuestasApi;
