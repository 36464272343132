import { CircularProgress, IconButton } from "@material-ui/core";
import { GetApp, Share } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useCallback, useState } from "react";
import { descargarArchivoDesdeDropboxYForzarDescarga } from "src/utils/dropboxUtils";
import { useSnackbar } from "src/components/snackbar";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
  },
}));

const ActionButtons = ({ idProposal, token }) => {
  const classes = useStyles();
  const [descargando, setDescargando] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const descargarPDF = useCallback(async () => {
    setDescargando(true);
    await descargarArchivoDesdeDropboxYForzarDescarga(
      "Propuestas/",
      `${idProposal}.pdf`
    );
    setDescargando(false);
  }, [idProposal]);

  const handleWebShare = useCallback(async () => {
    const url = `http://localhost:3000/propuesta/ver?token=${token}`;

    if (navigator.share) {
      navigator
        .share({
          title: "Propuesta",
          text: "Aquí tienes la propuesta que te enviamos.",
          url,
        })
        .catch((error) => console.log("Error al compartir", error));
    } else {
      await navigator.clipboard.writeText(url);

      enqueueSnackbar("Enlace copiado al portapapeles", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
  }, [enqueueSnackbar, token]);

  return (
    <div className={classes.actionButtons}>
      <IconButton
        title="Descargar PDF"
        size="small"
        onClick={descargarPDF}
        disabled={descargando}
      >
        {descargando ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <GetApp />
        )}
      </IconButton>
      <IconButton title="Compartir" size="small" onClick={handleWebShare}>
        <Share />
      </IconButton>
    </div>
  );
};

export default ActionButtons;
