function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/dashboard";

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  //   tareas: path(ROOTS_DASHBOARD, "/kanban"),
  clientes: {
    root: path(ROOTS_DASHBOARD, "/clientes"),
    listar: path(ROOTS_DASHBOARD, "/clientes/listar"),
    grupos: path(ROOTS_DASHBOARD, "/clientes/grupos"),
    planes: path(ROOTS_DASHBOARD, "/clientes/planes"),
  },
  tareas: {
    root: path(ROOTS_DASHBOARD, "/tareas"),
    listar: path(ROOTS_DASHBOARD, "/tareas/listar"),
    agregar: path(ROOTS_DASHBOARD, "/tareas/nueva-tarea"),
  },
  propuestas: {
    root: path(ROOTS_DASHBOARD, "/propuestas"),
    listar: path(ROOTS_DASHBOARD, "/propuestas/listar"),
    agregar: path(ROOTS_DASHBOARD, "/propuestas/nueva-propuesta"),
  },
  empleados: {
    root: path(ROOTS_DASHBOARD, "/empleados"),
  },
};
