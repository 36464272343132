import PropTypes from "prop-types";
import { useRef, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import bellFill from "@iconify/icons-eva/bell-fill";
import clockFill from "@iconify/icons-eva/clock-fill";
import doneAllFill from "@iconify/icons-eva/done-all-fill";
// material
import { alpha } from "@material-ui/core/styles";
import {
  Box,
  List,
  Badge,
  Button,
  Divider,
  ListItem,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
// components
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import { random } from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { tareasApi } from "src/services/tareasApi";
import { propuestasApi } from "src/services/propuestasApi";

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">{notification.nombre}</Typography>
  );
  return {
    title,
  };
}

function renderContentPropuesta(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.nombreSolicitante || notification.nombreCliente}
    </Typography>
  );
  return {
    title,
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
};

function calcularDiasTranscurridos(fecha) {
  let fechaHoy = new Date();
  let fechaInicial =
    fechaHoy.getFullYear() +
    "/" +
    (fechaHoy.getMonth() + 1) +
    "/" +
    fechaHoy.getDate();

  let fechaActual = moment(fechaInicial);
  let fechaFinal = moment(fecha);
  let dias = fechaActual.diff(fechaFinal, "days");
  let mensaje = "";

  if (dias === 0) {
    mensaje = "Hoy";
  } else if (dias === 1) {
    mensaje = "Hace " + dias + " dia";
  } else {
    mensaje = "Hace " + dias + " dias";
  }

  return mensaje;
}

function NotificationItem({ notification }, handleClose) {
  const { title } = renderContent(notification);

  return (
    <ListItem
      button
      to={`/dashboard/tareas/${notification.id}`}
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
      }}
    >
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Box
              component={Icon}
              icon={clockFill}
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {calcularDiasTranscurridos(notification.fechaInicio)}
          </Typography>
        }
      />
    </ListItem>
  );
}

function NotificationItemPropuesta({ notification }, handleClose) {
  const { title } = renderContentPropuesta(notification);

  return (
    <ListItem
      button
      to={`/dashboard/propuestas/${notification.tipo}/${notification.id}`}
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
      }}
    >
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Box
              component={Icon}
              icon={clockFill}
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {calcularDiasTranscurridos(notification.fechaCreacion)}
          </Typography>
        }
      />
    </ListItem>
  );
}

export default function NotificationsPopover() {
  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const sucursal = sucursalObtenida?.refSucursal;
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [tareasPendientes, setTareasPendientes] = useState(0);
  const [tareasPendientesArreglo, setTareasPendientesArreglo] = useState([]);
  const { data: propuestas = [], isLoading: cargandoPropuestas } =
    propuestasApi.useGetPropuestasEmpleadoQuery({
      usuario: user.rut,
      id: sucursal,
      tipo: "ALL",
    });

  const cantidadPropuestas = propuestas.length;

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { data: tareasAuxArray = [], isLoading: loadingTable } =
    tareasApi.useGetTareasAllQuery({
      id: sucursal,
      refEmpleado: user.rut,
    });

  useEffect(() => {
    if (!loadingTable) {
      let cant = 0;
      let tareasAux = [];

      tareasAuxArray.forEach((element) => {
        if (element.estado !== "TERMINADA") {
          tareasAux.push(element);
          cant++;
        } else {
        }
      });
      setTareasPendientesArreglo(tareasAux);
      setTareasPendientes(cant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTable]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        color={open ? "primary" : "default"}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <Badge
          badgeContent={cantidadPropuestas + tareasPendientes}
          color="error"
        >
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        style={{ overflowY: "scroll" }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificaciones</Typography>
          </Box>

          <IconButton color="primary">
            <Icon icon={doneAllFill} width={20} height={20} />
          </IconButton>
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Tareas {" (" + tareasPendientes + ")"}
              </ListSubheader>
            }
          >
            {tareasPendientesArreglo &&
            tareasPendientes &&
            tareasPendientes >= 1 &&
            tareasPendientesArreglo.length >= 1 ? (
              <NotificationItem
                handleClose={handleClose}
                key={
                  tareasPendientesArreglo[
                    random(0, tareasPendientesArreglo.length - 1)
                  ]?.id
                }
                notification={
                  tareasPendientesArreglo[
                    random(0, tareasPendientesArreglo.length - 1)
                  ]
                }
              />
            ) : (
              <Typography variant="subtitle2" style={{ textAlign: "center" }}>
                No existen tareas pendientes.{" "}
              </Typography>
            )}
          </List>

          <Divider />

          <Box sx={{ p: 1 }}>
            <Button
              fullWidth
              disableRipple
              component={RouterLink}
              to="/dashboard/tareas/"
            >
              Ver más
            </Button>
          </Box>

          <Divider />

          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Propuestas {" (" + cantidadPropuestas + ")"}
              </ListSubheader>
            }
          >
            {!cargandoPropuestas &&
            propuestas &&
            cantidadPropuestas &&
            cantidadPropuestas >= 1 ? (
              <NotificationItemPropuesta
                handleClose={handleClose}
                key={propuestas[random(0, propuestas.length - 1)].id}
                notification={propuestas[random(0, propuestas.length - 1)]}
              />
            ) : (
              <Typography variant="subtitle2" style={{ textAlign: "center" }}>
                No existen propuestas pendientes.{" "}
              </Typography>
            )}
          </List>
          <Divider />

          <Box sx={{ p: 1 }}>
            <Button
              fullWidth
              disableRipple
              component={RouterLink}
              to="/dashboard/propuestas/listar"
            >
              Ver más
            </Button>
          </Box>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
