import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Close, Info } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { fPercent } from "src/utils/formatNumber";
import {
  capitalizarPalabras,
  capitalizarPrimeraLetra,
} from "src/utils/sharedFunctions";

const useStyles = makeStyles((theme) => ({
  table: {
    "& th": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
    },
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[50],
    },
    "& tbody tr:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  serviceDescription: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  discountChip: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1),
  },
  dialogRoot: {
    "& .MuiDialog-paper": {
      borderRadius: theme.shape.borderRadius * 2,
      maxWidth: "800px",
      width: "100%",
    },
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  modalContent: {
    padding: theme.spacing(3),
    "& p": {
      whiteSpace: "pre-wrap",
      marginBottom: theme.spacing(2),
    },
  },
  companyInfoCard: {
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.grey[100],
    marginTop: theme.spacing(3),
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
  },
}));

const formatEndDiscountDate = (endDiscount) => {
  if (!endDiscount) {
    return "-";
  }
  const [cantidad, frecuencia] = endDiscount.split(" ");
  const frecuenciaCapitalizada = cantidad === 1 ? "Mes" : "Meses";
  return `${cantidad} ${
    frecuencia === "MES" ? frecuenciaCapitalizada : frecuencia
  }`;
};

const ServicesTable = ({ proposalData, proposalType }) => {
  const classes = useStyles();
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handleOpenPlanDetails = (plan) => {
    setSelectedPlan(plan);
  };

  const handleClosePlanDetails = () => {
    setSelectedPlan(null);
  };

  const renderPlanServices = (plans) => {
    return plans.map((plan, index) => (
      <TableRow key={index}>
        <TableCell>
          <Tooltip title={`Ver detalles ${plan.name}`}>
            <Box
              className={classes.serviceDescription}
              onClick={() => handleOpenPlanDetails(plan)}
            >
              {plan.name}
              <Info color="primary" fontSize="small" />
            </Box>
          </Tooltip>
        </TableCell>
        <TableCell align="center">{plan.unit}</TableCell>
        <TableCell align="right">
          {plan.price} {plan.currency}
        </TableCell>
        <TableCell align="center">
          {plan.discount > 0 ? (
            <Box className={classes.discountChip}>
              {fPercent(plan.discount)}
            </Box>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell align="right">
          {plan.finalPrice} {plan.currency}
        </TableCell>
        <TableCell align="center">
          {formatEndDiscountDate(plan.discountEndDate)}
        </TableCell>
      </TableRow>
    ));
  };

  const renderTaskServices = (task) => (
    <TableRow>
      <TableCell>{task.name}</TableCell>
      <TableCell align="right">
        {task.price} {task.currency}
      </TableCell>
      <TableCell align="center">
        {task.discount > 0 && (
          <Box className={classes.discountChip}>{fPercent(task.discount)}</Box>
        )}
      </TableCell>
      <TableCell align="right">
        {task.finalPrice} {task.currency}
      </TableCell>
      <TableCell align="center">{task.dateLimit}</TableCell>
    </TableRow>
  );

  return (
    <>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {proposalType === "PLAN" ? (
                <>
                  <TableCell>Servicio</TableCell>
                  <TableCell align="center">Frecuencia</TableCell>
                  <TableCell align="right">Valor Original</TableCell>
                  <TableCell align="center">Descuento</TableCell>
                  <TableCell align="right">Valor Final</TableCell>
                  <TableCell align="center">Duración Descuento</TableCell>
                </>
              ) : (
                <>
                  <TableCell>Servicio</TableCell>
                  <TableCell align="right">Valor Original</TableCell>
                  <TableCell align="center">Descuento</TableCell>
                  <TableCell align="right">Valor Final</TableCell>
                  <TableCell align="center">Fecha Límite</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {proposalType === "PLAN"
              ? renderPlanServices(proposalData.items)
              : renderTaskServices(proposalData.items[0])}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedPlan && (
        <Dialog
          open={!!selectedPlan}
          onClose={handleClosePlanDetails}
          className={classes.dialogRoot}
          maxWidth="md"
          fullWidth
        >
          <Box className={classes.modalHeader}>
            <Typography variant="h6">
              Detalles del Servicio: {selectedPlan.name}
            </Typography>
            <IconButton onClick={handleClosePlanDetails}>
              <Close />
            </IconButton>
          </Box>

          <DialogContent className={classes.modalContent}>
            <Typography variant="body1" paragraph>
              {selectedPlan.description}
            </Typography>

            <Card className={classes.companyInfoCard} elevation={0}>
              <Box className={classes.cardHeader}>
                <Typography variant="h6">Información de la Empresa</Typography>
              </Box>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">
                      <strong>RUT:</strong>{" "}
                      {formatRut(selectedPlan.rutCompany, RutFormat.DOTS_DASH)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Nombre:</strong>{" "}
                      {capitalizarPalabras(selectedPlan.nameCompany)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">
                      <strong>Dirección:</strong>{" "}
                      {capitalizarPrimeraLetra(selectedPlan.addressCompany)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Comuna:</strong>{" "}
                      {capitalizarPalabras(selectedPlan.cityCompany)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Teléfono:</strong> {selectedPlan.phoneCompany}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ServicesTable;
