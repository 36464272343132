import { makeStyles } from "@material-ui/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close, InfoOutlined, Save } from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";
import {
  capitalizarPalabras,
  capitalizarPrimeraLetra,
} from "src/utils/sharedFunctions";
import { tiposApi, useGetTipoMonedaQuery } from "src/services/tiposApi";
import Iconify from "src/components/iconify";
import calendarOutline from "@iconify/icons-eva/calendar-outline";
import clockOutline from "@iconify/icons-eva/clock-outline";
import money from "@iconify/icons-carbon/money";
import baselineAttachMoney from "@iconify/icons-ic/baseline-attach-money";
import baseDiscount from "@iconify/icons-eva/pricetags-outline";
import { obtenerMeses } from "src/utils/formatTime";
import { empleadosApi } from "src/services/empleadosApi";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import { useSnackbar } from "src/components/snackbar";
import { propuestasApi } from "src/services/propuestasApi";
import { formatearValorPlan } from "../../utils/functions";
import { URL_ORIGEN } from "src/utils/urlBack_End";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  card: {
    boxShadow: "none",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  cardTitle: {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  switchLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginLeft: 0,
  },
  priceField: {
    "& input": {
      fontWeight: 500,
    },
  },
  finalPrice: {
    backgroundColor: theme.palette.action.hover,
  },
  sectionDivider: {
    margin: theme.spacing(3, 0),
  },
  responsiveDivider: {
    margin: theme.spacing(3, 0),
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));

const frecuencias = ["MES", "AÑO"];
const regexRemuneraciones = /remuneraciones/i;

const meses = obtenerMeses();

const planSchema = Yup.object().shape({
  plan: Yup.object({
    nombre: Yup.string().required("El nombre del plan es requerido"),
    descripcion: Yup.string().required("La descripción del plan es requerida"),
    tipoMoneda: Yup.string().required(
      "El tipo de moneda del plan es requerido"
    ),
    valor: Yup.number().required("El valor del plan es requerido"),
  })
    // .mixed().oneOf([opciones])
    .required("El plan es requerido")
    .nullable(),
  encargado: Yup.object()
    // .mixed().oneOf([opciones])
    .required("El encargado es requerido")
    .nullable(),
  frecuencia: Yup.string().required("La frecuencia es requerida").nullable(),
  mesCargaTarea: Yup.string().when("frecuencia", {
    is: "AÑO",
    then: Yup.string()
      .required("El mes de carga de las tareas es requerido")
      .nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  mesFacturacion: Yup.string().when("frecuencia", {
    is: "AÑO",
    then: Yup.string()
      .required("El mes de facturación es requerido")
      .nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  tipoMoneda: Yup.string()
    .required("El tipo de moneda es requerido")
    .nullable(),
  facturadoGestor: Yup.boolean().required(
    "La facturación del gestor es requerido"
  ),
  precioFijo: Yup.number()
    .required("El precio fijo es requerido")
    .positive("El precio fijo debe ser un número positivo")
    .min(0, "El precio fijo no puede ser negativo"),
  descuento: Yup.number()
    .required("Es descuento es requerido")
    .min(0, "El descuento no puede ser negativo")
    .max(100, "El descuento no puede ser mayor al 100%"),
  tiempoDescuento: Yup.number().when("descuento", {
    is: (descuento) => descuento > 0,
    then: Yup.number()
      .required("El tiempo de descuento es requerido")
      .min(1, "El tiempo de descuento debe ser mayor a 0")
      .nullable(),
    otherwise: Yup.number().notRequired().nullable(),
  }),
  precioFinal: Yup.number()
    .required("El precio final es requerido")
    .positive("El precio final debe ser un número positivo")
    .min(0, "El precio final no puede ser negativo"),
});

const AddPlanModal = ({
  open,
  setOpen,
  planesSeleccionados,
  setFieldValue,
  sucursal,
  saveInDB = false,
  idPropuesta,
  rutCliente,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: planes, isLoading: cargandoPlanes } =
    tiposApi.useGetTipoPlanesQuery();
  const { data: monedasQuery, isLoading: cargandoMonedas } =
    useGetTipoMonedaQuery();
  const { data: empleados, isLoading: cargandoEmpleados } =
    empleadosApi.useGetEmpleadosActivosQuery({
      id: sucursal,
      estado: "ALL",
    });

  const [agregarPlanPropuesta, { isLoading: agregandoPlan }] =
    propuestasApi.useAgregarPlanPropuestaMutation();

  const { enqueueSnackbar } = useSnackbar();

  const monedas = monedasQuery
    ? monedasQuery.map((moneda) => moneda.nombre)
    : [];

  const formik = useFormik({
    initialValues: {
      plan: null,
      encargado: null,
      frecuencia: null,
      mesCargaTarea: null,
      mesFacturacion: null,
      tipoMoneda: null,
      facturadoGestor: true,
      precioFijo: 0,
      descuento: 0,
      tiempoDescuento: 0,
      precioFinal: 0,
      descripcion: "",
    },
    validationSchema: planSchema,
    onSubmit: async (values) => {
      try {
        const planSeleccionado = {
          tipoPlan: values.plan.nombre,
          tipoMoneda: values.tipoMoneda,
          precioFijoFinal: values.precioFijo,
          precioFinal: values.precioFinal,
          descuento: values.descuento,
          tiempoDescuento: values.tiempoDescuento,
          nombreEncargado: `${values.encargado.nombre} ${values.encargado.apellidos}`,
          rutEncargado: values.encargado.rut,
          facturadoGestor: values.facturadoGestor,
          descripcion: values.descripcion,
          mesCargaTarea: values.mesCargaTarea,
          mesFacturacion: values.mesFacturacion,
          frecuencia: values.frecuencia,
          precioFijo: values.plan.valor === values.precioFijo ? "SI" : "NO", // Si es precio fijo, en caso de cambio de precio del plan, no se modifican todos los planes al nuevo precio
        };

        const existePlan = planesSeleccionados.some(
          (plan) => plan.tipoPlan === planSeleccionado.tipoPlan
        );
        if (existePlan) {
          enqueueSnackbar(
            `El plan ${planSeleccionado.tipoPlan.toLowerCase()} ya existe`,
            {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            }
          );
          return;
        }

        if (saveInDB) {
          await onSubmitPlanDB(planSeleccionado, idPropuesta, rutCliente);
        } else {
          onSubmitPlan(planSeleccionado);
        }
        handleClose();
      } catch (error) {
        console.error("Error al guardar:", error);
      }
    },
  });

  useEffect(() => {
    if (formik.values.frecuencia === "AÑO") {
      formik.setFieldValue("mesCargaTarea", "FEBRERO");
      formik.setFieldValue("mesFacturacion", "MARZO");
    } else {
      formik.setFieldValue("mesCargaTarea", null);
      formik.setFieldValue("mesFacturacion", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.frecuencia, formik?.setFieldValue]);

  const onSubmitPlanDB = async (planSeleccionado, idPropuesta, rutCliente) => {
    if (!idPropuesta) {
      console.error(
        "El ID de la propuesta es requerido para guardar los datos"
      );
      return;
    }

    const dataToSave = {
      ...planSeleccionado,
      refPropuesta: idPropuesta,
      refCliente: rutCliente,
    };

    const { error } = await agregarPlanPropuesta(dataToSave);

    if (error) {
      enqueueSnackbar(
        error?.data?.message || "Ocurrió un error al añadir el plan",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
      return;
    }
    enqueueSnackbar(
      `Plan ${planSeleccionado.tipoPlan.toLowerCase()} agregado correctamente`,
      {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  };

  const onSubmitPlan = (planSeleccionado) => {
    setFieldValue("planesSeleccionados", [
      ...planesSeleccionados,
      planSeleccionado,
    ]);
    enqueueSnackbar(
      `Plan ${planSeleccionado.tipoPlan.toLowerCase()} agregado correctamente`,
      {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  };

  const descuentoMayorACero = formik.values.descuento > 0;

  useEffect(() => {
    const precioFinal =
      formik.values.precioFijo * (1 - formik.values.descuento / 100);
    formik.setFieldValue("precioFinal", Number(precioFinal.toFixed(2)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.precioFijo, formik.values.descuento]);

  useEffect(() => {
    if (formik.values.descuento === 0) {
      formik.setFieldValue("tiempoDescuento", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.descuento]);

  useEffect(() => {
    if (
      formik.values.plan &&
      regexRemuneraciones.test(formik.values.plan.nombre)
    ) {
      const empleadoRemuneraciones = {
        rut: !URL_ORIGEN.includes("http://localhost:3000")
          ? "190096874"
          : "192613639",
        nombre: "REMUNERACIONES",
        apellidos: "RR.HH",
      };
      formik.setFieldValue("encargado", empleadoRemuneraciones);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.plan, formik.values?.plan?.nombre]);

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  const handleChangePlan = useCallback(
    (_, newPlan) => {
      if (newPlan) {
        const { valor, tipoMoneda, descripcion } = newPlan;
        formik.setFieldValue("plan", newPlan);
        formik.setFieldValue("tipoMoneda", tipoMoneda);
        formik.setFieldValue("precioFijo", valor);
        formik.setFieldValue("descripcion", descripcion);
        formik.setFieldValue("encargado", null);
      }
    },
    [formik]
  );

  const getOptionLabel = useCallback((option) => {
    const nombreCompleto = `${option.nombre} ${option.apellidos}`;
    const rutFormateado = formatRut(option.rut, RutFormat.DOTS_DASH);
    return `${capitalizarPalabras(nombreCompleto)} (${rutFormateado})`;
  }, []);

  return (
    <Dialog open={open} maxWidth="lg" onClose={handleClose} fullWidth>
      <DialogTitle className={classes.dialogTitle}>
        Servicios plan
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          {/* Primera fila: Plan y Encargado */}
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <Typography variant="subtitle2" className={classes.cardTitle}>
              Selección del Plan
            </Typography>
            <Card className={classes.card}>
              <CardContent>
                <Autocomplete
                  fullWidth
                  value={formik.values.plan}
                  onChange={(_, newValue) => handleChangePlan(_, newValue)}
                  options={!cargandoPlanes && planes ? planes : []}
                  getOptionLabel={(option) =>
                    `${capitalizarPrimeraLetra(
                      option.nombre
                    )} (${formatearValorPlan(option.tipoMoneda, option.valor)})`
                  }
                  disabled={cargandoPlanes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Seleccione un plan"
                      variant="outlined"
                      placeholder="Buscar plan..."
                      disabled={cargandoPlanes}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {cargandoPlanes ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {formik.values.plan && (
                              <Tooltip
                                title={formik.values.plan.descripcion}
                                placement="top"
                              >
                                <InfoOutlined color="action" />
                              </Tooltip>
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      error={formik.touched.plan && Boolean(formik.errors.plan)}
                      helperText={formik.touched.plan && formik.errors.plan}
                    />
                  )}
                />
              </CardContent>
            </Card>
          </Grid>

          {isMobile && (
            <Grid item xs={12}>
              <Divider className={classes.responsiveDivider} />
            </Grid>
          )}

          <Grid item xs={12} md={6} lg={6} xl={6}>
            <Typography variant="subtitle2" className={classes.cardTitle}>
              Selección del encargado
            </Typography>
            <Card className={classes.card}>
              <CardContent>
                <Autocomplete
                  fullWidth
                  value={formik.values.encargado}
                  onChange={(_, newValue) =>
                    formik.setFieldValue("encargado", newValue)
                  }
                  options={!cargandoEmpleados && empleados ? empleados : []}
                  getOptionLabel={getOptionLabel}
                  disabled={
                    cargandoEmpleados ||
                    regexRemuneraciones.test(formik.values.plan?.nombre)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Seleccione un encargado"
                      variant="outlined"
                      disabled={
                        cargandoEmpleados ||
                        regexRemuneraciones.test(formik.values.plan?.nombre)
                      }
                      placeholder="Buscar encargado..."
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {cargandoEmpleados ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      error={
                        formik.touched.encargado &&
                        Boolean(formik.errors.encargado)
                      }
                      helperText={
                        formik.touched.encargado && formik.errors.encargado
                      }
                    />
                  )}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.sectionDivider} />
          </Grid>

          {/* Segunda fila: Frecuencia/Facturación y Precios */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" className={classes.cardTitle}>
              Frecuencia y Facturación
            </Typography>
            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      fullWidth
                      value={formik.values.frecuencia}
                      onChange={(_, newValue) =>
                        formik.setFieldValue("frecuencia", newValue)
                      }
                      options={frecuencias}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Frecuencia"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={clockOutline} width={18} />
                              </InputAdornment>
                            ),
                          }}
                          error={
                            formik.touched.frecuencia &&
                            Boolean(formik.errors.frecuencia)
                          }
                          helperText={
                            formik.touched.frecuencia &&
                            formik.errors.frecuencia
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      className={classes.switchLabel}
                      control={
                        <Switch
                          checked={formik.values.facturadoGestor}
                          onChange={() =>
                            formik.setFieldValue(
                              "facturadoGestor",
                              !formik.values.facturadoGestor
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Facturado por el gestor"
                      labelPlacement="start"
                    />
                  </Grid>
                  {formik.values.frecuencia === "AÑO" && (
                    <>
                      <Grid item xs={12}>
                        <Autocomplete
                          fullWidth
                          disabled
                          value={formik.values.mesCargaTarea}
                          onChange={(_, newValue) =>
                            formik.setFieldValue("mesCargaTarea", newValue)
                          }
                          options={meses}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Mes carga tarea"
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Iconify
                                      icon={calendarOutline}
                                      width={18}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                formik.touched.mesCargaTarea &&
                                Boolean(formik.errors.mesCargaTarea)
                              }
                              helperText={
                                formik.touched.mesCargaTarea &&
                                formik.errors.mesCargaTarea
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          fullWidth
                          disabled
                          value={formik.values.mesFacturacion}
                          onChange={(_, newValue) =>
                            formik.setFieldValue("mesFacturacion", newValue)
                          }
                          options={meses}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Mes facturación"
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Iconify
                                      icon={calendarOutline}
                                      width={18}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                formik.touched.mesFacturacion &&
                                Boolean(formik.errors.mesFacturacion)
                              }
                              helperText={
                                formik.touched.mesFacturacion &&
                                formik.errors.mesFacturacion
                              }
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {isMobile && (
            <Grid item xs={12}>
              <Divider className={classes.responsiveDivider} />
            </Grid>
          )}

          <Grid item xs={12} md={6} lg={6} xl={6}>
            <Typography variant="subtitle2" className={classes.cardTitle}>
              Precios y Moneda
            </Typography>
            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Autocomplete
                      fullWidth
                      value={formik.values.tipoMoneda}
                      onChange={(_, newValue) =>
                        formik.setFieldValue("tipoMoneda", newValue)
                      }
                      options={monedas}
                      getOptionLabel={(option) => option}
                      disabled={cargandoMonedas}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Tipo de moneda"
                          variant="outlined"
                          placeholder="Buscar tipo de moneda..."
                          disabled={cargandoMonedas}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={money} width={18} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <>
                                {cargandoMonedas ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={
                            formik.touched.tipoMoneda &&
                            Boolean(formik.errors.tipoMoneda)
                          }
                          helperText={
                            formik.touched.tipoMoneda &&
                            formik.errors.tipoMoneda
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      className={classes.priceField}
                      label="Precio sugerido"
                      variant="outlined"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      InputProps={{
                        startAdornment:
                          formik.values.tipoMoneda === "PESOS" ? (
                            <InputAdornment position="start">
                              <Iconify icon={baselineAttachMoney} width={18} />
                            </InputAdornment>
                          ) : formik.values.tipoMoneda === "DOLAR" ? (
                            <InputAdornment position="start">
                              USD
                            </InputAdornment>
                          ) : null,
                        endAdornment:
                          formik.values.tipoMoneda === "UF" ||
                          formik.values.tipoMoneda === "UTM" ? (
                            <InputAdornment position="end">
                              {formik.values.tipoMoneda}
                            </InputAdornment>
                          ) : null,
                      }}
                      {...formik.getFieldProps("precioFijo")}
                      error={
                        formik.touched.precioFijo &&
                        Boolean(formik.errors.precioFijo)
                      }
                      helperText={
                        formik.touched.precioFijo && formik.errors.precioFijo
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={descuentoMayorACero ? 6 : 12}>
                    <TextField
                      fullWidth
                      required
                      className={classes.priceField}
                      label="Descuento (%)"
                      variant="outlined"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon={baseDiscount} width={18} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      {...formik.getFieldProps("descuento")}
                      error={
                        formik.touched.descuento &&
                        Boolean(formik.errors.descuento)
                      }
                      helperText={
                        formik.touched.descuento && formik.errors.descuento
                      }
                    />
                  </Grid>
                  {descuentoMayorACero > 0 && (
                    <Grid item xs={12} md={descuentoMayorACero > 0 ? 6 : 12}>
                      <TextField
                        fullWidth
                        required
                        className={classes.priceField}
                        label="Tiempo de descuento"
                        variant="outlined"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon={clockOutline} width={18} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              Meses
                            </InputAdornment>
                          ),
                        }}
                        {...formik.getFieldProps("tiempoDescuento")}
                        error={
                          formik.touched.tiempoDescuento &&
                          Boolean(formik.errors.tiempoDescuento)
                        }
                        helperText={
                          formik.touched.tiempoDescuento &&
                          formik.errors.tiempoDescuento
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      className={`${classes.priceField} ${classes.finalPrice}`}
                      label="Precio final"
                      variant="outlined"
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon={baselineAttachMoney} width={18} />
                          </InputAdornment>
                        ),
                      }}
                      value={formik.values.precioFinal}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ padding: 16 }}>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          onClick={formik.handleSubmit}
          startIcon={<Save />}
          color="primary"
          loading={cargandoPlanes || agregandoPlan}
          variant="contained"
          disableElevation
        >
          Guardar cambios
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddPlanModal;
