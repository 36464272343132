import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { logout } from "src/reducers/authReducers";

const baseQuery = (baseUrl = "/") => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { extra }) => {
      // Verifica si ya existe un header "authorization"
      if (!headers.has("authorization")) {
        const token = extra?.token || localStorage.getItem("token");

        if (token && token.startsWith("Bearer ")) {
          headers.set("authorization", token);
        } else if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }
      }

      return headers;
    },
  });

  return async (args, api, extraOptions) => {
    const result = await rawBaseQuery(
      args,
      { ...api, extra: args.extra },
      extraOptions
    );

    if (result.error && result.error.status === 401) {
      api.dispatch(logout({ expired: true }));
    }
    return result;
  };
};

export default baseQuery;
