import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import { decodeToken } from "react-jwt";
import { useDispatch } from "react-redux";
import Etiqueta from "src/components/etiquetas/Etiquetas";
import Estadisticas from "src/pages/Estadisticas";
import DashboardApp from "src/pages/DashboardApp";
import Tareas from "src/pages/Tareas";
import TabsPlanes from "src/pages/TabsPlanes";
import Facturas from "src/pages/Facturacion";
import Pagos from "src/pages/Pagos";
import Clientes from "src/pages/Clientes";
import GrupoClientes from "src/pages/GrupoClientes";
import Empleados from "src/pages/Empleados";
import Sucursales from "src/pages/Sucursales";
import Configuraciones from "src/pages/Configuraciones";
import Informes from "src/pages/Informes";
import NuevaTarea from "src/pages/NuevaTarea";
import VerClientes from "src/pages/VerClientes";
import VerEmpleado from "src/pages/VerEmpleado";
import TipoPlanes from "src/pages/TipoPlanes";
import TipoTareas from "src/pages/TipoTareas";
import ProrrateoVista from "src/pages/ProrrateoVista";
import BaseDatosCliente from "src/pages/BaseDatosCliente";
import EditarTarea from "src/pages/EditarTarea";
import VerPropuesta from "src/pages/VerPropuesta";
import VerPlan from "src/pages/VerPlan";
import GraficoTareas from "src/pages/GraficoTareas";
import GraficoTareasEmpleado from "src/components/graficoTareas/GraficoTareasEmpleado";
import AreaLaboral from "src/pages/AreaLaboral";
import CambioEstadoTarea from "src/pages/CambioEstadoTarea";
import DashboardCliente from "src/pages/DashboardCliente";
import FacturasCliente from "src/components/pagesCliente/FacturasCliente";
import DatosClientes from "src/components/pagesCliente/DatosClientes";
import PagosWebPay from "src/components/pagesCliente/PagosWebPay";
import Liquidacion from "src/pages/Liquidacion";
import Login from "src/pages/Login";
import SearchNotFound from "src/components/SearchNotFound";
import DashboardLayout from "src/layouts/dashboard";
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
import { login } from "src/reducers/authReducers";
import NuevaPropuesta from "src/pages/NuevaPropuesta";
import Propuestas from "src/pages/Propuestas";
import ProposalView from "src/components/propuesta/visualizacionCliente/ProposalView";

// ----------------------------------------------------------------------

const getSuperAdminRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/estadisticas" replace /> },
    { path: "estadisticas", element: <Estadisticas /> },
    { path: "inicio", element: <DashboardApp /> },
    { path: "facturacion", element: <Facturas /> },
    { path: "pagos", element: <Pagos /> },
    {
      path: "tareas",
      children: [
        {
          element: <Navigate to="/dashboard/tareas/listar" replace />,
          index: true,
        },
        { path: "listar", element: <Tareas /> },
        { path: "nueva-tarea", element: <NuevaTarea /> },
        { path: "/:id", element: <EditarTarea /> },
      ],
    },
    {
      path: "empleados",
      children: [
        { path: "", element: <Empleados /> },
        { path: "/:id", element: <VerEmpleado /> },
      ],
    },
    { path: "sucursales", element: <Sucursales /> },
    { path: "configuraciones", element: <Configuraciones /> },
    { path: "informes", element: <Informes /> },
    // { path: 'agenda', element: <Agenda /> },
    {
      path: "clientes",
      children: [
        { path: "listar", element: <Clientes /> },
        { path: "/:id", element: <VerClientes /> },
        { path: "grupos", element: <GrupoClientes /> },
        { path: "planes", element: <TabsPlanes /> },
        { path: "planes/:id", element: <VerPlan /> },
      ],
    },
    { path: "configuraciones/tipo-planes", element: <TipoPlanes /> },
    { path: "configuraciones/tipo-tareas", element: <TipoTareas /> },
    { path: "configuraciones/prorrateo", element: <ProrrateoVista /> },
    {
      path: "configuraciones/base-datos-clientes",
      element: <BaseDatosCliente />,
    },
    {
      path: "propuestas",
      children: [
        { path: "listar", element: <Propuestas /> },
        { path: "nueva-propuesta", element: <NuevaPropuesta /> },
        { path: "/:tipo/:id", element: <VerPropuesta /> },
      ],
    },
    { path: "configuraciones/etiquetas", element: <Etiqueta /> },
    { path: "graficoTareas", element: <GraficoTareas /> },
    { path: "graficoTareas/:id", element: <GraficoTareasEmpleado /> },
    { path: "area-laboral", element: <AreaLaboral /> },
  ];
};

// El admin no ve sucursales
const getAdminRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/estadisticas" replace /> },
    { path: "estadisticas", element: <Estadisticas /> },
    { path: "inicio", element: <DashboardApp /> },
    { path: "facturacion", element: <Facturas /> },
    {
      path: "tareas",
      children: [
        {
          element: <Navigate to="/dashboard/tareas/listar" replace />,
          index: true,
        },
        { path: "listar", element: <Tareas /> },
        { path: "nueva-tarea", element: <NuevaTarea /> },
        { path: "/:id", element: <EditarTarea /> },
      ],
    },
    {
      path: "clientes",
      children: [
        { path: "listar", element: <Clientes /> },
        { path: "/:id", element: <VerClientes /> },
        { path: "grupos", element: <GrupoClientes /> },
        { path: "planes", element: <TabsPlanes /> },
        { path: "planes/:id", element: <VerPlan /> },
      ],
    },
    {
      path: "empleados",
      children: [
        { path: "", element: <Empleados /> },
        { path: "/:id", element: <VerEmpleado /> },
      ],
    },
    {
      path: "propuestas",
      children: [
        { path: "listar", element: <Propuestas /> },
        { path: "nueva-propuesta", element: <NuevaPropuesta /> },
        { path: "/:tipo/:id", element: <VerPropuesta /> },
      ],
    },
    { path: "pagos", element: <Pagos /> },
    { path: "informes", element: <Informes /> },
    { path: "graficoTareas", element: <GraficoTareas /> },
    { path: "graficoTareas/:id", element: <GraficoTareasEmpleado /> },
    { path: "area-laboral", element: <AreaLaboral /> },
    { path: "cambiarEstadoTarea/:id", element: <CambioEstadoTarea /> },
  ];
};

// El admin no ve sucursales
const getAdminOPRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/inicio" replace /> },
    { path: "inicio", element: <DashboardApp /> },
    {
      path: "clientes",
      children: [
        { path: "listar", element: <Clientes /> },
        { path: "/:id", element: <VerClientes /> },
        { path: "grupos", element: <GrupoClientes /> },
        { path: "planes", element: <TabsPlanes /> },
        { path: "planes/:id", element: <VerPlan /> },
      ],
    },
    {
      path: "propuestas",
      children: [
        { path: "listar", element: <Propuestas /> },
        { path: "nueva-propuesta", element: <NuevaPropuesta /> },
        { path: "/:tipo/:id", element: <VerPropuesta /> },
      ],
    },
    {
      path: "tareas",
      children: [
        {
          element: <Navigate to="/dashboard/tareas/listar" replace />,
          index: true,
        },
        { path: "listar", element: <Tareas /> },
        { path: "nueva-tarea", element: <NuevaTarea /> },
        { path: "/:id", element: <EditarTarea /> },
      ],
    },
    { path: "area-laboral", element: <AreaLaboral /> },
  ];
};

// Supervisor no ve sucursales, facturación, pagos, configuraciones, ni
// empleados
const getSupervisorRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/inicio" replace /> },
    { path: "inicio", element: <DashboardApp /> },
    {
      path: "tareas",
      children: [
        {
          element: <Navigate to="/dashboard/tareas/listar" replace />,
          index: true,
        },
        { path: "listar", element: <Tareas /> },
        { path: "nueva-tarea", element: <NuevaTarea /> },
        { path: "/:id", element: <EditarTarea /> },
      ],
    },
    {
      path: "clientes",
      children: [
        { path: "listar", element: <Clientes /> },
        { path: "/:id", element: <VerClientes /> },
        { path: "grupos", element: <GrupoClientes /> },
        { path: "planes", element: <TabsPlanes /> },
        { path: "planes/:id", element: <VerPlan /> },
      ],
    },
    { path: "informes", element: <Informes /> },
    // { path: 'agenda', element: <Agenda /> },
    {
      path: "propuestas",
      children: [
        { path: "listar", element: <Propuestas /> },
        { path: "nueva-propuesta", element: <NuevaPropuesta /> },
        { path: "/:tipo/:id", element: <VerPropuesta /> },
      ],
    },
    { path: "graficoTareas", element: <GraficoTareas /> },
    { path: "graficoTareas/:id", element: <GraficoTareasEmpleado /> },
    { path: "area-laboral", element: <AreaLaboral /> },
  ];
};

// Empleado no ve facturación, pagos, empleados, sucursales, configuraciones ni
// informes
const getEmpleadoRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/inicio" replace /> },
    { path: "inicio", element: <DashboardApp /> },
    {
      path: "tareas",
      children: [
        {
          element: <Navigate to="/dashboard/tareas/listar" replace />,
          index: true,
        },
        { path: "listar", element: <Tareas /> },
        { path: "nueva-tarea", element: <NuevaTarea /> },
        { path: "/:id", element: <EditarTarea /> },
      ],
    },
    {
      path: "clientes",
      children: [
        { path: "listar", element: <Clientes /> },
        { path: "/:id", element: <VerClientes /> },
        { path: "grupos", element: <GrupoClientes /> },
        { path: "planes", element: <TabsPlanes /> },
        { path: "planes/:id", element: <VerPlan /> },
      ],
    },
    // { path: 'agenda', element: <Agenda /> },
    { path: "nueva-tarea", element: <NuevaTarea /> },
    {
      path: "propuestas",
      children: [
        { path: "listar", element: <Propuestas /> },
        { path: "nueva-propuesta", element: <NuevaPropuesta /> },
        { path: "/:tipo/:id", element: <VerPropuesta /> },
      ],
    },
    { path: "graficoTareas", element: <GraficoTareas /> },
    { path: "graficoTareas/:id", element: <GraficoTareasEmpleado /> },
    { path: "area-laboral", element: <AreaLaboral /> },
  ];
};

// Gerente Sucursal sucursales, configuraciones ni
// informes
const getGerenteSucursalRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/inicio" replace /> },
    { path: "inicio", element: <DashboardApp /> },
    {
      path: "tareas",
      children: [
        {
          element: <Navigate to="/dashboard/tareas/listar" replace />,
          index: true,
        },
        { path: "listar", element: <Tareas /> },
        { path: "nueva-tarea", element: <NuevaTarea /> },
        { path: "/:id", element: <EditarTarea /> },
      ],
    },
    {
      path: "empleados",
      children: [
        { path: "", element: <Empleados /> },
        { path: "/:id", element: <VerEmpleado /> },
      ],
    },
    {
      path: "clientes",
      children: [
        { path: "listar", element: <Clientes /> },
        { path: "/:id", element: <VerClientes /> },
        { path: "planes", element: <TabsPlanes /> },
        { path: "planes/:id", element: <VerPlan /> },
      ],
    },
    {
      path: "propuestas",
      children: [
        { path: "listar", element: <Propuestas /> },
        { path: "nueva-propuesta", element: <NuevaPropuesta /> },
        { path: "/:tipo/:id", element: <VerPropuesta /> },
      ],
    },
    // { path: 'agenda', element: <Agenda /> },
    { path: "graficoTareas", element: <GraficoTareas /> },
    { path: "graficoTareas/:id", element: <GraficoTareasEmpleado /> },
    { path: "area-laboral", element: <AreaLaboral /> },
    { path: "facturacion", element: <Facturas /> },
    { path: "pagos", element: <Pagos /> },
    { path: "informes", element: <Informes /> },
  ];
};

const getClienteRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/inicio" replace /> },
    { path: "inicio", element: <DashboardCliente /> },
    { path: "facturación", element: <FacturasCliente /> },
    { path: "clientes", element: <DatosClientes /> },
    { path: "pago/:id", element: <PagosWebPay /> },
  ];
};

const getPublicRoutes = () => {
  return [
    {
      path: "/propuesta/ver",
      element: <ProposalView />,
    },
  ];
};

const getNotLoggedInRoutes = () => {
  return [{ path: "*", element: <Navigate to="/login" replace /> }];
};

export default function Router() {
  const userInfo = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const loggout = () => {
    const token = localStorage.getItem("token");
    const sucursalObtenida = localStorage.getItem("sucursal");
    const rolObtenido = localStorage.getItem("rol");
    let myDecodedToken;

    if (token) {
      try {
        myDecodedToken = decodeToken(token);
      } catch (error) {
        console.error("Error decoding token:", error);
        localStorage.clear();
        return;
      }

      if (myDecodedToken && myDecodedToken.exp) {
        const fechaTerminoToken = new Date(Number(myDecodedToken.exp) * 1000);
        const fechaActual = new Date();

        if (fechaActual > fechaTerminoToken) {
          localStorage.clear();
          return;
        }
      } else {
        // Si no se puede decodificar el token o no tiene una fecha de expiración válida
        localStorage.clear();
        return;
      }
    }

    if (!userInfo && token && sucursalObtenida && rolObtenido) {
      let newUser = myDecodedToken;
      if (sucursalObtenida === "CL") {
        dispatch(login(newUser));
        return newUser;
      } else {
        const sucursal = myDecodedToken?.sucursales?.filter(
          (e) => e.refSucursal === sucursalObtenida && e.refRol === rolObtenido
        )[0];
        if (sucursal) {
          newUser.sucursal = sucursal;
          newUser.rol = sucursal.refRol;
          dispatch(login(newUser));
          return newUser;
        } else {
          localStorage.clear();
          return;
        }
      }
    } else {
      return userInfo;
    }
  };

  const selectChildren = () => {
    const userAUX = loggout();
    if (!userAUX) return getNotLoggedInRoutes();
    if (
      userAUX.rol === "SUPER_ADMIN" &&
      (userAUX.empleado.rut === "192613639" ||
        userAUX.empleado.rut === "194467354" ||
        userAUX.empleado.rut === "121695979" ||
        userAUX.empleado.rut === "154157131" ||
        userAUX.empleado.rut === "132012636" ||
        userAUX.empleado.rut === "193928595")
    )
      return [
        ...getSuperAdminRoutes(),
        ...[{ path: "liquidacion", element: <Liquidacion /> }],
      ];
    if (userAUX.rol === "SUPER_ADMIN") return getSuperAdminRoutes();
    if (userAUX.rol === "ADMIN") return getAdminRoutes();
    if (userAUX.rol === "ADMIN_OPERACIONES") return getAdminOPRoutes();
    if (userAUX.rol === "SUPERVISOR") return getSupervisorRoutes();
    if (userAUX.rol === "EMPLEADO") return getEmpleadoRoutes();
    if (userAUX.rol === "CLIENTE") return getClienteRoutes();
    if (userAUX.rol === "GERENTE_SUCURSAL") return getGerenteSucursalRoutes();
  };

  const selectAppRoutesChildren = () => {
    const userAUX = loggout();

    let children = [
      { path: "/login", element: <Login /> },
      { path: "404", element: <SearchNotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ];

    if (userAUX && userAUX.rol) {
      if (userAUX.rol === "SUPER_ADMIN" || userAUX.rol === "ADMIN") {
        children.unshift({
          path: "/",
          element: <Navigate to="/dashboard/estadisticas" />,
        });
      } else {
        children.unshift({
          path: "/",
          element: <Navigate to="/dashboard/inicio" />,
        });
      }
    } else {
      children.unshift({
        path: "/",
        element: <Navigate to="/login" />,
      });
    }
    return children;
  };

  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: selectChildren(),
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: selectAppRoutesChildren(),
    },
    ...getPublicRoutes(),
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// ----------------------------------------------------------------------

export {
  getAdminRoutes,
  getEmpleadoRoutes,
  getSuperAdminRoutes,
  getSupervisorRoutes,
  getClienteRoutes,
  getAdminOPRoutes,
  getGerenteSucursalRoutes,
};
