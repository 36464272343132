import { Box, Typography } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React from "react";
import { formatearFecha } from "src/utils/formatTime";

const useStyles = makeStyles((theme) => ({
  expirationBanner: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`,
  },
  expirationIcon: {
    color: theme.palette.warning.main,
    fontSize: 40,
  },
}));

const ExpirationBanner = ({ isExpired, proposalData }) => {
  const classes = useStyles();

  if (!isExpired) return null;

  return (
    <Box className={classes.expirationBanner}>
      <Warning className={classes.expirationIcon} />
      <Box>
        <Typography variant="h6" gutterBottom>
          Propuesta Expirada
        </Typography>
        <Typography>
          Esta propuesta expiró el {formatearFecha(proposalData.expiryDate, "dddd DD [de] MMMM [del] YYYY")}. Por favor,
          contacta con nosotros para solicitar una nueva propuesta.
        </Typography>
      </Box>
    </Box>
  );
};

ExpirationBanner.propTypes = {
  proposalData: PropTypes.object.isRequired,
  isExpired: PropTypes.bool,
};

export default React.memo(ExpirationBanner);
