import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  API_KEY_OPEN_FACTURA_DEV,
  API_KEY_OPEN_FACTURA_PROD,
  API_OPEN_FACTURA_DEV,
  API_OPEN_FACTURA_PROD,
  API_SERVER,
  URL_ORIGEN,
} from "../../../utils/urlBack_End";
import { sentenceCase } from "change-case";
import { saveAs } from "file-saver";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DownloadIcon from "@material-ui/icons/Download";
import PaidIcon from "@material-ui/icons/Paid";
import CancelIcon from "@material-ui/icons/Cancel";
import * as locales from "@material-ui/core/locale";
// material
import {
  Button,
  Card,
  Container,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Divider,
  Chip,
  Box,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { TablaHead, TablaToolbar } from "../../_dashboard/tablas";
import Label from "../../Label";
import Scrollbar from "../../Scrollbar";
import SearchNotFound from "../../SearchNotFound";
import ModalChangeState from "../ModalChangeState";
import ModalForm from "../ModalForm";
import PagosFacturaPlanesDialog from "./PagosFacturaPlanesDialog";
import {
  actualizarFolioFactura,
  actualizarFolioFacturaTarea,
} from "../consultasFacturas";
import ModalVerFactura from "../ModalVerFactura";
import { Alerta } from "src/components/Alerta";
import { LoadingButton, LocalizationProvider } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { es } from "date-fns/locale";
import { useSelector } from "react-redux";
import moment from "moment";
import { facturasApi } from "src/services/facturasApi";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import { dteApi } from "src/services/dteApi";
import ObtenerFacturasPlanes from "./ObtenerFacturasPlanes";
import AutoCompleteField from "src/components/Modal/sharedFields/AutocompleteField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sucursalesApi } from "src/services/sucursalesApi";
import ExportarAExcel from "src/components/exportarExcelLiquidacion/ExportarExcelLiquidacion";
import handleError from "src/utils/manejo-errores.js";
import ExcelJS from "exceljs";
import {
  activarCalculoAutomatico,
  aplicarBordeUltimaFila,
  guardarArchivo,
} from "src/utils/excel-plantillas.js";
import dayjs from "dayjs";
import "dayjs/locale/es.js";
import { RutFormat, formatRut } from "@fdograph/rut-utilities";
import {
  ajustarFecha,
  calcularDiasRetraso,
  formatearFecha,
} from "src/utils/formatTime";
import Iconify from "src/components/iconify";
import checkCircleOutlined from "@iconify/icons-ant-design/check-circle-outlined";
import warningOutlined from "@iconify/icons-ant-design/warning-outlined";
import {
  capitalizarPrimeraLetra,
  decodeHtmlEntities,
} from "src/utils/sharedFunctions";
import CustomTablePagination from "src/components/CustomTablePagination";
import { SwapHoriz } from "@material-ui/icons";
import ModalCambioSucursal from "src/components/Modal/modalCambioSucursal";

dayjs.locale("es");

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const TABLE_HEAD = [
  { id: "rut", label: "RUT", alignRight: false },
  { id: "nombre", label: "Cliente", alignRight: false },
  { id: "telefono", label: "Teléfono", alignRight: false },
  { id: "folio", label: "Folio", alignRight: false },
  { id: "detalleFactura", label: "Glosa", alignRight: false },
  { id: "valorPesos", label: "Total ($)", alignRight: false },
  { id: "periodo", label: "Periodo", alignRight: false },
  { id: "fechaFacturacion", label: "Fecha Facturación", alignRight: false },
  { id: "fechaCorreo", label: "Fecha Envio Correo", alignRight: false },
  { id: "estadoCorreo", label: "Estado Correo", alignRight: false },
  { id: "estadoFactura", label: "Estado Facturación", alignRight: false },
  { id: "fechaPago", label: "Fecha Pago", alignRight: false },
  { id: "tipo", label: "Tipo Factura", alignRight: false },
  { id: "sucursal", label: "Sucursal", alignRight: false },
  { id: "retraso", label: "Retraso pago", alignRight: false },
  { id: "" },
];

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 10,
  order: "desc",
  orderBy: "fechaFacturacion",
  locale: "esES",
  filterName: "",
};

export default function FacturaPlanes({ tabIndex }) {
  const {
    empleado: user,
    sucursal: sucursalObtenida,
    rol: rolObtenido,
  } = useSelector((state) => state.auth.user);

  const [showObtenerFacturas, setShowObtenerFacturas] = useState(false);
  const [mostrarPagoFactura, setMostrarPagoFactura] = useState(false);
  const [statesTable, setStateTable] = useState(INITIAL_STATE_TABLE);
  const newDate = useMemo(() => new Date(), []);
  const [mesActual, setMesActual] = useState(newDate.getMonth());
  const [anioActual, setAnioActual] = useState(newDate.getFullYear());

  const [mesActual2, setMesActual2] = useState(newDate.getMonth());
  const [anioActual2, setAnioActual2] = useState(newDate.getFullYear());

  const [anios, setAnios] = useState([]);

  const [crearDTE] = dteApi.useCrearDTEMutation();

  const [loadingDescargar, setLoadingDescargar] = useState(false);
  const [loadingAnular, setLoadingAnular] = useState(false);

  const [getTokenFolioRef] = facturasApi.useLazyGetTokenFolioRefQuery();

  const {
    data: sucursalesEmpleado = [],
    isLoading: isLoadingSucursalesEmpleado,
  } = sucursalesApi.useGetSucursalesEmpleadosQuery(user.rut);

  const opcionesFiltro = !isLoadingSucursalesEmpleado
    ? [
        { id: 0, nombre: "TODAS" },
        ...sucursalesEmpleado.map((sucursal) => {
          const obj = {
            id: sucursal.refSucursal,
            nombre: sucursal.refSucursal,
          };
          return obj;
        }),
      ]
    : [];

  const ProrrateoSchema = Yup.object().shape({
    filtro: Yup.object()
      .shape({
        id: Yup.number().required(),
        nombre: Yup.string().required(),
      })
      .required("La sucursal es requerida"),
  });

  const formik = useFormik({
    initialValues: {
      filtro: { id: 0, nombre: "TODAS" },
      incluyeRemuneraciones: false,
    },
    validationSchema: ProrrateoSchema,
  });

  const { values, touched, errors, setFieldValue } = formik;

  const [exportarFacturasExcel] =
    facturasApi.useLazyGetFacturasTotalExportarQuery();
  const [loadingExport, setLoadingExport] = useState(false);

  const {
    data: facturas = { results: [], total: 0 },
    isLoading: loadingTable,
    refetch: recargarFacturas,
    isFetching: isFetchingSucursales,
  } = facturasApi.useGetFacturasPorSucursalQuery({
    sucursales:
      rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN"
        ? values.filtro.nombre === "TODAS" //Si selecciona todas las sucursales en el select, se pasan las sucursales del empleado para filtrar
          ? sucursalesEmpleado
          : values.filtro.nombre
        : sucursalObtenida,
    page: statesTable.currentPage,
    perPage: statesTable.rowsPerPageOptions,
    mesInicio: mesActual + 1 <= 9 ? `0${mesActual + 1}` : mesActual + 1,
    anioInicio: anioActual,
    mesFin: mesActual2 + 1 <= 9 ? `0${mesActual2 + 1}` : mesActual2 + 1,
    anioFin: anioActual2,
    search: statesTable.filterName,
  });

  const [modalCambioSucursal, setModalCambioSucursal] = useState(false);
  const [datosCambioSucursal, setDatosCambioSucursal] = useState(null);

  const [actualizarEstadoFacturaPlanes] =
    facturasApi.useUpdateEstadoFacturaPlanMutation();
  const [actualizarEstadoCorreoPlanes] =
    facturasApi.useUpdateEstadoCorreFacturaPlanesMutation();

  const [actualizarEstadoFacturaTareas] =
    facturasApi.useUpdateEstadoFacturaTareaMutation();
  const [actualizarEstadoCorreoTareas] =
    facturasApi.useUpdateEstadoCorreFacturaTareasMutation();

  const [actualizarEstadoFacturaAfecta] =
    facturasApi.useUpdateEstadoFacturaAfectaMutation();
  const [actualizarEstadoCorreoAfecta] =
    facturasApi.useUpdateEstadoCorreFacturaAfectaMutation();

  useEffect(() => {
    let arregloAnios = [];
    for (let index = newDate.getFullYear(); index >= 2020; index--) {
      arregloAnios.push(index);
    }
    setAnios(arregloAnios);
  }, [newDate]);

  function formatNumber(n) {
    n = n > 0 ? String(n).replace(/\D/g, "") : n;

    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }

  const handleChangePage = (event, newPage) => {
    setStateTable({ ...statesTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setStateTable({
      ...statesTable,
      rowsPerPageOptions: Number(event.target.value),
      currentPage: 0,
    });
  };

  const handleFilterByName = (event) => {
    setStateTable({ ...statesTable, filterName: event.target.value });
  };

  const facturasFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = [
      "folio",
      "refCliente",
      "valorPesos",
      "estadoFactura",
      "tipo",
    ];
    return applySortFilter(
      facturas.results,
      getComparator(statesTable.order, statesTable.orderBy),
      statesTable.filterName,
      propiedadesParaFiltrar
    );
  }, [
    facturas.results,
    statesTable.filterName,
    statesTable.order,
    statesTable.orderBy,
  ]);

  const filasVacias =
    !loadingTable && statesTable.currentPage > 0
      ? Math.max(
          0,
          (1 + statesTable.currentPage) * statesTable.rowsPerPageOptions -
            facturas.results.length
        )
      : 0;

  const seEncontroFactura = !loadingTable && facturas.results.length === 0;

  // .---------------- Alerta ------------------------
  const [showAlerta, setShowAlerta] = useState(false);
  const [alerta, setAlerta] = useState({
    mensaje: "error inesperado",
    color: "error",
  });

  const mostrarAlerta = (mensaje, color = "error") => {
    setAlerta({ mensaje, color });
    setShowAlerta(true);
  };

  const [selectedInvoice, setSelectedInvoice] = useState();

  //--------  Modal Factura  ------------------------------------
  const [selectFactura, setSelectFactura] = useState({
    estado: "",
    id: "",
    estadoCorreo: "",
    folio: "",
    refPlan: "",
    row: "",
  });

  const [facturaSeleccionada, setFacturaSeleccionada] = useState(null);

  const resetSelectFactura = () => {
    setSelectFactura({
      estado: "",
      id: "",
      estadoCorreo: "",
      folio: "",
      refPlan: "",
      row: "",
    });
  };

  //--------  Modal Correo  ------------------------------------

  const [openModalEC, setOpenModalEC] = useState(false);

  const handleClickEstadoCorreo = (row) => {
    setSelectedInvoice(row);
    setOpenModalEC(true);
  };

  const changeEstadoCorreo = async (estadoCorreo) => {
    let action;
    if (selectedInvoice.tipo === "PLAN") {
      actualizarEstadoCorreoPlanes({
        id: selectedInvoice.id,
        body: {
          empleadoEnviaCorreo: user.nombre + " " + user.apellidos,
          estadoCorreo: estadoCorreo,
        },
      }).unwrap();
    } else if (selectedInvoice.tipo === "TAREA") {
      actualizarEstadoCorreoTareas({
        id: selectedInvoice.id,
        body: {
          empleadoEnviaCorreo: user.nombre + " " + user.apellidos,
          estadoCorreo: estadoCorreo,
        },
      }).unwrap();
    } else {
      actualizarEstadoCorreoAfecta({
        id: selectedInvoice.id,
        body: {
          empleadoEnviaCorreo: user.nombre + " " + user.apellidos,
          estadoCorreo: estadoCorreo,
        },
      }).unwrap();
    }

    action
      .then(() => {
        setOpenModalEC(false);
        mostrarAlerta("Estado del correo actualizado con éxito.", "success");
        resetSelectFactura();
      })
      .catch(() => {
        mostrarAlerta("Error al cambiar estado del correo de la factura.");
      });
  };

  //--------  Modal Folio - Anulado  ------------------------------------

  const [openModalFA, setOpenModalFA] = useState(false);

  const submitFolioAnulada = async () => {
    let action;
    if (selectFactura.tipo === "PLAN") {
      action = actualizarEstadoFacturaPlanes({
        id: selectFactura.id,
        body: {
          folio: selectFactura.folio,
          estadoFactura: selectFactura.estado,
        },
      }).unwrap();
    } else if (selectFactura.tipo === "TAREA") {
      action = actualizarEstadoFacturaTareas({
        id: selectFactura.id,
        body: {
          folio: selectFactura.folio,
          estadoFactura: selectFactura.estado,
        },
      }).unwrap();
    } else {
      action = actualizarEstadoFacturaAfecta({
        id: selectFactura.id,
        body: {
          folio: selectFactura.folio,
          estadoFactura: selectFactura.estado,
        },
      }).unwrap();
    }

    action
      .then(() => {
        setOpenModalFA(false);
        mostrarAlerta("Factura anulada con éxito.", "success");
        resetSelectFactura();
      })
      .catch(() => {
        mostrarAlerta("Error al cambiar estado de la factura a 'ANULADA'.");
      });
  };

  //--------  Modal Folio  ------------------------------------

  const [openModalF, setOpenModalF] = useState(false);

  const handleClickFolio = (row) => {
    setSelectFactura({
      ...selectFactura,
      folio: row.folio,
      id: row.id,
      row,
    });
    setOpenModalF(true);
  };

  const changeFolio = async (folio) => {
    setSelectFactura({
      ...selectFactura,
      folio,
      id: selectFactura.id,
    });
  };

  const submitFolio = async () => {
    let error = false;

    if (selectFactura.tipo === "PLAN" || selectFactura.tipo === "AFECTA") {
      error = await actualizarFolioFactura({
        folio: selectFactura.folio,
        id: selectFactura.id,
      }).unwrap();
    } else {
      error = await actualizarFolioFacturaTarea({
        folio: selectFactura.folio,
        id: selectFactura.id,
      }).unwrap();
    }

    if (error === true) {
      setOpenModalF(false);
      recargarFacturas();
      mostrarAlerta("Folio de factura actualizado con éxito.", "success");
      resetSelectFactura();
    } else {
      mostrarAlerta("Error al cambiar Folio");
    }
  };

  //----------- Modal Ver ----------------------------------------
  const [openModalVF, setOpenModalVF] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc =
      statesTable.orderBy === property && statesTable.order === "asc";
    setStateTable({
      ...statesTable,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  const anularFactura = (row) => {
    setLoadingAnular(true);
    AlertaModal({
      title: "anular el documento",
      confirmButtonText: "Sí, anular",
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          crearDTE({
            montoFactura: row.valorPesos,
            tipoDTE: 61,
            tokenAnular: row.token,
            tipoFactura: row.tipo,
            ...(row.tipo === "TAREA" && { idTarea: row.refTarea }),
            sucursal: row.sucursal,
          })
            .unwrap()
            .then((res) => {
              const { error, PDF, FOLIO } = res;

              if (error || !PDF || !FOLIO) {
                const { details } = error;

                const detalle =
                  details && details.length > 0
                    ? details[0].issue
                    : error.message;

                const mensaje = detalle
                  ? `Error: ${detalle}`
                  : "Error desconocido al anular factura";

                AlertaToast({
                  action: mensaje,
                  fullText: true,
                  type: "error",
                }).fire();
                return;
              }
              saveAs("data:application/pdf;base64," + PDF, FOLIO + ".pdf");
              recargarFacturas();
              AlertaToast({
                element: "Documento tributario",
                action: "anulado",
              }).fire();
            })
            .catch((error) => {
              if (error.data?.message) {
                console.error(error);
                AlertaToast({
                  action: `Error: ${error.data?.message}`,
                  fullText: true,
                  type: "error",
                }).fire();
              } else {
                AlertaToast({
                  action: "Error al anular documento",
                  fullText: true,
                  type: "error",
                }).fire();
              }
            })
            .finally(() => {
              setLoadingAnular(false);
            });
        } else {
          setLoadingAnular(false);
        }
      });
  };
  const descargarFacturaAnulada = (factura) => {
    setLoadingDescargar(true);
    axios
      .get(`${API_SERVER}/facturas/tokenNotaCredito/${factura.folio}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (Array.isArray(response.data)) {
          let notaCreditoObtenido = response.data[0];
          axios
            .get(
              URL_ORIGEN.includes("http://localhost:3000")
                ? `${API_OPEN_FACTURA_DEV}/v2/dte/document/${notaCreditoObtenido.token}/pdf`
                : `${API_OPEN_FACTURA_PROD}/v2/dte/document/${notaCreditoObtenido.token}/pdf`,
              {
                headers: {
                  apikey: URL_ORIGEN.includes("http://localhost:3000")
                    ? API_KEY_OPEN_FACTURA_DEV
                    : API_KEY_OPEN_FACTURA_PROD,
                },
              }
            )
            .then((response2) => {
              saveAs(
                "data:application/pdf;base64," + response2.data.pdf,
                notaCreditoObtenido.folio + ".pdf"
              );
              setLoadingDescargar(false);
            })
            .catch(() => {
              setLoadingDescargar(false);
            });
        }
      })
      .catch(() => {
        setLoadingDescargar(false);
      });
  };

  const handleSucursalSelection = useCallback(
    (event, newValue) => {
      setFieldValue("filtro", newValue);
      setFieldValue("incluyeRemuneraciones", false);
    },
    [setFieldValue]
  );

  const handleOptionLabelDivision = useCallback((option) => option.nombre, []);

  const handleIsOptionEqualToValueDivision = useCallback(
    (option, value) => option.id === value.id,
    []
  );

  const manejarExportacionExcel = async () => {
    setLoadingExport(true);

    const body = {
      sucursales:
        rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN"
          ? values.filtro.nombre === "TODAS" //Si selecciona todas las sucursales en el select, se pasan las sucursales del empleado para filtrar
            ? sucursalesEmpleado
            : values.filtro.nombre
          : sucursalObtenida,
      mesInicio: mesActual + 1 <= 9 ? `0${mesActual + 1}` : mesActual + 1,
      anioInicio: anioActual,
      mesFin: mesActual2 + 1 <= 9 ? `0${mesActual2 + 1}` : mesActual2 + 1,
      anioFin: anioActual2,
      incluyeRemuneraciones: values.incluyeRemuneraciones,
    };

    try {
      const { data: facturas } = await exportarFacturasExcel(body);

      const templatePath = "/static/plantillas/PLANTILLA_FACTURAS.xlsx";

      const fechaInicio = dayjs()
        .year(anioActual)
        .month(mesActual)
        .format("MMMM YYYY");
      const fechaFin = dayjs()
        .year(anioActual2)
        .month(mesActual2)
        .format("MMMM YYYY");

      const datosExcel = {
        ...facturas,
        fechaInicio,
        fechaFin,
        sucursal: values.filtro.nombre,
      };

      const workbook = await rellenarPlantilla(templatePath, datosExcel);

      await guardarArchivo(
        workbook,
        `Facturas desde ${fechaInicio} hasta ${fechaFin}.xlsx`
      );
      setLoadingExport(false);
    } catch (error) {
      setLoadingExport(false);
      mostrarAlerta("Ocurrió un error desconocido al exportar facturas");
      handleError("manejarExportacionExcel", error);
    }
  };

  const openModalCambioSucursal = ({ id, antiguaSucursal, tipo }) => {
    setModalCambioSucursal(true);
    setDatosCambioSucursal({ id, antiguaSucursal, tipo });
  };

  if (tabIndex !== 0) return null;

  return (
    <>
      <Card>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            marginTop={1}
          >
            <Grid
              item
              xs={12}
              md={
                rolObtenido === "ADMIN" || rolObtenido === "SUPER_ADMIN"
                  ? 3
                  : 12
              }
              lg={
                rolObtenido === "ADMIN" || rolObtenido === "SUPER_ADMIN"
                  ? 3
                  : 12
              }
            >
              <TablaToolbar
                filterName={statesTable.filterName}
                onFilterName={handleFilterByName}
              />
            </Grid>

            {(rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN") && (
              <AutoCompleteField
                sx={{
                  marginRight: { md: 0, xs: 3, sm: 3 },
                  marginLeft: { md: 0, xs: 3, sm: 3 },
                }}
                disableClearable={true}
                id="autocomplete-filtro-facturas-sucursal"
                label="Sucursal"
                value={values.filtro}
                xs={12}
                md={3}
                lg={3}
                handleSucursalSelection={handleSucursalSelection}
                options={opcionesFiltro}
                touched={touched.filtro}
                error={errors.filtro}
                getOptionLabel={handleOptionLabelDivision}
                isOptionEqualToValue={handleIsOptionEqualToValueDivision}
                typeRenderInput="normal"
              />
            )}

            <Grid item lg={2} xs={12} md={2}>
              <Container>
                {(rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN") && (
                  <Button
                    variant="contained"
                    onClick={() => setShowObtenerFacturas(true)}
                  >
                    Obtener Facturas
                  </Button>
                )}
              </Container>
            </Grid>
            <Grid item lg={4} xs={12} md={4}>
              <Container sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <ExportarAExcel
                  nombreBoton="facturas"
                  onClick={manejarExportacionExcel}
                  loadingExport={loadingExport}
                />
                {values.filtro.nombre !== "REMUNERACIONES" &&
                  values.filtro.nombre !== "TODAS" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={values.incluyeRemuneraciones}
                          color="primary"
                          onChange={(e) =>
                            formik.setFieldValue(
                              "incluyeRemuneraciones",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="Incluir Remuneraciones"
                    />
                  )}
              </Container>
            </Grid>
          </Grid>
        </LocalizationProvider>
        <Divider sx={{ padding: 1 }} />
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
          <Grid
            container
            style={{ padding: "2rem 1.5rem 0rem 1.5rem" }}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item xs={12} md={5} style={{ marginRight: "10px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      MES INICIO
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={mesActual + 1}
                      label="MES"
                      onChange={(event) => {
                        setMesActual(event.target.value - 1);
                      }}
                    >
                      <MenuItem value={1}>ENERO</MenuItem>
                      <MenuItem value={2}>FEBRERO</MenuItem>
                      <MenuItem value={3}>MARZO</MenuItem>
                      <MenuItem value={4}>ABRIL</MenuItem>
                      <MenuItem value={5}>MAYO</MenuItem>
                      <MenuItem value={6}>JUNIO</MenuItem>
                      <MenuItem value={7}>JULIO</MenuItem>
                      <MenuItem value={8}>AGOSTO</MenuItem>
                      <MenuItem value={9}>SEPTIEMBRE</MenuItem>
                      <MenuItem value={10}>OCTUBRE</MenuItem>
                      <MenuItem value={11}>NOVIEMBRE</MenuItem>
                      <MenuItem value={12}>DICIEMBRE</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      AÑO INICIO
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={anioActual}
                      label="AÑO"
                      onChange={(event) => {
                        setAnioActual(event.target.value);
                      }}
                    >
                      {anios.map((elemento, idx) => {
                        return (
                          <MenuItem key={"anios_" + idx} value={elemento}>
                            {elemento}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs={12} md={5} style={{ marginRight: "10px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      MES FIN
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={mesActual2 + 1}
                      label="MES"
                      onChange={(event) => {
                        setMesActual2(event.target.value - 1);
                      }}
                    >
                      <MenuItem value={1}>ENERO</MenuItem>
                      <MenuItem value={2}>FEBRERO</MenuItem>
                      <MenuItem value={3}>MARZO</MenuItem>
                      <MenuItem value={4}>ABRIL</MenuItem>
                      <MenuItem value={5}>MAYO</MenuItem>
                      <MenuItem value={6}>JUNIO</MenuItem>
                      <MenuItem value={7}>JULIO</MenuItem>
                      <MenuItem value={8}>AGOSTO</MenuItem>
                      <MenuItem value={9}>SEPTIEMBRE</MenuItem>
                      <MenuItem value={10}>OCTUBRE</MenuItem>
                      <MenuItem value={11}>NOVIEMBRE</MenuItem>
                      <MenuItem value={12}>DICIEMBRE</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      AÑO FIN
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={anioActual2}
                      label="AÑO"
                      onChange={(event) => {
                        setAnioActual2(event.target.value);
                      }}
                    >
                      {anios.map((elemento, idx) => {
                        return (
                          <MenuItem key={"anios2_" + idx} value={elemento}>
                            {elemento}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </LocalizationProvider>
        <TableContainer>
          {!isFetchingSucursales ? (
            <Scrollbar>
              <Table stickyHeader>
                <TablaHead
                  order={statesTable.order}
                  orderBy={statesTable.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={!loadingTable && facturas.results.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {facturasFiltradas.map((row) => {
                    const {
                      id,
                      refCliente,
                      nombre,
                      folio,
                      valorPesos,
                      fecha,
                      sucursal,
                      fechaFacturacion,
                      fechaCorreo,
                      estadoCorreo,
                      estadoFactura,
                      fechaPago,
                      detalleFactura,
                      telefonoCliente,
                      tipo,
                    } = row;

                    const diasRetraso =
                      estadoFactura.toLowerCase() !== "pagado" &&
                      estadoFactura.toLowerCase() !== "anulado" &&
                      estadoFactura.toLowerCase() !== "incobrable"
                        ? calcularDiasRetraso(fechaFacturacion)
                        : 0;

                    const periodo =
                      sucursal && sucursal.toLowerCase() === "remuneraciones"
                        ? capitalizarPrimeraLetra(
                            formatearFecha(ajustarFecha(fecha, -1), "MMMM")
                          )
                        : capitalizarPrimeraLetra(
                            formatearFecha(fecha, "MMMM")
                          );

                    return (
                      <TableRow hover key={id}>
                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {refCliente
                              ? formatRut(refCliente, RutFormat.DOTS_DASH)
                              : "Cliente no encontrado"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="subtitle2">
                            {nombre ? nombre : "S/I"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {telefonoCliente ? telefonoCliente : "S/I"}
                        </TableCell>
                        <TableCell
                          align="left"
                          //onClick={() => handleClickFolio(row)}
                          style={{ cursor: "pointer" }}
                        >
                          <Button
                            color="primary"
                            disabled={
                              estadoFactura !== "NO FACTURADO" ||
                              estadoCorreo === "ENVIADO"
                            }
                            onClick={() => handleClickFolio(row)}
                          >
                            {folio ? folio : 0}
                          </Button>
                        </TableCell>
                        <TableCell align="left">
                          {detalleFactura
                            ? decodeHtmlEntities(detalleFactura, document)
                            : "S/I"}
                        </TableCell>
                        <TableCell align="left">
                          {formatNumber(valorPesos)}
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {periodo}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {fechaFacturacion === ""
                            ? fechaFacturacion
                            : moment(fechaFacturacion, "YYYY/MM/DD").format(
                                "DD/MM/YYYY"
                              )}
                        </TableCell>
                        <TableCell align="left">
                          {fechaCorreo === ""
                            ? "Sin enviar"
                            : moment(fechaCorreo, "YYYY/MM/DD").format(
                                "DD/MM/YYYY"
                              )}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            disabled={
                              estadoFactura !== "FACTURADO" ||
                              folio === "" ||
                              estadoCorreo === "ENVIADO"
                            }
                            onClick={() => handleClickEstadoCorreo(row)}
                          >
                            <Label
                              variant="ghost"
                              color={
                                (estadoCorreo === "ENVIADO" && "success") ||
                                "error"
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {sentenceCase(
                                estadoCorreo === ""
                                  ? "No enviado"
                                  : estadoCorreo
                              )}
                            </Label>
                          </Button>
                        </TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={
                              estadoFactura === "FACTURADO"
                                ? "success"
                                : estadoFactura === "PAGADO"
                                ? "info"
                                : "error"
                            }
                          >
                            {sentenceCase(estadoFactura)}
                          </Label>
                        </TableCell>

                        <TableCell align="left">
                          {fechaPago ? fechaPago : ""}
                        </TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={
                              (tipo === "PLAN" && "success") ||
                              (tipo === "TAREA" && "info") ||
                              "error"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {sentenceCase(tipo)}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {sucursal}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {diasRetraso > 0 ? (
                            <Chip
                              variant="outlined"
                              icon={
                                <Iconify width={14} icon={warningOutlined} />
                              }
                              label={`${diasRetraso} días`}
                              color="error"
                              size="small"
                            />
                          ) : (
                            <Chip
                              variant="outlined"
                              icon={
                                <Iconify
                                  width={14}
                                  icon={checkCircleOutlined}
                                />
                              }
                              label="Sin retraso"
                              color="success"
                              size="small"
                            />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Grid container>
                            {(rolObtenido === "ADMIN" ||
                              rolObtenido === "SUPER_ADMIN") && (
                              <Grid item>
                                <Button
                                  color="secondary"
                                  onClick={() =>
                                    openModalCambioSucursal({
                                      id,
                                      tipo,
                                      antiguaSucursal: sucursal,
                                    })
                                  }
                                >
                                  <SwapHoriz
                                    style={{ marginRight: "0.5rem" }}
                                  />{" "}
                                  Cambiar sucursal
                                </Button>
                              </Grid>
                            )}

                            {estadoFactura === "FACTURADO" && (
                              <>
                                {rolObtenido !== "EMPLEADO" &&
                                  rolObtenido !== "SUPERVISOR" &&
                                  rolObtenido !== "ADMIN_OPERACIONES" && (
                                    <Grid item>
                                      <LoadingButton
                                        loading={loadingAnular}
                                        color="error"
                                        onClick={() => {
                                          anularFactura(row);
                                        }}
                                      >
                                        <CancelIcon
                                          style={{ marginRight: "0.5rem" }}
                                        />{" "}
                                        Anular
                                      </LoadingButton>
                                    </Grid>
                                  )}
                              </>
                            )}
                            {estadoFactura !== "ANULADO" && (
                              <>
                                <Grid item>
                                  {" "}
                                  <Button
                                    onClick={() => {
                                      setFacturaSeleccionada(row);
                                      setOpenModalVF(true);
                                    }}
                                  >
                                    {" "}
                                    <VisibilityIcon
                                      style={{ marginRight: "0.5rem" }}
                                    />{" "}
                                    Ver pagos
                                  </Button>
                                </Grid>
                                {rolObtenido !== "EMPLEADO" &&
                                  rolObtenido !== "GERENTE_SUCURSAL" && (
                                    <Grid item>
                                      <Button
                                        disabled={
                                          estadoFactura === "PAGADO" ||
                                          estadoFactura === "INCOBRABLE"
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          setFacturaSeleccionada(row);
                                          setMostrarPagoFactura(true);
                                        }}
                                      >
                                        <PaidIcon
                                          style={{ marginRight: "0.5rem" }}
                                        />{" "}
                                        Pagar
                                      </Button>
                                    </Grid>
                                  )}
                              </>
                            )}

                            <Grid item>
                              {estadoFactura === "ANULADO" ? (
                                <LoadingButton
                                  color="primary"
                                  loading={loadingDescargar}
                                  onClick={async () => {
                                    setLoadingDescargar(true);
                                    if (
                                      row.tipo === "PLAN" ||
                                      row.tipo === "TAREA"
                                    ) {
                                      const tokenRef = await getTokenFolioRef({
                                        folio: row.folio,
                                        tipoFactura:
                                          row.tipo === "PLAN"
                                            ? "Factura Plan"
                                            : "Factura Tarea",
                                      }).unwrap();

                                      if (
                                        tokenRef?.token &&
                                        tokenRef?.estadoFactura !== "CORRECCIÓN"
                                      ) {
                                        axios
                                          .get(
                                            URL_ORIGEN.includes(
                                              "http://localhost:3000"
                                            )
                                              ? `${API_OPEN_FACTURA_DEV}/v2/dte/document/${tokenRef.token}/pdf`
                                              : `${API_OPEN_FACTURA_PROD}/v2/dte/document/${tokenRef.token}/pdf`,
                                            {
                                              headers: {
                                                apikey: URL_ORIGEN.includes(
                                                  "http://localhost:3000"
                                                )
                                                  ? API_KEY_OPEN_FACTURA_DEV
                                                  : API_KEY_OPEN_FACTURA_PROD,
                                              },
                                            }
                                          )
                                          .then((response) => {
                                            saveAs(
                                              "data:application/pdf;base64," +
                                                response.data.pdf,
                                              tokenRef.folio + ".pdf"
                                            );
                                            setLoadingDescargar(false);
                                          })
                                          .catch(() => {
                                            setLoadingDescargar(false);
                                          });
                                      } else {
                                        if (row.token) {
                                          axios
                                            .get(
                                              URL_ORIGEN.includes(
                                                "http://localhost:3000"
                                              )
                                                ? `${API_OPEN_FACTURA_DEV}/v2/dte/document/${row.token}/pdf`
                                                : `${API_OPEN_FACTURA_PROD}/v2/dte/document/${row.token}/pdf`,
                                              {
                                                headers: {
                                                  apikey: URL_ORIGEN.includes(
                                                    "http://localhost:3000"
                                                  )
                                                    ? API_KEY_OPEN_FACTURA_DEV
                                                    : API_KEY_OPEN_FACTURA_PROD,
                                                },
                                              }
                                            )
                                            .then((response) => {
                                              saveAs(
                                                "data:application/pdf;base64," +
                                                  response.data.pdf,
                                                row.folio + ".pdf"
                                              );
                                              setLoadingDescargar(false);
                                            })
                                            .catch(() => {
                                              setLoadingDescargar(false);
                                            });
                                        } else {
                                          AlertaToast({
                                            action:
                                              "Error, documento no encontrado",
                                            fullText: true,
                                            type: "error",
                                          }).fire();
                                          setLoadingDescargar(false);
                                        }
                                      }
                                    } else {
                                      descargarFacturaAnulada(row);
                                    }
                                  }}
                                >
                                  <DownloadIcon
                                    style={{ marginRight: "0.5rem" }}
                                  />{" "}
                                  Descargar
                                </LoadingButton>
                              ) : (
                                <LoadingButton
                                  color="primary"
                                  loading={loadingDescargar}
                                  onClick={async () => {
                                    setLoadingDescargar(true);

                                    if (
                                      row.tipo === "PLAN" ||
                                      row.tipo === "TAREA"
                                    ) {
                                      const tokenRef = await getTokenFolioRef({
                                        folio: row.folio,
                                        tipoFactura:
                                          row.tipo === "PLAN"
                                            ? "Factura Plan"
                                            : "Factura Tarea",
                                      }).unwrap();

                                      if (
                                        tokenRef?.token &&
                                        tokenRef?.estadoFactura !== "CORRECCIÓN"
                                      ) {
                                        axios
                                          .get(
                                            URL_ORIGEN.includes(
                                              "http://localhost:3000"
                                            )
                                              ? `${API_OPEN_FACTURA_DEV}/v2/dte/document/${tokenRef.token}/pdf`
                                              : `${API_OPEN_FACTURA_PROD}/v2/dte/document/${tokenRef.token}/pdf`,
                                            {
                                              headers: {
                                                apikey: URL_ORIGEN.includes(
                                                  "http://localhost:3000"
                                                )
                                                  ? API_KEY_OPEN_FACTURA_DEV
                                                  : API_KEY_OPEN_FACTURA_PROD,
                                              },
                                            }
                                          )
                                          .then((response) => {
                                            saveAs(
                                              "data:application/pdf;base64," +
                                                response.data.pdf,
                                              tokenRef.folio + ".pdf"
                                            );
                                            setLoadingDescargar(false);
                                          })
                                          .catch(() => {
                                            setLoadingDescargar(false);
                                          });
                                      } else {
                                        if (row.token) {
                                          axios
                                            .get(
                                              URL_ORIGEN.includes(
                                                "http://localhost:3000"
                                              )
                                                ? `${API_OPEN_FACTURA_DEV}/v2/dte/document/${row.token}/pdf`
                                                : `${API_OPEN_FACTURA_PROD}/v2/dte/document/${row.token}/pdf`,
                                              {
                                                headers: {
                                                  apikey: URL_ORIGEN.includes(
                                                    "http://localhost:3000"
                                                  )
                                                    ? API_KEY_OPEN_FACTURA_DEV
                                                    : API_KEY_OPEN_FACTURA_PROD,
                                                },
                                              }
                                            )
                                            .then((response) => {
                                              saveAs(
                                                "data:application/pdf;base64," +
                                                  response.data.pdf,
                                                row.folio + ".pdf"
                                              );
                                              setLoadingDescargar(false);
                                            })
                                            .catch(() => {
                                              setLoadingDescargar(false);
                                            });
                                        } else {
                                          AlertaToast({
                                            action:
                                              "Error, documento no encontrado",
                                            fullText: true,
                                            type: "error",
                                          }).fire();
                                          setLoadingDescargar(false);
                                        }
                                      }
                                    } else {
                                      axios
                                        .get(
                                          URL_ORIGEN.includes(
                                            "http://localhost:3000"
                                          )
                                            ? `${API_OPEN_FACTURA_DEV}/v2/dte/document/${row.token}/pdf`
                                            : `${API_OPEN_FACTURA_PROD}/v2/dte/document/${row.token}/pdf`,
                                          {
                                            headers: {
                                              apikey: URL_ORIGEN.includes(
                                                "http://localhost:3000"
                                              )
                                                ? API_KEY_OPEN_FACTURA_DEV
                                                : API_KEY_OPEN_FACTURA_PROD,
                                            },
                                          }
                                        )
                                        .then((response) => {
                                          saveAs(
                                            "data:application/pdf;base64," +
                                              response.data.pdf,
                                            row.folio + ".pdf"
                                          );
                                          setLoadingDescargar(false);
                                        })
                                        .catch(() => {
                                          setLoadingDescargar(false);
                                        });
                                    }
                                  }}
                                >
                                  <DownloadIcon
                                    style={{ marginRight: "0.5rem" }}
                                  />{" "}
                                  Descargar
                                </LoadingButton>
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {filasVacias > 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={15} />
                    </TableRow>
                  )}
                </TableBody>

                {seEncontroFactura && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={15} sx={{ py: 3 }}>
                        <SearchNotFound
                          searchFilter={true}
                          searchQuery={statesTable.filterName}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </Scrollbar>
          ) : (
            <Skeleton variant="rectangular" width="100%" height="500px" />
          )}
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <ThemeProvider
            theme={(outerTheme) =>
              createTheme(outerTheme, locales[statesTable.locale])
            }
          >
            <CustomTablePagination
              count={!loadingTable ? facturas.total : 0}
              rowsPerPage={statesTable.rowsPerPageOptions}
              page={statesTable.currentPage}
              colSpan={16}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 15, 50]}
            />
          </ThemeProvider>
        </Box>

        {/* 
        <ThemeProvider
          theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={facturas.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ThemeProvider> */}
      </Card>

      {selectedInvoice && (
        <ModalChangeState
          object={selectedInvoice}
          onSubmit={changeEstadoCorreo}
          title="Cambiar estado correo factura"
          state="estadoCorreo"
          onClose={() => setOpenModalEC(false)}
          states={["NO ENVIADO", "ENVIADO"]}
          disabled={optionsEstadoCorreoFactura[selectedInvoice.estadoCorreo]}
          open={openModalEC}
        />
      )}

      <ModalForm
        open={openModalF}
        setOpen={setOpenModalF}
        value={selectFactura.folio}
        title="Ingrese Folio"
        content=""
        label="Folio"
        type="String"
        onSubmit={submitFolio}
        setUpdate={changeFolio}
      />

      <ModalForm
        open={openModalFA}
        setOpen={setOpenModalFA}
        value={selectFactura.folio}
        title="Ingrese Nuevo Folio"
        content=""
        label="Folio"
        type="String"
        onSubmit={submitFolioAnulada}
        setUpdate={changeFolio}
      />

      {openModalVF && facturaSeleccionada && (
        <ModalVerFactura
          open={openModalVF}
          setOpen={setOpenModalVF}
          factura={facturaSeleccionada}
        />
      )}
      {mostrarPagoFactura && (
        <PagosFacturaPlanesDialog
          open={mostrarPagoFactura}
          setOpen={setMostrarPagoFactura}
          factura={facturaSeleccionada}
          recargarFacturas={recargarFacturas}
        />
      )}
      {showObtenerFacturas && (
        <ObtenerFacturasPlanes
          open={showObtenerFacturas}
          setOpen={setShowObtenerFacturas}
        />
      )}
      <Alerta
        showAlert={showAlerta}
        setShowAlert={setShowAlerta}
        message={alerta.mensaje}
        color={alerta.color}
      />
      {modalCambioSucursal && (
        <ModalCambioSucursal
          data={datosCambioSucursal}
          isOpen={modalCambioSucursal}
          setOpen={setModalCambioSucursal}
          sucursales={sucursalesEmpleado}
        />
      )}
    </>
  );
}

// const optionsEstadoFactura = {
//   "NO FACTURADO": [true, false, false],
//   FACTURADO: [true, true, false],
//   ANULADO: [true, true, true],
//   default: [false, false, false],
// };

const rellenar = (
  worksheet,
  datosTabla,
  tituloTabla,
  filaInicial,
  columnas
) => {
  try {
    const {
      HEADER_TITLE,
      TITLE_FONT_STYLE,
      HEADER_FILL,
      HEADER_FONT_STYLE,
      DATA_FONT_STYLE,
    } = CONSTANTES_EXCEL;

    const primeraColumna = columnas[0];
    const ultimaColumna = columnas[columnas.length - 1];

    worksheet.getCell(`${primeraColumna.id}${filaInicial - 1}`).value =
      tituloTabla;
    worksheet.mergeCells(
      `${primeraColumna.id}${filaInicial - 1}`,
      `${ultimaColumna.id}${filaInicial - 1}`
    );
    worksheet.getCell(`${primeraColumna.id}${filaInicial - 1}`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell(`${primeraColumna.id}${filaInicial - 1}`).fill =
      HEADER_TITLE;
    worksheet.getCell(`${primeraColumna.id}${filaInicial - 1}`).font =
      TITLE_FONT_STYLE;

    columnas.forEach((columna) => {
      worksheet.getCell(`${columna.id}${filaInicial}`).value = columna.titulo;
      worksheet.getCell(`${columna.id}${filaInicial}`).fill = HEADER_FILL;
      worksheet.getCell(`${columna.id}${filaInicial}`).font = HEADER_FONT_STYLE;
      worksheet.getCell(`${columna.id}${filaInicial}`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });

    datosTabla.forEach((dato, index) => {
      const rowIndex = filaInicial + 1 + index;
      columnas.forEach((columna) => {
        const valor = dato[columna.propiedad];
        const cell = worksheet.getCell(`${columna.id}${rowIndex}`);
        cell.font = DATA_FONT_STYLE;

        // Aplicar formato numérico si el valor es un número
        if (!isNaN(valor) && columna.propiedad !== "diasRetraso") {
          cell.value = valor;
          cell.numFmt = `$#,##0`;
        }

        if (columna.propiedad === "refCliente") {
          const rut = formatRut(valor, RutFormat.DOTS_DASH);
          cell.value = rut;
          cell.numFmt = "@"; // Se mantiene formato string en Excel
        } else if (columna.propiedad === "diasRetraso" && valor > 0) {
          // Cambiar el color del texto si el valor de dias retraso es mayor que 0
          cell.font = {
            ...DATA_FONT_STYLE,
            color: { argb: "FFFF0000" },
          };
          cell.value = valor;
          cell.numFmt = `#,##0`;
        } else {
          cell.value = valor;
        }

        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
      });
    });

    worksheet.columns.forEach((column, columnIndex) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const text = cell.text || "";
        maxLength = Math.max(maxLength, text.length);
      });
      column.width = maxLength + 7;
    });
  } catch (error) {
    handleError("rellenar", error);
  }
};

const rellenarTabla = (worksheet, datos, tituloTabla, tipo) => {
  try {
    let columnas = [
      { id: "A", titulo: "Cliente", propiedad: "nombre" },
      { id: "B", titulo: "RUT cliente", propiedad: "refCliente" },
      { id: "C", titulo: "Sucursal", propiedad: "sucursal" },
      { id: "D", titulo: "Tipo factura", propiedad: "tipo" },
      { id: "E", titulo: "Folio factura", propiedad: "folio" },
      { id: "F", titulo: "Glosa", propiedad: "detalleFactura" },
      { id: "G", titulo: "Estado factura", propiedad: "estadoFactura" },
      { id: "H", titulo: "Referencia folio", propiedad: "folioRef" },
      { id: "I", titulo: "Precio factura", propiedad: "valorPesos" },
      { id: "J", titulo: "Abono factura", propiedad: "abono" },
      { id: "K", titulo: "Deuda", propiedad: "deuda" },
      { id: "L", titulo: "Fecha facturación", propiedad: "fecha_facturacion" },
      { id: "M", titulo: "Días retraso", propiedad: "diasRetraso" },
      { id: "N", titulo: "Empleado", propiedad: "empleado" },
    ];
    // if (tipo === "TAREA") {
    //   const nuevaColumna = {
    //     id: "N",
    //     titulo: "Empleado",
    //     propiedad: "empleado",
    //   };
    //   columnas.push(nuevaColumna);
    // }

    const filaInicialTabla = 8;

    rellenar(worksheet, datos, tituloTabla, filaInicialTabla, columnas);
  } catch (error) {
    handleError("rellenarTabla", error);
  }
};

const cargarPlantilla = async (templatePath) => {
  const workbook = new ExcelJS.Workbook();
  const templateBuffer = await fetch(templatePath).then((response) =>
    response.arrayBuffer()
  );
  await workbook.xlsx.load(templateBuffer);
  return workbook;
};

const actualizarHojaFacturas = (
  workbook,
  facturas,
  nombreHoja,
  fechaInicio,
  fechaFin,
  sucursal
) => {
  const worksheet = workbook.getWorksheet(nombreHoja);
  if (facturas.length === 0) {
    worksheet.destroy();
    return;
  }
  worksheet.name = nombreHoja;
  colocarEncabezados(worksheet, fechaInicio, fechaFin, sucursal);
  rellenarTabla(
    worksheet,
    facturas,
    nombreHoja,
    nombreHoja === "Facturas de Tareas" ? "TAREA" : "PLAN"
  );
  aplicarBordeUltimaFila(worksheet, 0, 14);
};

const colocarEncabezados = (worksheet, fechaInicio, fechaFin, sucursal) => {
  worksheet.eachRow({ includeEmpty: true }, (row) => {
    row.eachCell((cell) => {
      if (cell.value && typeof cell.value === "string") {
        cell.value = cell.value
          .replace(/"FECHAINICIO"/g, fechaInicio.toUpperCase())
          .replace(/"FECHATERMINO"/g, fechaFin.toUpperCase())
          .replace(/"SUCURSAL"/g, sucursal);
      }
    });
  });
};

const rellenarPlantilla = async (templatePath, datos) => {
  try {
    // Crear un nuevo objeto Workbook de ExcelJS junto a su plantilla
    const workbook = await cargarPlantilla(templatePath);
    // Habilitar el cálculo automático en la hoja
    activarCalculoAutomatico(workbook);

    const { facturas, fechaInicio, fechaFin, sucursal } = datos;

    actualizarHojaFacturas(
      workbook,
      facturas,
      "Facturas",
      fechaInicio,
      fechaFin,
      sucursal
    );

    workbook.views = {
      activeTab: 0,
    };
    return workbook;
  } catch (error) {
    handleError("rellenarPlantilla", error);
  }
};

const optionsEstadoCorreoFactura = {
  "NO ENVIADO": [true, false],
  ENVIADO: [true, true],
  default: [false, false],
};

const CONSTANTES_EXCEL = {
  HEADER_FONT_STYLE: {
    name: "Arial Nova Cond",
    size: 12,
    bold: true,
    color: { argb: "44546A" },
  },
  TITLE_FONT_STYLE: {
    name: "Arial Nova Cond",
    size: 12,
    bold: true,
    color: { argb: "FFFFFF" },
  },
  DATA_FONT_STYLE: {
    name: "Arial Nova Cond",
    size: 12,
  },
  HEADER_FILL: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "ED7D31" },
  },
  HEADER_TITLE: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  },
};
