import { replace } from "lodash";
import numeral from "numeral";

// ----------------------------------------------------------------------

// Configurar el idioma para utilizar el punto como separador de miles
numeral.register("locale", "custom", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "K",
    million: "M",
    billion: "B",
    trillion: "T",
  },
  ordinal: function (number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "$",
  },
});

// Establecer el idioma por defecto
numeral.locale("custom");

export function formatAsCLP(amount) {
  const formatter = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  });
  return formatter.format(isNaN(Number(amount)) ? 0 : amount);
}

// Función para formatear números como moneda
export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
}

export function fPercent(number) {
  return numeral(number / 100).format("0.0%");
}

export function formatDecimal(number) {
  const num = typeof number === "string" ? parseFloat(number) : number;

  if (isNaN(num)) return "0";

  const parts = num.toString().split(".");

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return parts.length > 1 ? parts.join(",") : parts[0];
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}
export function formatoFecha() {
  let date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let fechaFinal = String(year) + "/";

  if (month < 10) {
    fechaFinal = fechaFinal + "0" + String(month) + "/";
  } else {
    fechaFinal = fechaFinal + String(month) + "/";
  }

  if (day < 10) {
    fechaFinal = fechaFinal + "0" + String(day);
  } else {
    fechaFinal = fechaFinal + String(day);
  }

  return fechaFinal;
}

export function formatoFecha2() {
  let date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let fechaFinal = String(year) + "-";

  if (month < 10) {
    fechaFinal = fechaFinal + "0" + String(month) + "-";
  } else {
    fechaFinal = fechaFinal + String(month) + "-";
  }

  if (day < 10) {
    fechaFinal = fechaFinal + "0" + String(day);
  } else {
    fechaFinal = fechaFinal + String(day);
  }

  return fechaFinal;
}
