import { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import {
  NavLink as RouterLink,
  // matchPath,
  // useLocation,
} from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import {
  alpha,
  useTheme,
  experimentalStyled as styled,
} from "@material-ui/core/styles";
import {
  Box,
  List,
  ListItem,
  Collapse,
  ListItemText,
  ListItemIcon,
  Stack,
} from "@material-ui/core";

import {
  getAdminRoutes,
  getEmpleadoRoutes,
  getSuperAdminRoutes,
  getSupervisorRoutes,
  getClienteRoutes,
  getAdminOPRoutes,
  getGerenteSucursalRoutes,
} from "../routes/routes";
import { useSelector } from "react-redux";
import Liquidacion from "src/pages/Liquidacion";
import { StyledSubheader } from "src/layouts/styles";
import NavList from "src/layouts/dashboard/NavList";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItem button disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  // const { pathname } = useLocation();
  const { rol: rolObtenido, empleado: empleadoObtenido } = useSelector(
    (state) => state.auth.user
  );

  const filteredNavConfig = filterNavConfig();

  function filterNavConfig() {
    if (rolObtenido === "SUPER_ADMIN") return getSuperAdminNavConfig();
    if (rolObtenido === "ADMIN") return getAdminNavConfig();
    if (rolObtenido === "SUPERVISOR") return getSupervisorNavConfig();
    if (rolObtenido === "EMPLEADO") return getEmpleadoNavConfig();
    if (rolObtenido === "CLIENTE") return getClienteNavConfig();
    if (rolObtenido === "ADMIN_OPERACIONES") return getAdminOPNavConfig();
    if (rolObtenido === "GERENTE_SUCURSAL") return getGerenteSucursalConfig();
  }

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  // Revisa si las rutas a las que tiene acceso un usuario contienen cierta
  // ruta
  function routesContainPath(routes, permittedPaths) {
    const rutasPermitidas = new Set(routes.map((ruta) => ruta.path));

    return permittedPaths.reduce((acc, seccion) => {
      const itemsFiltrados = seccion.items.filter((item) => {
        let pathWithNoSpaces = item.title.replaceAll(" ", "-");
        pathWithNoSpaces = removeAccents(pathWithNoSpaces);
        return rutasPermitidas.has(pathWithNoSpaces);
      });

      if (itemsFiltrados.length > 0) {
        acc.push({
          ...seccion,
          items: itemsFiltrados,
        });
      }

      return acc;
    }, []);
  }

  // El SUPER_ADMIN puede ver todas las opciones de la barra lateral
  function getSuperAdminNavConfig() {
    if (
      (empleadoObtenido.rut === "192613639" ||
        empleadoObtenido.rut === "194467354" ||
        empleadoObtenido.rut === "121695979" ||
        empleadoObtenido.rut === "154157131" ||
        empleadoObtenido.rut === "132012636" ||
        empleadoObtenido.rut === "193928595") &&
      rolObtenido === "SUPER_ADMIN"
    ) {
      return routesContainPath(
        [
          ...getSuperAdminRoutes(),
          ...[{ path: "liquidacion", element: <Liquidacion /> }],
        ],
        navConfig
      );
    } else {
      return routesContainPath(getSuperAdminRoutes(), navConfig);
    }
  }

  // Filtra las opciones de la barra lateral que coinciden con las rutas a las
  // que tiene acceso el ADMIN. Además remueve las rutas que el ADMIN tenga
  // restringidas
  function getAdminNavConfig() {
    return routesContainPath(getAdminRoutes(), navConfig);
  }

  // Filtra las opciones de la barra lateral que coinciden con las rutas a las
  // que tiene acceso el SUPERVISOR
  function getSupervisorNavConfig() {
    return routesContainPath(getSupervisorRoutes(), navConfig);
  }

  // Filtra las opciones de la barra lateral que coinciden con las rutas a las
  // que tiene acceso el EMPLEADO
  function getEmpleadoNavConfig() {
    return routesContainPath(getEmpleadoRoutes(), navConfig);
  }

  // Filtra las opciones de la barra lateral que coinciden con las rutas a las
  // que tiene acceso el ADMIN DE OPERACIONES
  function getAdminOPNavConfig() {
    return routesContainPath(getAdminOPRoutes(), navConfig);
  }

  function getGerenteSucursalConfig() {
    return routesContainPath(getGerenteSucursalRoutes(), navConfig);
  }

  // Filtra las opciones de la barra lateral que coinciden con las rutas a las
  // que tiene acceso el CLIENTE
  function getClienteNavConfig() {
    return routesContainPath(getClienteRoutes(), navConfig);
  }

  return (
    <Stack {...other}>
      {filteredNavConfig.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <StyledSubheader disableSticky>{group.subheader}</StyledSubheader>
            )}

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
  // return (
  //   <Box {...other}>
  //     <List disablePadding>
  //       {filteredNavConfig &&
  //         filteredNavConfig.map((item) => (
  //           <NavItem key={item.title} item={item} active={match} />
  //         ))}
  //     </List>
  //   </Box>
  // );
}
