import { memo, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import { es } from "date-fns/locale";
import dayjs from "dayjs";

const CalendarField = memo(
  ({
    id,
    label,
    value,
    onChange,
    inputFormat = "DD/MM/YYYY",
    touched,
    errors,
    tamanio = 6,
    views = ["day", "month", "year"],
    minDate = null,
    maxDate = null,
    required = false,
    ...other
  }) => {
    const [inputValue, setInputValue] = useState(
      value ? dayjs(value).format("DD/MM/YYYY") : ""
    );

    const handleInputChange = (e) => {
      let rawValue = e.target.value.replace(/\D/g, ""); // Elimina caracteres no numéricos
      if (rawValue.length > 8) rawValue = rawValue.slice(0, 8); // Limita a 8 caracteres

      // Formatea en DD/MM/YYYY dinámicamente
      let formattedValue = rawValue;
      if (rawValue.length >= 5) {
        formattedValue = `${rawValue.slice(0, 2)}/${rawValue.slice(
          2,
          4
        )}/${rawValue.slice(4)}`;
      } else if (rawValue.length >= 3) {
        formattedValue = `${rawValue.slice(0, 2)}/${rawValue.slice(2)}`;
      }

      setInputValue(formattedValue);

      // Si tiene el formato completo (8 dígitos), intenta convertirlo a una fecha válida
      if (rawValue.length === 8) {
        const parsedDate = dayjs(formattedValue, "DD/MM/YYYY");
        if (parsedDate.isValid()) {
          onChange(parsedDate.toDate()); // Notifica a Formik con un objeto de fecha
        }
      } else {
        onChange(null); // Notifica un valor nulo si el formato es incompleto
      }
    };

    const handlePickerChange = (newValue) => {
      setInputValue(newValue ? dayjs(newValue).format("DD/MM/YYYY") : "");
      onChange(newValue);
    };

    return (
      <Grid item xs={tamanio} {...other}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
          <DatePicker
            id={`${id}-date-picker`}
            label={label}
            value={value}
            onChange={handlePickerChange}
            views={views}
            disableMaskedInput
            inputFormat={inputFormat}
            renderInput={(params) => (
              <TextField
                {...params}
                required={required}
                label={label}
                helperText={touched && errors}
                error={touched && Boolean(errors)}
                fullWidth
                value={inputValue} // Sincroniza con el estado del inputValue
                onChange={handleInputChange} // Permite entrada manual
              />
            )}
            minDate={minDate}
            maxDate={maxDate}
          />
        </LocalizationProvider>
      </Grid>
    );
  }
);

export default CalendarField;
