import PropTypes from "prop-types";
import { useFormik } from "formik";
import ClienteForm from "../Modal/propuestas/steppers/propuestaPlan/formularios/ClienteForm";
import { useCallback, useEffect, useState } from "react";
import { propuestasApi } from "src/services/propuestasApi";
import { useSnackbar } from "src/components/snackbar";
import { cleanRut } from "@fdograph/rut-utilities";
import { limpiarString, normalizarEspacios } from "src/utils/sharedFunctions";
import { clienteSchema } from "../Modal/propuestas/steppers/schemas/commons";
import FormPaper from "../Modal/propuestas/components/FormPaper";
import {
  DisabledProvider,
  DisabledWrapper,
  useDisabledFormik,
} from "../disabled-screen/DisabledContext";
import { Alert, AlertTitle } from "@material-ui/core";

const showSwitch = (
  show,
  checked,
  setFieldValue,
  campo,
  label,
  elementsToReset = null,
  formik = null
) => {
  return {
    show,
    checked,
    label: checked ? label.checked : label.unChecked,
    onChangeSwitch: (event) => {
      // Verifica si hay elementos para restablecer y si el objeto formik está disponible
      if (elementsToReset && formik) {
        const { elements, defaults } = elementsToReset; // Obtiene los campos y valores por defecto
        const resetValues = { ...formik.values }; // Crea una copia de los valores actuales

        elements.forEach((element) => {
          // Si el campo tiene un valor definido en defaults, se utiliza; de lo contrario, usa ""
          resetValues[element] =
            defaults && defaults[element] !== undefined
              ? defaults[element]
              : "";
        });

        formik.setValues(resetValues); // Aplica los nuevos valores al formulario
      }
      // Actualiza el valor del check
      setFieldValue(campo, event.target.checked);
    },
  };
};

VerDatosCliente.propTypes = {
  propuesta: PropTypes.object,
};

export default function VerDatosCliente({
  propuesta,
  tipo = "PLAN",
  isDisabled,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [actualizarDatosCliente, { isLoading: guardandoDatos }] =
    tipo === "PLAN"
      ? propuestasApi.useActualizarDatosClienteMutation()
      : propuestasApi.useActualizarDatosClienteTareaEspecialMutation();
  const [clienteInvalido, setClienteInvalido] = useState(false);

  // Validar si el cliente existe
  useEffect(() => {
    if (!propuesta || !propuesta.cliente) {
      setClienteInvalido(true);
    }
  }, [propuesta]);

  const cliente = propuesta?.cliente || {
    rut: "",
    nombre: "",
    correo: "",
    telefono: "",
    comuna: "",
    direccion: "",
  };

  const baseFormik = useFormik({
    initialValues: {
      clienteExistente: propuesta.cliente ? true : false,
      sinRutCliente: propuesta.rutAuxiliar === "1" ? true : false,
      cliente: cliente,
      rutCliente: cliente.rut,
      nombreCliente: cliente.nombre,
      correoPersonalCliente: cliente.correo,
      telefonoCliente: cliente.telefono,
      comunaCliente: cliente.comuna,
      direccionCliente: cliente.direccion,
    },
    validationSchema: clienteSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const formik = useDisabledFormik(baseFormik);

  const handleSubmit = useCallback(
    async (values) => {
      if (isDisabled || clienteInvalido) return;
      const body = {
        ...values,
        idPropuesta: propuesta.id,
        rut: values.clienteExistente
          ? cleanRut(values.cliente.rut).toUpperCase()
          : cleanRut(values.rutCliente).toUpperCase(),
        nombreCliente: normalizarEspacios(values.nombreCliente).toUpperCase(),
        comunaCliente: limpiarString(
          normalizarEspacios(values.comunaCliente)
        ).toUpperCase(),
        correoPersonalCliente: limpiarString(
          normalizarEspacios(values.correoPersonalCliente)
        ).toUpperCase(),
        direccionCliente: normalizarEspacios(
          values.direccionCliente
        ).toUpperCase(),
      };

      const { error } = await actualizarDatosCliente(body);

      if (error) {
        enqueueSnackbar(
          error?.data?.message ||
            "Ocurrió un error al actualizar los datos del cliente",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
        return;
      }

      enqueueSnackbar("Datos cliente propuesta actualizados", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    },
    [
      actualizarDatosCliente,
      clienteInvalido,
      enqueueSnackbar,
      isDisabled,
      propuesta.id,
    ]
  );

  // Si el cliente es inválido, mostramos un mensaje de alerta
  if (clienteInvalido) {
    return (
      <Alert
        severity="warning"
        sx={{
          mb: 2,
          "& .MuiAlert-message": {
            width: "100%",
          },
        }}
      >
        <AlertTitle>Información incompleta</AlertTitle>
        <p>
          No se encontró información del cliente en esta propuesta. Es posible
          que la propuesta haya sido creada incorrectamente.
        </p>
        <p>
          Para resolver este problema, contáctese con administración.
        </p>

        <DisabledProvider isDisabled={isDisabled || clienteInvalido}>
          <DisabledWrapper
            message="Vista de solo lectura"
            overlayColor="rgba(240, 242, 245, 0.45)"
          >
            <FormPaper
              formik={formik}
              manageSwitchRut={
                !formik.values.clienteExistente
                  ? showSwitch(
                      true,
                      formik.values.sinRutCliente,
                      formik.setFieldValue,
                      "sinRutCliente",
                      { checked: "Sin RUT", unChecked: "Con RUT" },
                      {
                        elements: ["rutCliente"],
                      },
                      formik
                    )
                  : null
              }
              manageSwitch={showSwitch(
                true,
                formik.values.clienteExistente,
                formik.setFieldValue,
                "clienteExistente",
                {
                  checked: "Cliente existente",
                  unChecked: "Nuevo cliente",
                },
                {
                  elements: [
                    "nombreCliente",
                    "correoPersonalCliente",
                    "telefonoCliente",
                    "comunaCliente",
                    "direccionCliente",
                    "cliente",
                  ],
                  defaults: {
                    cliente: null,
                  },
                },
                formik
              )}
              title="Datos cliente"
            >
              <ClienteForm
                formik={formik}
                editar={true}
                isLoading={guardandoDatos}
              />
            </FormPaper>
          </DisabledWrapper>
        </DisabledProvider>
      </Alert>
    );
  }

  return (
    <DisabledProvider isDisabled={isDisabled}>
      <DisabledWrapper
        message="Vista de solo lectura"
        overlayColor="rgba(240, 242, 245, 0.45)"
      >
        <FormPaper
          formik={formik}
          manageSwitchRut={
            !formik.values.clienteExistente
              ? showSwitch(
                  true,
                  formik.values.sinRutCliente,
                  formik.setFieldValue,
                  "sinRutCliente",
                  { checked: "Sin RUT", unChecked: "Con RUT" },
                  {
                    elements: ["rutCliente"],
                  },
                  formik
                )
              : null
          }
          manageSwitch={showSwitch(
            true,
            formik.values.clienteExistente,
            formik.setFieldValue,
            "clienteExistente",
            {
              checked: "Cliente existente",
              unChecked: "Nuevo cliente",
            },
            {
              elements: [
                "nombreCliente",
                "correoPersonalCliente",
                "telefonoCliente",
                "comunaCliente",
                "direccionCliente",
                "cliente",
              ],
              defaults: {
                cliente: null, // Se especifica un valor para el campo cliente en elemnts, caso contrario toma valor ""
              },
            },
            formik
          )}
          title="Datos cliente"
        >
          <ClienteForm
            formik={formik}
            editar={true}
            isLoading={guardandoDatos}
          />
        </FormPaper>
      </DisabledWrapper>
    </DisabledProvider>
  );
}
