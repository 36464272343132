import { Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url('/static/illustrations/background-proposal.jpg')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  loadingContent: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius * 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
    boxShadow: theme.shadows[3],
  },
  progressBar: {
    color: theme.palette.primary.main,
  },
  loadingText: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
}));

const ProposalLoading = () => {
  const classes = useStyles();

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.loadingContent}>
        <CircularProgress
          className={classes.progressBar}
          size={60}
          thickness={4}
        />
        <Typography variant="h6" className={classes.loadingText}>
          Cargando propuesta...
        </Typography>
      </Box>
    </Box>
  );
};

export default ProposalLoading;
