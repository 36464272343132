import { validateRut } from "@fdograph/rut-utilities";
import * as Yup from "yup";

export const planesSeleccionados = Yup.object().shape({
  planesSeleccionados: Yup.array()
    .of(
      Yup.object().shape({
        tipoPlan: Yup.string()
          .required("El tipo de plan es obligatorio")
          .min(3, "El tipo de plan debe tener al menos 3 caracteres"),
        tipoMoneda: Yup.string().required("El tipo de moneda es obligatorio"),
        mesCargaTarea: Yup.string().when("frecuencia", {
          is: "AÑO",
          then: Yup.string()
            .required("El mes de carga de las tareas es requerido")
            .nullable(),
          otherwise: Yup.string().notRequired().nullable(),
        }),
        mesFacturacion: Yup.string().when("frecuencia", {
          is: "AÑO",
          then: Yup.string()
            .required("El mes de facturación es requerido")
            .nullable(),
          otherwise: Yup.string().notRequired().nullable(),
        }),
        precioFijoFinal: Yup.number()
          .required("El precio fijo es obligatorio")
          .positive("El precio fijo debe ser un número positivo")
          .min(0, "El precio fijo no puede ser negativo"),
        precioFinal: Yup.number()
          .required("El precio final es obligatorio")
          .positive("El precio final debe ser un número positivo")
          .min(0, "El precio final no puede ser negativo"),
        descuento: Yup.number()
          .required("El descuento es obligatorio")
          .min(0, "El descuento no puede ser negativo")
          .max(100, "El descuento no puede ser mayor al 100%"),
        nombreEncargado: Yup.string()
          .required("El nombre del encargado es obligatorio")
          .min(2, "El nombre del encargado debe tener al menos 2 caracteres")
          .max(100, "El nombre del encargado no puede exceder 100 caracteres"),
        rutEncargado: Yup.string().required(
          "El RUT del encargado es obligatorio"
        ),
        facturadoGestor: Yup.boolean().required(
          "El estado de facturación es obligatorio"
        ),
        descripcion: Yup.string().required("La descripción es obligatoria"),
        frecuencia: Yup.string().required("La frecuencia es obligatoria"),
        precioFijo: Yup.string().required("Verificación precio fijo requerida"),
      })
    )
    .min(1, "Debe seleccionar al menos un plan"),
});

export const clienteSchema = Yup.object().shape({
  clienteExistente: Yup.boolean(),
  sinRutCliente: Yup.boolean(),
  cliente: Yup.object().when("clienteExistente", {
    is: true,
    then: Yup.object()
      // .mixed().oneOf([opciones])
      .required("El cliente es requerido")
      .nullable(),
    otherwise: Yup.object().notRequired().nullable(),
  }),
  rutCliente: Yup.string().when(["sinRutCliente", "clienteExistente"], {
    is: (sinRutCliente, clienteExistente) =>
      !sinRutCliente && !clienteExistente,
    then: Yup.string()
      .trim()
      .required("El RUT es requerido")
      .test("valid-rut", "El RUT no es válido", (value) => {
        if (!value) return false;
        return validateRut(value);
      }),
    otherwise: Yup.string().notRequired(),
  }),
  nombreCliente: Yup.string().trim().required("El nombre es requerido"),
  correoPersonalCliente: Yup.string()
    .trim()
    .email("El formato del email no es válido")
    // .test(
    //   "excluir-dominio-taxtic",
    //   "El dominio del correo no es permitido",
    //   (value) => !value || !/^[\w.%+-]+@taxtic\.com$/i.test(value)
    // )
    .required("El email es requerido"),
  telefonoCliente: Yup.string()
    .trim()
    .length(9, "El teléfono debe tener 9 dígitos")
    .required("El teléfono es requerido"),
  comunaCliente: Yup.string().trim().required("La comuna es requerida"),
  direccionCliente: Yup.string().trim().required("La dirección es requerida"),
});

export const antecedentesSchema = Yup.object().shape({
  antecedentes: Yup.string()
    .trim()
    .required("Los antecedentes de la propuesta son requeridos"),
});

export const empleadosAdicionales = Yup.object().shape({
  empleadosAdicionales: Yup.array().notRequired(),
});

export const observacionesSchema = Yup.object().shape({
  observaciones: Yup.string(),
});
