import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import "./DisabledMode.css";

const DisabledContext = createContext(false);

export const DisabledProvider = ({ isDisabled, children }) => {
  return (
    <DisabledContext.Provider value={isDisabled}>
      {children}
    </DisabledContext.Provider>
  );
};

export const useDisabled = () => useContext(DisabledContext);

export const DisabledWrapper = ({
  children,
  className = "",
  forceDisabled,
  message = "Modo solo lectura",
  showMessage = true,
  overlayColor = "rgba(245, 245, 250, 0.4)",
  lockIcon = true,
}) => {
  // Obtenemos el valor del contexto
  const isDisabledFromContext = useDisabled();

  // Usamos el valor del contexto o el valor forzado si se proporciona
  const isDisabled =
    forceDisabled !== undefined ? forceDisabled : isDisabledFromContext;

  return (
    <div
      className={`form-wrapper ${
        isDisabled ? "disabled-mode" : ""
      } ${className}`}
      style={
        isDisabled
          ? { "--overlay-color": overlayColor, position: "relative" }
          : {}
      }
    >
      {isDisabled && (
        <>
          <div className="form-overlay"></div>

          {lockIcon && (
            <div className="lock-icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="3"
                  y="11"
                  width="18"
                  height="11"
                  rx="2"
                  ry="2"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <path
                  d="M7 11V7a5 5 0 0 1 10 0v4"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </svg>
            </div>
          )}

          {showMessage && <div className="read-only-message">{message}</div>}
        </>
      )}

      {children}
    </div>
  );
};

export const useDisabledFormik = (formik) => {
  const isDisabled = useDisabled();

  const disabledFormik = React.useMemo(() => {
    if (!isDisabled) return formik;

    const newFormik = { ...formik };

    Object.keys(formik).forEach((key) => {
      if (typeof formik[key] === "function") {
        if (
          ["getFieldProps", "getFieldMeta", "getFieldHelpers"].includes(key)
        ) {
          newFormik[key] = formik[key];
        } else {
          newFormik[key] = (...args) => {
            if (
              key === "handleSubmit" &&
              args[0] &&
              typeof args[0].preventDefault === "function"
            ) {
              args[0].preventDefault();
            }
            return undefined;
          };
        }
      }
    });

    newFormik.isSubmitting = true;

    return newFormik;
  }, [formik, isDisabled]);

  return disabledFormik;
};

DisabledProvider.propTypes = {
  isDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

DisabledWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  forceDisabled: PropTypes.bool,
  message: PropTypes.string,
  showMessage: PropTypes.bool,
  overlayColor: PropTypes.string,
  lockIcon: PropTypes.bool,
};
