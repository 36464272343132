import React, { useCallback, useState } from "react";
import { ACCESS_TOKEN } from "../../utils/urlBack_End";
// material
import {
  Card,
  Stack,
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  Alert,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { styled } from "@material-ui/core/styles";
import { TemplateHandler } from "easy-template-x";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { useSelector } from "react-redux";
import { capitalizarPalabras } from "src/utils/sharedFunctions";
import { propuestasApi } from "src/services/propuestasApi";
import { obtenerNombreMes } from "src/utils/formatTime";
import {
  eliminarArchivoDropbox,
  restaurarArchivoDropbox,
  subirArchivoADropbox,
} from "src/utils/dropboxUtils";
import { useSnackbar } from "src/components/snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    minWidth: "100px",
  },
  media: {
    height: 0,
    paddingTop: "100%", // 16:9
  },
  hidden: {
    display: "none",
  },
}));

function saveFile(filename, blob) {
  const blobUrl = URL.createObjectURL(blob);
  let link = document.createElement("a");
  link.download = filename;
  link.href = blobUrl;

  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    link.remove();
    window.URL.revokeObjectURL(blobUrl);
    link = null;
  }, 0);
}
const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

const getNombreCliente = (propuesta, tipo) => {
  const isPlan = tipo === "PLAN";

  const nombreSolicitante = propuesta?.nombreSolicitante;
  const nombreCliente = propuesta?.cliente?.nombre;

  const clienteNombre = isPlan
    ? capitalizarPalabras(nombreSolicitante ?? "Sin datos cliente")
    : capitalizarPalabras(nombreCliente ?? "Sin datos cliente");

  return clienteNombre;
};
export default function VerPropuestaArchivos(props) {
  const { propuesta, tipo } = props;
  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [actualizarPropuesta] =
    propuestasApi.useActualizarArchivoPropuestaMutation();
  const [file, setFile] = useState(null);
  const [showFile, setShowFile] = useState(!!propuesta.urlPropuesta);
  const [loading, setLoading] = useState(false);
  const [eliminandoArchivo, setEliminandoArchivo] = useState(false);
  const { data: servicios, isLoading: cargandoServicios } =
    propuestasApi.useObtenerServiciosQuery({
      tipo,
      id: propuesta.id,
    });

  const generateDocument = async () => {
    let dropboxEndpoint1 = "https://content.dropboxapi.com/2/files/download";
    let dropBoxApiArg = { path: "/Archivos/PropuestaMODELO.docx" };
    let postOptions1 = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
        "Dropbox-API-Arg": JSON.stringify(dropBoxApiArg),
      },
    };
    const response = await fetch(dropboxEndpoint1, postOptions1);
    const templateFile = await response.blob();
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const yyyy = today.getFullYear();

    const fechaFormateada = `${dd} de ${obtenerNombreMes(
      String(today.getMonth() + 1).padStart(2, "0")
    ).toLowerCase()} del ${yyyy}`;

    const nombreCliente = getNombreCliente(propuesta, tipo);

    const data = {
      ciudad: capitalizarPalabras(sucursalObtenida.comuna),
      fechaActual: fechaFormateada,
      nombreCliente,
      nombreEmpleado: capitalizarPalabras(`${user.nombre} ${user.apellidos}`),
      Planes: servicios,
    };
    const handler = new TemplateHandler();
    const doc = await handler.process(templateFile, data);
    saveFile(`Propuesta ${nombreCliente}.docx`, doc);
  };

  const handleFileInput = (e) => {
    const fileAux = e.target.files[0];
    if (fileAux === undefined) {
      return;
    }
    if (fileAux.type !== "application/pdf") {
      return;
    }
    setFile(fileAux);
    setShowFile(true);
  };

  const deleteFileFromDB = useCallback(async () => {
    try {
      const body = {
        urlPropuesta: "",
      };
      await eliminarArchivoDropbox("Propuestas/", `${propuesta.id}.pdf`);
      console.log({ idPropuesta: propuesta.id, ...body });
      await actualizarPropuesta({
        idPropuesta: propuesta.id,
        ...body,
      }).unwrap();
    } catch (error) {
      console.error("deleteFileFromDB", error);
      if (error?.status || error?.data) {
        // Si actualizar el archivo en la base de datos falla, se restaura el archivo
        try {
          await restaurarArchivoDropbox("Propuestas/", `${propuesta.id}.pdf`);
          console.log("Archivo restaurado exitosamente");
        } catch (restoreError) {
          console.error("Error al restaurar el archivo:", restoreError);
        }
      }
      throw error;
    }
  }, [actualizarPropuesta, propuesta.id]);

  const handleDeleteFile = useCallback(async () => {
    try {
      setEliminandoArchivo(true);
      await deleteFileFromDB();
      setFile(null);
      setShowFile(false);
      enqueueSnackbar("Archivo eliminado correctamente");
    } catch (error) {
      console.error("handleDeleteFile", error);
      console.error("handleAcceptProposal", error);
      enqueueSnackbar(
        error?.message ||
          error?.data?.message ||
          "Ocurrió un error al eliminar el archivo desde Dropbox. Reintente nuevamente.",
        {
          variant: "error",
        }
      );
    } finally {
      setEliminandoArchivo(false);
    }
  }, [deleteFileFromDB, enqueueSnackbar]);

  async function uploadFile() {
    setLoading(true);
    if (!file && propuesta.urlPropuesta) {
    }
    if (!file) return setLoading(false);
    const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;

    if (file.size >= UPLOAD_FILE_SIZE_LIMIT) {
      return;
    }

    try {
      const body = await subirArchivoADropbox(
        file,
        "Propuestas",
        undefined,
        setLoading,
        "urlPropuesta",
        false,
        `${propuesta.id}.pdf`,
        false
      );

      await actualizarPropuesta({
        idPropuesta: propuesta.id,
        ...body,
      }).unwrap();

      enqueueSnackbar("Archivo subido correctamente");
    } catch (error) {
      console.error(error);
      console.error("handleAcceptProposal", error);
      enqueueSnackbar(
        error?.data?.message ||
          error.message ||
          "Ocurrió un error al subir el archivo. Reintente nuevamente.",
        {
          variant: "error",
        }
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} xl={4} lg={4}>
          <Card sx={{ margin: 2 }}>
            <CardActionArea
              disabled={
                cargandoServicios ||
                propuesta.estado === "ENVIADA" ||
                propuesta.estado === "ACEPTADO_CLIENTE" ||
                propuesta.estado === "ACEPTADA"
              }
            >
              <CardMedia
                className={classes.media}
                image="/static/icons/word.svg"
                onClick={generateDocument}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
              <CardContent>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  component="p"
                  style={{ textAlign: "center" }}
                >
                  Exportar Propuesta Word
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={8} xl={8} lg={8}>
          <Card>
            <CardContent>
              <RootStyle>
                <div style={{ padding: "1rem" }}>
                  {propuesta.estado === "CREADA" && (
                    <Alert severity="info" sx={{ marginBottom: 2 }}>
                      Haga click en el botón 'Seleccionar Archivo' para subir un
                      documento en formato PDF asociado a esta tarea.
                    </Alert>
                  )}

                  {(propuesta.estado === "ENVIADA" ||
                    propuesta.estado === "ACEPTADO_CLIENTE" ||
                    propuesta.estado === "ACEPTADA") && (
                    <Alert severity="info" sx={{ marginBottom: 2 }}>
                      {`La propuesta ya se encuentra ${
                        propuesta.estado === "ACEPTADO_CLIENTE"
                          ? "aceptada por el cliente"
                          : propuesta.estado.toLowerCase()
                      }, solo podrá visualizarla.`}
                    </Alert>
                  )}

                  {propuesta.estado === "CREADA" && (
                    <Alert severity="warning">
                      Precaución: Solo se puede adjuntar un archivo por tarea.
                    </Alert>
                  )}

                  <div style={{ marginTop: "10px" }}>
                    <input
                      accept="application/pdf"
                      className={classes.hidden}
                      id="contained-button-file"
                      onChange={handleFileInput}
                      type="file"
                      disabled={
                        propuesta.estado === "ACEPTADA" ||
                        propuesta.estado === "ENVIADA" ||
                        propuesta.estado === "ACEPTADO_CLIENTE" ||
                        propuesta.estado === "RECHAZADA"
                      }
                    />

                    <label htmlFor={"contained-button-file"}>
                      <Button
                        component="span"
                        m={1}
                        variant="contained"
                        disabled={
                          propuesta.estado === "ACEPTADA" ||
                          propuesta.estado === "ENVIADA" ||
                          propuesta.estado === "ACEPTADO_CLIENTE" ||
                          propuesta.estado === "RECHAZADA"
                        }
                      >
                        Seleccionar Archivo
                      </Button>
                    </label>

                    {showFile && (propuesta.urlPropuesta || file) && (
                      <span>
                        <span
                          style={
                            propuesta.urlPropuesta ? { cursor: "pointer" } : {}
                          }
                        >
                          {propuesta.urlPropuesta ? (
                            <a
                              href={propuesta.urlPropuesta}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {" "}
                              {propuesta.id}
                              {".pdf"}
                            </a>
                          ) : (
                            <>
                              {" "}
                              {propuesta.id}
                              {".pdf"}
                            </>
                          )}
                        </span>

                        <span>
                          <IconButton
                            onClick={handleDeleteFile}
                            aria-label="delete"
                            disabled={
                              propuesta.estado === "ACEPTADA" ||
                              propuesta.estado === "ENVIADA" ||
                              propuesta.estado === "ACEPTADO_CLIENTE" ||
                              propuesta.estado === "RECHAZADA"
                            }
                          >
                            {eliminandoArchivo ? (
                              <CircularProgress size={20} />
                            ) : (
                              <DeleteIcon />
                            )}
                          </IconButton>
                        </span>
                      </span>
                    )}
                    <Stack direction="row" justifyContent="flex-end" mb={5}>
                      <LoadingButton
                        loading={loading}
                        onClick={uploadFile}
                        variant="contained"
                        disabled={
                          propuesta.estado === "ACEPTADA" ||
                          propuesta.estado === "ENVIADA" ||
                          propuesta.estado === "ACEPTADO_CLIENTE" ||
                          propuesta.estado === "RECHAZADA"
                        }
                      >
                        Guardar Archivos Adjuntos
                      </LoadingButton>
                    </Stack>
                  </div>
                </div>
              </RootStyle>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
}
