import { toLower } from "lodash";

const capitalizarPrimeraLetra = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const obtenerValorMoneda = (monedas, tipoMoneda) => {
  return monedas.find(({ moneda }) => moneda === tipoMoneda).valor || null;
};

const decodeHtmlEntities = (str, document) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = str;
  return txt.value;
};

const formatToSentenceCase = (text) => {
  // Primero convertimos todo a minúsculas
  const lowerCase = toLower(text);

  // Dividimos el texto en oraciones (por punto y espacio)
  return lowerCase.replace(/(^\w|\.\s+\w)/g, (letter) => letter.toUpperCase());
};

const limitarDecimalesSinRelleno = (precio, decimales = 6) => {
  // Redondear el precio a los decimales especificados
  const factor = Math.pow(10, decimales);
  const precioRedondeado = Math.round(precio * factor) / factor;

  // Convertir a string para eliminar ceros innecesarios
  const strPrecio = precioRedondeado.toString();

  // Verificar si hay decimales
  if (strPrecio.indexOf(".") !== -1) {
    // Eliminar ceros innecesarios al final de los decimales
    return parseFloat(strPrecio);
  } else {
    // Retornar el número sin cambios si no hay decimales
    return precioRedondeado;
  }
};

const capitalizarPalabras = (texto) => {
  return (
    texto
      ?.toLowerCase()
      .replace(/(?:^|\s)\S/g, function (letra) {
        return letra.toUpperCase();
      })
      .replace(/\b([EIRLSA]\.)\w*\b/g, function (abreviatura) {
        return abreviatura.toUpperCase();
      }) || "No definido"
  );
};

// Elimina acentos y caracteres especiales
const limpiarString = (texto) => {
  return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

// Elimina espacios innecesarios
const normalizarEspacios = (texto) => {
  return texto.trim().split(/\s+/).join(" ");
};

//  Elimina espacios, guiones y convierte a minúsculas
const normalizeString = (str) => {
  return str.trim().toLowerCase().replace(/[\s-]/g, "");
};

const isEmptyString = (str) => {
  return str.trim().length === 0;
};

const obtenerNombreCorto = (nombreCompleto) => {
  if (!nombreCompleto) return "";

  const partes = nombreCompleto.trim().split(/\s+/);

  if (partes.length === 1) return capitalizarPalabras(partes[0]);

  if (partes.length === 2) return capitalizarPalabras(partes.join(" "));

  const [primerNombre, ...resto] = partes;
  const primerApellido = resto.length ? resto[0] : "";

  return capitalizarPalabras(`${primerNombre} ${primerApellido}`);
};

const formatRutManual = (rut) => {
  // Elimina todos los caracteres que no sean números o la letra 'k'
  const cleanedRut = rut.replace(/[^0-9kK]/g, "");

  // Separa el dígito verificador del resto del RUT
  const dv = cleanedRut.slice(-1).toUpperCase(); // Dígito verificador (puede ser 'K')
  const body = cleanedRut.slice(0, -1); // Parte numérica del RUT

  // Formatea la parte numérica con puntos
  const formattedBody = body
    .split("")
    .reverse()
    .join("")
    .replace(/(\d{3})/g, "$1.")
    .split("")
    .reverse()
    .join("")
    .replace(/^\./, "");

  // Retorna el RUT formateado
  return `${formattedBody}-${dv}`;
};

export {
  capitalizarPrimeraLetra,
  capitalizarPalabras,
  limpiarString,
  isEmptyString,
  obtenerValorMoneda,
  limitarDecimalesSinRelleno,
  decodeHtmlEntities,
  normalizarEspacios,
  normalizeString,
  formatToSentenceCase,
  formatRutManual,
  obtenerNombreCorto,
};
