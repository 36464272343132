import PropTypes from "prop-types";
import { useFormik } from "formik";
import { propuestasApi } from "src/services/propuestasApi";
import ServiciosForm from "../Modal/propuestas/steppers/propuestaPlan/formularios/serviciosForm";
import { useEffect, useMemo, useState } from "react";
import { obtenerNombreMes } from "src/utils/formatTime";
import AddPlanModal from "../Modal/propuestas/steppers/propuestaPlan/modalPlan";
import { useSelector } from "react-redux";
import { planesSeleccionados } from "../Modal/propuestas/steppers/schemas/commons";
import FormPaper from "../Modal/propuestas/components/FormPaper";
import {
  DisabledProvider,
  DisabledWrapper,
  useDisabledFormik,
} from "../disabled-screen/DisabledContext";

const showButton = (show, label, onClick) => {
  return {
    show,
    label,
    onClick,
  };
};

VerPropuestaPlanes.propTypes = {
  propuesta: PropTypes.object,
};

export default function VerPropuestaPlanes({ propuesta, isDisabled = false }) {
  const { data: planesPropuesta, isLoading: cargandoPlanesPropuesta } =
    propuestasApi.useObtenerPlanesPropuestaQuery(propuesta.id);
  const [openModalPlan, setOpenModalPlan] = useState(false);
  const {
    sucursal: { refSucursal: sucursalObtenida },
  } = useSelector((state) => state.auth.user);

  const [rutCliente, setRutCliente] = useState("");

  const planesFormateados = useMemo(() => {
    if (!cargandoPlanesPropuesta && planesPropuesta && planesPropuesta.length > 0) {
      setRutCliente(planesPropuesta[0].refCliente);

      return planesPropuesta.map((plan) => ({
        refPropuesta: plan.refPropuesta,
        tipoPlan: plan.refTipoPlan,
        tipoMoneda: plan.tipoMoneda,
        mesCargaTarea: obtenerNombreMes(plan.mesCargaTareas),
        mesFacturacion: obtenerNombreMes(plan.mesFacturacion),
        precioFijoFinal: plan.valor,
        precioFinal: plan.valorDesc,
        descuento: plan.descuento,
        nombreEncargado: plan.nombreEmpleado,
        rutEncargado: plan.refEmpleado,
        facturadoGestor: plan.seFacturaGestor,
        descripcion: plan.descripcion,
        frecuencia: plan.tipoFrecuencia,
        precioFijo: plan.valorFijo,
      }));
    }
    return [];
  }, [cargandoPlanesPropuesta, planesPropuesta]);

  const baseFormik = useFormik({
    initialValues: {
      planesSeleccionados: [],
    },
    validationSchema: planesSeleccionados,
  });

  const formik = useDisabledFormik(baseFormik);

  useEffect(() => {
    if (!cargandoPlanesPropuesta && planesFormateados) {
      formik.setFieldValue("planesSeleccionados", planesFormateados);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargandoPlanesPropuesta, planesFormateados]);

  const handleClickButton = () => {
    setOpenModalPlan(true);
  };

  return (
    <DisabledProvider isDisabled={isDisabled}>
      <DisabledWrapper
        message="Vista de solo lectura"
        overlayColor="rgba(240, 242, 245, 0.45)"
      >
        <FormPaper
          formik={formik}
          manageButton={showButton(true, "Agregar servicio", handleClickButton)}
          title="Servicios propuestos"
        >
          <ServiciosForm
            formik={formik}
            isLoading={cargandoPlanesPropuesta}
            rutCliente={rutCliente}
            deleteFromDB={true}
          />
        </FormPaper>
        {openModalPlan && (
          <AddPlanModal
            open={openModalPlan}
            setOpen={setOpenModalPlan}
            planesSeleccionados={formik.values.planesSeleccionados}
            setFieldValue={formik.setFieldValue}
            sucursal={sucursalObtenida}
            saveInDB={true}
            idPropuesta={propuesta.id}
            rutCliente={rutCliente}
          />
        )}
      </DisabledWrapper>
    </DisabledProvider>
  );
}
