import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import StepperTarea from "./steppers/propuestaTareaEspecial/stepper-task";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

export default function ModalPropuestaTareaEspecial({
  open,
  setOpen,
  maxWidth,
  fullWidth,
}) {
  const {
    sucursal: { refSucursal: sucursalSeleccionada },
  } = useSelector((state) => state.auth.user);
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      onClose={handleClose}
      fullWidth={fullWidth}
      disableEscapeKeyDown
    >
      <DialogTitle className={classes.dialogTitle}>
        {`Crear propuesta tarea - Sucursal ${sucursalSeleccionada}`}
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <StepperTarea
          handleClose={handleClose}
          sucursal={sucursalSeleccionada}
        />
      </DialogContent>
    </Dialog>
  );
}
