import React from "react";
import { Box, Button, Typography, Container, Paper } from "@material-ui/core";
import {
  WifiOff,
  Refresh,
  Error as ErrorIcon,
  Warning,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundImage: `url('/static/illustrations/background-proposal.jpg')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
    width: "100%",
    margin: 0,
    padding: 0,
  },
  root: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
    boxSizing: "border-box",
  },
  paper: {
    padding: theme.spacing(6),
    borderRadius: theme.shape.borderRadius * 2,
    textAlign: "center",
    maxWidth: 480,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
  icon: {
    fontSize: 64,
    marginBottom: theme.spacing(3),
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  message: {
    marginBottom: theme.spacing(4),
    color: theme.palette.text.secondary,
  },
  button: {
    padding: theme.spacing(1, 4),
    borderRadius: theme.shape.borderRadius,
    textTransform: "none",
    fontSize: "1rem",
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ErrorView = ({ error, onRetry }) => {
  const classes = useStyles();

  const getErrorDetails = () => {
    if (!navigator.onLine) {
      return {
        icon: <WifiOff className={`${classes.icon} ${classes.errorIcon}`} />,
        title: "Sin conexión a Internet",
        message:
          "Por favor, verifica tu conexión a Internet e intenta nuevamente.",
        action: "Reintentar",
      };
    }

    if (error?.status === 404) {
      return {
        icon: <Warning className={`${classes.icon} ${classes.warningIcon}`} />,
        title: "Propuesta no encontrada",
        message: "La propuesta que buscas no existe o ha sido eliminada.",
        action: "Volver a intentar",
      };
    }

    return {
      icon: <ErrorIcon className={`${classes.icon} ${classes.errorIcon}`} />,
      title: "Error al cargar la propuesta",
      message:
        "Ha ocurrido un error al obtener los datos. Por favor, intenta nuevamente más tarde.",
      action: "Reintentar",
    };
  };

  const errorDetails = getErrorDetails();

  return (
    <Box className={classes.pageContainer}>
      <Container className={classes.root}>
        <Paper className={classes.paper} elevation={0}>
          {errorDetails.icon}

          <Typography variant="h4" className={classes.title}>
            {errorDetails.title}
          </Typography>

          <Typography variant="body1" className={classes.message}>
            {errorDetails.message}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={onRetry}
            className={classes.button}
            startIcon={<Refresh className={classes.buttonIcon} />}
          >
            {errorDetails.action}
          </Button>
        </Paper>
      </Container>
    </Box>
  );
};

export default ErrorView;
