import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import CustomAutocomplete from "src/components/material/CustomAutocomplete";
import { empleadosApi } from "src/services/empleadosApi";
import { capitalizarPalabras } from "src/utils/sharedFunctions";

const EmpleadosAdicionalesForm = ({ formik, sucursal }) => {
  const { data: empleados = [], isLoading: cargandoEmpleados } =
    empleadosApi.useGetEmpleadosActivosQuery({
      id: sucursal,
      estado: "ALL",
    });
  const { values, errors, touched, setFieldValue } = formik;

  const getOptionLabel = (option) => {
    const nombreCompleto = `${option.nombre} ${option.apellidos}`;
    const rutFormateado = formatRut(option.rut, RutFormat.DOTS_DASH);
    return `${capitalizarPalabras(nombreCompleto)} (${rutFormateado})`;
  };

  const handleAutocompleteChange = (event, newValue) => {
    event.stopPropagation();
    event.preventDefault();
    setFieldValue("empleadosAdicionales", newValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomAutocomplete
          options={empleados}
          maxTags={4}
          isRequired={false}
          label="Empleados"
          placeholder="Seleccione empleado"
          value={values.empleadosAdicionales}
          onChange={handleAutocompleteChange}
          error={errors.empleadosAdicionales}
          touched={
            Array.isArray(touched.empleadosAdicionales)
              ? touched.empleadosAdicionales.length > 0
              : touched.empleadosAdicionales
          }
          loading={cargandoEmpleados}
          getOptionLabel={getOptionLabel}
          onKeyDown={handleKeyDown}
        />
      </Grid>
    </Grid>
  );
};

EmpleadosAdicionalesForm.propTypes = {
  formik: PropTypes.object.isRequired,
  sucursal: PropTypes.string.isRequired,
};
export default React.memo(EmpleadosAdicionalesForm);
