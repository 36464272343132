import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Scrollbar from "../Scrollbar";
import { TablaHead, TablaToolbar } from "../_dashboard/tablas";
import { Delete, Edit } from "@material-ui/icons";
import SearchNotFound from "../SearchNotFound";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import { gavsApi } from "src/services/gavsApi";
import dayjs from "dayjs";
import "dayjs/locale/es.js";
import { capitalizarPrimeraLetra } from "src/utils/sharedFunctions";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Alerta } from "../Alerta";
import ModalGav from "../Modal/modalGav/ModalGav";
import ExportarAExcel from "../exportarExcelLiquidacion/ExportarExcelLiquidacion";
import { validarFormatoFecha } from "src/utils/formatTime";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import CalendarField from "../Modal/modalGav/Fields/CalendarField";
import ExcelJS from "exceljs";
import { prorrateosApi } from "src/services/prorrateosApi";
import AutoCompleteField from "../Modal/sharedFields/AutocompleteField";
import { formatAsCLP } from "src/utils/formatNumber";
import CustomTablePagination from "../CustomTablePagination";
import {
  activarCalculoAutomatico,
  aplicarBordeUltimaFila,
  colocarEncabezado,
  guardarArchivo,
} from "src/utils/excel-plantillas.js";

dayjs.locale("es");

const rellenarTabla = (
  worksheet,
  datosTabla,
  tituloTabla,
  filaInicial,
  columnas,
  tipoArreglo
) => {
  try {
    const {
      HEADER_TITLE,
      TITLE_FONT_STYLE,
      HEADER_FILL,
      HEADER_FONT_STYLE,
      DATA_FONT_STYLE,
    } = CONSTANTES_EXCEL;

    const primeraColumna = columnas[0];
    const ultimaColumna = columnas[columnas.length - 1];

    worksheet.getCell(`${primeraColumna.id}${filaInicial - 1}`).value =
      tituloTabla;
    worksheet.mergeCells(
      `${primeraColumna.id}${filaInicial - 1}`,
      `${ultimaColumna.id}${filaInicial - 1}`
    );
    worksheet.getCell(`${primeraColumna.id}${filaInicial - 1}`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell(`${primeraColumna.id}${filaInicial - 1}`).fill =
      HEADER_TITLE;
    worksheet.getCell(`${primeraColumna.id}${filaInicial - 1}`).font =
      TITLE_FONT_STYLE;

    columnas.forEach((columna) => {
      worksheet.getCell(`${columna.id}${filaInicial}`).value = columna.titulo;
      worksheet.getCell(`${columna.id}${filaInicial}`).fill = HEADER_FILL;
      worksheet.getCell(`${columna.id}${filaInicial}`).font = HEADER_FONT_STYLE;
      worksheet.getCell(`${columna.id}${filaInicial}`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });

    if (tipoArreglo === 0) {
      datosTabla.forEach((dato, index) => {
        const rowIndex = filaInicial + 1 + index;
        columnas.forEach((columna) => {
          const valor = dato[columna.propiedad];
          worksheet.getCell(`${columna.id}${rowIndex}`).value = valor;
          worksheet.getCell(`${columna.id}${rowIndex}`).font = DATA_FONT_STYLE;

          // Aplicar formato numérico si el valor es un número
          if (!isNaN(valor)) {
            worksheet.getCell(`${columna.id}${rowIndex}`).value = valor;
            worksheet.getCell(`${columna.id}${rowIndex}`).numFmt = `$#,##0`;
          }

          worksheet.getCell(`${columna.id}${rowIndex}`).alignment = {
            vertical: "middle",
            horizontal: "center",
          };
        });
      });
    } else if (tipoArreglo === 1) {
      datosTabla.forEach((dato, index) => {
        const rowIndex = filaInicial + 1 + index;
        columnas.forEach((columna) => {
          if (columna.propiedad === "nombreGav") {
            worksheet.getCell(`${columna.id}${rowIndex}`).value =
              dato.detallesGAV.nombre;
          } else if (columna.propiedad === "montoTotal") {
            const valor = dato.detallesGAV.montoTotal;
            if (!isNaN(valor)) {
              worksheet.getCell(`${columna.id}${rowIndex}`).value = valor;
              worksheet.getCell(`${columna.id}${rowIndex}`).numFmt = `$#,##0`;
            }
          } else if (columna.propiedad === "nombre") {
            const valor = `${dato.nombre} ${dato.apellidos}`;
            worksheet.getCell(`${columna.id}${rowIndex}`).value = valor;
          } else {
            const valor = dato[columna.propiedad];
            worksheet.getCell(`${columna.id}${rowIndex}`).value = valor;
            if (!isNaN(valor)) {
              worksheet.getCell(`${columna.id}${rowIndex}`).value = valor;
              worksheet.getCell(`${columna.id}${rowIndex}`).numFmt = `$#,##0`;
            }
          }
          worksheet.getCell(`${columna.id}${rowIndex}`).alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          worksheet.getCell(`${columna.id}${rowIndex}`).font = DATA_FONT_STYLE;
        });
      });
    }

    worksheet.columns.forEach((column, columnIndex) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const text = cell.text || "";
        maxLength = Math.max(maxLength, text.length);
      });
      column.width = maxLength + 4;
    });
  } catch (error) {
    console.error("Error en rellenarTabla: ", error.message);
  }
};

const rellenarPlantillaGastosPorSucursal = (
  worksheetGavs,
  gastosPorSucursal
) => {
  try {
    const columnas = [
      { id: "B", titulo: "Sucursal", propiedad: "refSucursal" },
      { id: "C", titulo: "Total", propiedad: "monto" },
    ];
    const filaInicialGastosSucursales = 8;
    const tituloGastosSucursal = "GASTOS SUCURSALES";

    rellenarTabla(
      worksheetGavs,
      gastosPorSucursal,
      tituloGastosSucursal,
      filaInicialGastosSucursales,
      columnas,
      0
    );
  } catch (error) {
    console.error(
      "Error en rellenarPlantillaGastosPorSucursal: ",
      error.message
    );
  }
};

const rellenarPlantillaGastosSumadosSucursalEmpleados = (
  worksheetGavs,
  gastosSucursalesYEmpleados
) => {
  try {
    const columnas = [
      { id: "F", titulo: "Sucursal", propiedad: "refSucursal" },
      { id: "G", titulo: "Total", propiedad: "monto" },
    ];
    const filaInicialGastosSucursales = 8;
    const tituloGastosSucursal =
      "GASTOS TOTALES SUCURSALES (SUCURSALES + EMPLEADOS)";

    rellenarTabla(
      worksheetGavs,
      gastosSucursalesYEmpleados,
      tituloGastosSucursal,
      filaInicialGastosSucursales,
      columnas,
      0
    );
  } catch (error) {
    console.error(
      "Error en rellenarPlantillaGastosPorSucursal: ",
      error.message
    );
  }
};

const rellenarPlantillaDetalleGastosSucursal = (
  filaInicial,
  worksheetGavs,
  detalleGastosSucursales
) => {
  const columnasDetallesEmpleados = [
    { id: "B", titulo: "GAV", propiedad: "nombreGav" },
    { id: "C", titulo: "Comentario", propiedad: "comentario" },
    { id: "D", titulo: "Fecha gasto", propiedad: "fechaGasto" },
    { id: "E", titulo: "Monto total GAV", propiedad: "montoTotal" },
    { id: "F", titulo: "Sucursal", propiedad: "sucursal" },
    { id: "G", titulo: "Monto a pagar por sucursal", propiedad: "montoPagar" },
  ];
  const tituloDetalleEmpleados = "DETALLE GASTOS SUCURSALES";

  rellenarTabla(
    worksheetGavs,
    detalleGastosSucursales,
    tituloDetalleEmpleados,
    filaInicial,
    columnasDetallesEmpleados,
    0
  );
};

const rellenarPlantillaGastosEmpleadosSucursal = (
  worksheetGavs,
  detalleGastosEmpleados
) => {
  try {
    const columnasDetallesEmpleados = [
      { id: "I", titulo: "GAV", propiedad: "nombreGav" },
      { id: "J", titulo: "Total GAV", propiedad: "totalGav" },
      { id: "K", titulo: "Sucursal", propiedad: "sucursal" },
      { id: "L", titulo: "Empleado", propiedad: "empleado" },
      { id: "M", titulo: "Monto a pagar", propiedad: "montoPagar" },
    ];
    const filaInicialDetalleEmpleados = 8;
    const tituloDetalleEmpleados = "DETALLE GASTOS EMPLEADOS SUCURSAL";

    rellenarTabla(
      worksheetGavs,
      detalleGastosEmpleados,
      tituloDetalleEmpleados,
      filaInicialDetalleEmpleados,
      columnasDetallesEmpleados,
      0
    );
  } catch (error) {
    console.error(
      "Error en rellenarPlantillaGastosEmpleadosSucursal: ",
      error.message
    );
  }
};

const rellenarPlantillaTotalFacturado = (
  worksheetRecaudaciones,
  facturadoSucursales
) => {
  try {
    const columnasDetallesEmpleados = [
      { id: "C", titulo: "Sucursal", propiedad: "sucursal" },
      { id: "D", titulo: "Total", propiedad: "monto" },
    ];
    const filaInicialDetalleEmpleados = 8;
    const tituloDetalleEmpleados = "FACTURADO";

    rellenarTabla(
      worksheetRecaudaciones,
      facturadoSucursales,
      tituloDetalleEmpleados,
      filaInicialDetalleEmpleados,
      columnasDetallesEmpleados,
      0
    );
  } catch (error) {
    console.error(
      "Error en rellenarPlantillaGastosEmpleadosSucursal: ",
      error.message
    );
  }
};

const rellenarPlantillaTotalRecaudado = (
  worksheetRecaudaciones,
  recaudadoSucursales
) => {
  try {
    const columnasDetallesEmpleados = [
      { id: "I", titulo: "Sucursal", propiedad: "sucursal" },
      { id: "J", titulo: "Total", propiedad: "monto" },
    ];
    const filaInicialDetalleEmpleados = 8;
    const tituloDetalleEmpleados = "RECAUDADO";

    rellenarTabla(
      worksheetRecaudaciones,
      recaudadoSucursales,
      tituloDetalleEmpleados,
      filaInicialDetalleEmpleados,
      columnasDetallesEmpleados,
      0
    );
  } catch (error) {
    console.error(
      "Error en rellenarPlantillaGastosEmpleadosSucursal: ",
      error.message
    );
  }
};

const rellenarPlantillaLiquidacionSucursales = (
  worksheetRecaudaciones,
  liquidacionSucursales,
  filaInicial
) => {
  try {
    const columnasDetallesEmpleados = [
      { id: "A", titulo: "Sucursal", propiedad: "refSucursal" },
      { id: "B", titulo: "Recaudado", propiedad: "totalRecaudado" },
      { id: "C", titulo: "Franquicia Taxtic (15%)", propiedad: "taxtic" },
      { id: "D", titulo: "Costos directos (85%)", propiedad: "descuento15" },
      { id: "E", titulo: "GAVs", propiedad: "gav" },
      { id: "F", titulo: "Monto facturación", propiedad: "valorAFacturar" },
    ];
    const tituloDetalleEmpleados = "LIQUIDACIÓN SUCURSALES";

    rellenarTabla(
      worksheetRecaudaciones,
      liquidacionSucursales,
      tituloDetalleEmpleados,
      filaInicial,
      columnasDetallesEmpleados,
      0
    );
  } catch (error) {
    console.error(
      "Error en rellenarPlantillaGastosEmpleadosSucursal: ",
      error.message
    );
  }
};

const rellenarPlantillaLiquidacionEmpleados = (
  worksheetRecaudaciones,
  liquidacionEmpleados,
  filaInicial
) => {
  try {
    const columnasDetallesEmpleados = [
      { id: "H", titulo: "Sucursal", propiedad: "sucursal" },
      { id: "I", titulo: "Empleado", propiedad: "empleado" },
      { id: "J", titulo: "Correo", propiedad: "correo" },
      { id: "K", titulo: "Teléfono", propiedad: "telefono" },
      { id: "L", titulo: "Total", propiedad: "montoTotal" },
    ];
    const tituloDetalleEmpleados = "LIQUIDACIÓN EMPLEADOS";

    rellenarTabla(
      worksheetRecaudaciones,
      liquidacionEmpleados,
      tituloDetalleEmpleados,
      filaInicial,
      columnasDetallesEmpleados,
      0
    );
  } catch (error) {
    console.error(
      "Error en rellenarPlantillaGastosEmpleadosSucursal: ",
      error.message
    );
  }
};

const rellenarPlantilla = async (templatePath, datosLiquidaciones) => {
  try {
    // Crear un nuevo objeto Workbook de ExcelJS
    const workbook = new ExcelJS.Workbook();

    // Cargar la plantilla Excel desde el archivo en la ruta especificada
    const templateBuffer = await fetch(templatePath).then((response) =>
      response.arrayBuffer()
    );
    await workbook.xlsx.load(templateBuffer);

    // Habilitar el cálculo automático en la hoja
    activarCalculoAutomatico(workbook);

    const worksheetRecaudaciones = workbook.worksheets[0]; // Hoja de recaudaciones
    const worksheetGavs = workbook.worksheets[1]; // Hoja de GAVs

    colocarEncabezado(
      worksheetRecaudaciones,
      "FECHA",
      datosLiquidaciones.fechaFacturacion
    );

    colocarEncabezado(
      worksheetGavs,
      "FECHA",
      datosLiquidaciones.fechaFacturacion
    );

    rellenarPlantillaGastosPorSucursal(
      worksheetGavs,
      datosLiquidaciones.gastosPorSucursal
    );

    rellenarPlantillaGastosSumadosSucursalEmpleados(
      worksheetGavs,
      datosLiquidaciones.gastosSucursalesYEmpleados
    );

    rellenarPlantillaGastosEmpleadosSucursal(
      worksheetGavs,
      datosLiquidaciones.detalleGastosEmpleados
    );

    // Dado el largo de la tabla de sucursales, se aumenta 3 para colocar la nueva tabla debajo
    const filaInicialDetalleGastosSucursales =
      8 + datosLiquidaciones.gastosPorSucursal.length + 3;

    rellenarPlantillaDetalleGastosSucursal(
      filaInicialDetalleGastosSucursales,
      worksheetGavs,
      datosLiquidaciones.detalleGastosSucursales
    );

    rellenarPlantillaTotalFacturado(
      worksheetRecaudaciones,
      datosLiquidaciones.facturadoSucursales
    );

    rellenarPlantillaTotalRecaudado(
      worksheetRecaudaciones,
      datosLiquidaciones.recaudadoSucursales
    );

    const filaInicialLiquidacionSucursales =
      8 + datosLiquidaciones.facturadoSucursales.length + 3;

    rellenarPlantillaLiquidacionSucursales(
      worksheetRecaudaciones,
      datosLiquidaciones.liquidaciones,
      filaInicialLiquidacionSucursales
    );

    const filaInicialLiquidacionEmpleados =
      8 + datosLiquidaciones.recaudadoSucursales.length + 3;

    rellenarPlantillaLiquidacionEmpleados(
      worksheetRecaudaciones,
      datosLiquidaciones.gastosPorEmpleado,
      filaInicialLiquidacionEmpleados
    );

    // Aplicar el borde a las celdas de la columna A a la L en la fila siguiente
    aplicarBordeUltimaFila(worksheetRecaudaciones, 0, 12);
    // Aplicar el borde a las celdas de la columna A a la M en la fila siguiente
    aplicarBordeUltimaFila(worksheetGavs, 0, 13);

    workbook.views = {
      activeTab: 0,
    };

    return workbook;
  } catch (error) {
    console.error(`Error in rellenarTemplate: ${error.message}`);
    throw error;
  }
};

const TablaGav = memo(
  ({
    tabIndex,
    data: gavs,
    loading: isLoadingGavs,
    obtenerInformeLiquidacion,
  }) => {
    const [statesModal, setStateModal] = useState(INITIAL_STATE_MODAL);
    const [showAlert, setShowAlert] = useState(false);
    const [rowSelected, setRowSelected] = useState(null);
    const [statesTable, setStateTable] = useState(INITIAL_STATE_TABLE);
    const [loadingDeleteIndex, setLoadingDeleteIndex] = useState(null);
    const [eliminarGav] = gavsApi.useEliminarGavMutation();
    const { data: prorrateos = [], isLoading: isLoadingSucursales } =
      prorrateosApi.useObtenerSucursalesConProrrateoQuery();
    const [loadingExport, setLoadingExport] = useState(false);

    const opcionesFiltro = !isLoadingSucursales
      ? [{ id: 0, nombre: "TODAS" }, ...prorrateos]
      : [];

    const handleFilterByName = (event) => {
      setStateTable({ ...statesTable, filterName: event.target.value });
    };

    const ProrrateoSchema = Yup.object().shape({
      filtro: Yup.object()
        .shape({
          id: Yup.number().required(),
          nombre: Yup.string().required(),
        })
        .required("La sucursal es requerida"),
      fechaFacturacion: Yup.date()
        .required("La fecha de facturación es requerida")
        .test(
          "formatoFecha",
          "Formato de fecha inválido. Use MM/YYYY",
          (value) => validarFormatoFecha(value, "MM/YYYY")
        )
        .transform((originalValue, originalObject) => {
          return validarFormatoFecha(originalValue, "MM/YYYY")
            ? dayjs(originalValue).toDate()
            : originalValue;
        })
        .typeError("Formato de fecha inválido. Use MM/YYYY"),
    });

    const formik = useFormik({
      initialValues: {
        filtro: { id: 0, nombre: "TODAS" },
        fechaFacturacion: new Date(),
      },
      validationSchema: ProrrateoSchema,
      onSubmit: () => {
        exportarExcelLiquidacion();
      },
    });

    const { values, touched, errors, handleSubmit, setFieldValue } = formik;

    const handleRequestSort = (event, property) => {
      const isAsc =
        statesTable.orderBy === property && statesTable.order === "asc";
      setStateTable({
        ...statesTable,
        order: isAsc ? "desc" : "asc",
        orderBy: property,
      });
    };

    const exportarExcelLiquidacion = async () => {
      setLoadingExport(true);

      const fechaFacturacion = dayjs(values.fechaFacturacion).format("YYYY/MM");
      const body = {
        fechaFacturacion: fechaFacturacion,
        sucursal: values.filtro.nombre,
      };
      const { data: datosLiquidaciones, error } =
        await obtenerInformeLiquidacion(body);

      if (error && error.data) {
        const {
          data: { message },
        } = error;

        AlertaToast({
          action: message,
          fullText: true,
          type: "error",
        }).fire();
        setLoadingExport(false);
        return;
      }
      try {
        const templatePath =
          "/static/plantillas/MODELO_LIQUIDACIONES_SUCURSALES.xlsx";

        const workbook = await rellenarPlantilla(
          templatePath,
          datosLiquidaciones
        );

        await guardarArchivo(
          workbook,
          `Liquidación-${datosLiquidaciones.fechaFacturacion}.xlsx`
        );
        setLoadingExport(false);
      } catch (error) {
        console.error("Error en exportarExcelLiquidaciones", error.message);
        AlertaToast({
          action: "Ocurrió un error desconocido al exportar el Excel",
          fullText: true,
          type: "error",
        }).fire();
        setLoadingExport(false);
      }
    };

    const handleChangeFechaFacturacion = useCallback(
      (newValue) => setFieldValue("fechaFacturacion", newValue),
      [setFieldValue]
    );

    const handleChangePage = (event, newPage) => {
      const maxPage = Math.max(
        0,
        Math.ceil(gavsFiltrados.length / statesTable.rowsPerPage) - 1
      );

      setStateTable({
        ...statesTable,
        currentPage: Math.min(newPage, maxPage),
      });
    };

    const handleChangeRowsPerPage = (event) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      setStateTable((prevState) => ({
        ...prevState,
        rowsPerPage: newRowsPerPage,
        currentPage: 0,
      }));
    };

    const handleClickAdd = useCallback(() => {
      setRowSelected({});
      setStateModal({ ...statesModal, abrirModal: true, seAgregaGav: true });
    }, [setRowSelected, setStateModal, statesModal]);

    const handleClickEdit = useCallback(
      (row) => {
        setRowSelected(row);
        setStateModal({ ...statesModal, abrirModal: true, seAgregaGav: false });
      },
      [setRowSelected, setStateModal, statesModal]
    );

    const handleClickDelete = useCallback(
      (row, index) => {
        setLoadingDeleteIndex(index);
        AlertaModal({
          title: "eliminar el GAV",
          confirmButtonText: "Sí, eliminar",
        })
          .fire()
          .then(async (result) => {
            if (!result.isConfirmed) {
              setLoadingDeleteIndex(null);
              return;
            }

            const body = {
              id: row.id,
            };
            await eliminarGav(body)
              .then((response) => {
                if (response.data.filasBorradas > 0) {
                  AlertaToast({
                    action: "GAV eliminado correctamente",
                    fullText: true,
                  }).fire();
                } else {
                  AlertaToast({
                    action: "Error el eliminar el GAV",
                    fullText: true,
                    type: "error",
                  }).fire();
                }
              })
              .catch((error) => {
                console.error("handleClickDelete: ", error);
                AlertaToast({
                  action: "No se pudo eliminar el GAV. Intente nuevamente",
                  fullText: true,
                  type: "error",
                }).fire();
              })
              .finally(() => {
                setLoadingDeleteIndex(null);
              });
          });
      },
      [eliminarGav]
    );

    const handleSucursalSelection = useCallback(
      (event, newValue) => {
        setFieldValue("filtro", newValue);
      },
      [setFieldValue]
    );

    const handleOptionLabelDivision = useCallback(
      (option) => option.nombre,
      []
    );

    const handleIsOptionEqualToValueDivision = useCallback(
      (option, value) => option.id === value.id,
      []
    );

    const gavsFiltrados = useMemo(() => {
      const propiedadesParaFiltrar = ["nombre", "montoTotal", "comentario"];
      return applySortFilter(
        gavs,
        getComparator(statesTable.order, statesTable.orderBy),
        statesTable.filterName,
        propiedadesParaFiltrar
      );
    }, [gavs, statesTable.filterName, statesTable.order, statesTable.orderBy]);

    const filasVacias =
      statesTable.currentPage > 0
        ? Math.max(
            0,
            (1 + statesTable.currentPage) * statesTable.rowsPerPage -
              gavs.length
          )
        : 0;

    const seEncontroGav = gavsFiltrados.length === 0;

    // Efecto para ajustar la página cuando los resultados filtrados cambian
    useEffect(() => {
      // Calcula el número máximo de páginas posibles
      const maxPage = Math.max(
        0,
        Math.ceil(gavsFiltrados.length / statesTable.rowsPerPage) - 1
      );

      // Si la página actual es mayor que el máximo de páginas, resetea a la última página válida
      if (statesTable.currentPage > maxPage) {
        setStateTable((prev) => ({
          ...prev,
          currentPage: maxPage,
        }));
      }
    }, [
      gavsFiltrados.length,
      statesTable.currentPage,
      statesTable.rowsPerPage,
    ]);

    if (tabIndex !== 0) return null;

    return (
      <>
        <Card>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs="auto">
              <TablaToolbar
                filterName={statesTable.filterName}
                onFilterName={handleFilterByName}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3" align="center">
                Gavs
              </Typography>
            </Grid>
            <Grid item xs md textAlign="right" marginRight={2}>
              <Button
                onClick={handleClickAdd.bind(null)}
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
              >
                Agregar GAV
              </Button>
            </Grid>
          </Grid>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs></Grid>
                <AutoCompleteField
                  disableClearable={true}
                  id="autocomplete-filtro"
                  label="Sucursal"
                  value={values.filtro}
                  xs={3}
                  md={3}
                  lg={3}
                  handleSucursalSelection={handleSucursalSelection}
                  options={opcionesFiltro}
                  touched={touched.filtro}
                  error={errors.filtro}
                  getOptionLabel={handleOptionLabelDivision}
                  isOptionEqualToValue={handleIsOptionEqualToValueDivision}
                  typeRenderInput="normal"
                />
                <CalendarField
                  id="fecha-facturacion"
                  label="Fecha facturación"
                  value={values.fechaFacturacion}
                  onChange={handleChangeFechaFacturacion}
                  inputFormat="MM/yyyy"
                  views={["month", "year"]}
                  touched={touched.fechaFacturacion}
                  errors={errors.fechaFacturacion}
                  tamanio={3}
                />
                <Grid item xs md textAlign="right" marginRight={2}>
                  <ExportarAExcel
                    nombreBoton="Liquidación"
                    loadingExport={loadingExport || isLoadingSucursales}
                  />
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>

          <Scrollbar>
            <TableContainer>
              {!isLoadingGavs ? (
                <Table>
                  <TablaHead
                    order={statesTable.order}
                    orderBy={statesTable.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={gavs.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {gavsFiltrados
                      .slice(
                        statesTable.currentPage * statesTable.rowsPerPage,
                        statesTable.currentPage * statesTable.rowsPerPage +
                          statesTable.rowsPerPage
                      )
                      .map((gav, index) => (
                        <TableRow hover key={gav.id}>
                          <TableCell>
                            {statesTable.currentPage * statesTable.rowsPerPage +
                              index +
                              1}
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2" noWrap>
                              {gav.nombre}
                            </Typography>
                          </TableCell>
                          <TableCell>{formatAsCLP(gav.montoTotal)}</TableCell>
                          <TableCell>
                            {dayjs(gav.fechaGasto).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell>
                            {dayjs(gav.fechaFacturacion).format("MM/YYYY")}
                          </TableCell>
                          <TableCell>
                            {gav.division === "empleadoEspecifico"
                              ? "Empleados específicos"
                              : capitalizarPrimeraLetra(gav.division)}
                          </TableCell>
                          <TableCell>{gav.comentario}</TableCell>
                          <TableCell>
                            <Grid
                              item
                              xs={12}
                              md
                              container
                              justifyContent="flex-end"
                              sx={{ flexWrap: "nowrap" }}
                            >
                              <Tooltip title="Editar GAV">
                                <Button
                                  color="primary"
                                  onClick={handleClickEdit.bind(null, gav)}
                                >
                                  <Edit />
                                </Button>
                              </Tooltip>
                              <Tooltip title="Eliminar GAV">
                                <Button
                                  color="error"
                                  onClick={() => handleClickDelete(gav, index)}
                                >
                                  {loadingDeleteIndex === index ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <Delete />
                                  )}
                                </Button>
                              </Tooltip>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}

                    {filasVacias > 0 && (
                      <TableRow style={{ height: 53 * filasVacias }}>
                        <TableCell colSpan={3} />
                      </TableRow>
                    )}
                  </TableBody>
                  {seEncontroGav && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                          <SearchNotFound
                            searchQuery={statesTable.filterName}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  <TableFooter>
                    <TableRow>
                      <ThemeProvider
                        theme={(outerTheme) =>
                          createTheme(outerTheme, locales[statesTable.locale])
                        }
                      >
                        <CustomTablePagination
                          count={gavsFiltrados.length}
                          rowsPerPageOptions={statesTable.rowsPerPageOptions}
                          rowsPerPage={statesTable.rowsPerPage}
                          page={statesTable.currentPage}
                          colSpan={8}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </ThemeProvider>
                    </TableRow>
                  </TableFooter>
                </Table>
              ) : (
                <Skeleton variant="rectangular" width="100%" height="500px" />
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        <Alerta
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          color="success"
          message="Prorrateo configurado correctamente"
        />
        {statesModal.abrirModal && (
          <ModalGav
            isOpen={statesModal.abrirModal}
            setOpen={setStateModal}
            data={rowSelected}
            agregar={statesModal.seAgregaGav}
            statesModal={statesModal}
          />
        )}
      </>
    );
  }
);

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPage: 5,
  rowsPerPageOptions: [5, 10, 25],
  order: "desc",
  orderBy: "fechaGasto",
  locale: "esES",
  filterName: "",
};

const INITIAL_STATE_MODAL = {
  abrirModal: false,
  seAgregaGav: false,
};

const TABLE_HEAD = [
  { id: "position", label: "N°", alignRight: false },
  { id: "nombre", label: "Nombre", alignRight: false },
  { id: "montoTotal", label: "Monto total", alignRight: false },
  { id: "fechaGasto", label: "Fecha gasto", alignRight: false },
  { id: "fechaFacturacion", label: "Fecha facturación", alignRight: false },
  { id: "divisionGasto", label: "División gasto", alignRight: false },
  { id: "comentarios", label: "Comentarios", alignRight: false },
  { id: "" },
];

const CONSTANTES_EXCEL = {
  HEADER_FONT_STYLE: {
    name: "Arial Nova Cond",
    size: 12,
    bold: true,
    color: { argb: "44546A" },
  },
  TITLE_FONT_STYLE: {
    name: "Arial Nova Cond",
    size: 12,
    bold: true,
    color: { argb: "FFFFFF" },
  },
  DATA_FONT_STYLE: {
    name: "Arial Nova Cond",
    size: 12,
  },
  HEADER_FILL: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "ED7D31" },
  },
  HEADER_TITLE: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  },
};

export default TablaGav;
