import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect, useCallback } from "react";
import { propuestasApi } from "src/services/propuestasApi";
import { fechaActual } from "src/utils/formatTime";
import { useSnackbar } from "src/components/snackbar";
import { LoadingButton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  rejectButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  otpInput: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  dialogContent: {
    minWidth: 300,
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  otpInfo: {
    fontSize: "0.9rem",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
  },
  commentInput: {
    marginTop: theme.spacing(2),
  },
  timerText: {
    color: theme.palette.warning.main,
    fontWeight: "bold",
    marginTop: theme.spacing(1),
  },
  termsContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  termsLink: {
    marginLeft: theme.spacing(1),
    textDecoration: "underline",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: 4,
  },
}));

const ResponseDialog = ({
  open,
  onClose,
  dialogType,
  setProposalStatus,
  token,
  refetch,
  contactClient,
  nombreCliente,
}) => {
  const classes = useStyles();
  const [comments, setComments] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpSentTime, setOtpSentTime] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [remainingTime, setRemainingTime] = useState(null);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const [generarOTP, { isLoading: enviandoCodigo }] =
    propuestasApi.useGenerarOTPMutation();
  const [verificarOTP, { isLoading: verificandoOTP }] =
    propuestasApi.useVerificarOTPMutation();
  const [rechazarPropuesta, { isLoading: rechazandoPropuesta }] =
    propuestasApi.useRechazarPropuestaMutation();
  const { enqueueSnackbar } = useSnackbar();

  const OTP_VALIDITY_MINUTES = 5;
  const OTP_VALIDITY_MS = OTP_VALIDITY_MINUTES * 60 * 1000;

  useEffect(() => {
    if (open) {
      clear();
    }
  }, [open]);

  const clear = () => {
    setComments("");
    setOtp("");
    setErrorMessage("");
    setOtpSentTime(null);
    setIsOtpSent(false);
    setAcceptedTerms(false);
    setShowTermsError(false);
  };

  useEffect(() => {
    let intervalId;

    if (otpSentTime && isOtpSent) {
      // Inicializar el contador inmediatamente
      const updateRemainingTime = () => {
        const now = new Date();
        const expirationTime = new Date(
          otpSentTime.getTime() + OTP_VALIDITY_MS
        );
        const timeLeft = expirationTime - now;

        if (timeLeft <= 0) {
          setRemainingTime(null);
          clearInterval(intervalId);
          clear();
        } else {
          const minutes = Math.floor(timeLeft / 60000);
          const seconds = Math.floor((timeLeft % 60000) / 1000);
          setRemainingTime({ minutes, seconds });
        }
      };

      // Actualizar inmediatamente y luego cada segundo
      updateRemainingTime();
      intervalId = setInterval(updateRemainingTime, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [otpSentTime, isOtpSent, OTP_VALIDITY_MS]);

  const handleOtpRequest = useCallback(async () => {
    try {
      const otpTime = fechaActual().toDate();
      setOtpSentTime(otpTime);
      await generarOTP({ token, otpSentTime: otpTime }).unwrap();
      setIsOtpSent(true);
      enqueueSnackbar(
        "Se ha enviado un código OTP a tu correo electrónico. Revisa la carpeta de SPAM si no lo encuentras."
      );
    } catch (error) {
      console.error("Error al generar el OTP:", error);
      enqueueSnackbar(
        error?.data?.message || "Ocurrió un error al generar el código OTP. Reintente nuevamente.",
        {
          variant: "error",
        }
      );
    }
  }, [enqueueSnackbar, generarOTP, token]);

  const handleVerifyOtp = useCallback(async () => {
    setShowTermsError(false);
    if (!acceptedTerms) {
      setShowTermsError(true);
      return;
    }

    try {
      await verificarOTP({
        token,
        otp,
        nombreCliente,
      }).unwrap();
      setRemainingTime(null);
      setOtpSentTime(null);
      setErrorMessage("");
      setProposalStatus(
        dialogType === "ACEPTADA" ? "ACEPTADO_CLIENTE" : "RECHAZADA"
      );
      enqueueSnackbar("Código verificado. Propuesta aceptada correctamente.");
      refetch();
      onClose();
    } catch (error) {
      console.error("Error al verificar el OTP:", error);
      if (
        error?.data?.message === "OTP expirado o inválido" ||
        error?.data?.message === "OTP expirado."
      ) {
        enqueueSnackbar(
          "El código OTP ingresado ha expirado o no es válido. Por favor, solicite uno nuevo.",
          {
            variant: "error",
          }
        );
        clear();
      } else if (error?.data.message === "OTP incorrecto.") {
        setErrorMessage(
          "Código OTP incorrecto. Por favor, verifica el código ingresado."
        );
      } else {
        enqueueSnackbar(
          "Ocurrió un error desconocido al verificar la propuesta. Contáctese con el remitente de la propuesta.",
          {
            variant: "error",
          }
        );
      }
    }
  }, [
    acceptedTerms,
    verificarOTP,
    token,
    otp,
    nombreCliente,
    setProposalStatus,
    dialogType,
    enqueueSnackbar,
    refetch,
    onClose,
  ]);

  const handleRejectProposal = useCallback(async () => {
    try {
      await rechazarPropuesta({
        token,
        comentarios: comments,
        nombreCliente,
      }).unwrap();
      enqueueSnackbar("Propuesta rechazada correctamente.");
      setProposalStatus(dialogType);
      refetch();
      onClose();
    } catch (error) {
      console.error("handleRejectProposal", error);
      enqueueSnackbar(
        error?.data?.message || "Ocurrió un error al rechazar la propuesta",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  }, [
    comments,
    dialogType,
    enqueueSnackbar,
    onClose,
    rechazarPropuesta,
    setProposalStatus,
    token,
    refetch,
    nombreCliente,
  ]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {dialogType === "ACEPTADA" ? "Aceptar Propuesta" : "Rechazar Propuesta"}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {dialogType === "ACEPTADA" && !isOtpSent ? (
          <>
            <Typography variant="body1">
              {`Para garantizar la seguridad de tu aceptación, presiona el botón y te enviaremos un código OTP a tu correo registrado (${contactClient}). Luego, ingrésalo para completar el proceso.`}
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleOtpRequest}
              disabled={enviandoCodigo}
              style={{ marginTop: 16 }}
            >
              {enviandoCodigo ? "Enviando..." : "Solicitar Código OTP"}
            </Button>
          </>
        ) : isOtpSent ? (
          <>
            <TextField
              className={classes.otpInput}
              label="Código OTP"
              variant="outlined"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              disabled={verificandoOTP}
              error={!!errorMessage}
              helperText={errorMessage}
            />
            <div className={classes.termsContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptedTerms}
                    onChange={(e) => setAcceptedTerms(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2">
                    Acepto los
                    <a
                      href="https://www.dropbox.com/scl/fi/e767r1vopyu6oqj68xyel/T-rminos-y-Condiciones.pdf?rlkey=fhaqvi47f0f367x624buc8ow9&st=eyiqj9qh&dl=0"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.termsLink}
                    >
                      términos y condiciones
                    </a>
                  </Typography>
                }
              />
            </div>
            {showTermsError && (
              <FormHelperText className={classes.errorText}>
                Debes aceptar los términos y condiciones.
              </FormHelperText>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleVerifyOtp}
              fullWidth
              style={{ marginTop: 16 }}
              disabled={verificandoOTP}
            >
              {verificandoOTP ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Verificar OTP"
              )}
            </Button>
            <Typography className={classes.otpInfo}>
              El código OTP es válido por 5 minutos.
            </Typography>
            {remainingTime ? (
              <Typography className={classes.timerText}>
                Tiempo restante: {remainingTime.minutes}:
                {remainingTime.seconds.toString().padStart(2, "0")} minutos
              </Typography>
            ) : (
              <Typography color="error" variant="body2">
                El código OTP ha expirado. Solicita uno nuevo.
              </Typography>
            )}
          </>
        ) : (
          <>
            <TextField
              autoFocus
              required
              margin="dense"
              label="Motivo del rechazo"
              type="text"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              className={classes.commentInput}
              error={!comments}
              helperText={!comments ? "Este campo es obligatorio" : ""}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        {dialogType === "RECHAZADA" && (
          <LoadingButton
            onClick={handleRejectProposal}
            variant="contained"
            className={classes.rejectButton}
            disabled={!comments || rechazandoPropuesta}
            loading={rechazandoPropuesta}
          >
            Rechazar
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ResponseDialog);
