import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, CircularProgress, TextField } from "@material-ui/core";

const CustomAutocomplete = ({
  options,
  maxTags = 4,
  size = "medium",
  label,
  placeholder,
  onChange,
  error = null,
  touched = false,
  loading = false,
  value = [],
  getOptionLabel,
  isRequired = false,
  ...rest
}) => {
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      loading={loading}
      disabled={loading}
      id="custom-autocomplete"
      limitTags={maxTags}
      options={options}
      value={value}
      size={size}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          required={isRequired}
          label={label}
          placeholder={placeholder}
          error={Boolean(error)}
          helperText={error ? error : ""}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...rest}
    />
  );
};

CustomAutocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  maxTags: PropTypes.number,
  size: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  touched: PropTypes.bool,
};

export default React.memo(CustomAutocomplete);
