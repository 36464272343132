import { alpha, Box, Collapse, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import Iconify from "../iconify";
import { useSettingsContext } from "../settings";
import StyledNotistack from "./styles";
import { SnackbarProvider as NotistackProvider, useSnackbar } from "notistack";
import infoFill from "@iconify/icons-eva/info-fill";
import checkmarkSquare2Fill from "@iconify/icons-eva/checkmark-square-2-fill";
import alertTriangleFill from "@iconify/icons-eva/alert-triangle-fill";
import alertCircleFill from "@iconify/icons-eva/alert-circle-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import { setGlobalEnqueueSnackbar } from ".";

// ----------------------------------------------------------------------

SnackbarProvider.propTypes = {
  children: PropTypes.node,
};

export default function SnackbarProvider({ children }) {
  const { themeDirection } = useSettingsContext();

  const ConfigureGlobalSnackbar = () => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      setGlobalEnqueueSnackbar(enqueueSnackbar);
    }, [enqueueSnackbar]);

    return null;
  };

  const isRTL = themeDirection === "rtl";

  const notistackRef = useRef(null);

  const onClose = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <StyledNotistack />

      <NotistackProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        TransitionComponent={isRTL ? Collapse : undefined}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        iconVariant={{
          info: <SnackbarIcon icon={infoFill} color="info" />,
          success: <SnackbarIcon icon={checkmarkSquare2Fill} color="success" />,
          warning: <SnackbarIcon icon={alertTriangleFill} color="warning" />,
          error: <SnackbarIcon icon={alertCircleFill} color="error" />,
        }}
        // With close as default
        action={(key) => (
          <IconButton size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
            <Iconify icon={closeFill} />
          </IconButton>
        )}
      >
        <ConfigureGlobalSnackbar />
        {children}
      </NotistackProvider>
    </>
  );
}

// ----------------------------------------------------------------------

SnackbarIcon.propTypes = {
  icon: PropTypes.object,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
  ]),
};

function SnackbarIcon({ icon, color }) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        justifyContent: "center",
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} />
    </Box>
  );
}
