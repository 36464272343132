import { CardHeader, Grid } from "@material-ui/core";
import React, { useMemo } from "react";
import { TablaToolbar } from "../_dashboard/tablas";
import ExportToExcel from "../ExportToExcel";
import PropTypes from "prop-types";
import { formatearFecha } from "src/utils/formatTime";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import { useSelector } from "react-redux";

const mapPropuestaPlan = (propuesta, sucursal) => ({
  id: propuesta.id,
  rutSolicitante: formatRut(propuesta.rutSolicitante, RutFormat.DOTS_DASH),
  nombreSolicitante: propuesta.nombreSolicitante,
  correoSolicitante: propuesta.correoSolicitante,
  telefonoSolicitante: propuesta.telefonoSolicitante,
  estado:
    propuesta.estado === "ACEPTADO_CLIENTE"
      ? "ACEPTADO POR EL CLIENTE"
      : propuesta.estado,
  fechaCreacion: formatearFecha(propuesta.fechaCreacion, "DD/MM/YYYY"),
  fechaEnvioCorreo: propuesta.fechaEnvioCorreo
    ? formatearFecha(propuesta.fechaEnvioCorreo, "DD/MM/YYYY")
    : "No enviada",
  urlPropuesta: propuesta.urlPropuesta ?? "Sin propuesta cargada",
  sucursal,
});

const mapPropuestaGeneral = (propuesta, sucursal) => ({
  id: propuesta.id,
  rutCliente: formatRut(propuesta.rutCliente, RutFormat.DOTS_DASH),
  nombreCliente: propuesta.nombreCliente,
  correoCliente: propuesta.correoCliente,
  nombreTarea: propuesta.nombreTarea,
  precio: propuesta.precioFinal,
  tipoMoneda: propuesta.tipoMoneda,
  rutEncargado: formatRut(propuesta.rutEmpleado, RutFormat.DOTS_DASH),
  nombreEncargado: propuesta.nombreEmpleado,
  correoEncargado: propuesta.correoEmpleado,
  rutVendedor: propuesta.vendidoTaxtic
    ? "-"
    : formatRut(propuesta.rutVendedor, RutFormat.DOTS_DASH),
  nombreVendedor: propuesta.vendidoTaxtic
    ? "Vendido Taxtic"
    : propuesta.nombreVendedor,
  estado:
    propuesta.estado === "ACEPTADO_CLIENTE"
      ? "ACEPTADO POR EL CLIENTE"
      : propuesta.estado,
  sucursal,
  urlPropuesta: propuesta.urlPropuesta || "Sin propuesta cargada",
});

const EncabezadoTablaPropuestas = ({
  title,
  subheader,
  statesTable,
  handleFilterByName,
  propuestas,
  tipo = "PLAN",
}) => {
  const { sucursal } = useSelector((state) => state.auth.user);

  const dataMapped = useMemo(() => {
    if (!propuestas?.length) return [];

    return propuestas.map((propuesta) =>
      propuesta.tipo === "PLAN"
        ? mapPropuestaPlan(propuesta, sucursal.refSucursal)
        : mapPropuestaGeneral(propuesta, sucursal.refSucursal)
    );
  }, [propuestas, sucursal?.refSucursal]);

  return (
    <Grid item>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md={6} lg={6}>
          <CardHeader title={title} subheader={subheader} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <TablaToolbar
                filterName={statesTable.filterName}
                onFilterName={handleFilterByName}
              />
            </Grid>
            <Grid item xs={12} sm={6} container justifyContent="flex-end">
              <ExportToExcel
                data={dataMapped}
                filename={
                  tipo === "PLAN"
                    ? `Propuestas de plan sucursal ${sucursal.refSucursal}`
                    : `Propuestas de tareas especiales sucursal ${sucursal.refSucursal}`
                }
                sheetName="propuestas"
                type="objects"
                style={{ margin: 24 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

EncabezadoTablaPropuestas.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  statesTable: PropTypes.object,
  handleFilterByName: PropTypes.func,
  propuestas: PropTypes.array,
};

export default React.memo(EncabezadoTablaPropuestas);
