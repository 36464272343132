import React, { useState, useEffect } from "react";
import { API_SERVER } from "../../utils/urlBack_End";
import * as Yup from "yup";
import { Form, useFormik, FormikProvider } from "formik";
import TablaPlanMasivo from "./TablaPlanMasivo";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Step,
  StepLabel,
  Stepper,
  Autocomplete,
  TextField,
  Container,
  Grid,
  FormControl,
} from "@material-ui/core";
import { lowerCase, startCase } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import CardPlan from "../propuesta/CardPlan";
import FormDefinirPrecio from "./FormDefinirPrecio";
import { FormFrecuenciaPlan } from "../propuesta/FormFrecuenciaPlan";
import { LoadingButton } from "@material-ui/lab";
import { useSelector } from "react-redux";

const styles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
    marginBottom: 12,
    marginTop: 60,
  },
  boton: {
    maxHeight: "3.2rem",
    minWidth: "100%",
    marginTop: 17,
  },
  selectMenu: {
    minWidth: "100%",
    marginBottom: 12,
  },
}));

export default function ModalPlanMasivo(props) {
  const {
    agregarPlan,
    setAgregarPlan,
    setOpenPlanMasivo,
    setPlanesOriginal,
    empleados,
    setColor,
    setMessage,
    setAlerta,
  } = props;

  const [expandir, setExpandir] = useState(0);
  const [clientes, setClientes] = useState([]);

  const [clienteNombre, setClienteNombre] = useState("");
  const [cliente, setCliente] = useState(null);
  const [clientesEmpleado, setClientesEmpleado] = useState([]);
  const steps = ["Empleado", "Cliente", "Plan", "Tabla Plan"];
  const [activeStep, setActiveStep] = useState(0);

  const classes = styles();
  const {
    empleado: usuario,
    sucursal: sucursalObtenida,
    rol: rolObtenido,
  } = useSelector((state) => state.auth.user);

  const [planes, setPlanes] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;
  const [nombrePlan, setNombrePlan] = useState("");

  const [datosPlanes, setDatosPlanes] = useState([]);
  const [planesAGuardar, setPlanesAGuardar] = useState([]);
  const [guardando, setGuardando] = useState(false);
  const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState("");
  const [empleadoSelect, setEmpleadoSelect] = useState(null);
  const [empleadoOptions, setEmpleadoOptions] = useState([]);

  function createOptions(data) {
    const options = [];
    data.map((value) => {
      options.push({ nombre: value.nombre });
    });

    return options;
  }

  useEffect(() => {
    setEmpleadoOptions(
      empleados.map((item) => {
        return {
          label: startCase(lowerCase(item.nombre + " " + item.apellidos)),
          value: item.rut,
        };
      })
    );
  }, [empleados]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setClientes(response.data);
      });
  }, []);

  useEffect(() => {
    let active = true;
    let data = [];

    if (!loading) {
      return undefined;
    }

    (async () => {
      await axios
        .get(`${API_SERVER}/tipos/tipoPlanes`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          setPlanes(response.data);
          data = response.data;
        });

      if (active) {
        setOptions([...createOptions(data)]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (
      empleados.length > 0 &&
      clientesEmpleado.length === 0 &&
      clientes.length > 0
    ) {
      let grupo = {
        nombre: "Todos los clientes",
        rut: usuario.rut,
        info: clientes,
      };
      setClientesEmpleado((clientesEmpleado) => [...clientesEmpleado, grupo]);
      Promise.all(
        empleados.map((empleado) => {
          axios
            .get(
              `${API_SERVER}/planes/empleado/${sucursalObtenida.refSucursal}/${empleado.rut}`,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then(function (response) {
              if (response.data === -2) window.location.reload();
              let grupo = {
                nombre:
                  "Clientes de: " + empleado.nombre + " " + empleado.apellidos,
                rut: empleado.rut,
                info: response.data,
              };
              setClientesEmpleado((clientesEmpleado) => [
                ...clientesEmpleado,
                grupo,
              ]);
            });
          return false;
        })
      );
    }
  }, [
    clientesEmpleado,
    empleados,
    usuario.apellidos,
    usuario.rut,
    usuario.nombre,
    rolObtenido,
    sucursalObtenida.refSucursal,
    clientes,
  ]);

  const generarPlanes = () => {
    if (cliente?.info === undefined) {
      //hacer plan para un solo cliente
      const refCliente = cliente.rut;
      setPlanesAGuardar([{ refCliente, ...values }]);
      const nombreCliente = clienteNombre;
      setDatosPlanes([
        { nombreCliente, nombreEmpleado: empleadoSelect.label, ...values },
      ]);
    } else {
      //variosClientes
      let datosPlanes = [];
      let planesAGuardar = [];
      // eslint-disable-next-line array-callback-return
      cliente.info.map((infoCliente) => {
        const refCliente = infoCliente.refCliente
          ? infoCliente.refCliente
          : infoCliente.rut;
        planesAGuardar = [...planesAGuardar, { refCliente, ...values }];
        const nombreCliente = infoCliente.nombre
          ? infoCliente.nombre
          : infoCliente.nombreCliente;
        datosPlanes = [
          ...datosPlanes,
          { nombreCliente, nombreEmpleado: empleadoSelect.label, ...values },
        ];
      });

      setPlanesAGuardar((planes) => [...planes, ...planesAGuardar]);
      setDatosPlanes((planes) => [...planes, ...datosPlanes]);
    }
  };

  function isEmptyObject(obj) {
    var name;
    for (name in obj) {
      return false;
    }
    return true;
  }

  const actualizar = () => {
    if (rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN") {
      axios
        .get(`${API_SERVER}/planes/sucursal/${sucursalObtenida.refSucursal}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          setPlanesOriginal(response.data);
        });
    } else {
      axios
        .get(
          `${API_SERVER}/planes/empleado/${sucursalObtenida.refSucursal}/${usuario.rut}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          let resultado = response.data;
          axios
            .get(
              `${API_SERVER}/empleados/empleadoPlan/${usuario.rut}/${sucursalObtenida.refSucursal}`,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((res) => {
              if (res.data === -2) window.location.reload();

              if (res.data && Array.isArray(res.data)) {
                res.data.forEach((t) => {
                  resultado.push(t);
                });
              }
              setPlanesOriginal(resultado);
            })
            .catch(() => {
              setPlanesOriginal(resultado);
            });
        });
    }
  };

  const mostrarMensaje = (color, mensaje) => {
    setColor(color);
    setMessage(mensaje);
    setAlerta(true);
    setGuardando(false);
    setAgregarPlan(false);
    setActiveStep(0);
    setOpenPlanMasivo(false);
  };

  const guardarDatos = () => {
    setGuardando(true);

    Promise.all(
      planesAGuardar.map((plan, i) =>
        axios
          .post(
            `${API_SERVER}/planes/relacionPlanCliente`,
            {
              ...plan,
              sucursal: sucursalObtenida.refSucursal,
              refEmpleado: empleadoSelect.value,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(function (response) {
            if (response.data === -2) window.location.reload();

            if (response.data === true) {
              setColor("success");
              setMessage(
                "El plan " +
                  plan.refTipoPlan +
                  " asignado al cliente: " +
                  plan.refCliente +
                  " Guardado con Éxito"
              );
            } else {
              setColor("error");
              setMessage("Ha ocurrido un error al guardar los datos");
              throw new Error("Ha ocurrido un error desconocido");
            }
            setAlerta(true);
          })
          .catch((error) => {
            throw error;
          })
      )
    )
      .then(function (response) {
        actualizar();
        mostrarMensaje("success", "El guardado de los datos ha terminado");
      })
      .catch((error) => {
        console.error(error);
        const mensaje = error.response
          ? error.response.data.message
          : error.message;
        mostrarMensaje(
          "error",
          `Error al añadir plan masivo: ${mensaje.replace(
            /BadRequestError: /,
            ""
          )}`
        );
      });
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (empleadoSelect !== null) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setColor("info");
        setMessage("Debe seleccionar un empleado.");
        setAlerta(true);
      }
    }
    if (activeStep === 1) {
      if (cliente !== null) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setColor("info");
        setMessage("Debe seleccionar al menos a un cliente");
        setAlerta(true);
      }
    }
    if (activeStep === 2) {
      if (selectedPlan === null) {
        setColor("info");
        setMessage("Debe seleccionar una Plan");
        setAlerta(true);
      } else if (
        parseFloat(values.descuento) > 0 &&
        values.fechaTerminoDesc === ""
      ) {
        setColor("info");
        setMessage("Debe Ingresar la Fecha del Término del Descuento");
        setAlerta(true);
      } else if (!isEmptyObject(errors) && !isEmptyObject(formik.touched)) {
        setColor("info");
        setMessage("Faltan Campos por Completar");
        setAlerta(true);
      } else {
        if (
          parseFloat(selectedPlan.valor) !== parseFloat(values.valor) ||
          selectedPlan.tipoMoneda.toUpperCase() !==
            values.tipoMoneda.toUpperCase()
        ) {
          values.valorFijo = "SI";
        } else {
          values.valorFijo = "NO";
        }
        generarPlanes();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    if (activeStep === 3) {
      guardarDatos();
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setCliente(null);
      setAgregarPlan(false);
    }
    if (activeStep === 2) {
      setPlanesAGuardar([]);
      setDatosPlanes([]);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const FormSchema = Yup.object().shape({
    valor: Yup.number()
      .min(0, "El precio no puede ser menor a $0")
      .required("El Precio debe ser mayor o igual a 0"),
    valorDesc: Yup.number()
      .min(0, "El precio no puede ser menor a $0")
      .required("El Precio debe ser mayor o igual a 0"),
    descuento: Yup.number()
      .min(0, "El descuento no puede ser menor a 0")
      .max(100, "El descuento no puede ser mayor a 100")
      .required("El Descuento debe ser mayor o igual a 0"),
    tipoMoneda: Yup.string().required("Tipo de moneda requerida"),
    frecuencia: Yup.number()
      .min(1, "La frecuencia no puede ser menor a 1")
      .max(10000, "La frecuencia no puede ser mayor a 10000")
      .required("Frecuencia debe ser mayor o igual a 1"),
    tipoFrecuencia: Yup.string().required("Tipo de frecuencia requerida"),
  });

  const formik = useFormik({
    initialValues: {
      refTipoPlan: "",
      descuento: "",
      valorDesc: "",
      fechaTerminoDesc: "",
      tipoMoneda: "",
      valor: "",
      valorFijo: "NO",
      recurrente: "SI",
      frecuencia: 1,
      tipoFrecuencia: "MES",
      fechaSiguienteCiclo: "",
      porcentajeFacturacion: 0,
      refEmpresaFacturacion: "",
      mesCargaTareas: "",
      refEmpleado: "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {},
  });

  const { errors, values, handleSubmit } = formik;

  return (
    <>
      <Dialog
        open={agregarPlan}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        maxWidth={"lg"}
      >
        <DialogTitle id="form-dialog-title">Creación Plan Masivo</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", minHeight: expandir }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {activeStep === 0 ? (
              <React.Fragment>
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{ textAlign: "center", marginTop: "30px" }}
                >
                  Seleccione Empleado
                </Typography>

                <FormControl
                  style={{ marginTop: "30px" }}
                  className={classes.formControl}
                >
                  <Autocomplete
                    value={empleadoSelect}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        setEmpleadoSeleccionado(newValue.value);
                        setEmpleadoSelect(newValue);
                        values.refEmpleado = newValue.value;
                      } else {
                        setEmpleadoSeleccionado("");
                        setEmpleadoSelect(null);
                        values.refEmpleado = null;
                      }
                    }}
                    fullWidth
                    id="combo-box-empleados"
                    options={empleadoOptions}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        type="rut"
                        label="Empleados"
                        required
                        value={empleadoSeleccionado}
                      />
                    )}
                  />
                </FormControl>
              </React.Fragment>
            ) : activeStep === 1 ? (
              <React.Fragment>
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{ textAlign: "center", marginTop: "30px" }}
                >
                  Seleccione Cliente
                </Typography>

                <Autocomplete
                  className={classes.formControl}
                  value={cliente}
                  disablePortal
                  options={clientesEmpleado
                    .filter((grupo) => grupo.info.length > 0)
                    .concat(clientes)}
                  isOptionEqualToValue={(option, value) =>
                    option.rut === value.rut && option.nombre === value.nombre
                  }
                  getOptionLabel={(option) => option.nombre}
                  onChange={(event, newValue) => {
                    setCliente(newValue);
                  }}
                  inputValue={clienteNombre}
                  onInputChange={(event, newInputValue) => {
                    setClienteNombre(newInputValue);
                  }}
                  fullWidth
                  onOpen={(e) => setExpandir(400)}
                  onClose={(e) => setExpandir(0)}
                  ListboxProps={{ style: { maxHeight: "15rem" } }}
                  renderInput={(params) => (
                    <TextField {...params} label="Seleccione Cliente" />
                  )}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep === 2 && (
                  <>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Plan
                    </Typography>
                    <FormikProvider value={formik}>
                      <Form
                        autoComplete="off"
                        noValidate
                        onSubmit={handleSubmit}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          spacing={3}
                        >
                          <Grid
                            item
                            xs={11}
                            style={{
                              marginLeft: "2.5%",
                              marginBottom: "20px",
                            }}
                          >
                            <Autocomplete
                              id="asynchronous-demo"
                              value={selectedPlan}
                              isOptionEqualToValue={(option, value) =>
                                option.nombre === value.nombre
                              }
                              getOptionLabel={(option) =>
                                option.nombre ? option.nombre : ""
                              }
                              inputValue={nombrePlan}
                              onInputChange={(_, newInputValue) => {
                                setNombrePlan(newInputValue);
                              }}
                              options={planes}
                              loading={loading}
                              open={open}
                              onOpen={() => setOpen(true)}
                              onClose={() => setOpen(false)}
                              onChange={(event, newValue) => {
                                if (newValue !== null) {
                                  setSelectedPlan(newValue);
                                  formik.setFieldValue(
                                    "refTipoPlan",
                                    newValue.nombre
                                  );
                                  formik.setFieldValue(
                                    "tipoMoneda",
                                    newValue.tipoMoneda
                                  );
                                  formik.setFieldValue(
                                    "valor",
                                    parseFloat(newValue.valor)
                                  );
                                  formik.setFieldValue(
                                    "valorDesc",
                                    parseFloat(newValue.valor)
                                  );
                                  formik.setFieldValue("descuento", 0);
                                } else {
                                  setSelectedPlan(null);
                                  formik.setFieldValue("refTipoPlan", "");
                                  formik.setFieldValue("tipoMoneda", "");
                                  formik.setFieldValue("valor", "");
                                  formik.setFieldValue("valorDesc", "");
                                  formik.setFieldValue("descuento", 0);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Seleccione Plan"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loading ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          {selectedPlan?.nombre ? (
                            <>
                              <Grid
                                alignItems="center"
                                container
                                direction="row"
                                spacing={3}
                              >
                                <Grid
                                  item
                                  xs={10}
                                  md={6}
                                  style={{ marginLeft: "2.5%" }}
                                >
                                  {" "}
                                  <CardPlan plan={selectedPlan} />
                                </Grid>
                                <Grid item xs={9} md={5}>
                                  {" "}
                                  <Grid item xs={11}>
                                    <h4>Definir Precio </h4>
                                  </Grid>
                                  <FormDefinirPrecio formik={formik} />
                                </Grid>
                                <Grid
                                  item
                                  xs={11}
                                  style={{ marginLeft: "2.5%" }}
                                >
                                  <h4>Definir Frecuencia </h4>
                                </Grid>
                                <Grid
                                  item
                                  xs={10}
                                  md={6}
                                  style={{ marginLeft: "2.5%" }}
                                >
                                  <FormFrecuenciaPlan formik={formik} />
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Form>
                    </FormikProvider>
                  </>
                )}

                {activeStep === 3 && (
                  <TablaPlanMasivo
                    planes={datosPlanes}
                    setPlanes={setDatosPlanes}
                    datosPlanes={planesAGuardar}
                    setDatosPlanes={setPlanesAGuardar}
                  />
                )}
              </React.Fragment>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Container style={{ margin: 20 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
              <Button
                color="inherit"
                onClick={handleBack}
                sx={{ mr: 1 }}
                disabled={guardando}
              >
                Volver
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <LoadingButton onClick={handleNext} loading={guardando}>
                {activeStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
              </LoadingButton>
              <Button></Button>
            </Box>
          </Container>
        </DialogActions>
      </Dialog>
    </>
  );
}
