import * as Yup from "yup";
import {
  antecedentesSchema,
  clienteSchema,
  empleadosAdicionales,
  observacionesSchema,
} from "./commons";
import {
  formatToDate,
  validarFormatoFecha,
  verificarFechaPosterior,
} from "src/utils/formatTime";
// import { formatToDate, validarFormatoFecha } from "src/utils/formatTime";

const responsableSchema = Yup.object().shape({
  vendidoTaxtic: Yup.bool()
    .required("Vendido por Taxtic es requerido")
    .default(false),
  vendedor: Yup.object().when("vendidoTaxtic", {
    is: false,
    then: Yup.object().required("El vendedor es requerido").nullable(),
    otherwise: Yup.object().notRequired().nullable(),
  }),
  encargado: Yup.object().required("El encargado es requerido").nullable(),
  supervisor: Yup.object().required("El supervisor es requerido").nullable(),
});

export const servicioSchema = Yup.object().shape({
  fechaLimite: Yup.date()
    .required("La fecha del gasto es requerida")
    .test(
      "formatoFecha",
      "Formato de fecha inválido. Use DD/MM/YYYY",
      (value) => validarFormatoFecha(value, "DD/MM/YYYY")
    )
    .transform((originalValue, originalObject) => {
      return validarFormatoFecha(originalValue, "DD/MM/YYYY")
        ? formatToDate(originalValue)
        : originalValue;
    })
    .test(
      "fechaNoPosterior",
      "La fecha no puede ser posterior al día de hoy",
      (value) => {
        if (!validarFormatoFecha(value, "DD/MM/YYYY")) {
          return false; // Si el formato es inválido, ya fallará el primer test
        }

        return verificarFechaPosterior(value);
      }
    )
    .typeError("Formato de fecha inválido. Use DD/MM/YYYY"),
  tareaExistente: Yup.bool()
    .required("Vendido por Taxtic es requerido")
    .default(true),
  tarea: Yup.object().when("tareaExistente", {
    is: true,
    then: Yup.object().required("La tarea es requerida").nullable(),
    otherwise: Yup.object().notRequired().nullable(),
  }),
  nombreTarea: Yup.string().when("tareaExistente", {
    is: false,
    then: Yup.string().required("El nombre de la tarea es requerido"),
    otherwise: Yup.string().notRequired(),
  }),
  tipoMoneda: Yup.string()
    .required("El tipo de moneda es obligatorio")
    .nullable(),
  precioFijo: Yup.number()
    .required("El precio es obligatorio")
    .positive("El precio debe ser un número positivo")
    .min(0, "El precio no puede ser negativo"),
  precioFinal: Yup.number()
    .required("El precio final es obligatorio")
    .positive("El precio final debe ser un número positivo")
    .min(0, "El precio final no puede ser negativo"),
  descuento: Yup.number()
    .required("El descuento es obligatorio")
    .min(0, "El descuento no puede ser negativo")
    .max(100, "El descuento no puede ser mayor al 100%"),
});

export const subtareaSchema = Yup.object().shape({
  subtareas: Yup.array().of(Yup.string()).optional(),
});

export const SCHEMAS_PROPOSAL_TASK = [
  responsableSchema,
  clienteSchema,
  antecedentesSchema,
  servicioSchema,
  subtareaSchema,
  empleadosAdicionales,
  observacionesSchema,
];
