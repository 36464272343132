import {
  formatRut,
  generateRut,
  RutFormat,
  validateRut,
} from "@fdograph/rut-utilities";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { clientesApi } from "src/services/clientesApi";
import { capitalizarPalabras } from "src/utils/sharedFunctions";

const ClienteForm = ({ formik, editar = false, isLoading }) => {
  const {
    values,
    touched,
    errors,
    getFieldProps,
    setFieldValue,
    setFieldError,
    handleSubmit,
  } = formik;

  const { data: clientes, isLoading: cargandoClientes } =
    clientesApi.useGetClientesQuery();

  const handleClienteSelection = useCallback(
    (event, newValue) => {
      const { nombre, direccion, comuna, telefono, correo } = newValue;

      setFieldValue("cliente", newValue);
      formik.setFieldValue("nombreCliente", nombre);
      formik.setFieldValue("correoPersonalCliente", correo);
      formik.setFieldValue("telefonoCliente", telefono);
      formik.setFieldValue("comunaCliente", comuna);
      formik.setFieldValue("direccionCliente", direccion);
    },
    [setFieldValue, formik]
  );

  useEffect(() => {
    if (values.sinRutCliente && !values.rutCliente) {
      const rutGenerado = generateRut();
      setFieldValue("rutCliente", rutGenerado);
    }
  }, [setFieldValue, values.sinRutCliente, values.rutCliente]);

  useEffect(() => {
    if (values.clienteExistente) {
      setFieldValue("rutCliente", "");
    }
  }, [setFieldValue, values.clienteExistente]);

  const getOptionLabelCliente = useCallback((option) => {
    const rutFormateado = formatRut(option.rut, RutFormat.DOTS_DASH);
    return `${capitalizarPalabras(option.nombre)} (${rutFormateado})`;
  }, []);

  const isOptionEqualToValueCliente = useCallback(
    (option, value) => option.rut === value.rut,
    []
  );

  const handleRutBlur = useCallback(() => {
    const rawRut = values.rutCliente;
    // Valida y formatea el RUT solo al perder el foco
    if (validateRut(rawRut)) {
      const formattedRut = formatRut(rawRut, RutFormat.DOTS_DASH);
      setFieldValue("rutCliente", formattedRut);
    } else {
      setFieldError("rutCliente", "El RUT no es válido");
    }
  }, [setFieldError, setFieldValue, values.rutCliente]);

  return (
    <Grid container spacing={2}>
      {!values.clienteExistente ? (
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <TextField
            fullWidth
            required
            id="textField-rutCliente"
            label="RUT cliente"
            value={values.rutCliente}
            variant="outlined"
            helperText={touched.rutCliente && errors.rutCliente}
            error={touched.rutCliente && Boolean(errors.rutCliente)}
            disabled={values.sinRutCliente}
            {...getFieldProps("rutCliente")}
            onBlur={handleRutBlur}
          />
        </Grid>
      ) : (
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Autocomplete
            multiple={false}
            disableClearable={true}
            value={values.cliente}
            onChange={handleClienteSelection}
            id="autocomplete-cliente"
            options={!cargandoClientes && clientes ? clientes : []}
            getOptionLabel={getOptionLabelCliente}
            isOptionEqualToValue={isOptionEqualToValueCliente}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Cliente"
                disabled={cargandoClientes}
                placeholder="Seleccione un ítem"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {cargandoClientes ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                error={touched.cliente && Boolean(errors.cliente)}
                helperText={touched.cliente && errors.cliente}
              />
            )}
            disabled={cargandoClientes}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <TextField
          fullWidth
          required
          id="textField-nombreCliente"
          label="Nombre"
          value={values.nombreCliente}
          variant="outlined"
          helperText={touched.nombreCliente && errors.nombreCliente}
          error={touched.nombreCliente && Boolean(errors.nombreCliente)}
          {...getFieldProps("nombreCliente")}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <TextField
          fullWidth
          required
          id="textField-correoPersonalCliente"
          label="Correo personal"
          value={values.correoPersonalCliente}
          variant="outlined"
          helperText={
            touched.correoPersonalCliente && errors.correoPersonalCliente
          }
          error={
            touched.correoPersonalCliente &&
            Boolean(errors.correoPersonalCliente)
          }
          {...getFieldProps("correoPersonalCliente")}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <TextField
          fullWidth
          required
          id="textField-telefonoCliente"
          label="Celular"
          value={values.telefonoCliente}
          variant="outlined"
          helperText={touched.telefonoCliente && errors.telefonoCliente}
          error={touched.telefonoCliente && Boolean(errors.telefonoCliente)}
          {...getFieldProps("telefonoCliente")}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <TextField
          fullWidth
          required
          id="textField-comunaCliente"
          label="Comuna"
          value={values.comunaCliente}
          variant="outlined"
          helperText={touched.comunaCliente && errors.comunaCliente}
          error={touched.comunaCliente && Boolean(errors.comunaCliente)}
          {...getFieldProps("comunaCliente")}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={values.clienteExistente ? 12 : 6}
        lg={values.clienteExistente ? 12 : 6}
        xl={values.clienteExistente ? 12 : 6}
      >
        <TextField
          fullWidth
          required
          id="textField-direccionCliente"
          label="Dirección"
          value={values.direccionCliente}
          variant="outlined"
          helperText={touched.direccionCliente && errors.direccionCliente}
          error={touched.direccionCliente && Boolean(errors.direccionCliente)}
          {...getFieldProps("direccionCliente")}
        />
      </Grid>
      {/* Nuevo Grid para el botón de guardar */}
      {editar && (
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
            loading={isLoading}
            fullWidth
          >
            Guardar
          </LoadingButton>
        </Grid>
      )}
    </Grid>
  );
};

ClienteForm.propTypes = {
  formik: PropTypes.object.isRequired,
  editar: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default React.memo(ClienteForm);
