import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faList,
  faFileSignature,
  faFileInvoice,
  faUsers,
  faAddressBook,
  faStoreAlt,
  faCog,
  faCalendar,
  faChartBar,
  faThList,
  faIdCard,
  faChartPie,
  faChartLine,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { PATH_DASHBOARD } from "src/routes/paths";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <FontAwesomeIcon icon={name} width={22} height={22} />
);

const sidebarConfig = [
  {
    subheader: "Dashboard",
    items: [
      {
        title: "estadísticas",
        path: "/dashboard/estadisticas",
        icon: getIcon(faChartLine),
      },
      {
        title: "informes",
        path: "/dashboard/informes",
        icon: getIcon(faChartBar),
      },
    ],
  },
  {
    subheader: "General",
    items: [
      {
        title: "inicio",
        path: "/dashboard/inicio",
        icon: getIcon(faHome),
      },
      {
        title: "tareas",
        path: PATH_DASHBOARD.tareas.root,
        icon: getIcon(faList),
        children: [
          {
            title: "Ver",
            path: PATH_DASHBOARD.tareas.listar,
          },
          {
            title: "Nueva tarea",
            path: PATH_DASHBOARD.tareas.agregar,
          },
        ],
      },
      {
        title: "propuestas",
        path: PATH_DASHBOARD.propuestas.root,
        icon: getIcon(faThList),
        children: [
          {
            title: "Ver",
            path: PATH_DASHBOARD.propuestas.listar,
          },
          {
            title: "Nueva propuesta",
            path: PATH_DASHBOARD.propuestas.agregar,
          },
        ],
      },
      {
        title: "planes",
        path: "/dashboard/planes",
        icon: getIcon(faFileSignature),
      },
      {
        title: "facturación",
        path: "/dashboard/facturacion",
        icon: getIcon(faFileInvoice),
      },
      {
        title: "clientes",
        path: PATH_DASHBOARD.clientes.root,
        icon: getIcon(faAddressBook),
        children: [
          {
            title: "Ver",
            path: PATH_DASHBOARD.clientes.listar,
          },
          {
            title: "Planes",
            path: PATH_DASHBOARD.clientes.planes,
          },
          {
            title: "Grupos",
            path: PATH_DASHBOARD.clientes.grupos,
          },
        ],
      },
      {
        title: "grupos clientes",
        path: "/dashboard/grupos-clientes",
        icon: getIcon(faUserFriends),
      },
      {
        title: "área laboral",
        path: "/dashboard/area-laboral",
        icon: getIcon(faIdCard),
      },
      {
        title: "empleados",
        path: "/dashboard/empleados",
        icon: getIcon(faUsers),
      },
      {
        title: "sucursales",
        path: "/dashboard/sucursales",
        icon: getIcon(faStoreAlt),
      },
      {
        title: "liquidación",
        path: "/dashboard/liquidacion",
        icon: getIcon(faChartPie),
      },
      {
        title: "agenda",
        path: "/dashboard/agenda",
        icon: getIcon(faCalendar),
      },
      {
        title: "login",
        path: "/login",
        icon: getIcon(faUsers),
      },
    ],
  },
  {
    subheader: "Configuraciones",
    items: [
      {
        title: "configuraciones",
        path: "/dashboard/configuraciones",
        icon: getIcon(faCog),
      },
    ],
  },
];

export default sidebarConfig;
