import { Box, IconButton, TablePagination, useTheme } from "@material-ui/core";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@material-ui/icons";
import React from "react";
import PropTypes from "prop-types";

const TablePaginationActions = React.memo((props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  // Calcula el número total de páginas
  const totalPages = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, Math.max(0, page - 1));
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, Math.min(page + 1, totalPages));
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, totalPages);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= totalPages}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= totalPages}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
});

const CustomTablePagination = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  colSpan,
  rowsPerPageOptions = [5, 10, 25],
}) => {
  // Calcula el número total de páginas
  const totalPages = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

  // Ajusta la página si está fuera de rango
  const adjustedPage = Math.min(page, totalPages);

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      colSpan={colSpan}
      count={count}
      rowsPerPage={rowsPerPage}
      page={adjustedPage}
      onPageChange={(event, newPage) => {
        // Asegúrate de que el nuevo valor de página no supere el máximo
        const safeNewPage = Math.min(newPage, totalPages);
        onPageChange(event, safeNewPage);
      }}
      onRowsPerPageChange={onRowsPerPageChange}
      ActionsComponent={TablePaginationActions}
    />
  );
};

CustomTablePagination.propTypes = {
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  colSpan: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
};

export default React.memo(CustomTablePagination);
