import React from "react";
import PropTypes from "prop-types";
import { alpha, styled } from "@material-ui/core";

// Mapeo de estados a colores semánticos
const STATUS_COLORS = {
  CREADA: "info", // Azul info - estado inicial
  ENVIADA: "warning", // Naranja - en proceso
  ACEPTADO_CLIENTE: "primary", // Azul primario - paso intermedio positivo
  ACEPTADA: "success", // Verde - finalización positiva
  RECHAZADA: "error", // Rojo - finalización negativa
  CADUCADA: "default", // Plomo
};

const STATUS_ICONS = {
  CREADA: "📝",
  ENVIADA: "📤",
  ACEPTADO_CLIENTE: "👍",
  ACEPTADA: "✅",
  RECHAZADA: "❌",
  CADUCADA: "⌛",
};

const RootStyle = styled("span")(({ theme, styleProps }) => {
  const { color, variant } = styleProps;

  const styleFilled = (color) => ({
    color: theme.palette[color].contrastText,
    backgroundColor: theme.palette[color].main,
  });

  const styleOutlined = (color) => ({
    color: theme.palette[color].main,
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette[color].main}`,
  });

  const styleGhost = (color) => ({
    color: theme.palette[color].dark,
    backgroundColor: alpha(theme.palette[color].main, 0.16),
  });

  return {
    height: 24,
    minWidth: 24,
    lineHeight: 0,
    borderRadius: 12,
    cursor: "default",
    alignItems: "center",
    whiteSpace: "nowrap",
    display: "inline-flex",
    justifyContent: "center",
    padding: theme.spacing(0.5, 1.5),
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(13),
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey[300],
    fontWeight: theme.typography.fontWeightBold,
    transition: theme.transitions.create(
      ["background-color", "color", "box-shadow"],
      {
        duration: theme.transitions.duration.shorter,
      }
    ),

    ...(color !== "default"
      ? {
          ...(variant === "filled" && { ...styleFilled(color) }),
          ...(variant === "outlined" && { ...styleOutlined(color) }),
          ...(variant === "ghost" && { ...styleGhost(color) }),
        }
      : {
          ...(variant === "outlined" && {
            backgroundColor: "transparent",
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[500_32]}`,
          }),
          ...(variant === "ghost" && {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.grey[500_16],
          }),
        }),
  };
});

const ProposalStatusLabel = ({
  estado,
  variant = "ghost",
  showIcon = true,
  ...other
}) => {
  const color = STATUS_COLORS[estado] || "default";
  const icon = showIcon ? STATUS_ICONS[estado] : "";

  const formatStatus = (status) => {
    return status
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <RootStyle styleProps={{ color, variant }} {...other}>
      {icon && <span style={{ marginRight: "4px" }}>{icon}</span>}
      {formatStatus(estado)}
    </RootStyle>
  );
};

ProposalStatusLabel.propTypes = {
  estado: PropTypes.oneOf([
    "CREADA",
    "ENVIADA",
    "ACEPTADO_CLIENTE",
    "ACEPTADA",
    "RECHAZADA",
    "CADUCADA",
  ]).isRequired,
  variant: PropTypes.oneOf(["filled", "outlined", "ghost"]),
  showIcon: PropTypes.bool,
};

export default ProposalStatusLabel;
