import {
  Autocomplete,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Iconify from "src/components/iconify";
import { tiposApi } from "src/services/tiposApi";
import { capitalizarPrimeraLetra } from "src/utils/sharedFunctions";
// Iconos
import money from "@iconify/icons-carbon/money";
import baselineAttachMoney from "@iconify/icons-ic/baseline-attach-money";
import baseDiscount from "@iconify/icons-eva/pricetags-outline";
import { formatearValorPlan } from "../../utils/functions";
import PropTypes from "prop-types";
import { LoadingButton } from "@material-ui/lab";
import CalendarField from "src/components/Modal/modalGav/Fields/CalendarField";
import { fechaActual } from "src/utils/formatTime";

const ServicioForm = ({ formik, editar, isLoading }) => {
  const { data: planes, isLoading: cargandoPlanes } =
    tiposApi.useGetTipoTareasQuery();
  const { data: monedasQuery, isLoading: cargandoMonedas } =
    tiposApi.useGetTipoMonedaQuery();
  const {
    values,
    touched,
    errors,
    setFieldValue,
    getFieldProps,
    handleSubmit,
  } = formik;

  const prevTareaExistente = useRef(values.tareaExistente);
  const monedas = useMemo(() => {
    return monedasQuery ? monedasQuery.map((moneda) => moneda.nombre) : [];
  }, [monedasQuery]);

  const handleChangePlan = useCallback(
    (_, newValue) => {
      setFieldValue("nombreTarea", newValue?.nombre);
      setFieldValue("tipoMoneda", newValue?.tipoMoneda);
      setFieldValue("precioFijo", newValue?.valor);
      setFieldValue("tarea", newValue);
      setFieldValue("subtareas", []);
    },
    [setFieldValue]
  );

  const getOptionLabel = (option) =>
    `${capitalizarPrimeraLetra(option.nombre)} (${formatearValorPlan(
      option.tipoMoneda,
      option.valor
    )})`;

  useEffect(() => {
    const precioFinal =
      formik.values.precioFijo * (1 - formik.values.descuento / 100);
    formik.setFieldValue("precioFinal", Number(precioFinal.toFixed(2)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.precioFijo, formik.values.descuento]);

  useEffect(() => {
    // Solo reiniciamos las subtareas si el valor de tareaExistente cambió de true a false
    if (prevTareaExistente.current !== values.tareaExistente) {
      // Si el valor de tareaExistente cambia a false (es decir, tarea no existente), reiniciamos las subtareas
      if (!values.tareaExistente) {
        setFieldValue("subtareas", []);
      }
      prevTareaExistente.current = values.tareaExistente;
    }
  }, [values.tareaExistente, setFieldValue]);

  const handleChangeFechaLimite = useCallback(
    (newValue) => setFieldValue("fechaLimite", newValue),
    [setFieldValue]
  );

  return (
    <Grid container spacing={2}>
      {values.tareaExistente && (
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Autocomplete
            fullWidth
            value={values.tarea}
            disableClearable
            onChange={handleChangePlan}
            options={!cargandoPlanes && planes ? planes : []}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) =>
              option.nombre === value.nombre
            }
            disabled={cargandoPlanes || !values.tareaExistente}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Seleccione una tarea"
                variant="outlined"
                disabled={cargandoPlanes || !values.tareaExistente}
                placeholder="Buscar tarea..."
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {cargandoPlanes ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                error={touched.tarea && Boolean(errors.tarea)}
                helperText={touched.tarea && errors.tarea}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <TextField
          fullWidth
          required
          id="textField-nombreTarea"
          label="Nombre de la tarea"
          disabled={Boolean(values.tareaExistente)}
          value={values.nombreTarea}
          variant="outlined"
          helperText={touched.nombreTarea && errors.nombreTarea}
          error={touched.nombreTarea && Boolean(errors.nombreTarea)}
          {...getFieldProps("nombreTarea")}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <Autocomplete
          fullWidth
          value={formik.values.tipoMoneda}
          onChange={(_, newValue) =>
            formik.setFieldValue("tipoMoneda", newValue)
          }
          options={monedas}
          getOptionLabel={(option) => option}
          disabled={cargandoMonedas}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Tipo de moneda"
              variant="outlined"
              placeholder="Buscar tipo de moneda..."
              disabled={cargandoMonedas}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon={money} width={18} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    {cargandoMonedas ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              error={
                formik.touched.tipoMoneda && Boolean(formik.errors.tipoMoneda)
              }
              helperText={formik.touched.tipoMoneda && formik.errors.tipoMoneda}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <TextField
          fullWidth
          required
          label={values.tareaExistente ? "Precio fijo" : "Valor"}
          variant="outlined"
          type="number"
          InputProps={{
            startAdornment:
              formik.values.tipoMoneda === "PESOS" ? (
                <InputAdornment position="start">
                  <Iconify icon={baselineAttachMoney} width={18} />
                </InputAdornment>
              ) : formik.values.tipoMoneda === "DOLAR" ? (
                <InputAdornment position="start">USD</InputAdornment>
              ) : null,
            endAdornment:
              formik.values.tipoMoneda === "UF" ||
              formik.values.tipoMoneda === "UTM" ? (
                <InputAdornment position="end">
                  {formik.values.tipoMoneda}
                </InputAdornment>
              ) : null,
          }}
          error={formik.touched.precioFijo && Boolean(formik.errors.precioFijo)}
          helperText={formik.touched.precioFijo && formik.errors.precioFijo}
          {...formik.getFieldProps("precioFijo")}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <TextField
          fullWidth
          required
          label="Descuento (%)"
          variant="outlined"
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={baseDiscount} width={18} />
              </InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          {...formik.getFieldProps("descuento")}
          error={formik.touched.descuento && Boolean(formik.errors.descuento)}
          helperText={formik.touched.descuento && formik.errors.descuento}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <TextField
          fullWidth
          required
          label="Precio final"
          variant="outlined"
          disabled
          InputProps={{
            startAdornment:
              formik.values.tipoMoneda === "PESOS" ? (
                <InputAdornment position="start">
                  <Iconify icon={baselineAttachMoney} width={18} />
                </InputAdornment>
              ) : formik.values.tipoMoneda === "DOLAR" ? (
                <InputAdornment position="start">USD</InputAdornment>
              ) : null,
            endAdornment:
              formik.values.tipoMoneda === "UF" ||
              formik.values.tipoMoneda === "UTM" ? (
                <InputAdornment position="end">
                  {formik.values.tipoMoneda}
                </InputAdornment>
              ) : null,
          }}
          value={formik.values.precioFinal}
        />
      </Grid>
      <CalendarField
        id="fecha-limite"
        label="Fecha límite"
        value={values.fechaLimite}
        onChange={handleChangeFechaLimite}
        inputFormat="dd/MM/yyyy"
        touched={touched.fechaLimite}
        errors={errors.fechaLimite}
        tamanio={12}
        minDate={fechaActual().format("YYYY/MM/DD")}
        required={true}
        md={6}
        lg={6}
        xl={6}
      />
      {editar && (
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
            loading={isLoading}
            fullWidth
          >
            Guardar
          </LoadingButton>
        </Grid>
      )}
    </Grid>
  );
};

ServicioForm.propTypes = {
  formik: PropTypes.object.isRequired,
  editar: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default React.memo(ServicioForm);
