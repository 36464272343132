import { useFormik } from "formik";
import FormPaper from "../Modal/propuestas/components/FormPaper";
import { subtareaSchema } from "../Modal/propuestas/steppers/schemas/proposal-task";
import SubtareasForm from "../Modal/propuestas/steppers/propuestaTareaEspecial/formularios/SubtareasForm";
import { useCallback, useEffect } from "react";
import { propuestasApi } from "src/services/propuestasApi";
import { useSnackbar } from "src/components/snackbar";
import {
  DisabledProvider,
  DisabledWrapper,
  useDisabledFormik,
} from "../disabled-screen/DisabledContext";

export default function VerSubtareasPropuestaTarea({
  propuesta,
  isDisabled = false,
}) {
  const [agregarSubtareaPropuesta, { isLoading: agregandoSubtarea }] =
    propuestasApi.useAgregarSubtareaPropuestaTareaMutation();
  const [eliminarSubtareaPropuesta, { isLoading: eliminandoSubtarea }] =
    propuestasApi.useEliminarSubtareaPropuestaTareaMutation();
  const { enqueueSnackbar } = useSnackbar();

  const baseFormik = useFormik({
    initialValues: {
      subtareas: propuesta.subtareas?.map((subtarea) => subtarea.nombre),
    },
    validationSchema: subtareaSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const formik = useDisabledFormik(baseFormik);

  const { setFieldValue } = formik;

  useEffect(() => {
    setFieldValue(
      "subtareas",
      propuesta.subtareas?.map((subtarea) => subtarea.nombre) || []
    );
  }, [propuesta, setFieldValue]);

  const handleSubmit = () => {};

  const handleDeleteSubtaskDB = useCallback(
    async (index) => {
      if (isDisabled) return;
      try {
        const subtarea = propuesta.subtareas[index];
        await eliminarSubtareaPropuesta({
          idSubtareaPropuesta: subtarea.id,
        }).unwrap();
        enqueueSnackbar("Subtarea eliminada exitosamente", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (error) {
        enqueueSnackbar(
          error?.data?.message ??
            "Ocurrió un error desconocido al eliminar la subtarea",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
        console.error("Error en handleDeleteSubtaskDB: ", error);
      }
    },
    [
      eliminarSubtareaPropuesta,
      enqueueSnackbar,
      isDisabled,
      propuesta.subtareas,
    ]
  );

  const handleUpdateSubtasksDB = useCallback(
    async (nombreSubtarea) => {
      try {
        const body = {
          idPropuesta: propuesta.id,
          nombre: nombreSubtarea.toUpperCase(),
        };
        await agregarSubtareaPropuesta(body).unwrap();
        enqueueSnackbar("Subtarea agregada exitosamente", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (error) {
        enqueueSnackbar(
          error?.data?.message ??
            "Ocurrió un error desconocido al agregar la subtarea",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
        console.error("Error en handleUpdateSubtasksDB: ", error);
      }
    },
    [agregarSubtareaPropuesta, enqueueSnackbar, propuesta.id]
  );

  return (
    <DisabledProvider isDisabled={isDisabled}>
      <DisabledWrapper
        message="Vista de solo lectura"
        overlayColor="rgba(240, 242, 245, 0.45)"
      >
        <FormPaper formik={formik} title="Subtareas">
          <SubtareasForm
            formik={formik}
            editar={true}
            handleDeleteSubtaskDB={handleDeleteSubtaskDB}
            handleUpdateSubtasksDB={handleUpdateSubtasksDB}
            agregandoSubtarea={agregandoSubtarea}
            eliminandoSubtarea={eliminandoSubtarea}
          />
        </FormPaper>
      </DisabledWrapper>
    </DisabledProvider>
  );
}
