import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useContext } from "react";

const HoverPopover = ({ id, classes, title, content, context }) => {
  const { hover, handleHoverOpen, handleHoverClose } = useContext(context);
  const buttonRef = React.useRef(null);

  return (
    <>
      <IconButton
        className={classes.infoButton}
        aria-owns={hover === id ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={() => handleHoverOpen(id)}
        onMouseLeave={handleHoverClose}
        aria-label={title}
        ref={buttonRef}
      >
        <Info />
      </IconButton>
      <Popover
        id="mouse-over-popover"
        open={hover === id}
        anchorEl={hover === id ? buttonRef.current : null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleHoverClose}
        disableRestoreFocus
        sx={{
          pointerEvents: "none",
        }}
      >
        <Box sx={{ p: 2, maxWidth: 280 }}>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {content}
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    margin: theme.spacing(2),
    borderRadius: 16,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.15)",
    transition: "transform 0.2s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  media: {
    height: 140,
    background: "linear-gradient(135deg, #FF8E53 30%, #FE6B8B 90%)", // Degradado
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    fontSize: "3.5rem",
    color: theme.palette.primary.contrastText,
  },
  content: {
    textAlign: "center",
    padding: theme.spacing(3),
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    fontSize: "1.2rem",
  },
  infoButton: {
    position: "absolute",
    top: 12,
    right: 12,
    color: theme.palette.grey[200],
    "&:hover": {
      color: theme.palette.primary.lighter,
    },
  },
}));

export default function Tarjeta({
  id,
  titleCard,
  titleHoverInfo,
  contentHoverInfo,
  handleClickAction,
  context,
  icon,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={handleClickAction}>
        <CardMedia className={classes.media}>
          {icon && React.cloneElement(icon, { className: classes.icon })}
        </CardMedia>
        <CardContent className={classes.content}>
          <Typography variant="h6" className={classes.title}>
            {titleCard}
          </Typography>
        </CardContent>
      </CardActionArea>
      <div>
        <HoverPopover
          id={id}
          classes={classes}
          title={titleHoverInfo}
          content={contentHoverInfo}
          context={context}
        />
      </div>
    </Card>
  );
}
