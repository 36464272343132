import { alpha } from "@material-ui/core/styles";

// ----------------------------------------------------------------------
function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const PRIMARY = {
  lighter: "#ffb685",
  light: "#ffa26b",
  main: "#ff802e",
  dark: "#d26b27",
  darker: "#a65522",
  contrastText: "#FFFFFF",
};

const GREEN = {
  lighter: "#d9f7d3",
  light: "#a3e9a4",
  main: "#00AB55",
  dark: "#008043",
  darker: "#005533",
  contrastText: "#FFFFFF",
};

const SECONDARY = {
  lighter: "#d6e4ff",
  light: "#84a9ff",
  main: "#3366FF",
  dark: "#2b57d9",
  darker: "#234ab8",
  contrastText: "#FFFFFF",
};

const INFO = {
  lighter: "#d0f2ff",
  light: "#74caff",
  main: "#1890FF",
  dark: "#0c53b7",
  darker: "#04297a",
  contrastText: "#FFFFFF",
};

const SUCCESS = {
  lighter: "#e9fcd4",
  light: "#aaf27f",
  main: "#54D62C",
  dark: "#47b825",
  darker: "#3c9a1f",
  contrastText: GREY[800],
};

const WARNING = {
  lighter: "#fff5cc",
  light: "#ffd666",
  main: "#FFAB00",
  dark: "#d99200",
  darker: "#b37a00",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#ffe7d9",
  light: "#ffa48d",
  main: "#FF4842",
  dark: "#d63b37",
  darker: "#af2e2c",
  contrastText: "#FFFFFF",
};

const TEXT = {
  primary: "#333333", // Color oscuro para texto principal, fácil de leer
  secondary: "#555555", // Color un poco más suave para subtítulos o descripciones
  disabled: "rgba(0, 0, 0, 0.38)", // Para textos deshabilitados, un estándar de accesibilidad
  hint: "rgba(0, 0, 0, 0.6)", // Sutilmente más claro que el `secondary`, para sugerencias
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  green: createGradient(GREEN.light, GREEN.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF", "#E8C8FF", "#C8AEFF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF", "#B3E5FF", "#81D1FF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC", "#97E5C0", "#6ED8A5"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6", "#FFEDD5", "#FFE7B8"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4", "#FFD5BB", "#FFB698"],
};

const palette = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY },
  green: { ...GREEN },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  // text: {
  //   primary: GREY[800],
  //   secondary: GREY[600],
  //   disabled: GREY[500],
  // },
  text: { ...TEXT },
  background: { paper: "#fff", default: "#fff", neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
