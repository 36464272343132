import { Grid, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const ObservacionesForm = ({ formik }) => {
  const { values, touched, errors, getFieldProps } = formik;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <TextField
          fullWidth
          multiline
          id="textField-observaciones"
          label="Observaciones"
          variant="outlined"
          rows={5}
          value={values.observaciones}
          helperText={touched.observaciones && errors.observaciones}
          error={touched.observaciones && Boolean(errors.observaciones)}
          {...getFieldProps("observaciones")}
        />
      </Grid>
    </Grid>
  );
};

ObservacionesForm.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default React.memo(ObservacionesForm);
