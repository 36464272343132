import { validateRut } from "@fdograph/rut-utilities";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  Fade,
} from "@material-ui/core";
import {
  Warning as WarningIcon,
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect, useCallback } from "react";
import { propuestasApi } from "src/services/propuestasApi";
import { formatRutManual } from "src/utils/sharedFunctions";
import { useSnackbar } from "src/components/snackbar";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    width: "450px",
    maxWidth: "90%",
    position: "relative",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    "& h2": {
      flexGrow: 1,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  title: {
    fontWeight: 500,
  },
  warningBox: {
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: theme.palette.warning.light,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    marginTop: "2px",
  },
  warningText: {
    color: theme.palette.warning.dark,
    flex: 1,
  },
  input: {
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  buttons: {
    marginTop: theme.spacing(4),
    "& > button": {
      marginLeft: theme.spacing(1),
    },
  },
  validRut: {
    color: theme.palette.success.main,
    marginLeft: theme.spacing(1),
    verticalAlign: "middle",
  },
  confirmBox: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
  },
}));

const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const RutModal = ({
  open,
  setOpen,
  id,
  rutAuxiliar,
  tipoPropuesta,
  dataCommissions,
  faltaCorreoFacturacion,
}) => {
  const classes = useStyles();
  const [rut, setRut] = useState("");
  const [correoFacturacion, setCorreoFacturacion] = useState("");
  const [error, setError] = useState("");
  const [errorCorreoFacturacion, setErrorCorreoFacturacion] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [aceptarPropuesta, { isLoading: aceptandoPropuesta }] =
    propuestasApi.useUpdateEstadoPropuestaMutation();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!open) {
      setRut("");
      setCorreoFacturacion("");
      setError("");
      setErrorCorreoFacturacion("");
      setIsValid(false);
      setIsValidEmail(false);
    }
  }, [open]);

  const handleClose = useCallback(
    (event, reason) => {
      if (reason && reason === "backdropClick") return;
      setOpen(false);
    },
    [setOpen]
  );

  const handleChangeCorreoFacturacion = useCallback(
    (e) => {
      const newValue = e.target.value;
      setCorreoFacturacion(newValue);
      if (!newValue && faltaCorreoFacturacion) {
        setErrorCorreoFacturacion("El correo de facturación es requerido");
        setIsValidEmail(false);
      } else if (newValue && !validateEmail(newValue)) {
        setErrorCorreoFacturacion("Ingrese un correo válido");
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
        setErrorCorreoFacturacion("");
      }
    },
    [faltaCorreoFacturacion]
  );

  const handleChange = useCallback((e) => {
    const newValue = e.target.value;
    const sanitizedValue = newValue.replace(/[^0-9k.-]/gi, "");
    const formattedRut = formatRutManual(sanitizedValue);
    setRut(formattedRut);
    const isValidRut = validateRut(formattedRut);
    setIsValid(isValidRut);
    setError(isValidRut ? "" : "RUT inválido. Por favor, verifica el formato.");
  }, []);

  const handleAcceptProposal = useCallback(
    async (newRut = null) => {
      try {
        const body = {
          estado: "ACEPTADA",
          rutNuevo: newRut,
          comisiones: dataCommissions, // Solo si es de tipo "TAREA"
          correoFacturacion,
        };
        await aceptarPropuesta({ id, tipo: tipoPropuesta, body }).unwrap();
        enqueueSnackbar("Propuesta aceptada correctamente");
      } catch (error) {
        console.error("handleAcceptProposal", error);
        enqueueSnackbar(error?.data?.message || "Ocurrió aceptar", {
          variant: "error",
        });
      }
      handleClose();
    },
    [
      id,
      handleClose,
      dataCommissions,
      correoFacturacion,
      aceptarPropuesta,
      tipoPropuesta,
      enqueueSnackbar,
    ]
  );

  const validateForm = useCallback(() => {
    if (rutAuxiliar && !rut) {
      setError("El RUT es requerido.");
      return false;
    }
    if (rutAuxiliar && !validateRut(rut)) {
      setError("RUT inválido. Por favor, verifica el formato.");
      return false;
    }

    if (faltaCorreoFacturacion) {
      if (!correoFacturacion) {
        setErrorCorreoFacturacion("El correo de facturación es requerido.");
        return false;
      }
      if (!validateEmail(correoFacturacion)) {
        setErrorCorreoFacturacion("Ingrese un correo válido.");
        return false;
      }
    }

    return true;
  }, [correoFacturacion, faltaCorreoFacturacion, rut, rutAuxiliar]);

  const handleSubmit = useCallback(() => {
    if (!validateForm()) return;

    handleAcceptProposal(rutAuxiliar ? rut : null);
  }, [validateForm, handleAcceptProposal, rut, rutAuxiliar]);

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Fade in={open}>
        <Box className={classes.paper}>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            aria-label="cerrar"
          >
            <CloseIcon />
          </IconButton>

          <div className={classes.header}>
            <Typography variant="h5" component="h2" className={classes.title}>
              Aceptar Propuesta
            </Typography>
          </div>

          {rutAuxiliar || faltaCorreoFacturacion ? (
            <>
              {rutAuxiliar && (
                <>
                  <div className={classes.warningBox}>
                    <WarningIcon className={classes.warningIcon} />
                    <Typography variant="body2" className={classes.warningText}>
                      Esta propuesta fue creada con un RUT temporal. Para
                      continuar, es necesario ingresar el RUT real de la
                      empresa.
                    </Typography>
                  </div>
                  <TextField
                    required
                    label="RUT Empresa"
                    fullWidth
                    value={rut}
                    onChange={handleChange}
                    error={!!error}
                    helperText={error}
                    variant="outlined"
                    className={classes.input}
                    placeholder="Ingrese el RUT de la empresa"
                    InputProps={{
                      endAdornment: isValid && (
                        <Tooltip title="RUT válido">
                          <CheckCircleIcon className={classes.validRut} />
                        </Tooltip>
                      ),
                    }}
                  />
                </>
              )}

              {faltaCorreoFacturacion && (
                <>
                  {!rutAuxiliar && (
                    <div className={classes.warningBox}>
                      <WarningIcon className={classes.warningIcon} />
                      <Typography
                        variant="body2"
                        className={classes.warningText}
                      >
                        Para continuar, es necesario ingresar un correo de
                        facturación.
                      </Typography>
                    </div>
                  )}
                  <TextField
                    required
                    label="Correo de facturación"
                    fullWidth
                    value={correoFacturacion}
                    onChange={handleChangeCorreoFacturacion}
                    error={!!errorCorreoFacturacion}
                    helperText={errorCorreoFacturacion}
                    variant="outlined"
                    className={classes.input}
                    placeholder="ejemplo@empresa.com"
                    type="email"
                    InputProps={{
                      endAdornment: isValidEmail && (
                        <Tooltip title="Email válido">
                          <CheckCircleIcon className={classes.validRut} />
                        </Tooltip>
                      ),
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <div className={classes.confirmBox}>
              <Typography variant="body1" gutterBottom>
                ¿Está seguro que desea aceptar esta propuesta?
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Esta acción no se puede deshacer.
              </Typography>
            </div>
          )}

          <Grid container justifyContent="flex-end" className={classes.buttons}>
            <Button onClick={handleClose} color="inherit" size="large">
              Cancelar
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              // disabled={
              //   (rutAuxiliar && !isValid) ||
              //   (faltaCorreoFacturacion && !isValidEmail)
              // }
              disabled={aceptandoPropuesta}
              size="large"
              loading={aceptandoPropuesta}
            >
              Aceptar
            </LoadingButton>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

RutModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  id: PropTypes.number,
  rutAuxiliar: PropTypes.bool,
  faltaCorreoFacturacion: PropTypes.bool,
  tipoPropuesta: PropTypes.string,
  dataCommissions: PropTypes.object,
};

export default React.memo(RutModal);
