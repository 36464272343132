import { Grid, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const AntecedentesForm = ({ formik }) => {
  const { values, touched, errors, getFieldProps } = formik;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <TextField
          required
          fullWidth
          multiline
          id="textField-antecedentes"
          label="Antecedentes"
          variant="outlined"
          rows={5}
          value={values.antecedentes}
          helperText={touched.antecedentes && errors.antecedentes}
          error={touched.antecedentes && Boolean(errors.antecedentes)}
          {...getFieldProps("antecedentes")}
        />
      </Grid>
    </Grid>
  );
};

AntecedentesForm.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default React.memo(AntecedentesForm);
