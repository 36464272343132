import {
  Card,
  createTheme,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  ThemeProvider,
  Tooltip,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as locales from "@material-ui/core/locale";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faHandshake,
  faTrash,
  faCheck,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import SearchNotFound from "../SearchNotFound";
import { formatearFecha } from "src/utils/formatTime";
import CustomTablePagination from "../CustomTablePagination";
import { capitalizarPalabras } from "src/utils/sharedFunctions";
import { TablaHead } from "../_dashboard/tablas";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import Scrollbar from "../Scrollbar";
import { AlertaModal } from "../AlertaSwall";
import { propuestasApi } from "src/services/propuestasApi";
import { useSnackbar } from "src/components/snackbar";
import usePropuestas from "./usePropuestas";
import EncabezadoTablaPropuestas from "./EncabezadoTablaPropuestas";
import PropTypes from "prop-types";
import ProposalStatusLabel from "../material/ProposalStatusLabel";
import RutModal from "../Modal/propuestas/Rut-modal";
import ModalPropuestaTracking from "../Modal/propuestas/Modal-propuesta-tracking";

const TABLE_HEAD = [
  { id: "position", label: "N°", alignRight: false },
  { id: "nombreEmpleado", label: "Vendedor", alignRight: false },
  { id: "nombreSolicitante", label: "Solicitante", alignRight: false },
  { id: "correoSolicitante", label: "Correo", alignRight: false },
  { id: "telefonoSolicitante", label: "Teléfono", alignRight: false },
  { id: "fechaCreacion", label: "Fecha creación", alignRight: false },
  { id: "estado", label: "Estado", alignRight: false },
  { id: "accion", label: "Acciones", alignRight: false },
];

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPage: 5,
  order: "desc",
  orderBy: "fechaCreacion",
  locale: "esES",
  filterName: "",
};

const TablaPropuestas = ({
  onUploadFile,
  onSendMailClient,
  title,
  subheader,
  tabIndex,
}) => {
  const navigate = useNavigate();
  const [openModalTracking, setOpenModalTracking] = useState(false);

  const {
    propuestas,
    isLoading: loadingTable,
    refetch,
  } = usePropuestas("PLAN");
  const [
    obtenerTracking,
    {
      data: datosTracking,
      isError: errorObteniendoDatosTracking,
      isLoading: cargandoDatosTacking,
    },
  ] = propuestasApi.useLazyTrackingPropuestaQuery();
  const [statesTable, setStateTable] = useState(INITIAL_STATE_TABLE);
  const [openModalAcceptation, setOpenModalAcceptation] = useState(false);
  const [eliminarPropuesta] = propuestasApi.useDeletePropuestaMutation();
  const [selectedProposal, setSelectedProposal] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const obtenerDatosTracking = useCallback(
    async (idPropuesta) => {
      obtenerTracking({ id: idPropuesta });
      setOpenModalTracking(true);
    },
    [obtenerTracking]
  );

  const propuestasFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = [
      "nombreEmpleado",
      "nombreSolicitante",
      "correoSolicitante",
      "telefonoSolicitante",
      "fechaCreacion",
      "estado",
    ];

    return applySortFilter(
      propuestas,
      getComparator(statesTable.order, statesTable.orderBy),
      statesTable.filterName,
      propiedadesParaFiltrar
    );
  }, [
    propuestas,
    statesTable.filterName,
    statesTable.order,
    statesTable.orderBy,
  ]);

  // Efecto para ajustar la página cuando los resultados filtrados cambian
  useEffect(() => {
    // Calcula el número máximo de páginas posibles
    const maxPage = Math.max(
      0,
      Math.ceil(propuestasFiltradas.length / statesTable.rowsPerPage) - 1
    );

    // Si la página actual es mayor que el máximo de páginas, resetea a la última página válida
    if (statesTable.currentPage > maxPage) {
      setStateTable((prev) => ({
        ...prev,
        currentPage: maxPage,
      }));
    }
  }, [
    propuestasFiltradas,
    setStateTable,
    statesTable.currentPage,
    statesTable.rowsPerPage,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc =
      statesTable.orderBy === property && statesTable.order === "asc";
    setStateTable({
      ...statesTable,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  const handlePageChange = (event, newPage) => {
    const maxPage = Math.max(
      0,
      Math.ceil(propuestasFiltradas.length / statesTable.rowsPerPage) - 1
    );

    setStateTable({
      ...statesTable,
      currentPage: Math.min(newPage, maxPage),
    });
  };

  const handleFilterByName = (event) => {
    setStateTable({ ...statesTable, filterName: event.target.value });
  };

  const handleEliminar = useCallback(
    (id) => {
      AlertaModal({
        title: "eliminar la propuesta",
        confirmButtonText: "Aceptar",
      })
        .fire()
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await eliminarPropuesta(id).unwrap();
              enqueueSnackbar("Propuesta eliminada correctamente", {});
            } catch (error) {
              enqueueSnackbar("Ocurrió un error a eliminar la propuesta", {
                variant: "error",
              });
            }
          }
        })
        .finally(() => {
          refetch();
        });
    },
    [eliminarPropuesta, enqueueSnackbar, refetch]
  );

  const handleOpenModalSendProposalClient = useCallback(
    (propuesta) => {
      if (!propuesta.urlPropuesta) {
        onUploadFile(propuesta);
        return;
      }

      onSendMailClient(propuesta);
    },
    [onSendMailClient, onUploadFile]
  );

  const handleProposalAceptation = useCallback((propuesta) => {
    setSelectedProposal(propuesta);
    setOpenModalAcceptation(true);
  }, []);

  const renderFilas = useCallback(
    () =>
      propuestasFiltradas
        .slice(
          statesTable.currentPage * statesTable.rowsPerPage,
          statesTable.currentPage * statesTable.rowsPerPage +
            statesTable.rowsPerPage
        )
        .map((propuesta, index) => (
          <TableRow hover key={propuesta.id}>
            <TableCell>
              {statesTable.currentPage * statesTable.rowsPerPage + index + 1}
            </TableCell>
            {[
              "nombreEmpleado",
              "nombreSolicitante",
              "correoSolicitante",
              "telefonoSolicitante",
              "fechaCreacion",
            ].map((columna) => (
              <TableCell
                key={columna}
                onClick={() =>
                  navigate(
                    `/dashboard/propuestas/${propuesta.tipo}/${propuesta.id}`
                  )
                }
                style={{ cursor: "pointer" }}
                align="left"
              >
                {columna === "fechaCreacion" || columna === "fechaEnvioCorreo"
                  ? formatearFecha(propuesta[columna], "DD/MM/YYYY")
                  : capitalizarPalabras(propuesta[columna])}
              </TableCell>
            ))}
            <TableCell align="left">
              <ProposalStatusLabel estado={propuesta.estado} />
              {/* {propuesta.estado !== "ACEPTADA" &&
              propuesta.estado !== "RECHAZADA" ? (
                <div>
                  <Label
                    onClick={() => onEstadoChange(propuesta)}
                    style={{ cursor: "pointer" }}
                    variant="ghost"
                    color={
                      (propuesta.estado === "CREADA" && "warning") || "info"
                    }
                  >
                    {capitalizarPalabras(propuesta.estado)}
                  </Label>
                   {propuesta.estado !== "ENVIADA" && (
                    <Tooltip title="Adjuntar">
                      <IconButton
                        onClick={() =>
                          handleOpenModalSendProposalClient(propuesta)
                        }
                      >
                        <Attachment />
                      </IconButton>
                    </Tooltip>
                  )} 
                </div>
              ) : (
                <Label
                  variant="ghost"
                  color={
                    (propuesta.estado === "RECHAZADA" && "error") ||
                    (propuesta.estado === "CREADA" && "warning") ||
                    (propuesta.estado === "ACEPTADA" && "success") ||
                    "info"
                  }
                >
                  {capitalizarPalabras(propuesta.estado)}
                </Label>
              )} */}
            </TableCell>
            <TableCell align="left">
              {propuesta.estado === "CREADA" && (
                <>
                  <Tooltip
                    title={`Enviar propuesta ${capitalizarPalabras(
                      propuesta.nombreSolicitante
                    )}`}
                  >
                    <IconButton
                      onClick={() =>
                        handleOpenModalSendProposalClient(propuesta)
                      }
                      color="secondary"
                    >
                      <FontAwesomeIcon icon={faPaperPlane} size="xs" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title={`Eliminar propuesta ${capitalizarPalabras(
                      propuesta.nombreSolicitante
                    )}`}
                  >
                    <IconButton
                      onClick={() => handleEliminar(propuesta.id)}
                      color="error"
                    >
                      <FontAwesomeIcon icon={faTrash} size="xs" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {propuesta.estado === "ENVIADA" && !propuesta.token && (
                <Tooltip
                  title={`Aceptar propuesta ${capitalizarPalabras(
                    propuesta.nombreSolicitante
                  )}`}
                >
                  <IconButton
                    onClick={() => handleProposalAceptation(propuesta)}
                    color="success"
                  >
                    <FontAwesomeIcon icon={faCheck} size="xs" />
                  </IconButton>
                </Tooltip>
              )}
              {(propuesta.estado === "ENVIADA" ||
                propuesta.estado === "ACEPTADA" ||
                propuesta.estado === "ACEPTADO_CLIENTE") &&
                propuesta.token && (
                  <Tooltip
                    title={`Estadísticas propuesta ${capitalizarPalabras(
                      propuesta.nombreSolicitante
                    )}`}
                  >
                    <IconButton
                      onClick={() => obtenerDatosTracking(propuesta.id)}
                      color="info"
                    >
                      <FontAwesomeIcon icon={faChartBar} size="xs" />
                    </IconButton>
                  </Tooltip>
                )}
              {propuesta.estado === "ACEPTADO_CLIENTE" && (
                <Tooltip
                  title={`Aceptación final propuesta ${capitalizarPalabras(
                    propuesta.nombreSolicitante
                  )}`}
                >
                  <IconButton
                    onClick={() => handleProposalAceptation(propuesta)}
                    color="success"
                  >
                    <FontAwesomeIcon icon={faHandshake} size="xs" />
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
        )),
    [
      propuestasFiltradas,
      statesTable.currentPage,
      statesTable.rowsPerPage,
      navigate,
      handleOpenModalSendProposalClient,
      handleEliminar,
      handleProposalAceptation,
      obtenerDatosTracking,
    ]
  );

  if (tabIndex !== 0) {
    return null;
  }

  return (
    <>
      <Card>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <EncabezadoTablaPropuestas
            handleFilterByName={handleFilterByName}
            propuestas={propuestas}
            statesTable={statesTable}
            title={title}
            subheader={subheader}
            tipo="PLAN"
          />
          <Grid item>
            <Scrollbar>
              <TableContainer component={Paper}>
                {!loadingTable ? (
                  <Table>
                    <TablaHead
                      order={statesTable.order}
                      orderBy={statesTable.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={propuestas.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {renderFilas()}
                      {propuestasFiltradas.length === 0 && (
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            {statesTable.filterName ? (
                              <SearchNotFound
                                searchQuery={statesTable.filterName}
                              />
                            ) : (
                              "No hay propuestas que mostrar."
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <ThemeProvider
                          theme={(outerTheme) =>
                            createTheme(outerTheme, locales[statesTable.locale])
                          }
                        >
                          <CustomTablePagination
                            count={propuestasFiltradas.length}
                            rowsPerPage={statesTable.rowsPerPage}
                            page={statesTable.currentPage}
                            colSpan={8}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={(event) => {
                              setStateTable((prevState) => ({
                                ...prevState,
                                rowsPerPage: Number(event.target.value),
                                currentPage: 0,
                              }));
                            }}
                          />
                        </ThemeProvider>
                      </TableRow>
                    </TableFooter>
                  </Table>
                ) : (
                  <Skeleton variant="rectangular" width="100%" height={500} />
                )}
              </TableContainer>
            </Scrollbar>
          </Grid>
        </Grid>
      </Card>
      <RutModal
        open={openModalAcceptation}
        setOpen={setOpenModalAcceptation}
        id={selectedProposal?.id}
        rutAuxiliar={selectedProposal?.rutAuxiliar === "1"}
        tipoPropuesta={selectedProposal?.tipo}
        faltaCorreoFacturacion={!selectedProposal?.correoFacturacionCliente}
      />
      {openModalTracking && (
        <ModalPropuestaTracking
          data={datosTracking}
          error={errorObteniendoDatosTracking}
          isLoading={cargandoDatosTacking}
          setOpen={setOpenModalTracking}
          open={openModalTracking}
        />
      )}
    </>
  );
};

TablaPropuestas.propTypes = {
  onUploadFile: PropTypes.func.isRequired,
  onSendMailClient: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default React.memo(TablaPropuestas);
