import { format, formatDistanceToNow, parse } from "date-fns";
import { es } from "date-fns/locale";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import esDayjs from "dayjs/locale/es.js";
import handleError from "./manejo-errores";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(esDayjs);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.tz.setDefault("America/Santiago");

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy", {
    locale: es,
  });
}

export function fechaActual() {
  return dayjs();
}

/**
 * Convierte un número de mes con dos dígitos (01-12) a su nombre correspondiente en mayúsculas.
 * Ejemplo: 01 -> ENERO, 02 -> FEBRERO
 *
 * @param {string} mes - El número del mes con dos dígitos (como "01", "02", etc.).
 * @returns {string} El nombre del mes en mayúsculas.
 */
export function obtenerNombreMes(mes) {
  if (!mes) return "";

  const meses = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];

  // Convertir el mes (que viene como "01", "02", ...) a un índice numérico
  const mesIndex = parseInt(mes, 10) - 1;

  if (mesIndex >= 0 && mesIndex <= 11) {
    return meses[mesIndex];
  } else {
    throw new Error("Mes no válido. Debe ser un número entre 01 y 12.");
  }
}

/**
 * Genera un array con los nombres de los meses en mayúsculas.
 * Ejemplo: ["ENERO", "FEBRERO", ... "DICIEMBRE"]
 */
export function obtenerMeses() {
  try {
    return Array.from(
      { length: 12 },
      (_, i) =>
        dayjs()
          .month(i) // Configurar el mes (0 = Enero, 11 = Diciembre)
          .format("MMMM") // Formatear en formato largo
          .toUpperCase() // Convertir a mayúsculas
    );
  } catch (error) {
    handleError("obtenerMeses", error);
    return [];
  }
}

/**
 * Calcula cuántos días han pasado desde una fecha dada hasta la fecha actual.
 * @param {string} fechaInicio - La fecha de inicio en formato ISO.
 * @returns {number} - Número de días transcurridos. Si la fecha es futura, devuelve un número negativo.
 */
export function calcularDiasActiva(fechaInicio) {
  try {
    // Parsear la fecha de inicio en UTC
    const fechaInicioDayjs = dayjs.utc(fechaInicio);

    // Validar si la fecha es válida
    if (!fechaInicioDayjs.isValid()) {
      throw new Error("Fecha de inicio no válida");
    }

    // Obtener la fecha actual en UTC
    const fechaActualDayjs = dayjs.utc();

    // Calcular la diferencia en días
    const diasTranscurridos = fechaActualDayjs.diff(fechaInicioDayjs, "day");

    return diasTranscurridos;
  } catch (error) {
    handleError("calcularDiasActiva", error);
    return null;
  }
}

export function convertirFechaAFormatoNumerico(fechaString) {
  try {
    // Parsear la fecha
    const fecha = parse(fechaString, "yyyy/MMMM", new Date(), { locale: es });

    // Formatear la fecha al formato deseado
    return format(fecha, "yyyy/MM");
  } catch (error) {
    handleError("convertirFechaAFormatoNumerico", error);
  }
}

export function fDateLong(date) {
  return format(new Date(date), "dd 'de' MMMM 'del' yyyy", {
    locale: es,
  });
}

export function fDateNumber(date) {
  return format(new Date(date), "dd/MM/yyyy", {
    locale: es,
  });
}

export function formatearFecha(fecha, formato) {
  try {
    let fechaDayjs;

    // Si es un objeto Date, convertir a Dayjs
    if (fecha instanceof Date) {
      fechaDayjs = dayjs(fecha);
    } else {
      // Primero intentar parsear como ISO
      fechaDayjs = dayjs(fecha);

      // Si no es válida, intentar con los otros formatos
      if (!fechaDayjs.isValid()) {
        const formatosPosibles = ["YYYY/MM/DD", "YYYY/MM", "DD/MM/YYYY"];

        for (const formatoPosible of formatosPosibles) {
          fechaDayjs = dayjs(fecha, formatoPosible, true);
          if (fechaDayjs.isValid()) break;
        }
      }
    }

    // Si no es válida, lanzar un error
    if (!fechaDayjs.isValid()) {
      throw new Error("Fecha no válida");
    }

    // Formatear la fecha al formato deseado
    return fechaDayjs.format(formato);
  } catch (error) {
    handleError("formatearFecha", error);
  }
}

// Función para calcular los días de retraso de una factura
export function calcularDiasRetraso(
  fechaEmisionStr,
  formatoEntrada = "YYYY/MM/DD",
  sumarDias = 30
) {
  // Parsear la fecha de emisión de la factura
  const fechaEmision = dayjs(fechaEmisionStr, formatoEntrada);

  // Sumar 30 días a la fecha de emisión
  const fechaLimite = fechaEmision.add(sumarDias, "day");

  // Obtener la fecha actual
  const fechaActual = dayjs();

  // Calcular los días de retraso
  let diasRetraso = 0;
  if (fechaActual.isAfter(fechaLimite)) {
    diasRetraso = fechaActual.diff(fechaLimite, "day");
  }

  return diasRetraso;
}

export function ajustarFecha(
  fecha,
  cantidad,
  unidad,
  formatoEntrada = "YYYY/MM",
  formatoSalida = "YYYY/MM"
) {
  try {
    const fechaDayjs = dayjs(fecha, formatoEntrada);
    if (!fechaDayjs.isValid()) {
      throw new Error("Fecha no válida");
    }

    let fechaAjustada;
    if (cantidad >= 0) {
      fechaAjustada = fechaDayjs.add(cantidad, unidad);
    } else {
      fechaAjustada = fechaDayjs.subtract(Math.abs(cantidad), unidad);
    }

    return fechaAjustada.format(formatoSalida);
  } catch (error) {
    handleError("ajustarFecha", error);
  }
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy HH:mm", {
    locale: es,
  });
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  try {
    return formatDistanceToNow(new Date(date), {
      addSuffix: true,
      locale: es,
    });
  } catch (error) {
    console.error("Error in fToNow", error.message);
  }
}

// Función para calcular la distancia en días entre dos fechas
export const calcularDistanciaDias = (fechaInicioStr, fechaFinStr) => {
  // Parsear las fechas
  const fechaInicio = dayjs(fechaInicioStr, "YYYY/MM/DD");
  const fechaFin = dayjs(fechaFinStr, "YYYY/MM/DD");

  // Calcular la diferencia en días
  const distanciaDias = fechaFin.diff(fechaInicio, "day");

  return distanciaDias;
};

export const validarFormatoFecha = (value, formato) => {
  let formattedDate;
  let regex;

  if (formato === "DD/MM/YYYY") {
    formattedDate = dayjs(value).format("DD/MM/YYYY");
    regex = /^\d{2}\/\d{2}\/\d{4}$/;
  }
  if (formato === "MM/YYYY") {
    formattedDate = dayjs(value).format("MM/YYYY");
    regex = /^\d{2}\/\d{4}$/;
  }
  return regex.test(formattedDate);
};

export const verificarFechaPosterior = (value) => {
  // Convierte el valor a dayjs si es un objeto Date
  const fechaValue = dayjs(value);

  // Comparar las fechas usando isSameOrAfter, asegurando que ambas sean instancias de dayjs
  return fechaValue.isSameOrAfter(dayjs(), "day");
};

/**
 * Verifica si una fecha es anterior a hoy.
 * @param {string|Date} fecha - La fecha a verificar.
 * @param {string} formatoEntrada - El formato de la fecha ingresada (opcional).
 * @returns {boolean} - True si la fecha es anterior a hoy, false si la fecha es inválida o posterior/igual a hoy.
 */
export function verificarExpiracion(fecha, formatoEntrada) {
  try {
    let fechaDayjs;

    // Si es un objeto Date
    if (fecha instanceof Date) {
      fechaDayjs = dayjs(fecha);
    } else {
      // Primero intentar parsear como ISO
      fechaDayjs = dayjs(fecha, formatoEntrada);

      // Si no es válida y se proporcionó un formato, intentar con ese formato
      if (!fechaDayjs.isValid() && formatoEntrada) {
        fechaDayjs = dayjs(fecha, formatoEntrada, true);
      }
    }

    // Si la fecha no es válida después de todos los intentos
    if (!fechaDayjs.isValid()) {
      console.error("Fecha no válida:", fecha);
      return false; // o podrías lanzar un error dependiendo de tus necesidades
    }

    return fechaDayjs.isBefore(dayjs(), "day");
  } catch (error) {
    console.error("Error al verificar expiración:", error);
    return false;
  }
}

/**
 * Calcula la cantidad de días restantes hasta una fecha.
 * @param {string|Date} fecha - La fecha de destino.
 * @param {string} formatoEntrada - El formato de la fecha ingresada.
 * @returns {number} - Días restantes. Devuelve un número negativo si la fecha ya pasó.
 */
export function calcularDiasRestantes(fecha, formatoEntrada) {
  // Parsear la fecha con el formato especificado
  const fechaDayjs = dayjs(fecha, formatoEntrada).startOf("day");

  // Validar si la fecha es válida antes de proceder
  if (!fechaDayjs.isValid()) {
    console.error("Fecha no válida:", fecha);
    return null; // O lanza un error según tu lógica
  }

  // Calcular los días restantes, normalizando el día actual
  const diferenciaDias = fechaDayjs.diff(dayjs().startOf("day"), "day");

  return diferenciaDias;
}

export const formatToDate = (fecha) => {
  try {
    return dayjs(fecha).toDate();
  } catch (error) {
    handleError("formatToDate, error");
  }
};

/**
 * Suma un número específico de días a una fecha.
 * @param {string|Date} fecha - La fecha base.
 * @param {number} dias - Número de días a sumar (puede ser negativo para restar).
 * @param {string} formatoEntrada - El formato de la fecha de entrada (ej: "DD/MM/YYYY").
 * @param {string} formatoSalida - El formato deseado para la fecha resultante (ej: "DD/MM/YYYY").
 * @returns {string} - La nueva fecha en el formato especificado.
 *
 * @example
 * // Sumar 5 días a una fecha
 * sumarDiasAFecha("01/01/2024", 5, "DD/MM/YYYY", "DD/MM/YYYY")
 * // Retorna: "06/01/2024"
 *
 * @example
 * // Restar 3 días a una fecha
 * sumarDiasAFecha("15/01/2024", -3, "DD/MM/YYYY", "DD/MM/YYYY")
 * // Retorna: "12/01/2024"
 */
export function sumarDiasAFecha(fecha, dias, formatoEntrada, formatoSalida) {
  try {
    // Parsear la fecha con el formato especificado
    const fechaDayjs = dayjs(fecha, formatoEntrada);

    // Validar si la fecha es válida
    if (!fechaDayjs.isValid()) {
      throw new Error(`Fecha no válida: ${fecha}`);
    }

    // Sumar los días (si es negativo, restará)
    const nuevaFecha = fechaDayjs.add(dias, "day");

    // Retornar la fecha en el formato solicitado
    return nuevaFecha.format(formatoSalida);
  } catch (error) {
    handleError("sumarDiasAFecha", error);
    return null;
  }
}
