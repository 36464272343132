import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  infoCard: {
    height: "100%",
    backgroundColor: theme.palette.grey[50],
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[4],
    },
  },
}));

const InfoCards = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Card className={classes.infoCard} elevation={0}>
      <CardContent>
        <Typography variant="h6" gutterBottom color="primary">
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

InfoCards.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object,
};

export default React.memo(InfoCards);
