import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  IconButton,
  Container,
  Button,
} from "@material-ui/core";
import {
  CalendarToday,
  CheckCircle,
  Cancel,
  Instagram,
  Description,
  Comment,
  Phone,
  Email,
  Badge,
  Person,
} from "@material-ui/icons";
import { Facebook, LinkedIn } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { formatearFecha, verificarExpiracion } from "src/utils/formatTime";
import ExpirationBanner from "./ExpirationBanner";
import TimeRemainingChip from "./TimeRemainingChip";
import ResponseDialog from "./ResponseDialog";
import ActionButtons from "./ActionButtons";
import ProposalStatusChip from "./ProposalStatusChip";
import InfoCards from "./InfoCards";
import { useSearchParams } from "react-router-dom";
import { propuestasApi } from "src/services/propuestasApi";
import ProposalLoading from "./ProposalLoading";
import dayjs from "dayjs";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import {
  capitalizarPalabras,
  capitalizarPrimeraLetra,
  formatToSentenceCase,
} from "src/utils/sharedFunctions";
import { formatDecimal } from "src/utils/formatNumber";
import ErrorView from "./ErrorView";
import ServicesTable from "./ServicesTable";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundImage: `url('/static/illustrations/background-proposal.jpg')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
    width: "100%",
    margin: 0,
    padding: 0,
  },
  root: {
    minHeight: "100vh",
    padding: theme.spacing(4),
    width: "100%",
    boxSizing: "border-box",
  },
  paper: {
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  logo: {
    height: 30,
    objectFit: "contain",
  },
  totalsCard: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius * 2,
  },
  footer: {
    marginTop: theme.spacing(6),
    textAlign: "center",
  },
  socialIcons: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& .MuiIconButton-root": {
      transition: "transform 0.2s ease-in-out",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  },
  chipTax: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontWeight: 600,
  },
  proposalActions: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
  },
  acceptButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  rejectButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const tiposMoneda = {
  PESOS: "CLP",
  UF: "UF",
  DOLAR: "USD",
  UTM: "UTM",
};
const formatearTipoMoneda = (tipoMoneda) => {
  return tiposMoneda[tipoMoneda] || "Falta definir";
};

const formatearDatosPropuesta = (informacion) => {
  return {
    proposalNumber: informacion.id.toString(),
    proposalType: informacion.tipo,
    proposalUrl: informacion.urlPropuesta,
    date: formatearFecha(informacion.fechaCreacion, "DD/MM/YYYY"),
    expiryDate: formatearFecha(dayjs.unix(informacion.exp), "DD/MM/YYYY"),
    status: informacion.estado,
    comments: informacion.comentarios,
    background: informacion.antecedentes,
    client: {
      name: capitalizarPalabras(
        informacion.solicitante?.nombre ||
          informacion.tareaPropuesta.cliente.nombre ||
          "No definido"
      ),
      rut:
        formatRut(
          informacion.solicitante?.rut ||
            informacion.tareaPropuesta.cliente.rut,
          RutFormat.DOTS_DASH
        ) || "No definido",
      contact:
        informacion.solicitante?.correo.toLowerCase() ||
        informacion.tareaPropuesta.cliente.correo.toLowerCase() ||
        "No definido",
      phone:
        informacion.solicitante?.telefono ||
        informacion.tareaPropuesta.cliente.telefono ||
        "No definido",
    },
    seller: {
      name: capitalizarPalabras(
        (informacion.tipo === "PLAN"
          ? `${informacion.vendedorRef?.nombre} ${informacion.vendedorRef?.apellidos}`
          : `${informacion.tareaPropuesta?.empleado?.nombre} ${informacion.tareaPropuesta?.empleado?.apellidos}`) ||
          "No definido",
        "a"
      ),
      email:
        informacion.vendedorRef?.correo ||
        informacion.tareaPropuesta?.empleado?.correo,
      phone:
        informacion.vendedorRef?.telefono ||
        informacion.tareaPropuesta?.empleado?.telefono,
    },
    items:
      informacion.tipo === "PLAN"
        ? informacion.planes.map((plan) => ({
            name: capitalizarPrimeraLetra(plan.refTipoPlan),
            unit: plan.tipoFrecuencia,
            price: formatDecimal(plan.valor),
            discount: plan.descuento,
            discountEndDate: plan.fechaTerminoDesc,
            currency: formatearTipoMoneda(plan.tipoMoneda),
            finalPrice: formatDecimal(plan.valorDesc),
            description: plan.tipoPlan.descripcion,
            rutCompany: plan.cliente.rut,
            nameCompany: plan.cliente.nombre,
            addressCompany: plan.cliente.direccion,
            cityCompany: plan.cliente.comuna,
            phoneCompany: plan.cliente.telefono,
          }))
        : [
            {
              name: capitalizarPrimeraLetra(
                informacion.tareaPropuesta.refTipoTarea
              ),
              price: formatDecimal(informacion.tareaPropuesta.valorOriginal),
              discount: informacion.tareaPropuesta.descuento,
              currency: formatearTipoMoneda(
                informacion.tareaPropuesta.tipoMoneda
              ),
              finalPrice: formatDecimal(
                informacion.tareaPropuesta.valorConDescuento
              ),
              dateLimit: formatearFecha(
                informacion.tareaPropuesta.fechaLimite,
                "DD/MM/YYYY"
              ),
            },
          ],
  };
};

const verificarExistenciaToken = (token) => {
  return !!token;
};

const ProposalView = () => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const token = searchParams.get("token");

  const {
    data: propuesta,
    isLoading: cargandoDatosPropuesta,
    error,
    refetch,
  } = propuestasApi.useObtenerPropuestaClienteQuery({
    token,
  });

  const tokenExist = verificarExistenciaToken(localStorage.getItem("token"));

  const proposalData = useMemo(() => {
    if (!cargandoDatosPropuesta && propuesta) {
      return formatearDatosPropuesta(propuesta);
    }
    return null;
  }, [cargandoDatosPropuesta, propuesta]);

  const [proposalStatus, setProposalStatus] = useState("ENVIADA");

  useEffect(() => {
    if (!cargandoDatosPropuesta && propuesta) {
      setProposalStatus(proposalData.status);
    }
  }, [cargandoDatosPropuesta, proposalData?.status, propuesta]);

  const isExpired = useMemo(() => {
    if (proposalData) {
      return verificarExpiracion(proposalData.expiryDate, "DD/MM/YYYY");
    }
    return false;
  }, [proposalData]);

  if (cargandoDatosPropuesta) {
    return <ProposalLoading />;
  }

  if (error) {
    return (
      <ErrorView
        error={error}
        onRetry={() => {
          refetch();
        }}
      />
    );
  }

  const handleOpenDialog = (type) => {
    setDialogType(type);
    setOpenDialog(true);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpenDialog(false);
  };

  const handleOpenUrl = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Box className={classes.pageContainer}>
      <Container maxWidth="lg" className={classes.root}>
        <Paper className={classes.paper}>
          <ExpirationBanner isExpired={isExpired} proposalData={proposalData} />

          <ActionButtons
            idProposal={proposalData.proposalNumber}
            token={token}
          />

          {propuesta.estado === "ENVIADA" && (
            <TimeRemainingChip
              isExpired={isExpired}
              proposalData={proposalData}
            />
          )}

          <ProposalStatusChip proposalStatus={proposalStatus} />

          <Box className={classes.header}>
            <img src="/static/logo.png" alt="Logo" className={classes.logo} />
            <Typography variant="h6" color="primary">
              Propuesta - {proposalData.proposalNumber}
            </Typography>
          </Box>

          <Grid container spacing={4} sx={{ mb: 6 }}>
            <Grid item xs={12} md={6}>
              <InfoCards title="Información de la venta">
                <Grid container spacing={2}>
                  {/* Columna Izquierda */}
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={2}>
                      <Box display="flex" alignItems="center" gap={2}>
                        <CalendarToday color="primary" />
                        <Box>
                          <Typography variant="body2" color="textSecondary">
                            Fecha de emisión
                          </Typography>
                          <Typography variant="subtitle1">
                            {proposalData.date}
                          </Typography>
                        </Box>
                      </Box>

                      <Box display="flex" alignItems="center" gap={2}>
                        <CalendarToday color="primary" />
                        <Box>
                          <Typography variant="body2" color="textSecondary">
                            Fecha de vencimiento
                          </Typography>
                          <Typography variant="subtitle1">
                            {proposalData.expiryDate}
                          </Typography>
                        </Box>
                      </Box>

                      <Box display="flex" alignItems="center" gap={2}>
                        <Description color="primary" />
                        <Box>
                          <Typography variant="body2" color="textSecondary">
                            Tipo
                          </Typography>
                          <Typography variant="subtitle1">
                            {capitalizarPalabras(proposalData.proposalType)}
                          </Typography>
                        </Box>
                      </Box>

                      <Box display="flex" alignItems="center" gap={2}>
                        <Comment color="primary" />
                        <Box>
                          <Typography variant="body2" color="textSecondary">
                            Comentarios
                          </Typography>
                          <Typography variant="subtitle1">
                            {formatToSentenceCase(proposalData.comments)}
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                  </Grid>

                  {/* Columna Derecha */}
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={2}>
                      <Box display="flex" alignItems="center" gap={2}>
                        <Person color="primary" />
                        <Box>
                          <Typography variant="body2" color="textSecondary">
                            Nombre del remitente
                          </Typography>
                          <Typography variant="subtitle1">
                            {proposalData.seller.name}
                          </Typography>
                        </Box>
                      </Box>

                      <Box display="flex" alignItems="center" gap={2}>
                        <Email color="primary" />
                        <Box>
                          <Typography variant="body2" color="textSecondary">
                            Email del remitente
                          </Typography>
                          <Typography variant="subtitle1">
                            <a
                              href={`mailto:${proposalData.seller.email}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {proposalData.seller.email}
                            </a>
                          </Typography>
                        </Box>
                      </Box>

                      <Box display="flex" alignItems="center" gap={2}>
                        <Phone color="primary" />
                        <Box>
                          <Typography variant="body2" color="textSecondary">
                            Teléfono del remitente
                          </Typography>
                          <Typography variant="subtitle1">
                            {proposalData.seller.phone}
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </InfoCards>
            </Grid>

            <Grid item xs={12} md={6}>
              <InfoCards title="Información del cliente">
                <Stack spacing={2}>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Person color="primary" />
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Nombre
                      </Typography>
                      <Typography variant="subtitle1">
                        {proposalData.client.name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" gap={2}>
                    <Badge color="primary" />
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        RUT
                      </Typography>
                      <Typography variant="subtitle1">
                        {proposalData.client.rut}
                      </Typography>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" gap={2}>
                    <Email color="primary" />
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Email
                      </Typography>
                      <Typography variant="subtitle1">
                        {proposalData.client.contact}
                      </Typography>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" gap={2}>
                    <Phone color="primary" />
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Teléfono
                      </Typography>
                      <Typography variant="subtitle1">
                        {proposalData.client.phone}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              </InfoCards>
            </Grid>

            <Grid item xs={12}>
              <InfoCards title="Antecedentes del Cliente">
                <Typography variant="body1">
                  {formatToSentenceCase(proposalData.background)}
                </Typography>
              </InfoCards>
            </Grid>
          </Grid>

          {/* <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Servicio</TableCell>
                  <TableCell align="right">Valor</TableCell>
                  <TableCell align="center">Descuento</TableCell>
                  <TableCell align="right">Valor Final</TableCell>
                  <TableCell>Moneda</TableCell>
                  <TableCell>Frecuencia</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {proposalData.items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.description}</TableCell>
                    <TableCell align="right">{item.price}</TableCell>
                    <TableCell align="center">{item.discount}</TableCell>
                    <TableCell align="right">{item.finalPrice}</TableCell>
                    <TableCell>{item.currency}</TableCell>
                    <TableCell>{item.unit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}

          <ServicesTable
            proposalData={proposalData}
            proposalType={proposalData.proposalType}
          />

          {/* <Card className={classes.totalsCard}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography>Monto neto</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography align="right">
                    {proposalData.totals.subtotal.toFixed(2)} UF
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{ my: 2, backgroundColor: "rgba(255, 255, 255, 0.2)" }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h5" fontWeight="bold">
                    Total
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h5" align="right" fontWeight="bold">
                    {proposalData.totals.total.toFixed(2)} UF
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card> */}

          {/* Sección de Acciones de Propuesta */}
          {proposalStatus === "ENVIADA" && !isExpired && !tokenExist && (
            <Box className={classes.proposalActions}>
              <Typography variant="h6" gutterBottom>
                Acciones de Propuesta
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.acceptButton}
                    startIcon={<CheckCircle />}
                    onClick={() => handleOpenDialog("ACEPTADA")}
                  >
                    Aceptar Propuesta
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.rejectButton}
                    startIcon={<Cancel />}
                    onClick={() => handleOpenDialog("RECHAZADA")}
                  >
                    Rechazar Propuesta
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box className={classes.footer}>
            <div className={classes.socialIcons}>
              <IconButton
                color="primary"
                onClick={() => handleOpenUrl("https://www.facebook.com/Taxtic")}
              >
                <Facebook />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() =>
                  handleOpenUrl("https://www.instagram.com/taxtic.chile/")
                }
              >
                <Instagram />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() =>
                  handleOpenUrl("https://cl.linkedin.com/company/taxtic-chile")
                }
              >
                <LinkedIn />
              </IconButton>
            </div>
            <Typography variant="body2" color="textSecondary">
              &copy; 2024 Taxtic. Todos los derechos reservados.
            </Typography>
          </Box>
        </Paper>
        {openDialog && (
          <ResponseDialog
            classes={classes}
            dialogType={dialogType}
            onClose={handleCloseDialog}
            open={openDialog}
            setProposalStatus={setProposalStatus}
            token={propuesta.token}
            contactClient={proposalData.client.contact}
            nombreCliente={proposalData.client.name}
            refetch={refetch}
          />
        )}
      </Container>
    </Box>
  );
};

export default ProposalView;
