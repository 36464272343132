import { alpha, Box, Paper } from "@material-ui/core";
import PropTypes from "prop-types";

PaperFormProposal.propTypes = {
  children: PropTypes.object.isRequired,
};

export default function PaperFormProposal({ children }) {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        my: 3,
        minHeight: 120,
        bgcolor: (theme) => alpha(theme.palette.background.paper, 0.9),
        borderRadius: 2,
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "translateY(-2px)",
          boxShadow: (theme) => theme.shadows[6],
        },
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Barra decorativa superior */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: 4,
          bgcolor: (theme) => theme.palette.primary.main,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      />

      {children}
    </Paper>
  );
}
