import { formatAsCLP } from "src/utils/formatNumber";

export function getStepContent(step, steps) {
  return steps[step] || "Paso desconocido";
}

export const showSwitch = (
  show,
  checked,
  setFieldValue,
  campo,
  label,
  elementsToReset = null,
  formik = null
) => {
  return {
    show,
    checked,
    label: checked ? label.checked : label.unChecked,
    onChangeSwitch: (event) => {
      // Verifica si hay elementos para restablecer y si el objeto formik está disponible
      if (elementsToReset && formik) {
        const { elements, defaults } = elementsToReset; // Obtiene los campos y valores por defecto
        const resetValues = { ...formik.values }; // Crea una copia de los valores actuales

        elements.forEach((element) => {
          // Si el campo tiene un valor definido en defaults, se utiliza; de lo contrario, usa ""
          resetValues[element] =
            defaults && defaults[element] !== undefined
              ? defaults[element]
              : "";
        });

        formik.setValues(resetValues); // Aplica los nuevos valores al formulario
      }
      // Actualiza el valor del check
      setFieldValue(campo, event.target.checked);
    },
  };
};

export const showButton = (show, label, onClick) => {
  return {
    show,
    label,
    onClick,
  };
};

export const formatearValorPlan = (tipoMoneda, precio) => {
  switch (tipoMoneda) {
    case "DOLAR":
      return `${formatAsCLP(precio)} USD`;
    case "PESOS":
      return `${formatAsCLP(precio)} CLP`;
    case "UF":
      return `${precio} UF`;
    case "UTM":
      return `${precio} UTM`;
    default:
      return "NVF";
  }
};
