import React, { useCallback, useState } from "react";
// material
import {
  TextField,
  Card,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { validateRut } from "@fdograph/rut-utilities";
import { propuestasApi } from "src/services/propuestasApi";
import { useSnackbar } from "src/components/snackbar";
import {
  DisabledProvider,
  DisabledWrapper,
} from "../disabled-screen/DisabledContext";

const SNACKBAR_OPTIONS = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
};

const validarEntradas = (
  rutSolicitante,
  nombreSolicitante,
  telefonoSolicitante,
  correoSolicitante,
  setErrores
) => {
  const nuevosErrores = {};

  if (rutSolicitante.trim() === "")
    nuevosErrores.rutSolicitante = "El RUT es requerido.";
  else if (!validateRut(rutSolicitante))
    nuevosErrores.rutSolicitante = "El RUT es inválido.";

  if (nombreSolicitante.trim() === "")
    nuevosErrores.nombreSolicitante = "El nombre es requerido.";
  if (telefonoSolicitante.trim() === "")
    nuevosErrores.telefonoSolicitante = "El teléfono es requerido.";
  if (correoSolicitante.trim() === "")
    nuevosErrores.correoSolicitante = "El correo es requerido.";
  else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(correoSolicitante))
    nuevosErrores.correoSolicitante = "El correo es inválido.";

  setErrores(nuevosErrores);

  return Object.keys(nuevosErrores).length === 0;
};

export default function VerPropuestaDatosSolicitante(props) {
  const { propuesta, isDisabled } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [mostrarCircular, setMostrarCircular] = useState(false);
  const [actualizarSolicitantePropuesta] =
    propuestasApi.useActualizarSolicitantePropuestaMutation();
  const [errores, setErrores] = useState({
    rutSolicitante: "",
    nombreSolicitante: "",
    telefonoSolicitante: "",
    correoSolicitante: "",
  });
  const [nombreSolicitante, setNombreSolicitante] = useState(
    propuesta.nombreSolicitante
  );
  const [rutSolicitante, setRutSolicitante] = useState(
    propuesta.rutSolicitante
  );
  const [telefonoSolicitante, setTelefonoSolicitante] = useState(
    propuesta.telefonoSolicitante
  );
  const [correoSolicitante, setCorreoSolicitante] = useState(
    propuesta.correoSolicitante
  );
  const [comentarios, setComentarios] = useState(propuesta.comentarios);

  const handleChangeNombreSolicitante = (event) => {
    setNombreSolicitante(event.target.value);
  };

  const handleChangeRutSolicitante = (event) => {
    setRutSolicitante(event.target.value);
  };

  const handleChangeTelefonoSolicitante = (event) => {
    setTelefonoSolicitante(event.target.value);
  };

  const handleChangeCorreoSolicitante = (event) => {
    setCorreoSolicitante(event.target.value);
  };

  const handleChangeComentarios = (event) => {
    setComentarios(event.target.value);
  };

  const guardarCambios = useCallback(async () => {
    if (isDisabled) return;

    setMostrarCircular(true);

    if (
      !validarEntradas(
        rutSolicitante,
        nombreSolicitante,
        telefonoSolicitante,
        correoSolicitante,
        setErrores
      )
    ) {
      setMostrarCircular(false);
      return;
    }

    const datosSolicitante = {
      id: propuesta.id,
      rutSolicitante,
      nombreSolicitante: nombreSolicitante.toUpperCase(),
      telefonoSolicitante,
      correoSolicitante,
      comentarios: comentarios.toUpperCase(),
    };

    try {
      await actualizarSolicitantePropuesta(datosSolicitante).unwrap();
      enqueueSnackbar("Datos del solicitante actualizados correctamente", {
        ...SNACKBAR_OPTIONS,
        variant: "success",
      });
      setErrores({});
    } catch (error) {
      console.error("guardarCambios", error);
      enqueueSnackbar(
        error?.message ||
          "Ocurrió un error al actualizar los datos del solicitante"
      );
    } finally {
      setMostrarCircular(false);
    }
  }, [
    actualizarSolicitantePropuesta,
    comentarios,
    correoSolicitante,
    enqueueSnackbar,
    isDisabled,
    nombreSolicitante,
    propuesta.id,
    rutSolicitante,
    telefonoSolicitante,
  ]);

  return (
    <DisabledProvider isDisabled={isDisabled}>
      <DisabledWrapper
        message="Vista de solo lectura"
        overlayColor="rgba(240, 242, 245, 0.45)"
      >
        <Card>
          <Typography variant="h5" style={{ textAlign: "center" }} gutterBottom>
            Datos Solicitante
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={3}
            style={{ marginLeft: "25px", marginRight: "25px" }}
            mb={5}
          >
            <TextField
              margin="dense"
              label="Rut Solicitante"
              type="text"
              value={rutSolicitante}
              onChange={handleChangeRutSolicitante}
              fullWidth
              required
              disabled={propuesta.estado === "ACEPTADA"}
              error={!!errores.rutSolicitante}
              helperText={errores.rutSolicitante}
            />

            <TextField
              margin="dense"
              label="Nombre Solicitante"
              type="text"
              value={nombreSolicitante}
              onChange={handleChangeNombreSolicitante}
              fullWidth
              required
              disabled={propuesta.estado === "ACEPTADA"}
              error={!!errores.nombreSolicitante}
              helperText={errores.nombreSolicitante}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
            spacing={3}
            style={{ marginLeft: "25px", marginRight: "25px" }}
          >
            <TextField
              margin="dense"
              label="Teléfono Solicitante"
              type="text"
              value={telefonoSolicitante}
              onChange={handleChangeTelefonoSolicitante}
              fullWidth
              required
              disabled={propuesta.estado === "ACEPTADA"}
              error={!!errores.telefonoSolicitante}
              helperText={errores.telefonoSolicitante}
            />

            <TextField
              margin="dense"
              label="Correo Solicitante"
              type="email"
              value={correoSolicitante}
              onChange={handleChangeCorreoSolicitante}
              fullWidth
              required
              disabled={propuesta.estado === "ACEPTADA"}
              error={!!errores.correoSolicitante}
              helperText={errores.correoSolicitante}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
            spacing={3}
            style={{ marginLeft: "25px", marginRight: "25px" }}
          >
            <TextField
              margin="dense"
              label="Comentarios"
              type="text"
              rows={4}
              value={comentarios}
              onChange={handleChangeComentarios}
              fullWidth
              multiline
              disabled={propuesta.estado === "ACEPTADA" && true}
            />
          </Stack>

          <div
            style={{
              textAlign: "right",
              marginRight: "25px",
              marginBottom: "25px",
            }}
          >
            {mostrarCircular ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={guardarCambios}
                disabled={propuesta.estado === "ACEPTADA" && true}
              >
                Guardar Cambios
              </Button>
            )}
          </div>
        </Card>
      </DisabledWrapper>
    </DisabledProvider>
  );
}
