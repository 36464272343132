import React from "react";
// routes
import Router from "./routes/routes";
// theme
import ThemeConfig from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import { Provider } from "react-redux";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import store from "./store";
import { es } from "date-fns/locale";
import { LocalizationProvider } from "@material-ui/lab";
import SnackbarProvider from "./components/snackbar";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
        <Provider store={store}>
          <SnackbarProvider>
            <ScrollToTop />
            <Router />
          </SnackbarProvider>
        </Provider>
      </LocalizationProvider>
    </ThemeConfig>
  );
}
