import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { useCallback, useState } from "react";
import Upload from "src/components/upload/upload";
import { capitalizarPalabras } from "src/utils/sharedFunctions";
import saveFill from "@iconify/icons-eva/save-outline";
import Icon from "@iconify/react";
import { useSnackbar } from "src/components/snackbar";
import { subirArchivoADropbox } from "src/utils/dropboxUtils";
import { propuestasApi } from "src/services/propuestasApi";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

const MAX_SIZE_FILE = 50 * 1024 * 1024; //50 MB
const MAX_FILES = 1;

export default function ModalSubirArchivo({
  open,
  setOpen,
  onSendMailClient,
  propuesta,
  maxWidth,
  fullWidth,
}) {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [subiendoArchivo, setSubiendoArchivo] = useState(false);
  const [actualizarPropuesta] =
    propuestasApi.useActualizarArchivoPropuestaMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const handleDropSingleFile = useCallback((acceptedFiles) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFile(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    } else {
      setFile(null);
    }
  }, []);

  const subirArchivoPropuesta = async () => {
    setSubiendoArchivo(true);
    try {
      const body = await subirArchivoADropbox(
        file,
        "Propuestas",
        undefined,
        subiendoArchivo,
        "urlPropuesta",
        false,
        `${propuesta.id}.pdf`,
        false
      );

      await actualizarPropuesta({
        idPropuesta: propuesta.id,
        ...body,
      }).unwrap();
      enqueueSnackbar("Archivo subido correctamente", {});
      handleClose();
      onSendMailClient(propuesta);
    } catch (error) {
      console.error("Error al subir archivo dropbox: ", error);
      enqueueSnackbar(
        error?.message ||
          error?.data?.message ||
          "Ocurrió un error al subir el archivo de la propuesta",
        {
          variant: "error",
        }
      );
    } finally {
      setSubiendoArchivo(false);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      onClose={handleClose}
      fullWidth={fullWidth}
    >
      <DialogTitle className={classes.dialogTitle}>
        {`Adjuntar archivo propuesta ${capitalizarPalabras(
          propuesta.tipo === "PLAN"
            ? propuesta.nombreSolicitante
            : propuesta.nombreCliente
        )}`}
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Upload
          disabled={false}
          file={file}
          loading={false}
          onDrop={handleDropSingleFile}
          onDelete={() => setFile(null)}
          accept={{
            "application/pdf": [".pdf"],
          }}
          maxSize={MAX_SIZE_FILE}
          maxFiles={MAX_FILES}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cerrar
        </Button>
        <LoadingButton
          disabled={!file || subiendoArchivo}
          startIcon={<Icon icon={saveFill} />}
          color="primary"
          onClick={subirArchivoPropuesta}
          loading={subiendoArchivo}
        >
          Guardar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
