import { createSlice } from "@reduxjs/toolkit";
import jwt from "jsonwebtoken";

const token = localStorage.getItem("token");

const jwtVerify = (tokenString) => {
  try {
    return jwt.verify(tokenString, "G4OH9PDGyhgfrnlc58zI");
  } catch (err) {
    // Si el token es inválido o ha expirado, retorna null
    return null;
  }
};

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: jwtVerify(token),
    sessionExpired: false,
  },
  reducers: {
    login: (state, param) => {
      const { payload } = param;
      state.user = payload;
      state.sessionExpired = false;
    },
    logout: (state, param) => {
      const { payload } = param;
      state.user = null;
      state.sessionExpired = payload?.expired || false;
      localStorage.clear();
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
