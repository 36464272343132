import AntecedentesForm from "../propuestaPlan/formularios/AntecedentesForm";
import ClienteForm from "../propuestaPlan/formularios/ClienteForm";
import EmpleadosAdicionalesForm from "../propuestaPlan/formularios/EmpleadosAdicionalesForm";
import EquipoResponsableForm from "../propuestaPlan/formularios/EquipoResponsableForm";
import ObservacionesForm from "../propuestaPlan/formularios/ObservacionesForm";
import serviciosForm from "../propuestaPlan/formularios/serviciosForm";
import SolicitanteForm from "../propuestaPlan/formularios/SolicitanteForm";
import EncargadosForm from "../propuestaTareaEspecial/formularios/EncargadosForm";
import ServicioForm from "../propuestaTareaEspecial/formularios/ServicioForm";
import SubtareasForm from "../propuestaTareaEspecial/formularios/SubtareasForm";

export const PLAN_STEPS = {
  0: {
    component: EquipoResponsableForm,
    getProps: (commonProps) => ({
      requireSucursal: true,
      sucursal: commonProps.sucursal,
    }),
  },
  1: {
    component: SolicitanteForm,
    props: {},
  },
  2: {
    component: AntecedentesForm,
    props: {},
  },
  3: {
    component: ClienteForm,
    props: {},
  },
  4: {
    component: serviciosForm,
    props: {},
  },
  5: {
    component: EmpleadosAdicionalesForm,
    getProps: (commonProps) => ({
      requireSucursal: true,
      sucursal: commonProps.sucursal,
    }),
  },
  6: {
    component: ObservacionesForm,
    props: {},
  },
};

export const TASK_STEPS = {
  0: {
    // responsables
    component: EncargadosForm,
    getProps: (commonProps) => ({
      requireSucursal: true,
      sucursal: commonProps.sucursal,
    }),
  },
  1: {
    component: ClienteForm,
    props: {},
  },
  2: {
    component: AntecedentesForm,
    props: {},
  },
  3: {
    // Servicio
    component: ServicioForm,
    props: {},
  },
  4: {
    //subtareas
    component: SubtareasForm,
    props: {},
  },
  5: {
    component: EmpleadosAdicionalesForm,
    getProps: (commonProps) => ({
      requireSucursal: true,
      sucursal: commonProps.sucursal,
    }),
  },
  6: {
    component: ObservacionesForm,
    props: {},
  },
};
