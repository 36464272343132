// validationSchemas.js
import { validateRut } from "@fdograph/rut-utilities";
import * as Yup from "yup";
//
import {
  antecedentesSchema,
  clienteSchema,
  empleadosAdicionales,
  observacionesSchema,
  planesSeleccionados,
} from "./commons";

const responsableSchema = Yup.object().shape({
  vendedor: Yup.object()
    // .mixed().oneOf([opciones])
    .required("El vendedor es requerido")
    .nullable(),
  supervisor: Yup.object().required("El supervisor es requerido").nullable(),
});

const solicitanteSchema = Yup.object().shape({
  sinRutSolicitante: Yup.boolean(),
  rutSolicitante: Yup.string().when("sinRutSolicitante", {
    is: false,
    then: Yup.string()
      .required("El RUT es requerido")
      .test("valid-rut", "El RUT no es válido", (value) => {
        if (!value) return false;
        return validateRut(value);
      }),
    otherwise: Yup.string().notRequired(),
  }),
  nombreSolicitante: Yup.string().required(
    "El nombre del solicitante es requerido"
  ),
  correoSolicitante: Yup.string()
    .email("El formato del email no es válido")
    // .test(
    //   "excluir-dominio-taxtic",
    //   "El dominio del correo no es permitido",
    //   (value) => !value || !/^[\w.%+-]+@taxtic\.com$/i.test(value)
    // )
    .required("El email es requerido"),
  telefonoSolicitante: Yup.string()
    .length(9, "El teléfono debe tener 9 dígitos")
    .required("El teléfono es requerido"),
});

export const SCHEMAS_PROPOSAL_PLAN = [
  responsableSchema,
  solicitanteSchema,
  antecedentesSchema,
  clienteSchema,
  planesSeleccionados,
  empleadosAdicionales,
  observacionesSchema,
];
