import { styled, Switch } from "@material-ui/core";

export const SwitchDesignCustomized = styled(Switch)(({ theme }) => ({
  width: 55,
  height: 27,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 1.01 21 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M15 3H5C3.9 3 3 3.9 3 5v10c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1.6 7L9.7 13.7c-.4.4-1 .4-1.4 0l-1.7-1.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l1 1 2.9-2.9c.4-.4 1-.4 1.4 0 .4.4.4 1.1.1 1.3z"/></svg>')`,
        backgroundSize: "20px 20px", // Ajusta el tamaño del icono
        backgroundPosition: "center",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.success.main,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 25,
    height: 25,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "20px 20px", // Asegura que el tamaño sea el mismo que el SVG
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 2 21 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M10 4a3 3 0 100 6 3 3 0 000-6zm0 8c-3.31 0-6 1.79-6 4v1h12v-1c0-2.21-2.69-4-6-4z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.warning.light,
    borderRadius: 20 / 2,
  },
}));

export const SwitchDesignCustomizedClient = styled(Switch)(({ theme }) => ({
  width: 55,
  height: 27,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        // Ícono para "Cliente Existente" (checked)
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm-1 15h2v-2h-2v2zm0-4h2V7h-2v6z"/></svg>')`,
        backgroundSize: "18px 18px",
        backgroundPosition: "center",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#0050a0" : "#003366",
    width: 25,
    height: 25,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "18px 18px",
      // Ícono para "Cliente Nuevo" (unchecked)
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M12 4a8 8 0 0 0 0 16 8 8 0 0 0 0-16zm1 11h-2v-2h2v2zm0-4h-2V7h2v4z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.grey[400],
    borderRadius: 20 / 2,
  },
}));
