import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_SERVER } from "src/utils/urlBack_End";
import PlanesAsociadosCliente from "../verplan/PlanesAsociadosCliente";
import Scrollbar from "../Scrollbar";
import { useSelector } from "react-redux";

const styles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function Plan(props) {
  const { value, cliente } = props;
  const navigate = useNavigate();
  const classes = styles();
  const [locale] = useState("esES");
  const [planes, setPlanes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { empleado: user, rol: rolObtenido } = useSelector(
    (state) => state.auth.user
  );

  const [planSeleccionado, setPlanSeleccionado] = useState();
  const [openPlanesAsociadosCliente, setOpenPlanesAsociadosCliente] =
    useState(false);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/planes/cliente/${cliente.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setPlanes(response.data);
      });
  }, [cliente.rut]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const disabled = (element) => {
    return rolObtenido === "EMPLEADO" && user.rut !== element.refEmpleado;
  };

  const navegar = (element) => {
    if (!disabled(element)) {
      navigate("/dashboard/clientes/planes/" + element.id);
    }
  };

  return (
    <div className={classes.pos}>
      {value === 4 && (
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2" className={classes.title}>
              Plan
            </Typography>
          </CardContent>

          <CardActions>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Empleado</TableCell>
                      <TableCell align="center">Sucursal</TableCell>
                      <TableCell align="center">Estado</TableCell>
                      <TableCell align="center">Contrato Firmado</TableCell>
                      <TableCell align="center">Planes Asociados</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {planes &&
                      planes
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((element, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align="center"
                                style={{
                                  cursor: !disabled(element)
                                    ? "pointer"
                                    : "auto",
                                }}
                                onClick={() => {
                                  navegar(element);
                                }}
                              >
                                {element.nombreEmpleado +
                                  " " +
                                  element.apellidoEmpleado}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  cursor: !disabled(element)
                                    ? "pointer"
                                    : "auto",
                                }}
                                onClick={() => {
                                  navegar(element);
                                }}
                              >
                                {element.sucursal}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  cursor: !disabled(element)
                                    ? "pointer"
                                    : "auto",
                                }}
                                onClick={() => {
                                  navegar(element);
                                }}
                              >
                                {element.estado}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  cursor: !disabled(element)
                                    ? "pointer"
                                    : "auto",
                                }}
                                onClick={() => {
                                  navegar(element);
                                }}
                              >
                                {element.contratoFirmado}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  onClick={() => {
                                    setPlanSeleccionado(element.id);
                                    setOpenPlanesAsociadosCliente(true);
                                  }}
                                >
                                  Planes
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </CardActions>
          <ThemeProvider
            theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
          >
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={planes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </Card>
      )}
      {openPlanesAsociadosCliente && (
        <PlanesAsociadosCliente
          open={openPlanesAsociadosCliente}
          setOpen={setOpenPlanesAsociadosCliente}
          id={planSeleccionado}
        />
      )}
    </div>
  );
}
