import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import PropTypes from "prop-types";
import AutoCompleteField from "../sharedFields/AutocompleteField";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useMemo } from "react";
import { LoadingButton } from "@material-ui/lab";
import { facturasApi } from "src/services/facturasApi";
import { useSnackbar } from "src/components/snackbar";

ModalCambioSucursal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  sucursales: PropTypes.array.isRequired,
};

export default function ModalCambioSucursal({
  isOpen,
  setOpen,
  data,
  sucursales,
}) {
  const [cambiarSucursalFactura, { isLoading: cambiandoSucursal }] =
    facturasApi.useCambiarSucursalMutation();
  const { enqueueSnackbar } = useSnackbar();

  const sucursalesMapped = useMemo(
    () =>
      sucursales.map((sucursal) => ({
        id: sucursal.refSucursal,
        nombre: sucursal.refSucursal,
      })),
    [sucursales]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const schema = Yup.object().shape({
    sucursal: Yup.object()
      .nullable()
      .shape({
        id: Yup.string().required("La sucursal es requerida"),
      })
      .required("La sucursal es requerida"),
  });

  const formik = useFormik({
    initialValues: {
      sucursal: null,
    },
    validationSchema: schema,
    onSubmit: () => {
      manejarCambioSucursal();
    },
  });
  const { values, touched, errors, setFieldValue, handleSubmit } = formik;

  const manejarCambioSucursal = useCallback(async () => {
    try {
      const result = await cambiarSucursalFactura({
        id: data.id,
        antiguaSucursal: data.antiguaSucursal,
        nuevaSucursal: values.sucursal.id,
        tipo: data.tipo,
      }).unwrap();

      enqueueSnackbar(
        result.message ||
          "La sucursal de la factura ha sido cambiada exitosamente",
        {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
      handleClose();
    } catch (error) {
      console.error("Error en manejarCambioSucursal", error);
      enqueueSnackbar(
        error?.data?.message ||
          error.message ||
          "Ocurrió un error al eliminar el plan",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  }, [
    cambiarSucursalFactura,
    data.antiguaSucursal,
    data.id,
    data.tipo,
    enqueueSnackbar,
    handleClose,
    values.sucursal?.id,
  ]);

  const handleSucursalSelection = useCallback(
    (event, newValue) => {
      setFieldValue("sucursal", newValue);
    },
    [setFieldValue]
  );

  const handleOptionLabelSucursal = useCallback((option) => option.nombre, []);

  const handleIsOptionEqualToValueSucursal = useCallback(
    (option, value) => option.id === value.id,
    []
  );

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="dialog-cambio-sucursal"
      id="dialog-cambio-sucursal"
      fullWidth
    >
      <DialogTitle>Cambio sucursal</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Por favor, selecciona la sucursal a la que deseas cambiar la factura.
        </DialogContentText>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <AutoCompleteField
                sx={{
                  marginTop: { md: 2, xs: 2, sm: 2 },
                }}
                disableClearable={true}
                id="autocomplete-sucursal"
                label="Sucursal"
                value={values.sucursal}
                xs={12}
                md={12}
                lg={12}
                handleSucursalSelection={handleSucursalSelection}
                options={sucursalesMapped}
                touched={touched.sucursal}
                error={errors.sucursal}
                getOptionLabel={handleOptionLabelSucursal}
                isOptionEqualToValue={handleIsOptionEqualToValueSucursal}
                typeRenderInput="normal"
              />
            </Grid>
            <DialogActions>
              <Button color="error" onClick={handleClose}>Cancelar</Button>
              <LoadingButton type="submit" loading={cambiandoSucursal}>
                Aceptar
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
