import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { empleadosApi } from "src/services/empleadosApi";
import { capitalizarPalabras } from "src/utils/sharedFunctions";

const EquipoResponsableForm = ({ formik, sucursal }) => {
  const { setFieldValue, touched, errors, values } = formik;
  const { data: empleados, isLoading: cargandoEmpleados } =
    empleadosApi.useGetEmpleadosActivosQuery({
      id: sucursal,
      estado: "ALL",
    });

  const getOptionLabel = (option) => {
    const nombreCompleto = `${option.nombre} ${option.apellidos}`;
    const rutFormateado = formatRut(option.rut, RutFormat.DOTS_DASH);
    return `${capitalizarPalabras(nombreCompleto)} (${rutFormateado})`;
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <Autocomplete
          fullWidth
          value={values.vendedor}
          onChange={(_, newValue) => setFieldValue("vendedor", newValue)}
          options={!cargandoEmpleados && empleados ? empleados : []}
          getOptionLabel={getOptionLabel}
          disabled={cargandoEmpleados}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Seleccione un vendedor"
              variant="outlined"
              disabled={cargandoEmpleados}
              placeholder="Buscar vendedor..."
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {cargandoEmpleados ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              error={touched.vendedor && Boolean(errors.vendedor)}
              helperText={touched.vendedor && errors.vendedor}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <Autocomplete
          fullWidth
          value={values.supervisor}
          onChange={(_, newValue) => setFieldValue("supervisor", newValue)}
          options={!cargandoEmpleados && empleados ? empleados : []}
          getOptionLabel={getOptionLabel}
          disabled={cargandoEmpleados}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Seleccione un supervisor"
              variant="outlined"
              disabled={cargandoEmpleados}
              placeholder="Buscar supervisor..."
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {cargandoEmpleados ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              error={touched.supervisor && Boolean(errors.supervisor)}
              helperText={touched.supervisor && errors.supervisor}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

EquipoResponsableForm.propTypes = {
  formik: PropTypes.object.isRequired,
  sucursal: PropTypes.string.isRequired,
};

export default React.memo(EquipoResponsableForm);
