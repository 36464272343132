import React, { createContext, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Page from "src/components/Page";
import { Titulo } from "src/components/Titulo";
import Tarjeta from "src/components/tarjeta";
import { Repeat, Task } from "@material-ui/icons";
import ModalPropuestaTareaEspecial from "src/components/Modal/propuestas/Modal-propuesta-tarea-especial";
import ModalPropuestaPlan from "src/components/Modal/propuestas/Modal-propuesta-plan";

// Contexto para la gestión del hover
const HoverContext = createContext();

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
}));

export default function NuevaPropuesta() {
  const classes = useStyles();
  const [hover, setHover] = useState(null);
  const [abrirModalTareaEspecial, setAbrirModalTareaEspecial] = useState(false);
  const [abrirModalPlan, setAbrirModalPlan] = useState(false);

  const handleHoverOpen = (id) => {
    setHover(id);
  };

  const handleHoverClose = () => {
    setHover(null);
  };

  const handleInfoClick = (message) => {
    if (message === "plan") {
      setAbrirModalPlan(true);
    } else if (message === "tarea-especial") {
      setAbrirModalTareaEspecial(true);
    }
  };

  return (
    <>
      <HoverContext.Provider
        value={{ hover, handleHoverOpen, handleHoverClose }}
      >
        <Page className={classes.root} title="Nueva propuesta">
          <Container>
            <Titulo>Nueva propuesta</Titulo>
            <Grid container spacing={4} justifyContent="center">
              {/* Tarjeta de Tarea Especial */}
              <Grid item xs={12} sm={6} md={4}>
                <Tarjeta
                  context={HoverContext}
                  handleClickAction={() => handleInfoClick("tarea-especial")}
                  id="tarea-especial"
                  titleCard="Tarea especial"
                  titleHoverInfo="Propuesta tarea especial"
                  contentHoverInfo="Las propuestas para tareas especiales corresponden a tareas
                    únicas que culminan con la emisión de una factura al
                    completarse. Estas no son de carácter periódico."
                  icon={<Task />}
                />
              </Grid>

              {/* Tarjeta de Plan */}
              <Grid item xs={12} sm={6} md={4}>
                <Tarjeta
                  context={HoverContext}
                  handleClickAction={() => handleInfoClick("plan")}
                  id="plan"
                  titleCard="Plan"
                  titleHoverInfo="Propuestas plan"
                  contentHoverInfo="Las propuestas de plan son aquellas que generan una tarea
                    mensual para el cliente."
                  icon={<Repeat />}
                />
              </Grid>
            </Grid>
          </Container>
        </Page>
      </HoverContext.Provider>
      {abrirModalTareaEspecial && (
        <ModalPropuestaTareaEspecial
          open={abrirModalTareaEspecial}
          setOpen={setAbrirModalTareaEspecial}
          fullWidth={true}
          maxWidth="lg"
        />
      )}
      {abrirModalPlan && (
        <ModalPropuestaPlan
          open={abrirModalPlan}
          setOpen={setAbrirModalPlan}
          fullWidth={true}
          maxWidth="lg"
        />
      )}
    </>
  );
}
