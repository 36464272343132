import React, { useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import arrowFill from "@iconify/icons-eva/arrow-back-fill";
import PropTypes from "prop-types";
import {
  Stack,
  Container,
  Typography,
  Box,
  Button,
  Tabs,
  Tab,
  Skeleton,
} from "@material-ui/core";
import Page from "../components/Page";
import VerPropuestaDatosSolicitante from "src/components/verpropuesta/VerPropuestaDatosSolicitante";
import VerPropuestaDatos from "src/components/verpropuesta/VerPropuestaDatos";
import VerPropuestaArchivos from "src/components/verpropuesta/VerPropuestaArchivos";
import Page404 from "./Page404";
import EmpleadosAsociadosPropuesta from "src/components/verpropuesta/EmpleadosAsociadosPropuesta";
import { useSelector } from "react-redux";
import VerPropuestaPlanes from "src/components/verpropuesta/VerPropuestaPlanes";
import VerDatosCliente from "src/components/verpropuesta/VerDatosCliente";
import { propuestasApi } from "src/services/propuestasApi";
import { useHistorialVisita } from "src/hooks/useHistorialVisita";
import VerServiciosPropuestaTarea from "src/components/verpropuesta/VerServiciosPropuestaTarea";
import VerSubtareasPropuestaTarea from "src/components/verpropuesta/VerSubtareasPropuestaTarea";
import { capitalizarPalabras } from "src/utils/sharedFunctions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TAB_CONFIGS = {
  PLAN: [
    {
      label: "Datos Solicitante",
      component: VerPropuestaDatosSolicitante,
      isDisabled: (propuesta) =>
        propuesta.estado === "ACEPTADA" ||
        propuesta.estado === "ENVIADA" ||
        propuesta.estado === "RECHAZADA" ||
        propuesta.estado === "ACEPTADO_CLIENTE" ||
        propuesta.estado === "CADUCADA",
    },
    {
      label: "Datos cliente",
      component: VerDatosCliente,
      isDisabled: (propuesta) =>
        propuesta.estado === "ACEPTADA" ||
        propuesta.estado === "ENVIADA" ||
        propuesta.estado === "RECHAZADA" ||
        propuesta.estado === "ACEPTADO_CLIENTE" ||
        propuesta.estado === "CADUCADA",
    },
    {
      label: "Datos Generales Propuesta",
      component: VerPropuestaDatos,
      isDisabled: (propuesta) =>
        propuesta.estado === "ACEPTADA" ||
        propuesta.estado === "ENVIADA" ||
        propuesta.estado === "RECHAZADA" ||
        propuesta.estado === "ACEPTADO_CLIENTE" ||
        propuesta.estado === "CADUCADA",
    },
    {
      label: "Empleados Asociados",
      component: EmpleadosAsociadosPropuesta,
      isDisabled: (propuesta) =>
        propuesta.estado === "ACEPTADA" ||
        propuesta.estado === "ENVIADA" ||
        propuesta.estado === "RECHAZADA" ||
        propuesta.estado === "ACEPTADO_CLIENTE" ||
        propuesta.estado === "CADUCADA",
    },
    {
      label: "Planes",
      component: VerPropuestaPlanes,
      isDisabled: (propuesta) =>
        propuesta.estado === "ACEPTADA" ||
        propuesta.estado === "ENVIADA" ||
        propuesta.estado === "RECHAZADA" ||
        propuesta.estado === "ACEPTADO_CLIENTE" ||
        propuesta.estado === "CADUCADA",
    },
    {
      label: "Archivos",
      component: VerPropuestaArchivos,
      tipo: (propuesta) => propuesta.tipo,
    },
  ],
  TAREA: [
    {
      label: "Datos Generales Propuesta",
      component: VerPropuestaDatos,
      tipo: (propuesta) => propuesta.tipo,
      isDisabled: (propuesta) =>
        propuesta.estado === "ACEPTADA" ||
        propuesta.estado === "ENVIADA" ||
        propuesta.estado === "RECHAZADA" ||
        propuesta.estado === "ACEPTADO_CLIENTE" ||
        propuesta.estado === "CADUCADA",
    },
    {
      label: "Datos cliente",
      component: VerDatosCliente,
      tipo: (propuesta) => propuesta.tipo,
      isDisabled: (propuesta) =>
        propuesta.estado === "ACEPTADA" ||
        propuesta.estado === "ENVIADA" ||
        propuesta.estado === "RECHAZADA" ||
        propuesta.estado === "ACEPTADO_CLIENTE" ||
        propuesta.estado === "CADUCADA",
    },
    {
      label: "Tarea",
      component: VerServiciosPropuestaTarea,
      isDisabled: (propuesta) =>
        propuesta.estado === "ACEPTADA" ||
        propuesta.estado === "ENVIADA" ||
        propuesta.estado === "RECHAZADA" ||
        propuesta.estado === "ACEPTADO_CLIENTE" ||
        propuesta.estado === "CADUCADA",
    },
    {
      label: "Subtareas",
      component: VerSubtareasPropuestaTarea,
      isDisabled: (propuesta) =>
        propuesta.estado === "ACEPTADA" ||
        propuesta.estado === "ENVIADA" ||
        propuesta.estado === "RECHAZADA" ||
        propuesta.estado === "ACEPTADO_CLIENTE" ||
        propuesta.estado === "CADUCADA",
    },
    {
      label: "Empleados Asociados",
      component: EmpleadosAsociadosPropuesta,
      isDisabled: (propuesta) =>
        propuesta.estado === "ACEPTADA" ||
        propuesta.estado === "ENVIADA" ||
        propuesta.estado === "RECHAZADA" ||
        propuesta.estado === "ACEPTADO_CLIENTE" ||
        propuesta.estado === "CADUCADA",
    },
    {
      label: "Archivos",
      component: VerPropuestaArchivos,
      tipo: (propuesta) => propuesta.tipo,
    },
  ],
};

export const getTitulo = (propuesta, tipo) => {
  if (!propuesta || !tipo) return "Sin título disponible";

  const isPlan = tipo === "PLAN";
  const nombreSolicitante = propuesta?.nombreSolicitante;
  const nombreCliente = propuesta?.cliente?.nombre;

  const clienteNombre = isPlan
    ? capitalizarPalabras(nombreSolicitante ?? "Sin datos cliente")
    : capitalizarPalabras(nombreCliente ?? "Sin datos cliente");

  const tipoPropuesta = isPlan ? "mensual" : "tarea especial";

  return `Propuesta ${tipoPropuesta} para ${clienteNombre}`;
};

export default function VerPropuesta() {
  const navigate = useNavigate();
  const { id, tipo } = useParams();
  const { empleado: user } = useSelector((state) => state.auth.user);
  const {
    data: propuesta,
    isLoading: cargandoPropuesta,
    refetch,
  } = propuestasApi.useGetPropuestaQuery({ id, tipo });

  const [value, setValue] = useState(0);

  const titulo = useMemo(() => getTitulo(propuesta, tipo), [propuesta, tipo]);
  const currentTabs = tipo === "PLAN" ? TAB_CONFIGS.PLAN : TAB_CONFIGS.TAREA;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useHistorialVisita(user);

  if (!propuesta || cargandoPropuesta) {
    return cargandoPropuesta ? <LoadingComponent /> : <Page404 />;
  }

  return (
    <Page title="TAXTIC">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Button
            variant="contained"
            color="info"
            startIcon={<Icon icon={arrowFill} />}
            onClick={() => navigate("/dashboard/propuestas/listar")}
          >
            Volver
          </Button>
          <Typography variant="h4" gutterBottom>
            {titulo}
          </Typography>
          <Box />
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Tabs proposal"
            >
              {currentTabs.map((tab, index) => (
                <Tab key={tab.label} label={tab.label} {...a11yProps(index)} />
              ))}
            </Tabs>
          </Box>

          {currentTabs.map((tab, index) => (
            <TabPanel key={index} value={value} index={index}>
              <div style={{ marginTop: "10px" }}>
                <tab.component
                  propuesta={propuesta}
                  refetch={refetch}
                  tipo={tab.tipo ? tab.tipo(propuesta) : undefined}
                  isDisabled={
                    tab.isDisabled ? tab.isDisabled(propuesta) : false
                  }
                />
              </div>
            </TabPanel>
          ))}
        </Box>
      </Container>
    </Page>
  );
}

const LoadingComponent = () => (
  <Page title="Cargando...">
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Skeleton variant="rectangular" width={100} height={36} />
        <Skeleton variant="text" width={300} height={40} />
        <Box />
      </Stack>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Skeleton variant="rectangular" width="100%" height={48} />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Skeleton variant="rectangular" width="100%" height={400} />
        </Box>
      </Box>
    </Container>
  </Page>
);
