import { AppBar, Container, Tab, Tabs, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import Page from "src/components/Page";
import TablaPropuestas from "src/components/propuesta/TablaPropuestas";
import { Titulo } from "src/components/Titulo";
import ModalSubirArchivo from "src/components/Modal/propuestas/Modal-subir-archivo";
import TablaPropuestasTareasEspeciales from "src/components/propuesta/TablaPropuestasTareasEspeciales";
import ModalEnvioPropuestaCliente from "src/components/Modal/propuestas/Modal-envio-propuesta-cliente";

export default function Propuestas() {
  const [tabIndex, setTabIndex] = useState(0);
  const [abrirModalArchivo, setAbrirModalArchivo] = useState(false);
  const [openModalSendProposalClient, setOpenModalSendProposalClient] =
    useState(false);
  const [propuestaSeleccionada, setPropuestaSeleccionada] = useState(null);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    localStorage.setItem("propuestasTabIndex", newValue);
  };

  useEffect(() => {
    const savedTabIndex = localStorage.getItem("propuestasTabIndex");
    if (savedTabIndex !== null) {
      setTabIndex(Number(savedTabIndex));
    } else {
      setTabIndex(0);
    }
  }, []);

  const handleUploadFile = useCallback((propuesta) => {
    setPropuestaSeleccionada(propuesta);
    setAbrirModalArchivo(true);
  }, []);

  const handleSendMailClient = useCallback((propuesta) => {
    setPropuestaSeleccionada(propuesta);
    setOpenModalSendProposalClient(true);
  }, []);

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Typography variant="h4" gutterBottom>
            Propuestas
          </Typography>
        </Titulo>
        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
            aria-label="tabs-configuracion-liquidaciones"
          >
            <Tab label="Propuestas de planes" />
            <Tab label="Propuestas de tareas especiales" />
          </Tabs>
        </AppBar>
        <TablaPropuestas
          title="Propuestas de planes"
          subheader="Se muestran las propuestas de planes"
          onUploadFile={handleUploadFile}
          onSendMailClient={handleSendMailClient}
          tabIndex={tabIndex}
        />
        <TablaPropuestasTareasEspeciales
          title="Propuestas de tareas especiales"
          subheader="Se muestran las propuestas de tareas especiales"
          onUploadFile={handleUploadFile}
          onSendMailClient={handleSendMailClient}
          tabIndex={tabIndex}
        />
      </Container>
      {abrirModalArchivo && (
        <ModalSubirArchivo
          open={abrirModalArchivo}
          setOpen={setAbrirModalArchivo}
          propuesta={propuestaSeleccionada}
          onSendMailClient={handleSendMailClient}
        />
      )}
      {openModalSendProposalClient && (
        <ModalEnvioPropuestaCliente
          open={openModalSendProposalClient}
          setOpen={setOpenModalSendProposalClient}
          propuesta={propuestaSeleccionada}
          fullWidth={true}
          maxWidth="md"
        />
      )}
    </Page>
  );
}
