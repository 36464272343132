import React, { useRef, useState, useEffect, useCallback } from "react";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import * as locales from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  TareasListHead,
  TareasListToolbar,
} from "../components/_dashboard/tareas";
import { Titulo } from "src/components/Titulo";
import ModifyTaskStateModal from "../components/_dashboard/tareas/ModifyTaskStateModal";
import { Alerta } from "../components/Alerta";
import { dbx } from "../utils/urlBack_End";
import { formatDate } from "../utils/converter";
import SimpleModal from "../layouts/SimpleModal";
import ArchivosAdjuntosDialog from "../components/tareas/ArchivosAdjuntosDialog";

import SubTareasDialog from "../components/tareas/SubTareasDialog";

import ModalFiltro from "src/components/ModalFiltro";
import ExportToExcelSubTareas from "src/components/ExportToExcelSubTareas";
import TabsComponente from "src/components/TabsComponente";
import SolicitarCambioEstadoDialog from "src/components/tareas/SolicitarCambioEstadoDialog";
import ExportToExcelTareas from "src/components/ExportToExcelTareas";
import { useSelector } from "react-redux";
import moment from "moment";
import { tareasApi } from "../services/tareasApi";
import { empleadosApi } from "../services/empleadosApi";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import CantidadRemuneracionesDialog from "src/components/tareas/CantidadRemuneracionesDialog";
import { useSnackbar } from "src/components/snackbar";

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function applySortFilter(rolObtenido, array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_el) => {
      if (
        rolObtenido === "ADMIN" ||
        rolObtenido === "SUPER_ADMIN" ||
        rolObtenido === "SUPERVISOR"
      ) {
        return (
          _el.fecha.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          removeAccents(_el.nombre.toLowerCase()).indexOf(
            removeAccents(query.toLowerCase())
          ) !== -1 ||
          removeAccents(_el.tipoTarea.toLowerCase()).indexOf(
            removeAccents(query.toLowerCase())
          ) !== -1 ||
          removeAccents(_el.nombreCliente.toLowerCase()).indexOf(
            removeAccents(query.toLowerCase())
          ) !== -1 ||
          removeAccents(_el.nombreEmpleado.toLowerCase()).indexOf(
            removeAccents(query.toLowerCase())
          ) !== -1 ||
          _el.fechaInicio.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          _el.fechaTermino.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          removeAccents(_el.estado.toLowerCase()).indexOf(
            removeAccents(query.toLowerCase())
          ) !== -1
        );
      } else {
        return (
          _el.fecha.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          removeAccents(_el.nombre.toLowerCase()).indexOf(
            removeAccents(query.toLowerCase())
          ) !== -1 ||
          removeAccents(_el.tipoTarea.toLowerCase()).indexOf(
            removeAccents(query.toLowerCase())
          ) !== -1 ||
          removeAccents(_el.nombreCliente.toLowerCase()).indexOf(
            removeAccents(query.toLowerCase())
          ) !== -1 ||
          _el.fechaInicio.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          _el.fechaTermino.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          removeAccents(_el.estado.toLowerCase()).indexOf(
            removeAccents(query.toLowerCase())
          ) !== -1
        );
      }
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Tareas() {
  const navigate = useNavigate();
  const isMounted = useRef(true);
  const {
    empleado: user,
    sucursal: sucursalObtenida,
    rol: rolObtenido,
  } = useSelector((state) => state.auth.user);
  const [page, setPage] = useState(
    localStorage.getItem("pageTask")
      ? parseInt(localStorage.getItem("pageTask"), 10)
      : 0
  );
  const [filtroAplicado, setFiltroAplicado] = useState(false);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("fechaTermino");
  const [filterName, setFilterName] = useState(
    localStorage.getItem("searchTask") ? localStorage.getItem("searchTask") : ""
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowPerPageTask")
      ? parseInt(localStorage.getItem("rowPerPageTask"), 10)
      : 10
  );
  const [locale] = useState("esES");
  const [tareas, setTareas] = useState([]);
  const [showModifyTaskState, setShowModifyTaskState] = useState(false);
  const [showCantidadRemuneraciones, setShowCantidadRemuneraciones] =
    useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [specificTask, setSpecificTask] = useState(null);
  const tableHead = useState(defineTableHeader())[0];
  const tableHead2 = useState(defineTableHeader2())[0];
  const [color, setColor] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingUpdateId, setLoadingUpdateId] = useState(null);

  const [guardandoDatos, setGuardandoDatos] = useState(false);

  const [mostrarSubTareas, setMostrarSubTareas] = useState(false);
  const [mostrarSolicitarCambioEstado, setMostrarSolicitarCambioEstado] =
    useState(false);
  const [tareaSeleccionada, setTareaSeleccionada] = useState(null);
  const [idSubTarea, setIdSubtarea] = useState(null);

  const [filtrar, setFiltrar] = useState(false);
  const [cabecera, setCabecera] = useState({});

  const [actualizarEstadoTarea] = tareasApi.useUpdateCambiaEstadoMutation();
  const [eliminarTarea] = tareasApi.useDeleteTareaMutation();

  const {
    data: tareasAux = [],
    isLoading: loadingTable,
    refetch: recargarTareas,
  } = tareasApi.useGetTareasAllQuery({
    id: sucursalObtenida.refSucursal,
    refEmpleado: user.rut,
    rolObtenido: rolObtenido,
  });
  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [addHistorialAcciones, user.rut]);

  function defineTableHeader() {
    const tableHeadAux = [
      // { id: 'id', label: 'ID', alignRight: false },
      { id: "nombreCliente", label: "Nombre Cliente", alignRight: false },
      { id: "nombre", label: "Tarea", alignRight: false },
      { id: "tipoTarea", label: "Tipo Tarea", alignRight: false },
      { id: "fechaInicio", label: "Fecha Inicio", alignRight: false },
      { id: "fechaTermino", label: "Fecha Término", alignRight: false },
      {
        id: "diasLimites",
        label: "Días Limites",
        alignRight: false,
      },
      {
        id: "diasTranscurridos",
        label: "Días Transcurridos",
        alignRight: false,
      },
      { id: "estado", label: "Estado", alignRight: false },
      { id: "subtareas", label: "", alignRight: false },
      { id: "editar", label: "", alignRight: false },
      { id: "eliminar", label: "", alignRight: false },
    ];

    if (
      rolObtenido === "ADMIN" ||
      rolObtenido === "SUPER_ADMIN" ||
      rolObtenido === "SUPERVISOR"
    ) {
      tableHeadAux.splice(
        0, // start at index 0
        0, // remove 0 items
        // add the following item
        {
          id: "nombreEmpleado",
          label: "Nombre Empleado Principal",
          alignRight: false,
        }
      );
    }
    return tableHeadAux;
  }

  function defineTableHeader2() {
    const tableHeadAux = [
      // { id: 'id', label: 'ID', alignRight: false },
      { id: "nombreCliente", label: "Nombre Cliente", alignRight: false },
      { id: "nombre", label: "Tarea", alignRight: false },
      { id: "tipoTarea", label: "Tipo Tarea", alignRight: false },
      { id: "fechaInicio", label: "Fecha Inicio", alignRight: false },
      { id: "fechaTermino", label: "Fecha Término", alignRight: false },
      { id: "estado", label: "Estado", alignRight: false },
    ];

    if (
      rolObtenido === "ADMIN" ||
      rolObtenido === "SUPER_ADMIN" ||
      rolObtenido === "SUPERVISOR"
    ) {
      tableHeadAux.splice(
        0, // start at index 0
        0, // remove 0 items
        // add the following item
        {
          id: "nombreEmpleado",
          label: "Nombre Empleado Principal",
          alignRight: false,
        }
      );
    }
    return tableHeadAux;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tareas.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    localStorage.setItem("pageTask", parseInt(newPage, 10));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem("rowPerPageTask", parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    localStorage.setItem("searchTask", event.target.value);
    setFilterName(event.target.value);
  };

  const handleDelete = (id) => {
    AlertaModal({
      title: "eliminar la tarea",
      confirmButtonText: "Sí, eliminar",
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          eliminarTarea(id)
            .unwrap()
            .then(async () => {
              AlertaToast({
                element: "Tarea",
                action: "eliminada",
              }).fire();
              try {
                await dbx.filesDeleteV2({ path: `/Tareas/${id}` });
              } catch {}
              setFiltroAplicado(false);
              recargarTareas();
              setSelectedTask(null);
            })
            .catch(() => {
              AlertaToast({
                action: "No se pudo actualizar la tarea en la base de datos.",
                fullText: true,
                type: "error",
              }).fire();
              setSelectedTask(null);
            });
        }
      });
  };

  const handleChangeTaskState = (id) => {
    const selectedTaskAux = tareas.find((t) => t.id === id);
    setSelectedTask(selectedTaskAux);
    setShowModifyTaskState(true);
  };

  const doChangeTaskState = (newState) => {
    setLoadingUpdateId(selectedTask.id);
    if (selectedTask.estado === newState) return setShowModifyTaskState(false);
    let modificacionEstado = {
      ...selectedTask,
      estado: newState,
    };
    if (
      newState === "TERMINADA" &&
      (selectedTask.tipoTarea === "MES" || selectedTask.tipoTarea === "AÑO") &&
      String(selectedTask.nombre)
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes("REMUNERACION")
    ) {
      setSelectedTask(modificacionEstado);
      setShowModifyTaskState(false);
      return setShowCantidadRemuneraciones(true);
    }
    if (newState === "TERMINADA") {
      if (selectedTask.tipoTarea === "ESPECIAL") {
        modificacionEstado.estado = "PENDIENTE DE FACTURACION";
      }
      modificacionEstado.fechaTermino = getToday();
    } else {
      modificacionEstado.fechaTermino = "";
    }
    setShowModifyTaskState(false);
    updateTaskInDB(modificacionEstado);
  };

  const getToday = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getUTCFullYear();
    return formatDate(`${year}/${month}/${day}`);
  };

  const updateTaskInDB = useCallback(
    async (modificacionEstado) => {
      try {
        await actualizarEstadoTarea({
          id: modificacionEstado.id,
          body: {
            estado: modificacionEstado.estado,
            fechaTermino: modificacionEstado.fechaTermino,
          },
        }).unwrap();
        enqueueSnackbar("Estado tarea actualizado correctamente", {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        setFiltroAplicado(false);
        setSelectedTask(null);
      } catch (error) {
        console.error("updateTaskInDB", error);
        enqueueSnackbar(
          error?.data?.message ||
            error.message ||
            "Ocurrió un error al actualizar el estado de la tarea",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
        setSelectedTask(null);
      } finally {
        setLoadingUpdateId(null);
      }
    },
    [actualizarEstadoTarea, enqueueSnackbar]
  );

  const updateTaskInDBRemuneraciones = (modificacionEstado) => {
    actualizarEstadoTarea({
      id: modificacionEstado.id,
      body: {
        estado: modificacionEstado.estado,
        fechaTermino: modificacionEstado.fechaTermino,
        cantidadRemuneraciones: modificacionEstado.cantidadFactura,
      },
    })
      .unwrap()
      .then(() => {
        AlertaToast({
          element: "Tarea",
          action: "actualizada",
        }).fire();
        setFiltroAplicado(false);
        recargarTareas();
        setSelectedTask(null);
      })
      .catch((error) => {
        const { data: dataError } = error;
        if (dataError.statusCode === 404) {
          AlertaToast({
            action: dataError.message,
            fullText: true,
            type: "error",
          }).fire();
        } else {
          AlertaToast({
            action: "No se pudo actualizar la tarea en la base de datos.",
            fullText: true,
            type: "error",
          }).fire();
        }

        setSelectedTask(null);
      })
      .finally(() => {
        setGuardandoDatos(false);
      });
  };

  const handleAttachment = (id) => {
    setSpecificTask(id);
    setShowAttachments(true);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tareas.length) : 0;

  const filteredItems = applySortFilter(
    rolObtenido,
    tareas,
    getComparator(order, orderBy),
    filterName
  );

  const isElementNotFound = filteredItems.length === 0;

  useEffect(() => {
    if (!loadingTable && !filtroAplicado) {
      setTareas(tareasAux);
    }
  }, [loadingTable, tareasAux, tareas, filtroAplicado]);

  const renderTableBody = () => {
    return filteredItems
      .filter(
        (e) =>
          e.estado !== "TERMINADA" &&
          e.estado !== "FACTURADO" &&
          e.estado !== "CANCELADO O RECHAZADO"
      )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row) => {
        const {
          id,
          nombre,
          tipoTarea,
          nombreCliente,
          nombreEmpleado,
          fechaInicio,
          fechaTermino,
          estado,
          diasTranscurridos,
          diasLimites,
        } = row;

        const isItemSelected = selected.indexOf(id) !== -1;
        const isLoading = loadingUpdateId === id;

        let estiloFila = { backgroundColor: "#FFFFFF" };
        if (diasLimites === "0") {
          estiloFila = { backgroundColor: "#FFCDD2" };
        } else if (diasLimites === "" && diasTranscurridos >= 14) {
          estiloFila = { backgroundColor: "#FFF9C4" };
        }

        return (
          <TableRow
            hover
            key={id}
            tabIndex={-1}
            role="checkbox"
            selected={isItemSelected}
            aria-checked={isItemSelected}
            style={estiloFila}
          >
            {isLoading ? (
              <TableCell colSpan={10} align="center">
                <CircularProgress size={24} />
              </TableCell>
            ) : (
              <>
                {(rolObtenido === "SUPER_ADMIN" ||
                  rolObtenido === "ADMIN" ||
                  rolObtenido === "SUPERVISOR") && (
                  <TableCell
                    onClick={() => {
                      navigate("/dashboard/tareas/" + id);
                    }}
                    align="left"
                    style={{ cursor: "pointer" }}
                  >
                    {nombreEmpleado}
                  </TableCell>
                )}
                <TableCell
                  onClick={() => {
                    navigate("/dashboard/tareas/" + id);
                  }}
                  style={{ cursor: "pointer" }}
                  align="left"
                >
                  {nombreCliente}
                </TableCell>
                <TableCell
                  align="left"
                  onClick={() => {
                    navigate("/dashboard/tareas/" + id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {nombre}
                </TableCell>
                <TableCell
                  align="left"
                  onClick={() => {
                    navigate("/dashboard/tareas/" + id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {tipoTarea}
                </TableCell>
                <TableCell
                  align="left"
                  onClick={() => {
                    navigate("/dashboard/tareas/" + id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {moment(fechaInicio, "YYYY/MM/DD").format("DD/MM/YYYY")}
                </TableCell>
                <TableCell
                  align="left"
                  onClick={() => {
                    navigate("/dashboard/tareas/" + id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {fechaTermino === ""
                    ? fechaTermino
                    : moment(fechaTermino, "YYYY/MM/DD").format(
                        "DD/MM/YYYY"
                      ) !== "Invalid date"
                    ? moment(fechaTermino).format("DD/MM/YYYY")
                    : fechaTermino}
                </TableCell>
                <TableCell
                  align="left"
                  onClick={() => {
                    navigate("/dashboard/tareas/" + id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {diasLimites}
                </TableCell>
                <TableCell
                  align="left"
                  onClick={() => {
                    navigate("/dashboard/tareas/" + id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {diasTranscurridos === 1
                    ? diasTranscurridos + " Día"
                    : diasTranscurridos + " Días"}
                </TableCell>
                <TableCell align="left">
                  <Label
                    onClick={() => handleChangeTaskState(id)}
                    style={{ cursor: "pointer" }}
                    variant="ghost"
                    color={
                      (estado === "PENDIENTE" && "error") ||
                      (estado === "EN PROCESO" && "warning") ||
                      (estado === "TERMINADA" && "success") ||
                      "info"
                    }
                  >
                    {sentenceCase(estado)}
                  </Label>
                  {estado !== "TERMINADA" &&
                    estado !== "PENDIENTE DE FACTURACION" &&
                    estado !== "FACTURADO" && (
                      <Tooltip title="Adjuntar">
                        <IconButton onClick={() => handleAttachment(row)}>
                          <AttachmentIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                </TableCell>
                <TableCell align="left">
                  <Label
                    onClick={() => {
                      setIdSubtarea(row);
                      setMostrarSubTareas(true);
                    }}
                    style={{ cursor: "pointer" }}
                    variant="ghost"
                    color={"info"}
                  >
                    {sentenceCase("Subtareas")}
                  </Label>
                </TableCell>
                <TableCell align="right">
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {(rolObtenido === "SUPER_ADMIN" ||
                      rolObtenido === "ADMIN") &&
                      estado !== "TERMINADA" &&
                      estado !== "PENDIENTE DE FACTURACION" &&
                      estado !== "FACTURADO" && (
                        <Tooltip title="Eliminar">
                          <IconButton
                            color="error"
                            onClick={() => handleDelete(id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                  </Grid>
                </TableCell>
              </>
            )}
          </TableRow>
        );
      });
  };

  const renderTableBodyTerminada = () => {
    return filteredItems
      .filter(
        (e) =>
          e.estado === "TERMINADA" ||
          e.estado === "FACTURADO" ||
          e.estado === "CANCELADO O RECHAZADO"
      )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row) => {
        const {
          id,
          nombre,
          tipoTarea,
          nombreCliente,
          nombreEmpleado,
          fechaInicio,
          fechaTermino,
          estado,
        } = row;
        const isItemSelected = selected.indexOf(id) !== -1;

        return (
          <TableRow
            hover
            key={id}
            tabIndex={-1}
            role="checkbox"
            selected={isItemSelected}
            aria-checked={isItemSelected}
          >
            {(rolObtenido === "SUPER_ADMIN" ||
              rolObtenido === "ADMIN" ||
              rolObtenido === "SUPERVISOR") && (
              <TableCell
                onClick={() => {
                  navigate("/dashboard/tareas/" + id);
                }}
                align="left"
                style={{ cursor: "pointer" }}
              >
                {nombreEmpleado}
              </TableCell>
            )}
            <TableCell
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
              style={{ cursor: "pointer" }}
              align="left"
            >
              {nombreCliente}
            </TableCell>
            <TableCell
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
              style={{ cursor: "pointer" }}
            >
              {nombre}
            </TableCell>
            <TableCell
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
              style={{ cursor: "pointer" }}
            >
              {tipoTarea}
            </TableCell>
            <TableCell
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
              style={{ cursor: "pointer" }}
            >
              {moment(fechaInicio, "YYYY/MM/DD").format("DD/MM/YYYY")}
            </TableCell>
            <TableCell
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
              style={{ cursor: "pointer" }}
            >
              {fechaTermino === ""
                ? fechaTermino
                : moment(fechaTermino, "YYYY/MM/DD").format("DD/MM/YYYY") !==
                  "Invalid date"
                ? moment(fechaTermino).format("DD/MM/YYYY")
                : fechaTermino}
            </TableCell>
            <TableCell align="left">
              <Label
                onClick={() => {
                  if (
                    rolObtenido === "SUPER_ADMIN" ||
                    rolObtenido === "ADMIN"
                  ) {
                    handleChangeTaskState(id)
                    // setTareaSeleccionada(row);
                    // setMostrarSolicitarCambioEstado(true);
                  } else {
                    setTareaSeleccionada(row);
                    setMostrarSolicitarCambioEstado(true);
                  }
                }}
                style={{ cursor: "pointer" }}
                variant="ghost"
                color={
                  (estado === "PENDIENTE" && "error") ||
                  (estado === "EN PROCESO" && "warning") ||
                  (estado === "TERMINADA" && "success") ||
                  "info"
                }
              >
                {sentenceCase(estado)}
              </Label>
            </TableCell>

            {/* <TableCell align="left">
              <div
                onClick={() => {
                  setSelectedTask(tareas.find((t) => t.id === id));
                  setShowModifyTaskTag(true);
                }}
              >
                <Tooltip title="Etiquetas">
                  <IconButton>
                    <LocalOfferIcon />
                  </IconButton>
                </Tooltip>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  spacing={0.5}
                >
                  {etiqueta &&
                    etiqueta.split("|").map((t, idx) => (
                      <Grid item key={idx} xs={4}>
                        <div
                          style={{
                            width: 15,
                            height: 15,
                            borderRadius: 7,
                            border: "1px solid",
                            backgroundColor: etiquetas.find(
                              (et) => et.nombreEtiqueta === t
                            )?.color,
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </div>
            </TableCell> */}
          </TableRow>
        );
      });
  };

  const tablaTodoTarea = () => {
    return (
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={5} md={5}>
            <TareasListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <ExportToExcelTareas
              data={tareasAux}
              filename="tareas"
              sheetName="Tareas"
              setShowAlert={setShowAlert}
              setColor={setColor}
              setMessage={setError}
              type="objects"
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <ExportToExcelSubTareas
              data={tareasAux}
              filename="subtareas"
              sheetName="Subtareas"
              setShowAlert={setShowAlert}
              setColor={setColor}
              setMessage={setError}
              empleado={user.rut}
              tipoEmpleado={rolObtenido === "EMPLEADO" ? rolObtenido : "OTRO"}
              sucursal={sucursalObtenida.refSucursal}
            />
          </Grid>
        </Grid>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            {!loadingTable ? (
              <Table>
                <TareasListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHead}
                  rowCount={tareas.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  setCabecera={setCabecera}
                  setFiltrar={setFiltrar}
                />
                <TableBody>
                  {renderTableBody()}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isElementNotFound && tareas.length > 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {tareas.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No hay tareas que mostrar.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            ) : (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </TableContainer>
        </Scrollbar>
        <ThemeProvider
          theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={tareas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      </Card>
    );
  };

  const tablaTareaTerminada = () => {
    return (
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={5} md={5}>
            <TareasListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </Grid>

          {/*<Grid item xs={12} sm={5}>
               {etiquetas.length > 0 && (
                <Box mt={3} mr={2} ml={2} maxWidth="90%" minWidth="30%">
                  <FilterSelector
                    label="Filtrar por etiquetas"
                    options={etiquetas}
                    title="nombreEtiqueta"
                    onChange={handleTagChange}
                  />
                </Box>
              )}
            </Grid> */}
          <Grid item xs={3} md={3}>
            <ExportToExcelTareas
              data={tareas}
              filename="tareas"
              sheetName="Tareas"
              setShowAlert={setShowAlert}
              setColor={setColor}
              setMessage={setError}
              type="objects"
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <ExportToExcelSubTareas
              filename="subtareas"
              sheetName="Subtareas"
              setShowAlert={setShowAlert}
              setColor={setColor}
              setMessage={setError}
              empleado={user.rut}
              tipoEmpleado={rolObtenido === "EMPLEADO" ? rolObtenido : "OTRO"}
              sucursal={sucursalObtenida.refSucursal}
            />
          </Grid>
        </Grid>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            {!loadingTable ? (
              <Table>
                <TareasListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHead2}
                  rowCount={tareas.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  setCabecera={setCabecera}
                  setFiltrar={setFiltrar}
                />
                <TableBody>
                  {renderTableBodyTerminada()}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isElementNotFound && tareas.length > 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {tareas.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No hay tareas que mostrar.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            ) : (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </TableContainer>
        </Scrollbar>
        <ThemeProvider
          theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={tareas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      </Card>
    );
  };

  const handleAplicarFiltro = (data, tipo) => {
    if (tipo === "letras" || tipo === "defecto") {
      setFiltroAplicado(true);
      setTareas(data);
    } else {
      setFiltroAplicado(false);
      setTareas(tareasAux);
    }
  };
  const encabezados = ["Tareas", "Tareas Terminadas"];
  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Typography variant="h4" gutterBottom>
            Tareas
          </Typography>
        </Titulo>
        <TabsComponente
          encabezados={encabezados}
          tabs={[tablaTodoTarea(), tablaTareaTerminada()]}
        />

        {selectedTask && showCantidadRemuneraciones && (
          <CantidadRemuneracionesDialog
            open={showCantidadRemuneraciones}
            setOpen={setShowCantidadRemuneraciones}
            tarea={selectedTask}
            updateTaskInDBRemuneraciones={updateTaskInDBRemuneraciones}
            guardandoDatos={guardandoDatos}
            setGuardandoDatos={setGuardandoDatos}
          />
        )}

        {selectedTask && (
          <ModifyTaskStateModal
            task={selectedTask}
            onSubmit={doChangeTaskState}
            onClose={() => setShowModifyTaskState(false)}
            states={[
              "PENDIENTE",
              "EN PROCESO",
              "OBSERVADA SII",
              "TERMINADA",
              "FACTURADO",
              "CANCELADO O RECHAZADO",
            ]}
            open={showModifyTaskState}
            cargandoDatos={false}
          />
        )}

        {/*selectedTask && (
          <ModifyTaskTagModal
            task={selectedTask}
            onSubmit={doChangeTaskTags}
            onClose={() => setShowModifyTaskTag(false)}
            tags={etiquetas}
            open={showModifyTaskTag}
            setTask={(t) => {
              const filtered = tareas.filter(
                (tarea) => tarea.id !== selectedTask.id
              );
              setTareas([...filtered], selectedTask);
              setSelectedTask(t);
            }}
          />
          )*/}

        <Alerta
          showAlert={showError}
          setShowAlert={setShowError}
          message={error}
          color="error"
        />

        {showAlert && (
          <Alerta
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            message={error}
            color={color}
          />
        )}

        {specificTask && (
          <SimpleModal
            maxWidth="md"
            open={showAttachments}
            showAcceptButton={false}
            showCancelButton={false}
            title="Archivo Adjunto"
          >
            <ArchivosAdjuntosDialog
              tarea={specificTask}
              setShowError={setShowError}
              setError={(msg) => {
                setError(msg);
                setShowError(true);
              }}
              onClose={() => setShowAttachments(false)}
            />
          </SimpleModal>
        )}

        {mostrarSubTareas && (
          <SubTareasDialog
            open={mostrarSubTareas}
            setOpen={setMostrarSubTareas}
            tarea={idSubTarea}
          />
        )}
        {filtrar && (
          <ModalFiltro
            cabecera={cabecera}
            open={filtrar}
            setOpen={setFiltrar}
            info={tareas}
            onSubmit={handleAplicarFiltro}
          />
        )}
        {mostrarSolicitarCambioEstado && (
          <SolicitarCambioEstadoDialog
            open={mostrarSolicitarCambioEstado}
            setOpen={setMostrarSolicitarCambioEstado}
            tarea={tareaSeleccionada}
          />
        )}
      </Container>
    </Page>
  );
}
