const COMMOND_FIELDS = {
  vendedor: null,
  supervisor: null,
  antecedentes: "",
  clienteExistente: true,
  sinRutCliente: false,
  cliente: null,
  rutCliente: "",
  nombreCliente: "",
  correoPersonalCliente: "",
  telefonoCliente: "",
  comunaCliente: "",
  direccionCliente: "",
  empleadosAdicionales: [],
  observaciones: "",
};

export const INITIAL_VALUES_PROPOSAL_PLAN = {
  ...COMMOND_FIELDS,
  sinRutSolicitante: false,
  rutSolicitante: "",
  nombreSolicitante: "",
  correoSolicitante: "",
  telefonoSolicitante: "",
  planesSeleccionados: [],
};

export const INITIAL_VALUES_PROPOSAL_TASK = {
  ...COMMOND_FIELDS,
  vendidoTaxtic: false,
  encargado: null,
  fechaLimite: null,
  tareaExistente: true,
  tarea: null,
  nombreTarea: "",
  tipoMoneda: null,
  precioFijo: 0,
  descuento: 0,
  precioFinal: 0,
  subtareas: [],
};
