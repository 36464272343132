import { useRef, useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { API_SERVER } from "../utils/urlBack_End";
import { Alerta } from "../components/Alerta";
import axios from "axios";

// material
import {
  Card,
  Grid,
  CardContent,
  Container,
  Typography,
  CardActionArea,
  CardMedia,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
// components
import Page from "../components/Page";
import AgregarTareaInterna from "src/components/tareainterna/AgregarTareaInterna";
import AgregarTareaMasiva from "src/components/tareamasiva/AgregarTareaMasiva";
import { Titulo } from "src/components/Titulo";
import AgregarTareaMeson from "../components/tarea/AgregarTareaMeson";
import NuevaPropuesta from "src/components/NuevaPropuesta/NuevaPropuesta";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";
import handleError from "src/utils/manejo-errores";
const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    minWidth: "100px",
  },
  media: {
    height: 0,
    paddingTop: "100%", // 16:9
  },
}));

export default function NuevaTarea() {
  const [openTareaNormal, setOpenTareaNormal] = useState(false);
  const [openTareaInterna, setOpenTareaInterna] = useState(false);
  const [openTareaMasiva, setOpenTareaMasiva] = useState(false);
  const [monedas, setMonedas] = useState([]);
  const [color, setColor] = useState("error");
  const [message, setMessage] = useState("");
  const [alerta, setAlerta] = useState(false);
  const classes = useStyles();
  const { empleado: user, rol: rolObtenido } = useSelector(
    (state) => state.auth.user
  );
  const isMounted = useRef(true);

  const [informacionTareas, setInformacionTareas] = useState(null);

  const [mostrarInformacionTarea, setMostrarInfromacionTareas] =
    useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  const crearAccionEmpleado = useCallback(
    async (rut, url) => {
      try {
        await addHistorialAcciones({
          user: rut,
          body: {
            urlAccion: url,
          },
        });
      } catch (error) {
        handleError("crearAccionesEmpleado (nuevaTarea)", error);
      }
    },
    [addHistorialAcciones]
  );

  useEffect(() => {
    const urlActual = window.location.href;
    if (!urlActual.includes("http://localhost:3000")) {
      crearAccionEmpleado(user.rut, urlActual);
    }
  }, [crearAccionEmpleado, user.rut]);

  const [modalPropuesta, setOpenModalPropuesta] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/tipos/tipoMoneda`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setMonedas(response.data);
        }
      });
  }, []);

  const handleClickOpenTareaInterna = () => {
    setOpenTareaInterna(true);
  };

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Typography variant="h4" gutterBottom>
            Nueva Tarea
          </Typography>
          <br />
        </Titulo>
        <Grid container spacing={3}>
          <Grid item xs={6} md={3}>
            <Card>
              <Button
                fullWidth
                color="info"
                variant="contained"
                onClick={() => {
                  setInformacionTareas(
                    "Las tareas internas son tareas que no se cobran"
                  );
                  setMostrarInfromacionTareas(true);
                }}
              >
                Información
              </Button>
              <CardActionArea onClick={handleClickOpenTareaInterna}>
                <CardMedia
                  className={classes.media}
                  image="/static/icons/tareaInterna.svg"
                />
                <CardContent>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    component="p"
                    style={{ textAlign: "center" }}
                  >
                    Interna
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          {(rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN") && (
            <>
              {/* <Grid item xs={6} md={3}>
                <Card >
                  <Button
                    fullWidth
                    color="info"
                    variant="contained"
                    onClick={() => {
                      setInformacionTareas("Las tareas de mesón son tareas rápidas y no necesariamente generan factura.");
                      setMostrarInfromacionTareas(true);
                    }}
                  >
                    Información
                  </Button>
                  <CardActionArea onClick={handleClickOpenTareaNormal}>
                    <CardMedia
                      className={classes.media}
                      image="/static/icons/tarea.svg"
                    />
                    <CardContent>
                      <Typography
                        variant="h4"
                        color="textPrimary"
                        component="p"
                        style={{ textAlign: "center" }}
                      >
                        Mesón
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
                  */}

              {/* <Grid item xs={6} md={3}>
                <Card >
                  <Button
                    fullWidth
                    color="info"
                    variant="contained"
                    onClick={() => {
                      setInformacionTareas("Las tareas masivas son tareas especiales que se les realiza a un conjunto de clientes y generan una factura.");
                      setMostrarInfromacionTareas(true);
                    }}
                  >
                    Información
                  </Button>
                  <CardActionArea onClick={handleClickOpenTareaMasiva}>
                    <CardMedia
                      className={classes.media}
                      image="/static/icons/tareaMasiva.svg"
                    />
                    <CardContent>
                      <Typography
                        variant="h4"
                        color="textPrimary"
                        component="p"
                        style={{ textAlign: "center" }}
                      >
                        Masiva
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                  </Grid>*/}
            </>
          )}
        </Grid>

        {openTareaNormal && (
          <AgregarTareaMeson
            openTareaNormal={openTareaNormal}
            setOpenTareaNormal={setOpenTareaNormal}
            monedas={monedas}
          />
        )}

        {openTareaInterna && (
          <AgregarTareaInterna
            openTareaInterna={openTareaInterna}
            setOpenTareaInterna={setOpenTareaInterna}
          />
        )}

        {modalPropuesta && (
          <NuevaPropuesta
            open={modalPropuesta}
            setOpen={setOpenModalPropuesta}
          />
        )}
        {openTareaMasiva && (
          <AgregarTareaMasiva
            openTareaMasiva={openTareaMasiva}
            setOpenTareaMasiva={setOpenTareaMasiva}
            setAlerta={setAlerta}
            setColor={setColor}
            setMessage={setMessage}
          />
        )}

        {alerta && (
          <Alerta
            showAlert={alerta}
            setShowAlert={setAlerta}
            color={color}
            message={message}
          />
        )}

        {mostrarInformacionTarea && (
          <Dialog
            open={mostrarInformacionTarea}
            onClose={() => {
              setMostrarInfromacionTareas(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>{"Información"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {informacionTareas}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setInformacionTareas("");
                  setMostrarInfromacionTareas(false);
                }}
                autoFocus
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Container>
    </Page>
  );
}
