import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  statusChip: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    fontWeight: 600,
  },
}));

const ProposalStatusChip = ({ proposalStatus }) => {
  const classes = useStyles();

  const statusConfig = {
    ENVIADA: { label: "Pendiente", color: "default" },
    ACEPTADA: { label: "Aceptado Taxtic", color: "success" },
    ACEPTADO_CLIENTE: { label: "Aceptado cliente", color: "success" },
    RECHAZADA: { label: "Rechazado", color: "error" },
    CADUCADA: { label: "Caducado", color: "warning" },
  };

  return proposalStatus !== "ENVIADA" ? (
    <Chip
      label={statusConfig[proposalStatus].label}
      color={statusConfig[proposalStatus].color}
      className={classes.statusChip}
    />
  ) : null;
};

ProposalStatusChip.propTypes = {
  proposalStatus: PropTypes.string,
};

export default React.memo(ProposalStatusChip);
