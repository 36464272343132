import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { ColorlibConnector } from "../styles";
import { useState } from "react";
import ColorlibStepIcon from "../../components/ColorlibStepIcon";
import FormPaper from "../../components/FormPaper";
import { getStepContent, showSwitch } from "../utils/functions";
import { useFormikStepper } from "../hooks/useFormikStepper";
import { SCHEMAS_PROPOSAL_TASK } from "../schemas/proposal-task";
import { INITIAL_VALUES_PROPOSAL_TASK } from "../utils/initialValues";
import { TASK_STEPS } from "../utils/stepsConfig";
import { useSnackbar } from "src/components/snackbar";
import { CheckCircleOutline, ErrorOutline } from "@material-ui/icons";
import { cleanRut } from "@fdograph/rut-utilities";
import { normalizarEspacios } from "src/utils/sharedFunctions";
import { propuestasApi } from "src/services/propuestasApi";

const STEPS = [
  "Encargados",
  "Información de la empresa",
  "Antecedentes",
  "Servicio propuesto",
  "Subtareas",
  "Empleados adicionales",
  "Observaciones",
];

export default function StepperTarea({ handleClose, sucursal }) {
  const [activeStep, setActiveStep] = useState(0);
  const [saveStatus, setSaveStatus] = useState({
    success: false,
    error: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [agregarPropuestaTareaEspecial, { isLoading: guardandoDatos }] =
    propuestasApi.useAddPropuestaTareaEspecialMutation();

  const handleSubmit = async (values) => {
    try {
      setActiveStep((prevStep) => prevStep + 1);
      const {
        empleadosAdicionales,
        cliente, // Si el cliente existe, tiene valor
        rutCliente, // Si no existe el cliente, tendrá valor
        nombreCliente,
        correoPersonalCliente,
        telefonoCliente,
        comunaCliente,
        direccionCliente,
        observaciones,
        vendidoTaxtic,
        vendedor: vendedorAux,
        encargado: { refEmpleado: rutEncargado },
        supervisor: { refEmpleado: rutSupervisor },
        antecedentes,
        clienteExistente,
        sinRutCliente,
        nombreTarea,
        tipoMoneda,
        descuento,
        precioFijo,
        precioFinal,
        subtareas,
        fechaLimite,
        tareaExistente,
        tarea: tareaAux,
      } = values;

      const rutVendedor = vendidoTaxtic ? "TAXTIC" : vendedorAux.refEmpleado;
      const rutEmpresa = !clienteExistente ? rutCliente : cliente?.rut;

      const tarea = {
        nombre: nombreTarea.toUpperCase(),
        tipoMoneda,
        descuento,
        precioFijo,
        precioFinal,
        fechaLimite,
        valorFijo:
          tareaExistente && tareaAux.valor === precioFijo ? "SI" : "NO",
      };

      const body = {
        empleados: empleadosAdicionales.map(
          (empleadoAdicional) => empleadoAdicional.refEmpleado
        ),
        refCliente: cleanRut(rutEmpresa).toUpperCase(),
        correoFacturacion: clienteExistente ? cliente.correoFacturacion : "",
        nombreEmpresa: normalizarEspacios(nombreCliente).toUpperCase(),
        correoEmpresa: normalizarEspacios(correoPersonalCliente).toUpperCase(),
        telefonoEmpresa: telefonoCliente,
        comunaEmpresa: normalizarEspacios(comunaCliente).toUpperCase(),
        direccionEmpresa: normalizarEspacios(direccionCliente).toUpperCase(),
        comentarios: normalizarEspacios(observaciones).toUpperCase(),
        rutEncargado: cleanRut(rutEncargado).toUpperCase(),
        vendedor: cleanRut(rutVendedor).toUpperCase(),
        supervisor: cleanRut(rutSupervisor).toUpperCase(),
        vendidoTaxtic,
        sucursal,
        rutAuxiliar: sinRutCliente,
        antecedentes: normalizarEspacios(antecedentes).toUpperCase(),
        tarea,
        subtareas,
      };

      await agregarPropuestaTareaEspecial(body).unwrap();

      // Manejar el éxito
      setSaveStatus({
        success: true,
        error: null,
      });

      // Mostrar notificación de éxito
      enqueueSnackbar("Propuesta guardada exitosamente", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (error) {
      setSaveStatus({
        success: false,
        error: error.message || "Error al guardar la propuesta",
      });

      enqueueSnackbar("Error al guardar la propuesta", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const { formik, validateStep } = useFormikStepper({
    activeStep,
    initialValues: INITIAL_VALUES_PROPOSAL_TASK,
    onFormSubmit: handleSubmit,
    validationSchemas: SCHEMAS_PROPOSAL_TASK,
  });

  const renderStepContent = (step) => {
    const { component: StepComponent, getProps } = TASK_STEPS[step];

    const stepProps = getProps ? getProps({ sucursal }) : {};

    return <StepComponent formik={formik} {...stepProps} />;
  };

  const handleNext = async () => {
    const isValid = await validateStep(activeStep);
    if (isValid) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      enqueueSnackbar("Por favor, complete todos los campos requeridos", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderFinalStep = () => {
    if (guardandoDatos) {
      return (
        <Paper
          sx={{
            p: 3,
            my: 3,
            minHeight: 120,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
          }}
        >
          <CircularProgress color="secondary" />
          <Typography sx={{ my: 2, textAlign: "center" }}>
            Guardando propuesta, por favor espere...
          </Typography>
        </Paper>
      );
    }

    if (saveStatus.success) {
      return (
        <Paper
          sx={{
            p: 3,
            my: 3,
            minHeight: 120,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: (theme) => alpha(theme.palette.success.light, 0.12),
          }}
        >
          <CheckCircleOutline color="success" sx={{ fontSize: 60, mb: 2 }} />
          <Typography
            variant="h6"
            sx={{ textAlign: "center", color: "success.main" }}
          >
            Propuesta guardada exitosamente
          </Typography>
          <Typography sx={{ textAlign: "center", mt: 1 }}>
            Puede cerrar la ventana.
          </Typography>
        </Paper>
      );
    }

    if (saveStatus.error) {
      return (
        <Paper
          sx={{
            p: 3,
            my: 3,
            minHeight: 120,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: (theme) => alpha(theme.palette.error.light, 0.12),
          }}
        >
          <ErrorOutline color="error" sx={{ fontSize: 60, mb: 2 }} />
          <Typography
            variant="h6"
            sx={{ textAlign: "center", color: "error.main" }}
          >
            Error al guardar la propuesta
          </Typography>
          <Typography sx={{ textAlign: "center", mt: 1 }}>
            {saveStatus.error}
          </Typography>
        </Paper>
      );
    }

    return (
      <Paper
        sx={{
          p: 3,
          my: 3,
          minHeight: 120,
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
        }}
      >
        <Typography sx={{ my: 1 }}>Propuesta completada</Typography>
      </Paper>
    );
  };

  return (
    <>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {STEPS.map((label) => (
          <Step key={label}>
            <StepLabel
              aria-label={`Paso ${label}`}
              StepIconComponent={(props) => (
                <ColorlibStepIcon {...props} type="plan" />
              )}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === STEPS.length ? (
        <>
          {renderFinalStep()}

          <Box sx={{ textAlign: "right" }}>
            {saveStatus.success && (
              <>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleClose}
                  sx={{ mr: 1 }}
                >
                  Terminar
                </Button>
              </>
            )}
          </Box>
        </>
      ) : (
        <>
          <FormPaper
            formik={formik}
            title={getStepContent(activeStep, STEPS)}
            manageSwitch={
              activeStep === 0
                ? showSwitch(
                    true,
                    formik.values.vendidoTaxtic,
                    formik.setFieldValue,
                    "vendidoTaxtic",
                    {
                      checked: "Vendido por Taxtic",
                      unChecked: "Vendido por personal",
                    },
                    {
                      elements: ["vendedor"],
                      defaults: {
                        vendedor: null,
                      },
                    },
                    formik
                  )
                : activeStep === 1
                ? showSwitch(
                    true,
                    formik.values.clienteExistente,
                    formik.setFieldValue,
                    "clienteExistente",
                    {
                      checked: "Cliente existente",
                      unChecked: "Nuevo cliente",
                    },
                    {
                      elements: [
                        "nombreCliente",
                        "correoPersonalCliente",
                        "telefonoCliente",
                        "comunaCliente",
                        "direccionCliente",
                        "cliente",
                      ],
                      defaults: {
                        cliente: null, // Se especifica un valor para el campo cliente en elemnts, caso contrario toma valor ""
                      },
                    },
                    formik
                  )
                : activeStep === 3
                ? showSwitch(
                    true,
                    formik.values.tareaExistente,
                    formik.setFieldValue,
                    "tareaExistente",
                    {
                      checked: "Tarea existente",
                      unChecked: "Nueva tarea",
                    },
                    {
                      elements: [
                        "tarea",
                        "nombreTarea",
                        "tipoMoneda",
                        "precioFijo",
                        "descuento",
                        "fechaLimite",
                      ],
                      defaults: {
                        tipoMoneda: null,
                        descuento: 0,
                        tarea: null,
                        fechaLimite: null,
                      },
                    },
                    formik
                  )
                : null
            }
            manageSwitchClient={
              activeStep === 1
                ? showSwitch(
                    true,
                    formik.values.clienteExistente,
                    formik.setFieldValue,
                    "clienteExistente",
                    {
                      checked: "Cliente existente",
                      unChecked: "Nuevo cliente",
                    },
                    {
                      elements: [
                        "nombreCliente",
                        "correoPersonalCliente",
                        "telefonoCliente",
                        "comunaCliente",
                        "direccionCliente",
                        "cliente",
                      ],
                      defaults: {
                        cliente: null, // Se especifica un valor para el campo cliente en elemnts, caso contrario toma valor ""
                      },
                    },
                    formik
                  )
                : null
            }
          >
            {renderStepContent(activeStep)}
          </FormPaper>
          <Box sx={{ textAlign: "right" }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Volver
            </Button>
            <Button
              variant="contained"
              onClick={
                activeStep === STEPS.length - 1
                  ? formik.handleSubmit
                  : handleNext
              }
              sx={{ mr: 1 }}
            >
              {activeStep === STEPS.length - 1 ? "Guardar" : "Siguiente"}
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
