import PropTypes from "prop-types";
import Iconify from "src/components/iconify";
import teamOutlined from "@iconify/icons-ant-design/team-outlined";
import personOutline from "@iconify/icons-eva/person-outline";
import baselineBusiness from "@iconify/icons-ic/baseline-business";
import taskView from "@iconify/icons-ant-design/solution-outlined";
import baselineComment from "@iconify/icons-ic/baseline-comment";
import fileTextOutlined from "@iconify/icons-ant-design/file-text-outlined";
import peopleOutline from "@iconify/icons-eva/people-outline";
import { useTheme } from "@emotion/react";
import { styled } from "@material-ui/core";

const ColorlibStepIconRoot = styled("div")(({ theme }) => ({
  zIndex: 1,
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.disabled,
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.grey[300]
      : theme.palette.grey[700],
}));

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.number,
  completed: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default function ColorlibStepIcon(props) {
  const theme = useTheme();
  const { active, completed, className, icon, type } = props;

  const icons =
    type === "plan"
      ? {
          1: <Iconify icon={teamOutlined} width={24} />,
          2: <Iconify icon={personOutline} width={24} />,
          3: <Iconify icon={fileTextOutlined} width={24} />,
          4: <Iconify icon={baselineBusiness} width={24} />,
          5: <Iconify icon={taskView} width={24} />,
          6: <Iconify icon={peopleOutline} width={24} />,
          7: <Iconify icon={baselineComment} width={24} />,
        }
      : {};

  return (
    <ColorlibStepIconRoot
      className={className}
      sx={{
        ...(active && {
          boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
          color: "white",
          backgroundImage: `linear-gradient(to right, ${theme.palette.error.light}, ${theme.palette.error.main})`,
        }),
        ...(completed && {
          color: "white",
          backgroundImage: `linear-gradient(to right, ${theme.palette.error.light}, ${theme.palette.error.main})`,
        }),
      }}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}
