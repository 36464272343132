import { Card, CardHeader, styled, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import useChart from "../charts/use-chart";
import Chart from "../charts/chart";
import { useEffect, useRef, useState } from "react";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 400;

const LEGEND_HEIGHT = 72;

const StyledChart = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": {
    height: CHART_HEIGHT,
  },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

GraficoComisionesEmpleado2.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function GraficoComisionesEmpleado2({
  title,
  subheader,
  chart,
  ...other
}) {
  const theme = useTheme();
  const { colors, series, options, typeMoney } = chart;
  const [isResizing, setIsResizing] = useState(false);
  const resizeTimer = useRef(null);

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    colors,
    labels: series.map((i) => i.label),
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: "center" },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value) => `${value}${typeMoney}`,
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "90%",
          labels: {
            value: {
              formatter: (value) => `${value}${typeMoney}`,
            },
            total: {
              formatter: (w) => {
                const sum = (w.globals.seriesTotals || []).reduce(
                  (a, b) => a + b,
                  0
                );
                return `${parseFloat(sum.toFixed(2))}${typeMoney}`;
              },
            },
          },
        },
      },
    },
    ...options,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsResizing(true);
      clearTimeout(resizeTimer.current);
      resizeTimer.current = setTimeout(() => {
        setIsResizing(false);
      }, 300);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(resizeTimer.current);
    };
  }, []);

  const validChartSeries = series
    .map((i) => Number(i.value))
    .filter((i) => !isNaN(i) && i > 0 && i !== null);

  const shouldRenderChart =
    !isResizing &&
    validChartSeries.length > 0 &&
    validChartSeries.every((value) => value !== null);

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <StyledChart dir="ltr">
        {shouldRenderChart && (
          <Chart
            type="donut"
            series={validChartSeries}
            options={chartOptions}
            height={280}
          />
        )}
      </StyledChart>
    </Card>
  );
}
