import Icon from "@iconify/react";
import {
  Dialog,
  DialogContent,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import paperPlaneOutline from "@iconify/icons-eva/paper-plane-outline";
import { useCallback, useState } from "react";
import { capitalizarPalabras } from "src/utils/sharedFunctions";
import { propuestasApi } from "src/services/propuestasApi";
import { useSnackbar } from "src/components/snackbar";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "white",
      position: "relative",
      overflow: "hidden",
    },
  },
  orangeBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "30vh",
    backgroundColor: "#FF6B00",
    zIndex: 0,
  },
  contentContainer: {
    position: "relative",
    zIndex: 1,
    maxWidth: "600px",
    margin: "0 auto",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: theme.spacing(4),
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
    zIndex: 2,
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    "& img": {
      maxWidth: "150px",
      height: "auto",
    },
  },
  title: {
    color: "#00A884",
    fontSize: "24px",
    textAlign: "center",
    margin: `${theme.spacing(3)}px 0`,
    fontWeight: 500,
  },
  staticContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    color: "#333",
  },
  strongStaticContent: {
    backgroundColor: "#f8f9fa",
    padding: theme.spacing(2),
    borderLeft: "4px solid #FF6B00",
    marginBottom: theme.spacing(3),
    fontWeight: 500,
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
    },
    marginBottom: theme.spacing(3),
  },
  actionButton: {
    backgroundColor: "#FF6B00",
    color: "white",
    padding: "12px 24px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 500,
    display: "block",
    margin: `${theme.spacing(3)}px auto`,
    "&:hover": {
      backgroundColor: "#e65c00",
    },
  },
  footer: {
    textAlign: "center",
    color: "#666",
    fontSize: "14px",
    marginTop: theme.spacing(3),
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
    "& .MuiButton-root": {
      margin: theme.spacing(0, 1),
    },
  },
}));

const defaultMessage = `En TAXTIC, nos enorgullece presentar una propuesta diseñada especialmente para ti.

Estamos convencidos de que esta propuesta puede ayudarte a alcanzar tus objetivos de manera más eficiente y efectiva.`;

export default function ModalEnvioPropuestaCliente({
  open,
  setOpen,
  maxWidth,
  fullWidth,
  propuesta,
}) {
  const classes = useStyles();
  const [message, setMessage] = useState(defaultMessage);
  const [enviarPropuestaCliente, { isLoading: enviandoPropuesta }] =
    propuestasApi.useEnviarPropuestaClienteMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(async () => {
    try {
      await enviarPropuestaCliente({ id: propuesta.id, message }).unwrap();
      enqueueSnackbar("Correo enviado correctamente.", {});
      handleClose();
    } catch (error) {
      console.error("handleSubmit (ModaEnvioPropuestaCliente)", error);
      enqueueSnackbar(
        error?.message ||
          error?.data?.message ||
          "Ocurrió un error al enviar la propuesta al cliente, por favor reintente nuevamente.",
        {
          variant: "error",
        }
      );
    }
  }, [
    enqueueSnackbar,
    enviarPropuestaCliente,
    handleClose,
    message,
    propuesta.id,
  ]);

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      onClose={handleClose}
      fullWidth={fullWidth}
      className={classes.dialog}
    >
      <div className={classes.orangeBackground} />
      <IconButton className={classes.closeButton} onClick={handleClose}>
        <Close />
      </IconButton>

      <DialogContent>
        <div className={classes.contentContainer}>
          <div className={classes.header}>
            <img src="/static/logo.png" alt="Taxtic Logo" />
          </div>

          <h1 className={classes.title}>
            Propuesta{" "}
            {capitalizarPalabras(
              propuesta.nombreSolicitante || propuesta.nombreCliente
            )}
          </h1>

          <div className={classes.staticContent}>
            Estimado/a{" "}
            {capitalizarPalabras(
              propuesta.nombreSolicitante || propuesta.nombreCliente
            )}
            ,
          </div>

          <TextField
            className={classes.textField}
            multiline
            fullWidth
            rows={6}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="outlined"
            placeholder="Mensaje para el cliente"
          />

          <div className={classes.staticContent}>
            <p className={classes.strongStaticContent}>
              <strong>
                Para revisar y aceptar esta propuesta, debes hacer clic en el
                botón "Ver propuesta" que aparece más abajo.
              </strong>
            </p>

            <p>{`Si tienes alguna pregunta o necesitas más información, comunícate directamente con ${
              propuesta.correoVendedor || propuesta.correoEmpleado
            } ¡Estaremos encantados de ayudarte!`}</p>
          </div>

          <div className={classes.actions}>
            <LoadingButton
              onClick={handleClose}
              color="error"
              variant="contained"
            >
              Cerrar
            </LoadingButton>
            <LoadingButton
              onClick={handleSubmit}
              startIcon={<Icon icon={paperPlaneOutline} />}
              color="primary"
              variant="contained"
              disabled={enviandoPropuesta}
              loading={enviandoPropuesta}
            >
              {enviandoPropuesta ? "Enviando..." : "Enviar propuesta"}
            </LoadingButton>
          </div>

          <div className={classes.footer}>
            Este es un recordatorio automático del sistema de gestión de tareas.
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
