import { Chip } from "@material-ui/core";
import { AccessTime } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { calcularDiasRestantes } from "src/utils/formatTime";

const useStyles = makeStyles((theme) => ({
  expiredChip: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(14),
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
    "& .MuiChip-icon": {
      color: theme.palette.error.dark,
    },
  },
  timeRemainingChip: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(14),
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
    "& .MuiChip-icon": {
      color: theme.palette.info.dark,
    },
  },
}));

const TimeRemainingChip = ({ proposalData, isExpired }) => {
  const classes = useStyles();

  // Función para calcular días restantes
  const getRemainingDays = useMemo(() => {
    return calcularDiasRestantes(proposalData.expiryDate, "DD/MM/YYYY");
  }, [proposalData.expiryDate]);

  if (isExpired) {
    return (
      <Chip
        icon={<AccessTime />}
        label="Propuesta Expirada"
        className={classes.expiredChip}
      />
    );
  }

  const days = getRemainingDays;

  let label = "";
  if (days === 0) {
    label = "Expira hoy";
  } else if (days === 1) {
    label = "Expira mañana";
  } else {
    label = `Expira en ${days} días`;
  }

  return (
    <Chip
      icon={<AccessTime />}
      label={label}
      className={classes.timeRemainingChip}
    />
  );
};

TimeRemainingChip.propTypes = {
  proposalData: PropTypes.object.isRequired,
  isExpired: PropTypes.bool,
};

export default React.memo(TimeRemainingChip);
