import PropTypes from "prop-types";
import PaperFormProposal from "./PaperFormProposal";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from "@material-ui/core";
import { Form, FormikProvider } from "formik";
import { SwitchDesignCustomized, SwitchDesignCustomizedClient } from "./styles";
import roundAddCircle from "@iconify/icons-ic/round-add-circle";
import Iconify from "src/components/iconify";

FormPaper.propTypes = {
  formik: PropTypes.object.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  manageSwitch: PropTypes.shape({
    show: PropTypes.bool,
    checked: PropTypes.bool,
    onChangeSwitch: PropTypes.func,
    label: PropTypes.string,
  }),
  manageButton: PropTypes.shape({
    show: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.string,
  }),
  manageSwitchRut: PropTypes.shape({
    show: PropTypes.bool,
    checked: PropTypes.bool,
    onChangeSwitch: PropTypes.func,
    label: PropTypes.string,
  }),
};

export default function FormPaper({
  formik,
  children,
  title,
  manageSwitch,
  manageSwitchRut,
  manageButton,
}) {
  return (
    <PaperFormProposal>
      {/* Contenedor principal */}
      <Box>
        {title && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* Título a la izquierda */}
            <Typography variant="h6"> {title} </Typography>

            {/* Contenedor de elementos alineados a la derecha */}
            <Box display="flex" alignItems="center" gap={2}>
              {/* Botón */}
              {manageButton?.show && (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={manageButton.onClick}
                  endIcon={<Iconify icon={roundAddCircle} />}
                >
                  {manageButton.label}
                </Button>
              )}

              {/* Switch RUT cliente */}
              {manageSwitchRut?.show && (
                <FormControlLabel
                  control={
                    <SwitchDesignCustomized
                      sx={{ m: 1 }}
                      checked={manageSwitchRut.checked}
                      onChange={manageSwitchRut.onChangeSwitch}
                      size="small"
                    />
                  }
                  label={manageSwitchRut.label}
                  labelPlacement="start"
                />
              )}

              {/* Switch 2 */}
              {manageSwitch?.show && (
                <FormControlLabel
                  control={
                    <SwitchDesignCustomizedClient
                      sx={{ m: 1 }}
                      checked={manageSwitch.checked}
                      onChange={manageSwitch.onChangeSwitch}
                      size="small"
                    />
                  }
                  label={manageSwitch.label}
                  labelPlacement="start"
                />
              )}
            </Box>
          </Stack>
        )}

        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

        <FormikProvider value={formik}>
          <Form
            onSubmit={formik.handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            {children}
          </Form>
        </FormikProvider>
      </Box>
    </PaperFormProposal>
  );
}
