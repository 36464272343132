import React, { useState, useEffect } from "react";
import { API_SERVER } from "../../utils/urlBack_End";

// material
import {
  Card,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  InputAdornment,
  DialogContent,
  TextField,
  DialogActions,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
  Tabs,
  Tab,
  Skeleton,
  CircularProgress,
  Chip,
} from "@material-ui/core";

import axios from "axios";
import Scrollbar from "../Scrollbar";

import { es } from "date-fns/locale";

import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import DeleteIcon from "@material-ui/icons/Delete";

import { round } from "lodash";
import { useSelector } from "react-redux";
import moment from "moment";
import { planesApi } from "src/services/planesApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import { Clear, Done } from "@material-ui/icons";
import { fPercent } from "src/utils/formatNumber";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function VerPlanPropuesta(props) {
  const { id } = props;
  // const [relacionPlanes, setRelacionPlanes] = useState([]);

  const { rol: rolObtenido } = useSelector((state) => state.auth.user);

  const [planSeleccionado, setPlanSeleccionado] = useState({});

  const [descuento, setDescuento] = useState(0);
  const [fechaTermDesc, setTermDesc] = useState("");

  const [open, setOpen] = useState(false);
  const [openEliminar, setOpenEliminar] = useState(false);

  const [value, setValue] = useState(null);

  const [mesFacturacion, setMesFacturacion] = useState("");

  const [openNotificacion, setOpenNotificacion] = useState(false);
  const [colorNotificacion, setColorNotificacion] = useState("success");
  const [mensajeNotificacion, setMensajeNotificacion] = useState("");

  const [isChecked, setIsChecked] = useState(false);
  const [valorPlan, setValorPlan] = useState(0);
  const [tipoMoneda, setTipoMoneda] = useState("");
  const [monedas, setMonedas] = useState([]);
  const [valueTabs, setValueTabs] = useState(0);
  const [cargandoSwitch, setCargandoSwitch] = useState(null);

  const { data: relacionPlanes = [], isLoading: isLoadingPlanes } =
    planesApi.useObtenerPlanesAsociadosClienteQuery(id);
  const [editarPlan] = planesApi.useEditarPlanMutation();
  const [eliminarPlan] = planesApi.useEliminarPlanMutation();
  const [editarSeFacturaGestor] =
    planesApi.useEditarFacturacionGestorMutation();

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  const handleClickOpen = (elemento) => {
    if (elemento.valorFijo === "SI") {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    setPlanSeleccionado(elemento);
    setValorPlan(elemento.valor);
    setTipoMoneda(elemento.tipoMoneda);
    setDescuento(elemento.descuento && elemento.descuento);
    setTermDesc(elemento.fechaTerminoDesc && elemento.fechaTerminoDesc);
    setMesFacturacion(elemento.mesFacturacion ? elemento.mesFacturacion : "");
    setValue(
      elemento.fechaTerminoDesc ? new Date(elemento.fechaTerminoDesc) : null
    );
    setOpen(true);
  };

  const handleClickOpenEliminar = (elemento) => {
    if (relacionPlanes.length > 1) {
      setPlanSeleccionado(elemento);
      setOpenEliminar(true);
    } else {
      setOpenNotificacion(true);
      setColorNotificacion("error");
      setMensajeNotificacion("Debe tener al menos un plan asociado");
    }
  };

  const guardarDatos = () => {
    setOpenNotificacion(false);
    if (descuento > 0 && (fechaTermDesc === "" || fechaTermDesc === " ")) {
      setMensajeNotificacion(
        "Falta ingresar la fecha de término del descuento"
      );
      setColorNotificacion("info");
      setOpenNotificacion(true);
    } else if (isChecked && Number.isNaN(valorPlan)) {
      setMensajeNotificacion("El valor del plan debe ser un número.");
      setColorNotificacion("info");
      setIsChecked(false);
      setOpenNotificacion(true);
    } else if (isChecked && valorPlan < 0) {
      setMensajeNotificacion("El valor del plan debe ser mayor a 0");
      setColorNotificacion("info");
      setIsChecked(false);
      setOpenNotificacion(true);
    } else if (isChecked && tipoMoneda === "") {
      setMensajeNotificacion("Debe ingresar un tipo de moneda");
      setColorNotificacion("info");
      setIsChecked(false);
      setOpenNotificacion(true);
    } else {
      const body = {
        refPlan: id,
        refTipoPlan: planSeleccionado.refTipoPlan,
        fechaTerminoDesc: fechaTermDesc,
        descuento: Number.parseFloat(descuento),
        valorFijo: isChecked ? "SI" : "NO",
        valor: isChecked ? Number.parseFloat(valorPlan) : 0,
        tipoMoneda: isChecked ? tipoMoneda : "",
        mesFacturacion: mesFacturacion,
      };
      editarPlan(body)
        .then(() => {
          AlertaToast({
            action: "Plan editado correctamente",
            fullText: true,
          }).fire();
        })
        .catch((error) => {
          console.error("Error en guardarDatos verPlanPropuesta: ", error);
          AlertaToast({
            action: "Error en el servidor",
            fullText: true,
            type: "error",
          }).fire();
        })
        .finally(setOpen(false));
    }
    setOpen(false);
  };

  const handleClickEliminarPlan = () => {
    const body = {
      refPlan: id,
      refTipoPlan: planSeleccionado.refTipoPlan,
    };
    eliminarPlan(body)
      .then(() => {
        AlertaToast({
          action: "Plan eliminado correctamente",
          fullText: true,
        }).fire();
      })
      .catch((error) => {
        console.error("Error en HandleClickEliminarPlan: ", error);
        AlertaToast({
          action: "Error en el servidor",
          fullText: true,
          type: "error",
        }).fire();
      })
      .finally(setOpenEliminar(false));
  };

  const handleClose = () => {
    setPlanSeleccionado({});
    setIsChecked(false);
    setValorPlan("");
    setTipoMoneda("");
    setValueTabs(0);
    setOpen(false);
  };

  const handleCloseEliminar = () => {
    setPlanSeleccionado({});
    setOpenEliminar(false);
  };

  const handleCloseNotificacion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotificacion(false);
  };

  useEffect(() => {
    axios
      .get(`${API_SERVER}/tipos/tipoMoneda`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setMonedas(response.data);
      });
  }, []);

  function verificarNumero(n) {
    n = String(n);
    let expreg = /^[0-9]+\.[0-9]*$|^[0-9]+$/;
    return expreg.test(n);
  }

  const handleChangeValorPlan = (event) => {
    if (verificarNumero(event.target.value)) {
      if (
        tipoMoneda === "PESOS" &&
        (String(event.target.value).includes(".") ||
          String(event.target.value).includes(","))
      ) {
        let valor = parseFloat(String(event.target.value).replaceAll(".", ""));
        valor = parseFloat(String(valor).replaceAll(",", ""));
        setValorPlan(parseFloat(valor));
      } else {
        setValorPlan(parseFloat(event.target.value));
      }
    } else {
      setValorPlan("");
    }
  };

  const handleChangeTipoMoneda = (event) => {
    if (event.target.value === "PESOS" && String(valorPlan).includes(".")) {
      setValorPlan(String(valorPlan).replaceAll(".", ""));
    }
    setTipoMoneda(event.target.value);
  };

  const handleChangeChecked = (e) => {
    setIsChecked((isChecked) => !isChecked);
  };

  const handleChangeSeFacturaGestor = (plan, index) => {
    setCargandoSwitch(index);
    AlertaModal({
      title: "actualizar facturación gestor",
      confirmButtonText: "Sí, actualizar",
      target: document.getElementById("modal-mostrar-planes"),
    })
      .fire()
      .then(async (result) => {
        if (!result.isConfirmed) {
          setCargandoSwitch(null);
          return;
        }
        const nuevoEstado = !plan.seFacturaGestor;

        const body = {
          refPlan: id,
          refTipoPlan: plan.refTipoPlan,
          seFacturaGestor: nuevoEstado,
        };

        await editarSeFacturaGestor(body)
          .then((response) => {
            if (response.data?.filasActualizadas > 0) {
              AlertaToast({
                action: "Campo actualizado correctamente",
                fullText: true,
              }).fire();
            } else {
              AlertaToast({
                action: "Error al actualizar el campo",
                fullText: true,
                type: "error",
              }).fire();
            }
          })
          .catch((error) => {
            console.error("Error en handleChangeSeFacturaGesto", error);
            AlertaToast({
              action: "Error en el servidor",
              fullText: true,
              type: "error",
            }).fire();
          })
          .finally(() => {
            setCargandoSwitch(null);
          });
      });
  };

  return (
    <>
      <Card>
        <Typography variant="h5" style={{ textAlign: "center" }} gutterBottom>
          Planes Asociados
        </Typography>
        <Scrollbar>
          {!isLoadingPlanes ? (
            <TableContainer style={{ marginTop: "30px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre plan</TableCell>
                    <TableCell>Valor base</TableCell>
                    <TableCell>Valor ajustado</TableCell>
                    <TableCell>Descuento</TableCell>
                    <TableCell>Fecha término descuento</TableCell>
                    <TableCell>Valor final</TableCell>
                    <TableCell>¿Facturación gestor?</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {relacionPlanes.map((element, indice) => {
                    if (element.valorFijo === "SI") {
                      return (
                        <TableRow key={indice}>
                          <TableCell
                            onClick={(event) => {
                              event.stopPropagation();
                              if (rolObtenido === "EMPLEADO") {
                              } else {
                                handleClickOpen(element);
                              }
                            }}
                            component="th"
                            // scope="row"
                            style={{ cursor: "pointer" }}
                          >
                            {element.refTipoPlan}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (rolObtenido === "EMPLEADO") {
                              } else {
                                handleClickOpen(element);
                              }
                            }}
                          >
                            {element.valorOriginalPlan}{" "}
                            {element.tipoMonedaOriginalPlan}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (rolObtenido === "EMPLEADO") {
                              } else {
                                handleClickOpen(element);
                              }
                            }}
                          >
                            {element.valor} {element.tipoMoneda}
                          </TableCell>

                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (rolObtenido === "EMPLEADO") {
                              } else {
                                handleClickOpen(element);
                              }
                            }}
                          >
                            {fPercent(element.descuento)}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (rolObtenido === "EMPLEADO") {
                              } else {
                                handleClickOpen(element);
                              }
                            }}
                          >
                            {element.fechaTerminoDesc === ""
                              ? element.fechaTerminoDesc
                              : moment(
                                  element.fechaTerminoDesc,
                                  "YYYY/MM/DD"
                                ).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (rolObtenido === "EMPLEADO") {
                              } else {
                                handleClickOpen(element);
                              }
                            }}
                          >
                            {element.valorDesc} {element.tipoMoneda}
                          </TableCell>
                          <TableCell>
                            {cargandoSwitch === indice ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Chip
                                icon={
                                  element.seFacturaGestor === 1 ? (
                                    <Done />
                                  ) : (
                                    <Clear />
                                  )
                                }
                                label={
                                  element.seFacturaGestor === 1 ? "Sí" : "No"
                                }
                                variant={
                                  element.seFacturaGestor === 1
                                    ? "filled"
                                    : "outlined"
                                }
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleChangeSeFacturaGestor(element, indice);
                                }}
                                clickable
                                color={
                                  element.seFacturaGestor === 1
                                    ? "success"
                                    : "error"
                                }
                                size="small"
                              />
                            )}
                          </TableCell>
                          <TableCell
                            onClick={(event) => {
                              event.stopPropagation();
                              handleClickOpenEliminar(element);
                            }}
                          >
                            <DeleteIcon
                              color={
                                relacionPlanes.length === 1
                                  ? "disabled"
                                  : "error"
                              }
                              style={{
                                cursor:
                                  relacionPlanes.length === 1
                                    ? "default"
                                    : "pointer",
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return (
                        <TableRow key={indice}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (rolObtenido === "EMPLEADO") {
                              } else {
                                handleClickOpen(element);
                              }
                            }}
                          >
                            {element.refTipoPlan}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (rolObtenido === "EMPLEADO") {
                              } else {
                                handleClickOpen(element);
                              }
                            }}
                          >
                            {element.valorOriginalPlan}{" "}
                            {element.tipoMonedaOriginalPlan}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (rolObtenido === "EMPLEADO") {
                              } else {
                                handleClickOpen(element);
                              }
                            }}
                          >
                            {element.valor} {element.tipoMoneda}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (rolObtenido === "EMPLEADO") {
                              } else {
                                handleClickOpen(element);
                              }
                            }}
                          >
                            {fPercent(element.descuento)}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (rolObtenido === "EMPLEADO") {
                              } else {
                                handleClickOpen(element);
                              }
                            }}
                          >
                            {element.fechaTerminoDesc === ""
                              ? element.fechaTerminoDesc
                              : moment(
                                  element.fechaTerminoDesc,
                                  "YYYY/MM/DD"
                                ).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (rolObtenido === "EMPLEADO") {
                              } else {
                                handleClickOpen(element);
                              }
                            }}
                          >
                            {element.valorDesc} {element.tipoMonedaOriginalPlan}
                          </TableCell>
                          <TableCell>
                            {cargandoSwitch === indice ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Chip
                                icon={
                                  element.seFacturaGestor === 1 ? (
                                    <Done />
                                  ) : (
                                    <Clear />
                                  )
                                }
                                label={
                                  element.seFacturaGestor === 1 ? "Sí" : "No"
                                }
                                variant={
                                  element.seFacturaGestor === 1
                                    ? "filled"
                                    : "outlined"
                                }
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleChangeSeFacturaGestor(element, indice);
                                }}
                                clickable
                                color={
                                  element.seFacturaGestor === 1
                                    ? "success"
                                    : "error"
                                }
                                size="small"
                              />
                            )}
                          </TableCell>
                          <TableCell
                            onClick={(event) => {
                              event.stopPropagation();
                              handleClickOpenEliminar(element);
                            }}
                          >
                            <DeleteIcon
                              color={
                                relacionPlanes.length === 1
                                  ? "disabled"
                                  : "error"
                              }
                              style={{
                                cursor:
                                  relacionPlanes.length === 1
                                    ? "default"
                                    : "pointer",
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Skeleton variant="rectangular" width="100%" height="500px" />
          )}
        </Scrollbar>
      </Card>

      {open && (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>Editar Plan</DialogTitle>
          <DialogContent>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={valueTabs}
                  onChange={handleChangeTabs}
                  aria-label="basic tabs example"
                >
                  <Tab label="Valor" {...a11yProps(0)} />
                  {planSeleccionado &&
                    planSeleccionado.tipoFrecuencia &&
                    planSeleccionado.tipoFrecuencia === "AÑO" && (
                      <Tab label="Facturación" {...a11yProps(1)} />
                    )}
                </Tabs>
              </Box>
              <TabPanel value={valueTabs} index={0}>
                <React.Fragment>
                  <TextField
                    margin="dense"
                    label="Nombre Plan"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={
                      planSeleccionado.refTipoPlan &&
                      planSeleccionado.refTipoPlan
                    }
                    disabled
                  />
                  <Grid
                    container
                    spacing={3}
                    style={
                      isChecked
                        ? { marginTop: "10px", marginBottom: "5px" }
                        : {}
                    }
                  >
                    <Grid
                      item
                      xs={3}
                      md={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FormControlLabel
                        label="¿Valor manual?"
                        control={
                          <Checkbox
                            color="secondary"
                            checked={isChecked}
                            onChange={(e) => handleChangeChecked(e)}
                          />
                        }
                      />
                    </Grid>

                    {isChecked && (
                      <>
                        <Grid item xs={3} md={3}>
                          <TextField
                            margin="dense"
                            label="Valor Tarea"
                            variant="outlined"
                            value={valorPlan}
                            onChange={handleChangeValorPlan}
                            type="number"
                            fullWidth
                            required
                          />
                        </Grid>

                        <Grid item xs={3} md={3}>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">
                              Tipo Moneda (*)
                            </InputLabel>
                            <Select
                              style={{ margin: 8 }}
                              value={tipoMoneda}
                              variant="outlined"
                              onChange={handleChangeTipoMoneda}
                            >
                              <MenuItem value="">
                                <em>Ninguno</em>
                              </MenuItem>
                              {monedas.map((element, index) => {
                                return (
                                  <MenuItem key={index} value={element.nombre}>
                                    {element.nombre}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <TextField
                    margin="dense"
                    label="Descuento Plan"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                    fullWidth
                    variant="outlined"
                    value={descuento}
                    onChange={(event) => {
                      if (
                        event.target.value >= 0 &&
                        event.target.value <= 100
                      ) {
                        setDescuento(event.target.value);
                      } else {
                        setDescuento(0);
                      }
                    }}
                  />

                  <br></br>

                  <LocalizationProvider
                    style={{ marginTop: "10px" }}
                    dateAdapter={AdapterDateFns}
                    locale={es}
                  >
                    <DatePicker
                      label="Fecha Término Descuento"
                      minDate={new Date()}
                      value={value}
                      onChange={(newValue) => {
                        let variable = new Date(newValue);
                        let mesActual = "";
                        if (variable.getMonth() + 1 < 10) {
                          mesActual = "0" + (variable.getMonth() + 1);
                        } else {
                          mesActual = variable.getMonth() + 1;
                        }
                        let fechaTerm =
                          variable.getFullYear() +
                          "/" +
                          mesActual +
                          "/" +
                          variable.getDate();

                        setTermDesc(fechaTerm);

                        setValue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </React.Fragment>
              </TabPanel>
              {planSeleccionado &&
                planSeleccionado.tipoFrecuencia &&
                planSeleccionado.tipoFrecuencia === "AÑO" && (
                  <TabPanel value={valueTabs} index={1}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Mes Facturación
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        value={mesFacturacion}
                        label="Mes Facturación"
                        onChange={(event) => {
                          setMesFacturacion(event.target.value);
                        }}
                      >
                        <MenuItem value={"01"}>ENERO</MenuItem>
                        <MenuItem value={"02"}>FEBRERO</MenuItem>
                        <MenuItem value={"03"}>MARZO</MenuItem>
                        <MenuItem value={"04"}>ABRIL</MenuItem>
                        <MenuItem value={"05"}>MAYO</MenuItem>
                        <MenuItem value={"06"}>JUNIO</MenuItem>
                        <MenuItem value={"07"}>JULIO</MenuItem>
                        <MenuItem value={"08"}>AGOSTO</MenuItem>
                        <MenuItem value={"09"}>SEPTIEMBRE</MenuItem>
                        <MenuItem value={"10"}>OCTUBRE</MenuItem>
                        <MenuItem value={"11"}>NOVIEMBRE</MenuItem>
                        <MenuItem value={"12"}>DICIEMBRE</MenuItem>
                      </Select>
                    </FormControl>
                  </TabPanel>
                )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Cancelar
            </Button>
            <Button onClick={guardarDatos} color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openEliminar && (
        <Dialog
          open={openEliminar}
          onClose={handleCloseEliminar}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Eliminar Plan</DialogTitle>
          <DialogContent>
            <Alert severity="error">
              ¿Seguro que quieres eliminar el plan seleccionado?
            </Alert>
            <TextField
              margin="dense"
              label="Nombre Plan"
              type="text"
              fullWidth
              variant="outlined"
              value={
                planSeleccionado.refTipoPlan && planSeleccionado.refTipoPlan
              }
              disabled
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEliminar} color="error">
              Cancelar
            </Button>
            <Button onClick={handleClickEliminarPlan} color="primary">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openNotificacion && (
        <Snackbar
          open={openNotificacion}
          autoHideDuration={6000}
          onClose={handleCloseNotificacion}
        >
          <Alert
            onClose={handleCloseNotificacion}
            severity={colorNotificacion}
            sx={{ width: "100%" }}
          >
            {mensajeNotificacion}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
