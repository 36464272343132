import { globalEnqueueSnackbar } from "src/components/snackbar";

const sessionMiddleware = (store) => (next) => (action) => {
  if (action.type === "auth/logout" && action.payload?.expired) {
    if (globalEnqueueSnackbar) {
      globalEnqueueSnackbar(
        "Tu sesión ha expirado. Por favor, inicia sesión nuevamente.",
        {
          variant: "warning",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  }
  return next(action);
};

export default sessionMiddleware;
