import React, { useState, useEffect } from "react";
import { API_SERVER } from "../../utils/urlBack_End";
import { lowerCase, startCase } from "lodash";
// material
import {
  TextField,
  Card,
  Typography,
  FormControl,
  Stack,
  Alert,
  Autocomplete,
} from "@material-ui/core";
import axios from "axios";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { useSelector } from "react-redux";
import { propuestasApi } from "src/services/propuestasApi";
import { useSnackbar } from "src/components/snackbar";
import {
  DisabledProvider,
  DisabledWrapper,
} from "../disabled-screen/DisabledContext";

export default function VerPropuestaPlanes(props) {
  const { sucursal: sucursalObtenida, rol: rolObtenido } = useSelector(
    (state) => state.auth.user
  );
  const sucursal = sucursalObtenida.refSucursal;

  const { propuesta, tipo, isDisabled } = props;

  const [actualizarDatosPropuesta] =
    propuestasApi.useUpdateDatosPropuestaMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [antecedentes, setAntecedentes] = useState(propuesta.antecedentes);
  const [comentarios, setComentarios] = useState(propuesta.comentarios);
  const [supervisor, setSupervisor] = useState(propuesta.supervisor);
  const [vendedor, setVendedor] = useState(propuesta.vendedor);
  const [empleados, setEmpleados] = useState([]);

  const [mostrarCircular, setMostrarCircular] = useState(false);

  const autorizado = rolObtenido !== "EMPLEADO";

  const [empleadoOptions, setEmpleadoOptions] = useState([]);

  const [empleadoPrincipalSelect, setEmpleadoPrincipalSelect] = useState(null);
  const [supervisorSelect, setSupervisorSelect] = useState(null);
  const [supervisorOptions, setSupervisorOptions] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/empleados/empleadoSucursal/${sucursal}/ALL`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setEmpleados(response.data);
      });
  }, [sucursal]);

  useEffect(() => {
    setEmpleadoOptions(
      empleados.map((item) => {
        return {
          label: startCase(lowerCase(item.nombre + " " + item.apellidos)),
          value: item.rut,
        };
      })
    );
  }, [empleados]);

  useEffect(() => {
    const buscarEmpleado = () => {
      let empleado = null;
      let refSupervisor = null;
      if (vendedor !== "" && empleadoOptions.length > 0) {
        empleado = empleadoOptions.find((item) => item.value === vendedor);

        setEmpleadoPrincipalSelect(empleado !== undefined ? empleado : null);
        setSupervisorOptions(empleadoOptions);
      }

      if (supervisor !== "" && empleadoOptions.length > 0) {
        refSupervisor = empleadoOptions.find(
          (item) => item.value === supervisor
        );
        setSupervisorSelect(refSupervisor !== undefined ? refSupervisor : null);
      }
    };
    if (supervisorSelect === null || empleadoPrincipalSelect === null)
      buscarEmpleado();
  }, [
    empleadoOptions,
    empleadoPrincipalSelect,
    supervisorSelect,
    vendedor,
    supervisor,
  ]);

  const handleChangeAntecedentes = (event) => {
    setAntecedentes(event.target.value);
  };

  const handleChangeComentarios = (event) => {
    setComentarios(event.target.value);
  };

  const guardarCambios = async () => {
    if (isDisabled) return;
    setMostrarCircular(true);
    const { estado, fechaEnvioCorreo } = propuesta;
    if (
      (estado === "ENVIADA" ||
        estado === "ACEPTADA" ||
        estado === "RECHAZADA") &&
      (fechaEnvioCorreo === "" || fechaEnvioCorreo === " ")
    ) {
      enqueueSnackbar("Fecha de envío requerida", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      setMostrarCircular(false);
    } else if (!vendedor) {
      enqueueSnackbar("Vendedor requerido", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      setMostrarCircular(false);
    } else if (!supervisor) {
      enqueueSnackbar("Supervisor requerido", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      setMostrarCircular(false);
    } else if (estado.toUpperCase() === "ACEPTADA") {
      enqueueSnackbar(
        "Para cambiar el estado a aceptada, se debe realizar desde la tabla",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
      setMostrarCircular(false);
    } else {
      const datosSolicitante = {
        id: propuesta.id,
        estado: estado.toUpperCase(),
        fechaEnvioCorreo: fechaEnvioCorreo,
        vendedor,
        supervisor,
        antecedentes,
      };

      const { error } = await actualizarDatosPropuesta(datosSolicitante);

      if (error) {
        enqueueSnackbar(
          error.data.message
            ? error.data.message
            : "Error al actualizar datos propuesta",
          {
            variant: "error",
          }
        );
        setMostrarCircular(false);
        return;
      }

      enqueueSnackbar("Datos propuesta actualizados correctamente", {});
      setMostrarCircular(false);
    }
  };

  const handleChangeSupervisores = (value) => {
    const supervisores = empleadoOptions.filter(
      (item) => item.value !== value.value
    );

    setSupervisorOptions([...supervisores]);
  };

  return (
    <DisabledProvider isDisabled={isDisabled}>
      <DisabledWrapper
        message="Vista de solo lectura"
        overlayColor="rgba(240, 242, 245, 0.45)"
      >
        <Card>
          <Typography variant="h5" style={{ textAlign: "center" }} gutterBottom>
            Datos Propuesta
          </Typography>

          <Alert severity="info">
            Información: El estado se puede cambiar en la tabla de propuestas.
          </Alert>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
            spacing={3}
            style={{
              marginLeft: "25px",
              marginRight: "25px",
              marginTop: "30px",
            }}
          >
            <TextField
              margin="dense"
              label="Estado"
              type="text"
              value={propuesta.estado}
              fullWidth
              disabled
            />
            <TextField
              margin="dense"
              label="Fecha de Envio"
              type="text"
              value={propuesta.fechaEnvioCorreo}
              fullWidth
              disabled
            />
          </Stack>

          {(rolObtenido === "SUPER_ADMIN" ||
            rolObtenido === "ADMIN" ||
            rolObtenido === "SUPERVISOR") && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
              spacing={3}
              style={{
                marginLeft: "25px",
                marginRight: "25px",
                marginTop: "30px",
              }}
            >
              <FormControl fullWidth>
                <Autocomplete
                  disabled={
                    !autorizado ||
                    propuesta.estado === "ACEPTADA" ||
                    propuesta.estado === "ACEPTADO_CLIENTE" ||
                    propuesta.estado === "ENVIADA" ||
                    (tipo === "TAREA" && Boolean(propuesta.vendidoTaxtic))
                  }
                  disablePortal
                  value={empleadoPrincipalSelect}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setVendedor(newValue.value);
                      setEmpleadoPrincipalSelect(newValue);
                      handleChangeSupervisores(newValue);
                    } else {
                      setVendedor("");
                      setEmpleadoPrincipalSelect(null);
                    }
                  }}
                  fullWidth
                  id="combo-box-vendedor"
                  options={empleadoOptions}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      type="string"
                      label="Vendedor"
                      required
                      value={vendedor}
                    />
                  )}
                />
              </FormControl>
            </Stack>
          )}

          {(rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN") && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
              spacing={3}
              style={{
                marginLeft: "25px",
                marginRight: "25px",
                marginTop: "30px",
              }}
            >
              <FormControl fullWidth>
                <Autocomplete
                  disabled={!autorizado}
                  disablePortal
                  value={supervisorSelect}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setSupervisor(newValue.value);
                      setSupervisorSelect(newValue);
                    } else {
                      setSupervisor("");
                      setSupervisorSelect(null);
                    }
                  }}
                  fullWidth
                  id="combo-box-Supervisor"
                  options={supervisorOptions}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      type="rut"
                      label="Supervisor Asignado"
                      value={supervisor}
                    />
                  )}
                />
              </FormControl>
            </Stack>
          )}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
            spacing={3}
            style={{ marginLeft: "25px", marginRight: "25px" }}
          >
            <TextField
              margin="dense"
              label="Antecedentes"
              type="text"
              rows={4}
              value={antecedentes}
              onChange={handleChangeAntecedentes}
              fullWidth
              multiline
              disabled={
                propuesta.estado === "ACEPTADA" ||
                propuesta.estado === "ACEPTADO_CLIENTE"
              }
            />
          </Stack>
          {tipo === "TAREA" && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
              spacing={3}
              style={{ marginLeft: "25px", marginRight: "25px" }}
            >
              <TextField
                margin="dense"
                label="Comentarios"
                type="text"
                rows={4}
                value={comentarios}
                onChange={handleChangeComentarios}
                fullWidth
                multiline
                disabled={
                  propuesta.estado === "ACEPTADA" ||
                  propuesta.estado === "ACEPTADO_CLIENTE"
                }
              />
            </Stack>
          )}

          <div
            style={{
              textAlign: "right",
              marginRight: "25px",
              marginBottom: "25px",
            }}
          >
            <LoadingButton
              loading={mostrarCircular}
              variant="contained"
              color="primary"
              onClick={guardarCambios}
              disabled={propuesta.estado === "ACEPTADA" || isDisabled}
            >
              Guardar Cambios
            </LoadingButton>
          </div>
        </Card>
      </DisabledWrapper>
    </DisabledProvider>
  );
}
