import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { servicioSchema } from "../Modal/propuestas/steppers/schemas/proposal-task";
import ServicioForm from "../Modal/propuestas/steppers/propuestaTareaEspecial/formularios/ServicioForm";
import FormPaper from "../Modal/propuestas/components/FormPaper";
import { showSwitch } from "../Modal/propuestas/steppers/utils/functions";
import { propuestasApi } from "src/services/propuestasApi";
import { useSnackbar } from "src/components/snackbar";
import {
  DisabledProvider,
  DisabledWrapper,
  useDisabledFormik,
} from "../disabled-screen/DisabledContext";

export default function VerServiciosPropuestaTarea({
  propuesta,
  isDisabled = false,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [actualizarTareaPropuesta, { isLoading: actualizandoTareaPropuesta }] =
    propuestasApi.useActualizarServicioPropuestoTareaSegunIDMutation();

  const baseFormik = useFormik({
    initialValues: {
      tareaExistente: Boolean(propuesta.tareaExistente),
      tarea: {
        nombre: propuesta.nombreTarea,
        valor: propuesta.precioSugerido,
        tipoMoneda: propuesta.tipoMoneda,
      },
      nombreTarea: propuesta.nombreTarea,
      tipoMoneda: propuesta.tipoMoneda,
      precioFijo: propuesta.precioSugerido,
      descuento: propuesta.descuento,
      precioFinal: propuesta.precioFinal,
      fechaLimite: propuesta.fechaLimite,
    },
    validationSchema: servicioSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const formik = useDisabledFormik(baseFormik);

  const handleSubmit = useCallback(
    async (values) => {
      if (isDisabled) return;
      try {
        const {
          nombreTarea,
          tipoMoneda,
          descuento,
          precioFijo,
          precioFinal,
          fechaLimite,
          tarea: tareaAux,
        } = values;

        const tarea = {
          idPropuesta: propuesta.id,
          tareaExistente: Boolean(values.tareaExistente),
          nombre: nombreTarea.toUpperCase(),
          tipoMoneda,
          descuento,
          precioFijo,
          precioFinal,
          fechaLimite,
          valorFijo: tareaAux?.valor === precioFijo ? "SI" : "NO",
        };

        await actualizarTareaPropuesta(tarea).unwrap();

        enqueueSnackbar("Tarea propuesta actualizada correctamente", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (error) {
        console.error("handleSubmit verServicioPropuestaTarea", error);
        enqueueSnackbar(
          error?.message ||
            "Ocurrió un error al actualizar la tarea de la propuesta",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      }
    },
    [actualizarTareaPropuesta, enqueueSnackbar, isDisabled, propuesta.id]
  );

  return (
    <DisabledProvider isDisabled={isDisabled}>
      <DisabledWrapper
        message="Vista de solo lectura"
        overlayColor="rgba(240, 242, 245, 0.45)"
      >
        <FormPaper
          formik={formik}
          manageSwitch={showSwitch(
            true,
            Boolean(formik.values.tareaExistente),
            formik.setFieldValue,
            "tareaExistente",
            {
              checked: "Tarea existente",
              unChecked: "Nueva tarea",
            },
            {
              elements: [
                "nombreTarea",
                "tipoMoneda",
                "precioFijo",
                "descuento",
                "tarea",
              ],
              defaults: {
                tarea: null,
                tipoMoneda: null,
                descuento: 0,
              },
            },
            formik
          )}
          title="Servicios propuestos"
        >
          <ServicioForm
            formik={formik}
            editar={true}
            isLoading={actualizandoTareaPropuesta}
          />
        </FormPaper>
      </DisabledWrapper>
    </DisabledProvider>
  );
}

VerServiciosPropuestaTarea.propTypes = {
  propuesta: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};
