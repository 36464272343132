import {
  Alert,
  Box,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useTheme,
  alpha,
} from "@material-ui/core";
import {
  CalendarToday,
  Cancel,
  CheckCircle,
  Close,
  Send,
  Visibility,
  Timeline,
  History,
  TrendingUp,
} from "@material-ui/icons";
import { useState } from "react";
import Chart from "src/components/charts/chart";
import { calcularDiasActiva, formatearFecha } from "src/utils/formatTime";

// Componente de panel de estadísticas
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tracking-tabpanel-${index}`}
      aria-labelledby={`tracking-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

// Función para obtener el color según el tipo de evento
const getEventColor = (tipoEvento) => {
  switch (tipoEvento) {
    case "envio":
      return "primary";
    case "primera_vista":
    case "vista":
      return "info";
    case "aceptada":
      return "success";
    case "rechazada":
      return "error";
    default:
      return "default";
  }
};

// Función para obtener el icono según el tipo de evento
const getEventIcon = (tipoEvento) => {
  switch (tipoEvento) {
    case "envio":
      return <Send fontSize="small" />;
    case "primera_vista":
    case "vista":
      return <Visibility fontSize="small" />;
    case "aceptada":
      return <CheckCircle fontSize="small" />;
    case "rechazada":
      return <Cancel fontSize="small" />;
    default:
      return null;
  }
};

// Función para obtener el texto del tipo de evento
const getEventText = (tipoEvento) => {
  switch (tipoEvento) {
    case "envio":
      return "Envío";
    case "primera_vista":
      return "Primera vista";
    case "vista":
      return "Vista";
    case "aceptada":
      return "Aceptada";
    case "rechazada":
      return "Rechazada";
    default:
      return tipoEvento;
  }
};

const ModalPropuestaTracking = ({ open, setOpen, data, error, isLoading }) => {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Preparar datos para el gráfico de ApexCharts
  const prepareChartData = (actividadPorDia) => {
    if (!actividadPorDia || actividadPorDia.length === 0) {
      return {
        options: {
          chart: {
            id: "actividad-propuesta",
          },
          xaxis: {
            categories: [],
          },
          colors: [
            theme.palette.info.main,
            theme.palette.info.light,
            theme.palette.primary.main,
            theme.palette.success.main,
            theme.palette.error.main,
          ],
          stroke: {
            curve: "smooth",
            width: 2,
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          legend: {
            position: "top",
          },
        },
        series: [],
      };
    }

    // Preparamos un objeto para agrupar datos por fecha
    const dataByDate = {};
    const eventTypes = [
      "vista",
      "primera_vista",
      "envio",
      "aceptada",
      "rechazada",
    ];

    // Inicializar estructura
    actividadPorDia.forEach((item) => {
      if (!dataByDate[item.fecha]) {
        dataByDate[item.fecha] = {
          vista: 0,
          primera_vista: 0,
          envio: 0,
          aceptada: 0,
          rechazada: 0,
        };
      }
      dataByDate[item.fecha][item.tipo_evento] = item.cantidad;
    });

    // Extraer fechas ordenadas
    const fechas = Object.keys(dataByDate).sort();

    // Crear series para cada tipo de evento
    const series = eventTypes.map((tipo) => {
      return {
        name: getEventText(tipo),
        data: fechas.map((fecha) => dataByDate[fecha][tipo] || 0),
      };
    });

    // Configurar opciones del gráfico
    const options = {
      chart: {
        id: "actividad-propuesta",
        background: theme.palette.background.paper,
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
        },
        fontFamily: theme.typography.fontFamily,
      },
      xaxis: {
        categories: fechas,
        labels: {
          rotate: -45,
          style: {
            fontSize: "12px",
            fontWeight: 500,
            colors: theme.palette.text.secondary,
          },
          formatter: function (value) {
            return formatearFecha(value, "DD/MM/YYYY");
          },
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return Math.round(value) + " eventos";
          },
          style: {
            fontSize: "12px",
            colors: theme.palette.text.secondary,
          },
        },
      },
      colors: [
        theme.palette.info.main,
        theme.palette.info.light,
        theme.palette.primary.main,
        theme.palette.success.main,
        theme.palette.error.main,
      ],
      stroke: {
        curve: "smooth",
        width: 2.5,
      },
      tooltip: {
        shared: false,
        intersect: true,
        theme: theme.palette.mode,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const fecha = w.globals.categoryLabels[dataPointIndex];
          const tipoEvento = w.globals.seriesNames[seriesIndex];
          const cantidad = series[seriesIndex][dataPointIndex];

          const iconos = {
            Envío: "📤",
            "Primera vista": "👀",
            Vista: "🔍",
            Aceptada: "✅",
            Rechazada: "❌",
          };

          const bgColor = theme.palette.mode === "dark" ? "#333" : "#fff";
          const textColor = theme.palette.mode === "dark" ? "#fff" : "#333";

          return `
              <div style="
                background: ${bgColor};
                padding: 12px;
                border-radius: 8px;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
                font-size: 14px;
                color: ${textColor};
                border-left: 4px solid ${w.globals.colors[seriesIndex]};
              ">
                <strong style="display: flex; align-items: center; gap: 5px; font-size: 15px;">
                  ${iconos[tipoEvento] || "📌"} ${tipoEvento}
                </strong>
                <div style="margin-top: 8px; display: flex; align-items: center; gap: 5px;">
                  📅 <strong>${formatearFecha(fecha, "DD/MM/YYYY")}</strong>
                </div>
                <div style="margin-top: 4px; display: flex; align-items: center; gap: 5px;">
                  🔢 <strong>${cantidad} evento${
            cantidad !== 1 ? "s" : ""
          }</strong>
                </div>
              </div>
            `;
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        markers: {
          width: 12,
          height: 12,
          radius: 6,
        },
        itemMargin: {
          horizontal: 15,
          vertical: 8,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        labels: {
          colors: theme.palette.text.primary,
        },
      },
      grid: {
        borderColor: theme.palette.divider,
        strokeDashArray: 3,
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 10,
          right: 10,
          bottom: 10,
          left: 10,
        },
      },
      markers: {
        size: 6,
        strokeWidth: 0,
        hover: {
          size: 8,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.2,
          opacityFrom: 1,
          opacityTo: 0.7,
          stops: [0, 100],
        },
      },
    };

    return { options, series };
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ResumenEstadistico = ({ data }) => {
    if (!data) return null;

    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          mb: 3,
          justifyContent: "center",
        }}
      >
        <Card
          elevation={1}
          sx={{
            p: 2,
            flex: "1 1 150px",
            display: "flex",
            alignItems: "center",
            gap: 2,
            borderLeft: `4px solid ${theme.palette.info.main}`,
            transition: "transform 0.2s, box-shadow 0.2s",
            "&:hover": {
              transform: "translateY(-3px)",
              boxShadow: 3,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 45,
              height: 45,
              borderRadius: "50%",
              backgroundColor: alpha(theme.palette.info.main, 0.1),
              color: theme.palette.info.main,
            }}
          >
            <Visibility />
          </Box>
          <Box>
            <Typography variant="h4" component="div" sx={{ fontWeight: 500 }}>
              {data.estadisticas.vistas}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Visualizaciones
            </Typography>
          </Box>
        </Card>
        <Card
          elevation={1}
          sx={{
            p: 2,
            flex: "1 1 150px",
            display: "flex",
            alignItems: "center",
            gap: 2,
            borderLeft: `4px solid ${theme.palette.warning.main}`,
            transition: "transform 0.2s, box-shadow 0.2s",
            "&:hover": {
              transform: "translateY(-3px)",
              boxShadow: 3,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 45,
              height: 45,
              borderRadius: "50%",
              backgroundColor: alpha(theme.palette.warning.main, 0.1),
              color: theme.palette.warning.main,
            }}
          >
            <CalendarToday />
          </Box>
          <Box>
            <Typography variant="h4" component="div" sx={{ fontWeight: 500 }}>
              {data.enviada
                ? calcularDiasActiva(data.enviada.fecha_evento) || "0"
                : "0"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Días activa
            </Typography>
          </Box>
        </Card>
        <Card
          elevation={1}
          sx={{
            p: 2,
            flex: "1 1 150px",
            display: "flex",
            alignItems: "center",
            gap: 2,
            borderLeft: `4px solid ${theme.palette.success.main}`,
            transition: "transform 0.2s, box-shadow 0.2s",
            "&:hover": {
              transform: "translateY(-3px)",
              boxShadow: 3,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 45,
              height: 45,
              borderRadius: "50%",
              backgroundColor: alpha(theme.palette.success.main, 0.1),
              color: theme.palette.success.main,
            }}
          >
            <CalendarToday />
          </Box>
          <Box>
            <Typography variant="h4" component="div" sx={{ fontWeight: 500 }}>
              {data.diasRestantes}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Días restantes
            </Typography>
          </Box>
        </Card>
        <Card
          elevation={1}
          sx={{
            p: 2,
            flex: "1 1 150px",
            display: "flex",
            alignItems: "center",
            gap: 2,
            borderLeft: `4px solid ${
              data.estadisticas.aceptadas > 0
                ? theme.palette.success.main
                : data.estadisticas.rechazadas > 0
                ? theme.palette.error.main
                : theme.palette.warning.main
            }`,
            transition: "transform 0.2s, box-shadow 0.2s",
            "&:hover": {
              transform: "translateY(-3px)",
              boxShadow: 3,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 45,
              height: 45,
              borderRadius: "50%",
              backgroundColor: alpha(
                data.estadisticas.aceptadas > 0
                  ? theme.palette.success.main
                  : data.estadisticas.rechazadas > 0
                  ? theme.palette.error.main
                  : theme.palette.warning.main,
                0.1
              ),
              color:
                data.estadisticas.aceptadas > 0
                  ? theme.palette.success.main
                  : data.estadisticas.rechazadas > 0
                  ? theme.palette.error.main
                  : theme.palette.warning.main,
            }}
          >
            {data.estadisticas.aceptadas > 0 ? (
              <CheckCircle />
            ) : data.estadisticas.rechazadas > 0 ? (
              <Cancel />
            ) : (
              <Timeline />
            )}
          </Box>
          <Box>
            <Typography variant="h5" component="div" sx={{ fontWeight: 500 }}>
              {data.estadisticas.aceptadas > 0
                ? "Aceptada"
                : data.estadisticas.rechazadas > 0
                ? "Rechazada"
                : "Pendiente"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {data.estadisticas.aceptadas > 0
                ? "Propuesta aprobada"
                : data.estadisticas.rechazadas > 0
                ? "Propuesta rechazada"
                : "Esperando respuesta"}
            </Typography>
          </Box>
        </Card>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.default,
          py: 2.5,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TrendingUp sx={{ mr: 1.5, color: theme.palette.primary.main }} />
          <Typography variant="h5" component="div">
            Estadísticas de la propuesta
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
            transition: "all 0.2s",
            "&:hover": {
              backgroundColor: alpha(theme.palette.error.main, 0.1),
              color: theme.palette.error.main,
            },
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          p: 0,
          "&:first-of-type": {
            pt: 0,
          },
        }}
      >
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 8,
            }}
          >
            <CircularProgress size={60} thickness={4} sx={{ mb: 2 }} />
            <Typography variant="body1" color="textSecondary">
              Cargando datos de la propuesta...
            </Typography>
          </Box>
        )}
        {error && (
          <Alert
            severity="error"
            sx={{
              m: 3,
              borderRadius: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              Error al cargar los datos: {error.message || "Intente nuevamente"}
            </Typography>
          </Alert>
        )}

        {data && (
          <Box sx={{ px: 3, py: 2 }}>
            <Paper
              elevation={0}
              sx={{
                p: 2.5,
                mb: 3,
                borderRadius: 2,
                backgroundColor: alpha(theme.palette.background.default, 0.7),
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography
                variant="h5"
                component="div"
                sx={{
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontWeight: 500,
                }}
              >
                <Timeline fontSize="small" color="primary" />
                Seguimiento de Propuesta
              </Typography>
              <Box sx={{ mb: 1 }}>
                <Box
                  sx={{
                    color: "text.secondary",
                    display: "flex",
                    alignItems: "center",
                    mb: 0.5,
                  }}
                >
                  <Visibility fontSize="small" sx={{ mr: 1 }} />
                  <Typography component="span">
                    Visualizaciones totales:
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: 600,
                      ml: 1,
                      color: theme.palette.info.main,
                    }}
                  >
                    {data.estadisticas.vistas}
                  </Typography>
                </Box>
                <Box sx={{ mb: 1 }}>
                  <Box
                    sx={{
                      color: "text.secondary",
                      display: "flex",
                      alignItems: "center",
                      mb: 0.5,
                    }}
                  >
                    <CalendarToday fontSize="small" sx={{ mr: 1 }} />
                    Fecha envío:
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 600,
                        ml: 1,
                      }}
                    >
                      {data.enviada
                        ? formatearFecha(
                            data.enviada.fecha_evento,
                            "DD [de] MMMM [del] YYYY, HH:mm"
                          )
                        : "No disponible"}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mb: 1 }}>
                  <Box
                    sx={{
                      color: "text.secondary",
                      display: "flex",
                      alignItems: "center",
                      mb: 0.5,
                    }}
                  >
                    <CalendarToday fontSize="small" sx={{ mr: 1 }} />
                    Primera visualización:
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 600,
                        ml: 1,
                      }}
                    >
                      {data.primeraVista
                        ? formatearFecha(
                            data.primeraVista.fecha_evento,
                            "DD [de] MMMM [del] YYYY, HH:mm"
                          )
                        : "No disponible"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    color: "text.secondary",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {data.estadisticas.aceptadas > 0 ? (
                    <CheckCircle
                      fontSize="small"
                      sx={{ mr: 1, color: theme.palette.success.main }}
                    />
                  ) : data.estadisticas.rechazadas > 0 ? (
                    <Cancel
                      fontSize="small"
                      sx={{ mr: 1, color: theme.palette.error.main }}
                    />
                  ) : (
                    <Send
                      fontSize="small"
                      sx={{ mr: 1, color: theme.palette.primary.main }}
                    />
                  )}
                  Estado actual:
                  <Chip
                    size="small"
                    label={
                      data.estadisticas.aceptadas > 0
                        ? "Aceptada"
                        : data.estadisticas.rechazadas > 0
                        ? "Rechazada"
                        : "Pendiente"
                    }
                    color={
                      data.estadisticas.aceptadas > 0
                        ? "success"
                        : data.estadisticas.rechazadas > 0
                        ? "error"
                        : "primary"
                    }
                    sx={{ ml: 1, fontWeight: 500 }}
                  />
                </Box>
              </Box>
            </Paper>
            <ResumenEstadistico data={data} />

            <Box sx={{ width: "100%" }}>
              <Paper
                elevation={1}
                sx={{
                  mb: 3,
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    backgroundColor: theme.palette.background.default,
                  }}
                >
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="tracking tabs"
                    variant="fullWidth"
                    sx={{
                      "& .MuiTab-root": {
                        minHeight: 64,
                        fontWeight: 500,
                        textTransform: "none",
                        fontSize: "1rem",
                      },
                      "& .Mui-selected": {
                        color: theme.palette.primary.main,
                      },
                      "& .MuiTabs-indicator": {
                        height: 3,
                        borderTopLeftRadius: 3,
                        borderTopRightRadius: 3,
                      },
                    }}
                  >
                    <Tab label="Actividad" icon={<Timeline />} />
                    <Tab label="Historial" icon={<History />} />
                  </Tabs>
                </Box>

                <TabPanel value={tabValue} index={0}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <TrendingUp fontSize="small" color="primary" />
                    Actividad por día
                  </Typography>

                  <Box sx={{ height: 380, mt: 3 }}>
                    {data.actividadPorDia && data.actividadPorDia.length > 0 ? (
                      <Chart
                        options={prepareChartData(data.actividadPorDia).options}
                        series={prepareChartData(data.actividadPorDia).series}
                        type="line"
                        height={380}
                      />
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          p: 4,
                          backgroundColor: alpha(
                            theme.palette.background.default,
                            0.5
                          ),
                          borderRadius: 2,
                        }}
                      >
                        <Timeline
                          sx={{
                            fontSize: 48,
                            color: alpha(theme.palette.text.secondary, 0.5),
                            mb: 2,
                          }}
                        />
                        <Typography variant="h6" color="text.secondary">
                          No hay datos de actividad disponibles
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mt: 1, textAlign: "center" }}
                        >
                          Cuando la propuesta reciba interacciones, podrás ver
                          aquí el historial de actividad.
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <History fontSize="small" color="primary" />
                    Historial de eventos
                  </Typography>

                  <TableContainer
                    component={Paper}
                    sx={{
                      mt: 2,
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: 1,
                      boxShadow: "none",
                    }}
                  >
                    <Table size="medium">
                      <TableHead>
                        <TableRow
                          sx={{
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.05
                            ),
                          }}
                        >
                          <TableCell sx={{ fontWeight: 600 }}>Evento</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>Fecha</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>IP</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>
                            Navegador
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.historial.length > 0 ? (
                          data.historial.map((evento, index) => (
                            <TableRow
                              key={evento.id}
                              sx={{
                                transition: "background-color 0.2s",
                                "&:hover": {
                                  backgroundColor: alpha(
                                    theme.palette.primary.main,
                                    0.03
                                  ),
                                },
                                backgroundColor:
                                  index % 2 === 0
                                    ? "inherit"
                                    : alpha(
                                        theme.palette.background.default,
                                        0.3
                                      ),
                              }}
                            >
                              <TableCell>
                                <Chip
                                  icon={getEventIcon(evento.tipo_evento)}
                                  label={getEventText(evento.tipo_evento)}
                                  color={getEventColor(evento.tipo_evento)}
                                  size="small"
                                  sx={{
                                    fontWeight: 500,
                                    "& .MuiChip-icon": {
                                      ml: 0.5,
                                    },
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                {formatearFecha(
                                  evento.fecha_evento,
                                  "DD [de] MMMM [del] YYYY, HH:mm"
                                )}
                              </TableCell>
                              <TableCell>{evento.ip || "—"}</TableCell>
                              <TableCell>
                                {evento.user_agent
                                  ? evento.user_agent.substring(0, 30) +
                                    (evento.user_agent.length > 30 ? "..." : "")
                                  : "—"}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={4}
                              sx={{ textAlign: "center", py: 4 }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <History
                                  sx={{
                                    fontSize: 40,
                                    color: alpha(
                                      theme.palette.text.secondary,
                                      0.5
                                    ),
                                    mb: 1.5,
                                  }}
                                />
                                <Typography
                                  variant="body1"
                                  color="text.secondary"
                                >
                                  No hay eventos registrados
                                </Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              </Paper>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalPropuestaTracking;
