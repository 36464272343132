import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  InputBase,
  FormHelperText,
  Box,
  Skeleton,
  CircularProgress,
} from "@material-ui/core";
import { Delete, Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { tareasApi } from "src/services/tareasApi";
import { limpiarString, normalizeString } from "src/utils/sharedFunctions";

const useStyles = makeStyles((theme) => ({
  subtasksContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  subtaskList: {
    padding: 0,
  },
  subtaskItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
  },
  subtaskText: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  deleteIconButton: {
    color: theme.palette.error.main,
  },
  addArea: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: theme.spacing(2),
    width: "100%",
  },
  addInputContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  addInput: {
    flexGrow: 1,
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
    width: "100%",
    "&.error": {
      borderColor: theme.palette.error.main,
    },
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
}));

const SubtareasForm = ({
  formik,
  editar = false,
  handleUpdateSubtasksDB,
  handleDeleteSubtaskDB,
  agregandoSubtarea = false,
  eliminandoSubtarea = false,
}) => {
  const classes = useStyles();
  const [nombreSubtarea, setNombreSubtarea] = useState("");
  const [subtareaError, setSubtareaError] = useState("");
  const [indexDeleted, setIndexDeleted] = useState(null);
  const { values, setFieldValue } = formik;
  const [obtenerSubtareas, { isLoading: obteniendoSubtareas }] =
    tareasApi.useLazyGetTipoTareaQuery();
  const procesadasRef = React.useRef(new Set()); // Para rastrear subtareas ya procesadas

  const obtenerSubtareasCallback = useCallback(async () => {
    try {
      const subtareas = await obtenerSubtareas({
        id: values.tarea.nombre,
        tipoTarea: "ESPECIAL",
      }).unwrap();

      const subtareasRefs = subtareas.map((subtarea) => subtarea.nombre);

      // Evitar procesar subtareas ya existentes
      const subtareasNuevas = subtareasRefs.filter(
        (ref) => !procesadasRef.current.has(ref)
      );

      // Actualizar el set con las nuevas subtareas
      subtareasNuevas.forEach((ref) => procesadasRef.current.add(ref));

      // Si hay subtareas nuevas, actualiza Formik
      if (subtareasNuevas.length > 0) {
        const subtareasCombinadas = [
          ...new Set([...(values.subtareas || []), ...subtareasNuevas]),
        ];
        setFieldValue("subtareas", subtareasCombinadas);
      }
    } catch (error) {
      console.error("Error al obtener subtareas:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obtenerSubtareas, setFieldValue, values.tarea?.nombre]);

  useEffect(() => {
    if (formik.values.tareaExistente && !obteniendoSubtareas) {
      obtenerSubtareasCallback();
    }
  }, [
    formik.values.tareaExistente,
    obtenerSubtareasCallback,
    obteniendoSubtareas,
  ]);

  const handleAddSubtarea = useCallback(async () => {
    const trimmedSubtarea = nombreSubtarea.trim();
    if (trimmedSubtarea === "") {
      setSubtareaError("Por favor, introduce una subtarea.");
      return;
    }

    const cleanedSubtarea = limpiarString(trimmedSubtarea);
    const normalizedNewSubtarea = normalizeString(cleanedSubtarea);
    const normalizedSubtareasSet = new Set(
      values.subtareas.map((subtarea) =>
        normalizeString(limpiarString(subtarea))
      )
    );

    if (normalizedSubtareasSet.has(normalizedNewSubtarea)) {
      setSubtareaError("Esta subtarea ya existe.");
      return;
    }

    if (!editar) {
      const updatedSubtareas = [...values.subtareas, trimmedSubtarea];
      setFieldValue("subtareas", updatedSubtareas);
    } else {
      await handleUpdateSubtasksDB(trimmedSubtarea);
    }

    setSubtareaError("");
    setNombreSubtarea("");
  }, [
    handleUpdateSubtasksDB,
    nombreSubtarea,
    setFieldValue,
    editar,
    values.subtareas,
  ]);

  const handleDeleteSubtarea = useCallback(
    async (index) => {
      if (!editar) {
        const updatedSubtareas = [...values.subtareas];
        updatedSubtareas.splice(index, 1);
        setFieldValue("subtareas", updatedSubtareas);
      } else {
        setIndexDeleted(index);
        await handleDeleteSubtaskDB(index);
        setIndexDeleted(null);
      }
    },
    [handleDeleteSubtaskDB, setFieldValue, editar, values.subtareas]
  );

  return (
    <Paper elevation={0} className={classes.subtasksContainer}>
      <List dense={true} className={classes.subtaskList}>
        {obteniendoSubtareas ? (
          // Mostrar Skeleton mientras se cargan las subtareas
          <>
            {[...Array(5)].map((_, index) => (
              <ListItem key={index} className={classes.skeleton}>
                <Skeleton variant="text" width="100%" />
              </ListItem>
            ))}
          </>
        ) : values.subtareas && values.subtareas.length === 0 ? (
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            style={{ paddingTop: "10px" }}
          >
            No hay subtareas agregadas.
          </Typography>
        ) : (
          values.subtareas &&
          values.subtareas.map((subtarea, index) => (
            <ListItem className={classes.subtaskItem} key={index}>
              <ListItemText
                className={classes.subtaskText}
                primary={subtarea}
              />
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeleteSubtarea(index)}
                className={classes.deleteIconButton}
              >
                {indexDeleted === index && eliminandoSubtarea ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <Delete />
                )}
              </IconButton>
            </ListItem>
          ))
        )}
      </List>
      <Box className={classes.addArea}>
        <Box className={classes.addInputContainer}>
          <InputBase
            placeholder="Añadir subtarea"
            className={`${classes.addInput} ${subtareaError ? "error" : ""}`}
            value={nombreSubtarea}
            onChange={(e) => setNombreSubtarea(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAddSubtarea();
              }
            }}
          />
          {subtareaError && (
            <FormHelperText error>{subtareaError}</FormHelperText>
          )}
        </Box>
        <IconButton
          className={classes.addButton}
          color="primary"
          onClick={handleAddSubtarea}
          disabled={agregandoSubtarea}
        >
          {agregandoSubtarea ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <Add />
          )}
        </IconButton>
      </Box>
    </Paper>
  );
};

export default React.memo(SubtareasForm);
